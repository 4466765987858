import { atom, selector } from 'recoil';

// 유저 정보 Atom
export const userState = atom({
  key: 'userState',
  default: JSON.parse(sessionStorage.getItem('user')) || null
});

// 로그인 여부 Selector
export const isAuthenticatedState = selector({
  key: 'isAuthenticatedState',
  get: ({ get }) => {
    const user = get(userState);
    return !!user; // 유저 정보가 있으면 true
  }
});
