import React from 'react';

const PercentProgressBar = ({
  plot = [],
  bg1 = 'progress-gradient',
  bg2 = '#FFBE5C',
  labels = ['투자자산', '현금성자산'],
  labelsCenter = true
}) => {
  return (
    <div>
      <div className="relative">
        <div
          className="w-full h-10 rounded-[8px] relative flex justify-between"
          style={{ background: bg2 }}
        >
          <div
            className={`h-full rounded-s-[6px] relative bg-${bg1}`}
            style={{ width: `${plot[0]}%` }}
          >
            {plot[0] > 0 && (
              <p
                className={`text-white text-base leading-[100%] font-bold absolute top-1/2 ${labelsCenter ? 'left-4' : plot[0] > 22 ? 'right-4' : 'left-4'} transform -translate-y-1/2`}
              >
                {plot[0]}%
              </p>
            )}
          </div>
          {plot[2] && (
            <div
              className="h-full rounded-e-[6px] relative bg-[#EBEBEB]"
              style={{ width: `${plot[2]}%` }}
            />
          )}

          {labels[0] !== '투자자산' && plot[1] === 100 && (
            <p className="text-white text-base leading-[100%] font-bold absolute top-1/2 right-4 transform -translate-y-1/2">
              {plot[1]}%
            </p>
          )}
        </div>
      </div>
      {labelsCenter ? (
        <div className="flex justify-between mt-[10px] h-[17px]">
          <div className="flex items-center">
            <div
              className={`inline h-[12px] w-[12px] rounded-full bg-${bg1}`}
            />
            <span className="text-xs leading-[140%] ml-1">{labels[0]}</span>
          </div>
          <div className="flex items-center">
            <div
              className="inline h-[12px] w-[12px] rounded-full"
              style={{ background: bg2 }}
            />
            <span className="text-xs leading-[140%] ml-1">{labels[1]}</span>
          </div>
        </div>
      ) : (
        <div className="flex flex-col justify-between gap-[6px] mt-[10px]">
          {plot[0] > 0 && (
            <div className="flex items-center">
              <div
                className={`inline h-[12px] w-[12px] rounded-full bg-${bg1}`}
              />
              <span className="text-xs leading-[140%] ml-1">{labels[0]}</span>
            </div>
          )}
          <div className="flex items-center">
            <div
              className="inline h-[12px] w-[12px] rounded-full"
              style={{ background: bg2 }}
            />
            <span className="text-xs leading-[140%] ml-1">{labels[1]}</span>
          </div>
          {labels[2] && (
            <div className="flex items-center">
              <div className="inline h-[12px] w-[12px] rounded-full bg-[#EBEBEB]" />
              <span className="text-xs leading-[140%] ml-1">{labels[2]}</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PercentProgressBar;
