import React from 'react';
import ReactMarkdown from 'react-markdown';
import PieChartExample from '../../chart/PieChartExample';
import { formatKoreanCurrency } from '../../../util/formatKoreanCurrency';

const DebtChartReport = ({ data = {} }) => {
  const { plot = [], text = '' } = data;

  return (
    <div className="report-container">
      <section className="w-full p-6 pb-4">
        <div className="w-full h-[104px] justify-between items-center inline-flex mb-3">
          {/* 총자산 */}
          <div className="w-2/5 min-w-[108px] flex flex-col justify-center items-center h-full px-4 py-[18px] report-shadow rounded-lg">
            <p className="text-center mb-3 text-[14px] text-[#82817F] leading-[100%]">
              총자산 <span className="text-[10px]">(순자산+부채)</span>
            </p>
            <p className="text-center text-[16px] font-bold leading-[22px]">
              {formatKoreanCurrency(plot[0] * 10000)}원
            </p>
          </div>
          {/* 부채, 순자산 */}
          <div className="ml-2 w-3/5 min-w-[202px] h-full px-4 py-[18px] rounded-lg report-shadow flex-col">
            <div className="flex justify-between items-center">
              <p className="text-left text-sm leading-[140%] text-[#82817F]">
                <span className="text-base mr-[6px]">🏦</span>
                부채
              </p>
              <p className="text-right text-base font-semibold leading-[100%]">
                {formatKoreanCurrency(plot[1] * 10000)}원
              </p>
            </div>
            <div
              style={{
                width: '100%',
                textAlign: 'center',
                borderBottom: '1px solid #ebebeb',
                margin: '10px 0'
              }}
            />
            <div className="flex justify-between items-center">
              <p className="inline-flex items-center text-left text-sm leading-[140%] text-[#82817F]">
                <span className="text-base mr-[6px] mt-[1px]">💵</span>
                순자산
              </p>
              <p className="text-base font-semibold leading-[100%]">
                {formatKoreanCurrency(plot[2] * 10000)}원
              </p>
            </div>
          </div>
        </div>
        <div className="w-full px-6 pt-5 rounded-2xl report-shadow">
          <p className="text-base font-semibold leading-[100%] text-[#959493]">
            부채 구성
          </p>
          {plot[1] > 0 ? (
            <PieChartExample plot={[plot[4], plot[3]]} />
          ) : (
            <div className="mt-1 mb-5 p-6 flex flex-col justify-center items-center bg-[#FFF7E6] rounded-lg">
              <img
                src="/picture/icons/partying-face.png"
                alt="no debt"
                width={48}
                height={48}
              />
              <p className="text-base font-bold leading-[100%] mt-3">
                깨끗한 부채 상태를 가지고 있어요
              </p>
              <p className="text-xs leading-[100%] mt-2">
                부채를 활용한 목표를 세우기 좋은 상황이에요
              </p>
            </div>
          )}
        </div>
      </section>

      <section className="w-full pt-4 px-[30px] pb-6">
        <p className="text-[16px] font-bold leading-[100%] mb-3">
          <span className="mr-[6px]">🔍</span>
          재무 건강 상태를 체크해보세요
        </p>
        <ReactMarkdown className="text-[14px] font-normal leading-[170%]">
          {text}
        </ReactMarkdown>
      </section>
    </div>
  );
};

export default DebtChartReport;
