import React, { useState } from 'react';
import { useSetSurveyValue } from '../../hook/useSetSurveyValue';
import { formatKoreanCurrency } from '../../util/formatKoreanCurrency';
import { LoanInterestCalculator } from '../LoanInterestCalculator/LoanInterestCalculator';
export const PreviousAnswerSelfList = ({
  setIsNumberPadOn,
  setKeySelected,
  setForm,
  form,
  setIsChanged
}) => {
  // const [isSelfHouseLoanYN, setIsSelfHouseLoanYN] = useState(
  //   form.r_selfHouseLoanYN
  // );
  // const [isSelfHouseLoanType, setIsSelfHouseLoanType] = useState(
  //   form.r_selfHouseLoanType
  // );
  const [isCalculatorOpen, setIsCalculatorOpen] = useState(false);
  const setSurveyValue = useSetSurveyValue();

  const handleDropdownChange = (key, value) => {
    setIsChanged(true);
    setSurveyValue(key, value);
    setForm((prev) => ({ ...prev, [key]: value }));
    if (
      key === 'r_selfHouseLoanYN' &&
      value === true &&
      (form.r_selfHouseLoanType === '' || form.r_selfHouseLoanType === null)
    ) {
      setForm((prev) => ({ ...prev, r_selfHouseLoanType: '월세자금대출' }));
    }
    if (key === 'r_selfHousingType' && (value === '' || value === null)) {
      setForm((prev) => ({ ...prev, r_selfHousingType: '월세' }));
    }
  };

  const handleNumberPadOn = (key) => {
    setIsChanged(true);
    setIsNumberPadOn(true);
    setKeySelected(key);
  };

  const EditableField = ({
    label,
    suffix,
    value,
    onChange,
    prefix,
    isDropdown,
    options
  }) => {
    return (
      <div className="flex items-center justify-between pl-2 pr-1 pt-6 pb-4 border-b border-[#EBEBEB] last:border-none">
        <label className="text-[15px] font-medium leading-[140%] tracking-[0.3px] text-[#5B5A58]">
          {label}
        </label>
        <div className="flex items-center">
          {prefix && (
            <span className="mr-2 text-[16px] font-medium leading-[140%] tracking-[0.32px] text-[#82817F]">
              {prefix}
            </span>
          )}
          {!isDropdown ? (
            <button
              onClick={() => onChange()}
              className="text-right border-b border-[#A9A8A7] focus:outline-none w-24 text-[16px] font-medium leading-[140%] tracking-[0.32px]"
            >
              {value}
            </button>
          ) : (
            <select
              value={value}
              onChange={(e) => {
                const inputValue = e.target.value;
                const parsedValue =
                  inputValue === 'true'
                    ? true
                    : inputValue === 'false'
                      ? false
                      : inputValue;
                onChange(parsedValue);
              }}
              className="px-3 py-[6px] border rounded-md border-[#959493] text-left text-sm leading-[24px] tracking-[-0.154px] focus:outline-none"
            >
              {options.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          )}
          {suffix && (
            <span className="ml-2 text-[16px] font-medium leading-[140%] tracking-[-0.32px] text-[#82817F]">
              {suffix}
            </span>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="w-full">
      <EditableField
        key="r_selfAge"
        label="현재 나이"
        suffix="세"
        prefix="만"
        value={form.r_selfAge}
        onChange={() => handleNumberPadOn('r_selfAge')}
      />
      <EditableField
        key="r_selfIncome"
        label="세후 소득"
        suffix="원"
        prefix="월"
        value={new Intl.NumberFormat('ko-KR').format(form.r_selfIncome)}
        onChange={() => handleNumberPadOn('r_selfIncome')}
      />
      <EditableField
        key="r_selfSaving"
        label="저축 금액"
        suffix="원"
        prefix="월"
        value={new Intl.NumberFormat('ko-KR').format(form.r_selfSaving)}
        onChange={() => handleNumberPadOn('r_selfSaving')}
      />
      <EditableField
        key="r_selfRetirementAge"
        label="은퇴 시기"
        suffix="세"
        prefix="만"
        value={form.r_selfRetirementAge}
        onChange={(value) => handleDropdownChange('r_selfRetirementAge', value)}
      />
      <EditableField
        key="r_selfCashAsset"
        label="현금성 자산"
        suffix="원"
        value={new Intl.NumberFormat('ko-KR').format(form.r_selfCashAsset)}
        onChange={() => handleNumberPadOn('r_selfCashAsset')}
      />
      <EditableField
        key="r_selfInvestmentAsset"
        label="투자자산"
        suffix="원"
        value={new Intl.NumberFormat('ko-KR').format(
          form.r_selfInvestmentAsset
        )}
        onChange={() => handleNumberPadOn('r_selfInvestmentAsset')}
      />
      <EditableField
        key="r_selfHouseLoanYN"
        label="주거용 대출"
        isDropdown
        value={form.r_selfHouseLoanYN}
        options={[
          { label: '네, 있습니다', value: true },
          { label: '아니요, 없습니다', value: false }
        ]}
        onChange={(value) => handleDropdownChange('r_selfHouseLoanYN', value)}
      />
      {form.r_selfHouseLoanYN === true && (
        <EditableField
          key="r_selfHouseLoanType"
          label="대출 종류"
          isDropdown
          value={form.r_selfHouseLoanType}
          options={[
            { label: '월세자금대출', value: '월세자금대출' },
            { label: '전세자금대출', value: '전세자금대출' },
            { label: '주택담보대출', value: '주택담보대출' }
          ]}
          onChange={(value) =>
            handleDropdownChange('r_selfHouseLoanType', value)
          }
        />
      )}
      {form.r_selfHouseLoanYN === true &&
        (form.r_selfHouseLoanType === '월세자금대출' ||
          form.r_selfHouseLoanType === '전세자금대출') && (
          <EditableField
            key="r_selfDeposit"
            label={
              form.r_selfHouseLoanType === '월세자금대출'
                ? '월세 보증금'
                : '전세보증금'
            }
            suffix="원"
            value={new Intl.NumberFormat('ko-KR').format(form.r_selfDeposit)}
            onChange={() => handleNumberPadOn('r_selfDeposit')}
          />
        )}
      {form.r_selfHouseLoanYN &&
        form.r_selfHouseLoanType === '월세자금대출' && (
          <EditableField
            key="r_selfHouseMonthlyPayment"
            label="월세"
            suffix="원"
            value={new Intl.NumberFormat('ko-KR').format(
              form.r_selfHouseMonthlyPayment
            )}
            onChange={() => handleNumberPadOn('r_selfHouseMonthlyPayment')}
          />
        )}
      {form.r_selfHouseLoanYN === true &&
        (form.r_selfHouseLoanType === '월세자금대출' ||
          form.r_selfHouseLoanType === '전세자금대출') && (
          <EditableField
            key="r_selfHomeLoan"
            label="대출금"
            suffix="원"
            value={new Intl.NumberFormat('ko-KR').format(form.r_selfHomeLoan)}
            onChange={() => handleNumberPadOn('r_selfHomeLoan')}
          />
        )}
      {form.r_selfHouseLoanYN === true &&
        (form.r_selfHouseLoanType === '월세자금대출' ||
          form.r_selfHouseLoanType === '전세자금대출') && (
          <EditableField
            key="r_selfHousingExpense"
            label="이자"
            suffix="원"
            value={new Intl.NumberFormat('ko-KR').format(
              form.r_selfHousingExpense
            )}
            onChange={() => handleNumberPadOn('r_selfHousingExpense')}
          />
        )}
      {form.r_selfHouseLoanYN === true &&
        form.r_selfHouseLoanType === '주택담보대출' && (
          <div className="flex items-center justify-between pl-2 pr-1 pt-6 pb-4 border-b border-[#EBEBEB] last:border-none">
            <label className="text-[15px] font-medium leading-[140%] tracking-[0.3px] text-[#5B5A58]">
              <div>
                이자
                <button
                  onClick={() => {
                    setIsCalculatorOpen(true);
                  }}
                >
                  <span className="text-orange underline ml-3 text-xs">
                    이자 계산기
                  </span>
                </button>
              </div>
            </label>
            <div className="flex items-center">
              <button
                onClick={() =>
                  handleNumberPadOn(
                    'r_selfHousingExpense',
                    form.r_selfHousingExpense
                  )
                }
                className="text-right border-b border-[#A9A8A7] focus:outline-none w-24 text-[16px] font-medium leading-[140%] tracking-[0.32px]"
              >
                <span>{formatKoreanCurrency(form.r_selfHousingExpense)}</span>
              </button>
              <span className="ml-2 text-[16px] font-medium leading-[140%] tracking-[0.32px] text-[#82817F]">
                원
              </span>
            </div>
          </div>
        )}
      {form.r_selfHouseLoanYN === false && (
        <EditableField
          key="r_selfHousingType"
          label="주거 형태"
          isDropdown
          value={form.r_selfHousingType}
          options={[
            { label: '월세', value: '월세' },
            { label: '전세', value: '전세' },
            {
              label: '자가 혹은 부모님과 함께 거주',
              value: '자가 혹은 부모님과 함께 거주'
            }
          ]}
          onChange={(value) => handleDropdownChange('r_selfHousingType', value)}
        />
      )}
      {form.r_selfHouseLoanYN === false &&
        form.r_selfHousingType === '월세' && (
          <EditableField
            key="r_selfHouseMonthlyPayment"
            label="월세"
            suffix="원"
            value={new Intl.NumberFormat('ko-KR').format(
              form.r_selfHouseMonthlyPayment
            )}
            onChange={() => handleNumberPadOn('r_selfHouseMonthlyPayment')}
          />
        )}
      {form.r_selfHouseLoanYN === false &&
        form.r_selfHousingType === '전세' && (
          <EditableField
            key="r_selfDeposit"
            label="전세보증금"
            suffix="원"
            value={new Intl.NumberFormat('ko-KR').format(form.r_selfDeposit)}
            onChange={() => handleNumberPadOn('r_selfDeposit')}
          />
        )}
      <EditableField
        key="r_selfEtcDebt"
        label="기타 대출"
        suffix="원"
        value={new Intl.NumberFormat('ko-KR').format(form.r_selfEtcDebt)}
        onChange={() => handleNumberPadOn('r_selfEtcDebt')}
      />
      {isCalculatorOpen && (
        <div className="overlay bg-[#242424] bg-opacity-10 overflow-hidden">
          <div className="loan-calculator-container z-[100] absolute top-[54px] w-[94%] rounded-2xl bg-white overflow-auto ">
            <h2 className="px-6 pt-6 pb-2 border-b border-[#ECECEC] text-base font-bold leading-[24px] ">
              주택담보대출 이자 계산기
            </h2>
            <LoanInterestCalculator
              isSelfArg={true}
              setIsCalculatorOpen={setIsCalculatorOpen}
              setForm={setForm}
            />
          </div>
        </div>
      )}
    </div>
  );
};
