import React from 'react';
import ReactMarkdown from 'react-markdown';
import PieChartExample from '../../chart/PieChartExample';
import DSRProgressBar from '../component/DSRProgressBar';

const ApartmentDSRAnalysisReport = ({ data = {} }) => {
  const { plot = [], text = '' } = data;
  return (
    <div className="report-container">
      <section className="w-full pt-6 px-[30px] pb-2">
        <p className="text-lg font-bold leading-[100%] mb-3">📊 DSR 분석</p>
        <ReactMarkdown className="text-[14px] font-normal leading-[170%]">
          {text}
        </ReactMarkdown>
      </section>
      <section className="px-6 py-4 flex flex-col gap-4">
        <div className="w-full px-6 pt-5 rounded-2xl report-shadow">
          <p className="text-sm font-semibold leading-[100%] text-[#959493]">
            대출 상환 금액
          </p>
          <p className="text-lg font-bold leading-[100%] mt-2 mb-[-16px]">
            월 {plot[0]}만원
          </p>
          <PieChartExample
            plot={[plot[1], plot[2]]}
            labels={['아파트', '주거용']}
            unit="만원"
            backgroundColor={['#FF6319', '#793B8A']}
            hoverBackgroundColor={['#E8570F', '#6D327A']}
            defaultActiveIndex={0}
          />
        </div>

        <div className="w-full px-6 py-5 rounded-2xl report-shadow">
          {plot[4] > 40 ? (
            <p className="text-base font-bold leading-[140%]">
              DSR이 <span className="text-orange">{plot[3]}%를 초과</span>하여
              <br />
              대출 계획 조정이 필요해요
            </p>
          ) : (
            <p className="text-base font-bold leading-[140%]">
              DSR이 <span className="text-orange">{plot[3]}%를 넘지 않아</span>
              <br />
              대출 실행 가능성이 높아보여요
            </p>
          )}
          <DSRProgressBar plot={[plot[3], plot[4]]} label={plot[3] + '%'} />
        </div>
      </section>
    </div>
  );
};

export default ApartmentDSRAnalysisReport;
