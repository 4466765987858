import { useNavigate } from 'react-router-dom';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { config } from '../config';
import { surveyState } from '../recoil/atoms';
import { useFetchDeleteAnswer } from './useFetchDeleteAnswer';
import { useMoveToTheQuestion } from './useMoveToTheQuestion';
import { useSetSurveyValue } from './useSetSurveyValue';

export const useUndoQuestion = () => {
  const navigate = useNavigate();
  const [survey, setSurvey] = useRecoilState(surveyState);
  const moveToTheQuestion = useMoveToTheQuestion();
  const setSurveyValue = useSetSurveyValue();
  const resetSurvey = useResetRecoilState(surveyState);
  const fetchDeleteAnswer = useFetchDeleteAnswer();
  const { r_idx, r_allQuestions } = survey;
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const undoQuestion = async () => {
    console.log('r_idx: ', r_idx);
    let messages = survey.r_messages;
    const mlength = messages.length;
    let finberryCnt = 0;
    let moveToIdx = 0;
    console.log('r_idx: ', r_idx);
    if (
      survey.r_allQuestions[r_idx].questionId === config.GENDER_IDX ||
      survey.r_allQuestions[r_idx].questionId ===
        config.CHECK_REDO_SURVEY_IDX ||
      r_idx === config.END_IDX - 1 ||
      !survey.r_messages.some((message) => message.role === 'user')
    ) {
      resetSurvey();
      navigate('/');
      return;
    }

    for (let i = mlength - 1; i >= 0; i--) {
      if (messages[i].role === 'user') {
        await fetchDeleteAnswer(messages[i].content.questionId);
      } else if (messages[i].role === 'finberry') {
        finberryCnt++;
        if (finberryCnt === 2) {
          console.log('messages: ', messages, ',    i: ', i);
          moveToIdx = messages[i + 1].content.questionId;
          messages = messages.slice(0, i);
          console.log('messages2: ', messages);

          setSurvey((prevSurvey) => {
            return {
              ...prevSurvey,
              r_messages: messages
            };
          });
          moveToTheQuestion(moveToIdx);
          return;
        }
      }
    }
  };
  return undoQuestion;
};
