import { useRecoilValue } from 'recoil';
import api from '../api/api';
import { surveyState } from '../recoil/atoms';

export const useFetchDeleteAnswer = () => {
  const survey = useRecoilValue(surveyState);

  const fetchDeleteAnswer = async (questionId) => {
    console.log('deleting answer...');
    const response = await api.post('/answer/delete', {
      orderId: survey.r_orderId,
      questionId: questionId
    });
    console.log('response: ', response);
  };
  return fetchDeleteAnswer;
};
