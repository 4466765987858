import { useRecoilValue } from 'recoil';
import { config } from '../config';
import { surveyState } from '../recoil/atoms';
import { useAddMessage } from './useAddMessage';
import { useFetchApartmentList } from './useFetchApartmentList';
import { useFetchCarList } from './useFetchCarList';
import { useMoveToTheQuestion } from './useMoveToTheQuestion';
import { useSetSurveyValue } from './useSetSurveyValue';

export const useSetNumberpadValue = () => {
  const setSurveyValue = useSetSurveyValue();
  const fetchCarList = useFetchCarList();
  const fetchApartmentList = useFetchApartmentList();
  const moveToTheQuestion = useMoveToTheQuestion();
  const survey = useRecoilValue(surveyState);
  const addMessage = useAddMessage();
  const setNumberpadValue = async (questionId, inputValue, setInputValue) => {
    console.log('questionId:', questionId, 'inputValue:', inputValue);
    switch (questionId) {
      case config.AGE_IDX:
        console.log('AGE_IDX:', inputValue);
        setSurveyValue('r_age', inputValue);
        setSurveyValue('r_selfAge', inputValue);
        setSurveyValue('r_spouseAge', inputValue);
        break;

      case config.SELF_INCOME_IDX:
        console.log('SELF_INCOME_IDX:', inputValue);
        setSurveyValue('r_selfIncome', inputValue);
        break;

      case config.SELF_SAVINGS_IDX:
        console.log('SELF_SAVINGS_IDX:', inputValue);
        console.log('survey.r_selfIncome:', survey.r_selfIncome);
        if (parseInt(inputValue) > parseInt(survey.r_selfIncome)) {
          addMessage({
            questionText: '저축액이 너무 높습니다. 저축액을 다시 입력해주세요'
          });
          setInputValue('');
          return -1;
        }
        setSurveyValue('r_selfSaving', inputValue);
        break;

      case config.SELF_RETIREMENT_AGE_IDX:
        console.log('SELF_RETIREMENT_AGE_IDX:', inputValue);
        if (parseInt(inputValue) < parseInt(survey.r_age)) {
          addMessage({
            questionText:
              '퇴직 나이가 현재 나이보다 낮습니다. 나이를 다시 입력해주세요'
          });
          setInputValue('');
          return -1;
        }
        setSurveyValue('r_selfRetirementAge', inputValue);
        break;

      case config.SPOUSE_INCOME_IDX:
        console.log('SPOUSE_INCOME_IDX:', inputValue);
        setSurveyValue('r_spouseIncome', inputValue);
        break;

      case config.SPOUSE_SAVINGS_IDX:
        console.log('SPOUSE_SAVINGS_IDX:', inputValue);
        if (parseInt(inputValue) > parseInt(survey.r_spouseIncome)) {
          alert('저축액이 너무 높습니다.');
          setInputValue('');
          return -1;
        }
        setSurveyValue('r_spouseSaving', inputValue);
        break;
      case config.SPOUSE_AGE_IDX:
        console.log('SPOUSE_AGE_IDX:', inputValue);
        setSurveyValue('r_spouseAge', inputValue);
        break;

      case config.SPOUSE_RETIREMENT_AGE_IDX:
        console.log('SPOUSE_RETIREMENT_AGE_IDX:', inputValue);
        if (parseInt(inputValue) < parseInt(survey.r_spouseAge)) {
          addMessage({
            questionText:
              '퇴직 나이가 현재 나이보다 낮습니다. 나이를 다시 입력해주세요'
          });
          setInputValue('');
          return -1;
        }
        setSurveyValue('r_spouseRetirementAge', inputValue);
        break;

      case config.SELF_CASH_ASSET_IDX:
        console.log('SELF_CASH_ASSET_IDX:', inputValue);
        setSurveyValue('r_selfCashAsset', inputValue);
        break;

      case config.SPOUSE_CASH_ASSET_IDX:
        console.log('SPOUSE_CASH_ASSET_IDX:', inputValue);
        setSurveyValue('r_spouseCashAsset', inputValue);
        break;

      case config.SELF_INVESTMENT_ASSET_IDX:
        console.log('SELF_INVESTMENT_ASSET_IDX:', inputValue);
        setSurveyValue('r_selfInvestmentAsset', inputValue);
        break;

      case config.SPOUSE_INVESTMENT_ASSET_IDX:
        console.log('SPOUSE_INVESTMENT_ASSET_IDX:', inputValue);
        setSurveyValue('r_spouseInvestmentAsset', inputValue);
        break;

      //본인 집 관련 질문 시작
      case config.SELF_MONTHLY_PAY_DEPOSIT_IDX:
        console.log('SELF_MONTHLY_PAY_DEPOSIT_IDX:', inputValue);
        setSurveyValue('r_selfDeposit', inputValue);
        break;

      case config.SELF_HOUSE_LOAN_AMOUNT_IDX_1:
        console.log('SELF_HOUSE_LOAN_AMOUNT_IDX_1:', inputValue);
        setSurveyValue('r_selfHomeLoan', inputValue);
        break;

      case config.SELF_HOUSE_LOAN_INTEREST_IDX_1:
        console.log('SELF_HOUSE_LOAN_INTEREST_IDX_1:', inputValue);
        setSurveyValue('r_selfHousingExpense', inputValue);
        break;

      case config.SELF_HOUSE_LOAN_MONTHLY_PAYMENT_IDX:
        console.log('SELF_HOUSE_LOAN_MONTHLY_PAYMENT_IDX:', inputValue);
        setSurveyValue('r_selfHouseMonthlyPayment', inputValue);
        break;

      case config.SELF_JEONSE_DEPOSIT_IDX:
        console.log('SELF_JEONSE_DEPOSIT_IDX:', inputValue);
        setSurveyValue('r_selfDeposit', inputValue);
        break;

      case config.SELF_HOUSE_LOAN_AMOUNT_IDX_2:
        console.log('SELF_HOUSE_LOAN_AMOUNT_IDX_2:', inputValue);
        setSurveyValue('r_selfHomeLoan', inputValue);
        break;

      case config.SELF_HOUSE_LOAN_INTEREST_IDX_2:
        console.log('SELF_HOUSE_LOAN_INTEREST_IDX_2:', inputValue);
        setSurveyValue('r_selfHousingExpense', inputValue);
        break;

      case config.SELF_MONTHLY_PAY_AMOUNT_WO_LOAN_IDX:
        console.log('MONTHLY_PAY_AMOUNT_WO_LOAN_IDX:', inputValue);
        setSurveyValue('r_selfHouseMonthlyPayment', inputValue);
        break;

      case config.SELF_JEONSE_AMONT_WO_LOAN_IDX:
        console.log('JEONSE_AMOUNT_WE_LOAN_IDX:', inputValue);
        setSurveyValue('r_selfDeposit', inputValue);
        break;

      //배우자 집 관련 질문 시작
      case config.SPOUSE_MONTHLY_PAY_DEPOSIT_IDX:
        console.log('SPOUSE_MONTHLY_PAY_DEPOSIT_IDX:', inputValue);
        setSurveyValue('r_spouseDeposit', inputValue);
        break;

      case config.SPOUSE_HOUSE_LOAN_AMOUNT_IDX_1:
        console.log('SPOUSE_HOUSE_LOAN_AMOUNT_IDX_1:', inputValue);
        setSurveyValue('r_spouseHomeLoan', inputValue);
        break;

      case config.SPOUSE_HOUSE_LOAN_INTEREST_IDX_1:
        console.log('SPOUSE_HOUSE_LOAN_INTEREST_IDX:', inputValue);
        setSurveyValue('r_spouseHousingExpense', inputValue);
        break;

      case config.SPOUSE_HOUSE_LOAN_MONTHLY_PAYMENT_IDX:
        console.log('SPOUSE_HOUSE_MONTHLY_PAYMENT_IDX:', inputValue);
        setSurveyValue('r_spouseHouseMonthlyPayment', inputValue);
        break;

      case config.SPOUSE_JEONSE_DEPOSIT_IDX:
        console.log('SPOUSE_JEONSE_DEPOSIT_IDX:', inputValue);
        setSurveyValue('r_spouseDeposit', inputValue);
        break;

      case config.SPOUSE_HOUSE_LOAN_AMOUNT_IDX_2:
        console.log('SPOUSE_HOUSE_LOAN_AMOUNT_IDX_2:', inputValue);
        setSurveyValue('r_spouseHomeLoan', inputValue);
        break;

      case config.SPOUSE_HOUSE_LOAN_INTEREST_IDX_2:
        console.log('SPOUSE_HOUSE_LOAN_INTEREST_IDX_2:', inputValue);
        setSurveyValue('r_spouseHousingExpense', inputValue);
        break;

      case config.SPOUSE_MONTHLY_PAY_AMOUNT_WO_LOAN_IDX:
        console.log('SPOUSE_MONTHLY_PAY_AMOUNT_WO_LOAN_IDX:', inputValue);
        setSurveyValue('r_spouseHouseMonthlyPayment', inputValue);
        break;

      case config.SPOUSE_JEONSE_AMONT_WO_LOAN_IDX:
        console.log('SPOUSE_JEONSE_AMONT_WO_LOAN_IDX:', inputValue);
        setSurveyValue('r_spouseDeposit', inputValue);
        break;

      case config.SELF_ETC_DEBT_IDX:
        console.log('SELF_ETC_DEBT_IDX:', inputValue);
        setSurveyValue('r_selfEtcDebt', inputValue);
        break;

      case config.SPOUSE_ETC_DEBT_IDX:
        console.log('SPOUSE_ETC_DEBT_IDX:', inputValue);
        setSurveyValue('r_spouseEtcDebt', inputValue);
        break;

      case config.MARRIAGE_AFTER_IDX:
        console.log('MARRIAGE_AFTER_IDX:', inputValue);
        setSurveyValue('r_marriageYear', inputValue);
        break;

      case config.CAR_PURCHASE_YEAR_IDX:
        console.log('CAR_PURCHASE_YEAR_IDX:', inputValue);
        // if (parseInt(inputValue) < parseInt(survey.r_marriageYear)) {
        //   addMessage({
        //     questionText: '차량 구매 시기를 결혼 후로 설정해 주세요.'
        //   });
        //   setInputValue('');
        //   return -1;
        // }
        setSurveyValue('r_car_age', inputValue);
        break;

      case config.CAR_PRICE_IDX:
        console.log('CAR_PRICE_IDX:', inputValue);
        setSurveyValue('r_carPrice', inputValue);
        const result = await fetchCarList(inputValue);
        console.log('result:', result);
        if (result === -1) {
          addMessage({
            questionText:
              '해당 조건을 만족하는 차량이 없습니다. 다른 가격을 입력 해주세요.'
          });
          setInputValue('');
        }
        return result;

      case config.APT_PURCHASE_YEAR_IDX:
        console.log('APT_PURCHASE_YEAR_IDX:', inputValue);
        // if (parseInt(inputValue) < parseInt(survey.r_marriageYear)) {
        //   addMessage({
        //     questionText: '아파트 구매 시기를 결혼 후로 설정해 주세요.'
        //   });
        //   setInputValue('');
        //   return -1;
        // }
        setSurveyValue('r_apartment_age', inputValue);
        break;

      case config.APT_PRICE_IDX:
        console.log('APARTMENT_PRICE_IDX:', inputValue);
        setSurveyValue('r_apartmentPrice', inputValue);
        break;

      case config.APT_REGION1_IDX:
        console.log('APT_REGION1_IDX:', inputValue);
        setSurveyValue('r_apartmentAddress_1', inputValue);
        return result;
      case config.APT_REGION2_IDX:
        console.log('APT_REGION2_IDX:', inputValue);
        setSurveyValue('r_apartmentAddress_2', inputValue);
        const result2 = await fetchApartmentList(inputValue);
        console.log('result2:', result2);
        if (result2 === -1) {
          addMessage({
            questionText:
              '해당 조건을 만족하는 아파트가 없습니다. 다른 가격을 입력 해주세요.'
          });
          setInputValue('');
        }
        break;

      case config.CHILDREN_AGE_IDX:
        console.log('CHILDREN_AGE_IDX:', inputValue);
        if (
          parseInt(inputValue) <
          parseInt(survey.r_selfAge) + parseInt(survey.r_marriageYear)
        ) {
          addMessage({
            questionText:
              '결혼 후 나이를 설정해 주세요. 나이는 본인 나이 기준입니다.'
          });
          setInputValue('');
          return -1;
        }
        setSurveyValue('r_children_age', inputValue);
        break;

      case config.EXTRA_GOAL_MONEY_IDX:
        console.log('EXTRA_GOAL_MONEY_IDX:', inputValue);
        setSurveyValue('r_etc_goal_money', inputValue);
        break;

      case config.EXTRA_GOAL_YEAR_IDX:
        console.log('EXTRA_GOAL_YEAR_IDX:', inputValue);
        if (
          parseInt(inputValue) <
          parseInt(survey.r_age) + parseInt(survey.r_marriageYear)
        ) {
          // alert('목표 달성 나이가 현재 나이보다 낮습니다.');
          addMessage({
            questionText:
              '결혼 후 나이를 설정해 주세요. 나이는 본인 나이 기준입니다.'
          });
          setInputValue('');
          return -1;
        }
        setSurveyValue('r_etc_goal_year', inputValue);
        break;
    }
    return 0;
  };

  return setNumberpadValue;
};
