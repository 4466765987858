import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStep } from '../../hook/useStep';
import PasswordForm from '../../component/user/PasswordForm';
import { resetPassword } from '../../util/resetPassword';
import MobilePhoneFormWrapper from '../../component/user/MobilePhoneFormWrapper';

const STEPS = ['휴대전화번호', '비밀번호'];

const ResetPasswordPage = () => {
  const { currentStep, nextStep, prevStep } = useStep(0, STEPS.length);
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    plainPassword: ''
  });
  const [isReadyToSubmit, setIsReadyToSubmit] = useState(false);

  useEffect(() => {
    if (isReadyToSubmit) {
      handleSubmit(userData);
      setIsReadyToSubmit(false);
    }
  }, [isReadyToSubmit, userData]);

  const handleSubmit = async (userData) => {
    const result = await resetPassword(userData);

    if (result.success) {
      alert('비밀번호가 재설정되었습니다.');
      navigate('/user/login/mobile');
    } else {
      alert(`${result.error}`);
      window.location.reload();
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <MobilePhoneFormWrapper
            type="reset"
            nextStep={nextStep}
            setUserData={setUserData}
          />
        );
      case 1:
        return (
          <PasswordForm
            prevStep={prevStep}
            handleSubmit={handleSubmit}
            setUserData={setUserData}
            setIsReadyToSubmit={setIsReadyToSubmit}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <div className="h-[100dvh] w-full max-w-md">
      {renderStepContent(currentStep)}
    </div>
  );
};

export default ResetPasswordPage;
