import { useRecoilState } from 'recoil';
import { popupState } from '../recoil/popupStateAtom';
import { useFetchReportData } from './useFetchReportData';
import { useFetchReportSelection } from './useFetchReportSelection';
import { useFetchReportCashInvestment } from './useFetchReportCashInvestment';

export function useReportPopup() {
  const [isOpen, setIsOpen] = useRecoilState(popupState);
  const { fetchReportData } = useFetchReportData();
  const { sendSelectionRequest } = useFetchReportSelection();
  const { sendCashInvestmentRequest } = useFetchReportCashInvestment();

  const openPopup = async (orderId) => {
    await Promise.all([
      sendSelectionRequest({ orderId }),
      sendCashInvestmentRequest({ orderId }),
      fetchReportData({ orderId })
    ]);
    setIsOpen(true);
  };
  const closePopup = () => setIsOpen(false);

  return {
    isOpen,
    openPopup,
    closePopup
  };
}
