import React, { useState } from 'react';
import BackHeader from '../../component/header/BackHeader';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { surveyState } from '../../recoil/atoms';
import dayjs from 'dayjs';
import { formatKoreanCurrency } from '../../util/formatKoreanCurrency';
import { config } from '@fortawesome/fontawesome-svg-core';
import AlertModal from '../../component/AlertModal';
import { requestRefund } from '../../util/requestRefund';
import { addKakaoChannel } from '../../util/addKakaoChannel';

function isWithinSevenDays(createdAt) {
  if (!createdAt) {
    return false; // createdAt이 없으면 false 반환
  }
  const sevenDaysAgo = dayjs().subtract(7, 'days');
  return dayjs(createdAt).isAfter(sevenDaysAgo);
}

const PurchaseDetailPage = () => {
  const { id } = useParams();
  const survey = useRecoilValue(surveyState);
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);

  const product =
    survey.r_paymentHistories.find(
      (item) => item.paymentHistory.id === Number(id)
    ) || {};

  const { paymentHistory = {}, detail = {} } = product;
  const isRefundable =
    paymentHistory.isRefundable === true &&
    paymentHistory.isFinished === false &&
    isWithinSevenDays(paymentHistory.createdAt);

  const openAlertModal = (e) => {
    e.preventDefault();
    setIsAlertModalOpen(true);
  };

  const closeAlertModal = (e) => {
    e.preventDefault();
    setIsAlertModalOpen(false);
  };

  const handleRefund = async (e) => {
    e.preventDefault();

    const result = await requestRefund({
      userId: paymentHistory.userId,
      orderId: paymentHistory.orderId
    });
    if (result.success) {
      window.alert('환불 처리 되었습니다.');
      window.location.reload();
    } else {
      window.alert(result.error);
    }
  };

  return Object.keys(paymentHistory).length > 0 ? (
    <div className="relative max-w-md w-full h-[100dvh] overflow-hidden">
      {isAlertModalOpen && (
        <>
          <div className="bg-[#484644] bg-opacity-20 fixed top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 w-full h-full" />
          <AlertModal
            onClose={closeAlertModal}
            onClick={handleRefund}
            message={
              <div className="w-full flex flex-col gap-2 justify-center items-start">
                <p className="text-[20px] font-semibold leading-[30px]">
                  환불을 진행하시겠습니까?
                </p>
                <p className="text-[14px] font-normal leading-[20px] text-[#8A8685]">
                  환불 신청이 접수된 후, 내용을 확인하여 환불 절차를 진행합니다.
                  카드로 결제하신 경우, 환불 금액이 카드사에 따라 3-7일(영업일
                  기준) 내에 처리될 수 있습니다.
                </p>
              </div>
            }
            buttonText="환불 신청하기"
            subButtonText="닫기"
            flexButton={true}
          />
        </>
      )}
      <header className="sticky z-50 border-b border-[#ECECEC] text-black flex justify-center items-center">
        <div className="absolute left-0">
          <BackHeader className="flex items-center px-4" />
        </div>
        <h1 className="header-title font-bold leading-[28px] py-[10px]">
          구매 내역 상세
        </h1>
      </header>

      <div className="flex flex-col gap-2 h-[100dvh] pb-12 overflow-auto">
        <section className="flex flex-col gap-4 p-4">
          <div className="flex justify-between">
            <span className="text-sm font-semibold leading-[140%] tracking-[-0.28px] text-[#82817F]">
              {dayjs(paymentHistory.createdAt).format('YYYY.MM.DD')}
            </span>
          </div>
          <div>
            <div className="flex justify-between items-stretch gap-[18px]">
              <img src="/logo174.png" alt="logo" width={64} height={64} />
              <div className="w-full flex flex-col justify-between items-stretch">
                <h3 className="text-sm font-semibold leading-[140%] tracking-[-0.24px]">
                  {paymentHistory.productName}
                </h3>
                <span className="text-sm font-medium leading-[140%] tracking-[-0.28px] text-[#959493]">
                  주문번호 {paymentHistory.orderId}
                </span>
              </div>
            </div>
            {isRefundable && (
              <div className="mt-4 flex justify-between h-8 gap-2">
                <button
                  onClick={openAlertModal}
                  className="h-full w-full border border-[#D1D1D0] rounded-[5px] bg-[#F8F8F8]"
                >
                  <span className="text-sm font-semibold leading-[140%] tracking-[-0.28px]">
                    환불하기
                  </span>
                </button>
              </div>
            )}
          </div>
        </section>
        <section className="flex flex-col gap-2 p-4 pb-6">
          <h2 className="font-bold leading-[140%] tracking-[-0.32px]">
            결제 정보
          </h2>
          <p className="flex justify-between text-[15px] font-medium leading-[140%] tracking-[-0.3px]">
            <span>결제 일시</span>
            <span>
              {dayjs(paymentHistory.createdAt).format('YYYY.MM.DD HH:mm:ss')}
            </span>
          </p>
          <p className="flex justify-between text-[15px] font-medium leading-[140%] tracking-[-0.3px]">
            <span>결제 금액</span>
            <span>
              {formatKoreanCurrency(paymentHistory.amount) ||
                formatKoreanCurrency(config.FINBERRY_INFO.price)}
              원
            </span>
          </p>
          <p className="flex justify-between text-[15px] font-medium leading-[140%] tracking-[-0.3px]">
            <span>결제 수단</span>
            <span>{detail.type}</span>
          </p>
          <div className="pl-3 flex flex-col gap-2">
            <p className="text-xs font-medium leading-[140%] tracking-[-0.24px] text-[#959493]">
              {detail.type === '카드'
                ? `${detail.cardProvider} ${detail.cardNumber}`
                : detail.easyPayProvider}
              |{' '}
              {!!detail.installmentPlanMonths
                ? `${detail.installmentPlanMonths}개월`
                : '일시불'}
            </p>
            {detail.type === '카드' && (
              <p className="text-xs font-medium leading-[140%] tracking-[-0.24px] text-[#959493]">
                무이자 적용 여부는 카드사로 문의하시면 정확하게 확인할 수
                있습니다.
              </p>
            )}
          </div>
        </section>
        {paymentHistory.isRefunded && (
          <section className="flex flex-col gap-2 p-4 pb-6">
            <h2 className="font-bold leading-[140%] tracking-[-0.32px]">
              환불 요약 정보
            </h2>
            <p className="flex justify-between text-[15px] font-medium leading-[140%] tracking-[-0.3px]">
              <span>환불 완료 일시</span>
              <span>
                {dayjs(paymentHistory.updatedAt).format('YYYY.MM.DD HH:mm:ss')}
              </span>
            </p>
            <p className="flex justify-between text-[15px] font-medium leading-[140%] tracking-[-0.3px]">
              <span>환불 완료 금액</span>
              <span>
                {formatKoreanCurrency(paymentHistory.amount) ||
                  formatKoreanCurrency(config.FINBERRY_INFO.price)}
                원
              </span>
            </p>
            <p className="flex justify-between text-[15px] font-medium leading-[140%] tracking-[-0.3px]">
              <span>환불 수단</span>
              <span>{detail.type}</span>
            </p>
            <div className="pl-3 flex flex-col gap-2">
              <p className="text-xs font-medium leading-[140%] tracking-[-0.24px] text-[#959493]">
                {detail.type === '카드'
                  ? `${detail.cardProvider} ${detail.cardNumber}`
                  : detail.easyPayProvider}
                |{' '}
                {!!detail.installmentPlanMonths
                  ? `${detail.installmentPlanMonths}개월`
                  : '일시불'}
              </p>
            </div>
          </section>
        )}
        <section className="flex flex-col gap-2 p-4 pb-6">
          <div className="pb-2 border-b border-[#EBEBEB]">
            <h4 className="text-xs font-medium leading-[24px]">
              결제 취소 및 환불 안내
            </h4>
          </div>
          <div className="pb-2 border-b border-[#EBEBEB]">
            <ul className="list-disc ml-5">
              <li className="text-xs leading-[24px]">
                결제 후 7일 이내에 콘텐츠를 이용하지 않았을 경우, 전액 환불이
                가능합니다.
              </li>
              <li className="text-xs leading-[24px]">
                할인받은 금액은 환불 금액에서 제외되며, 환불 금액은 실제 결제
                금액을 기준으로 산정됩니다.
              </li>
              <li className="text-xs leading-[24px]">
                결제 후 제공된 콘텐츠가 기술적 문제로 인해 정상적으로 이용되지
                못한 경우, 확인 후 적절한 조치를 통해 환불이 진행됩니다.
              </li>
            </ul>
          </div>
          <p className="text-xs leading-[24px]">
            이 외의 문의사항 또는 도움이 필요하신 경우{' '}
            <button onClick={addKakaoChannel} className="text-orange underline">
              카카오톡 채널
            </button>
            로 문의해주세요.
          </p>
        </section>
      </div>
    </div>
  ) : (
    <div className="mt-14 p-3 text-sm">해당 구매 내역이 존재하지 않습니다.</div>
  );
};

export default PurchaseDetailPage;
