import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { useNavigate } from 'react-router-dom';
import ReactStars from 'react-rating-stars-component';
import TextareaAutosize from 'react-textarea-autosize';
import usePreventSwipe from '../../hook/usePreventSwipe';
import { useRecoilValue } from 'recoil';
import { surveyState } from '../../recoil/atoms';
import { sendReview } from '../../util/sendReview';
import ReportHeader from '../header/ReportHeader';
import PrimaryButton from '../button/PrimaryButton';
import AlertModal from '../AlertModal';

const REVIEW_COMMENTS = {
  1: '별로에요',
  2: '아쉬워요',
  3: '보통이에요',
  4: '괜찮아요',
  5: '최고에요'
};

const ReviewPopup = ({ closeReviewPopup }) => {
  const rootElement = document.getElementById('root'); // <div id="root"> 안에 렌더링
  usePreventSwipe('.popup-report-container'); // 터치 이동 방지 훅 호출
  const survey = useRecoilValue(surveyState);
  const navigate = useNavigate();
  const [rating, setRating] = useState(5);
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
  const [review, setReview] = useState('');

  const ratingChanged = (newRating) => {
    setRating(newRating);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value.length > 500) {
      return;
    }
    setReview(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      userId: Number(survey.r_userId),
      surveyId: survey.r_surveyId,
      reviewPoint: rating,
      reviewComment: review
    };
    const result = await sendReview(data);
    if (result.success) {
      setIsAlertModalOpen(true);
    } else {
      alert(`${result.error}`);
    }
  };

  const goToMain = (e) => {
    e.preventDefault();
    setIsAlertModalOpen(false);
    navigate('/');
    setTimeout(() => window.location.reload(), 100);
  };

  return ReactDOM.createPortal(
    <div
      className={`popup-report-container ${isAlertModalOpen ? 'static bg-[#484644] bg-opacity-20 z-00' : 'bg-white'}`}
    >
      {isAlertModalOpen && (
        <>
          <div className="bg-[#484644] bg-opacity-20 fixed top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 w-full h-full" />
          <AlertModal
            onClick={goToMain}
            message={
              <div className="w-full flex flex-col gap-2 justify-center items-center">
                <p className="text-[18px] font-medium leading-[100%] text-center">
                  리뷰 작성을 완료했어요
                </p>
                <p className="text-[13px] font-medium leading-[100%] text-[#82817F]">
                  소중한 후기 남겨주셔서 감사합니다
                </p>
              </div>
            }
            buttonText="메인으로 돌아가기"
          />
        </>
      )}
      <ReportHeader
        title="리뷰 작성하기"
        onClick={!isAlertModalOpen ? closeReviewPopup : undefined}
      />
      <div className="relative w-full max-w-md h-[100dvh] flex flex-col pt-11 text-[#484644]">
        <div className="px-6 pt-12 pb-4">
          <section className="pb-12 flex flex-col items-center gap-2 border-b border-[#EBEBEB]">
            <h2 className="text-[24px] font-light leading-[140%] text-center">
              핀베리와 함께한 경험은
              <br />
              어땠나요?
            </h2>
            <div className="flex flex-col gap-1">
              <ReactStars
                count={5}
                value={rating}
                onChange={ratingChanged}
                size={38}
                activeColor="#FF6319"
                color="#D1D1D0"
              />
              <p className="flex justify-center items-center text-orange font-semibold leading-[100%] gap-[6px]">
                <span>{rating}점</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="4"
                  height="4"
                  viewBox="0 0 4 4"
                  fill="none"
                >
                  <circle cx="2" cy="2" r="2" fill="#FF6319" />
                </svg>
                <span>{REVIEW_COMMENTS[rating]}</span>
              </p>
            </div>
          </section>
          <section className="pt-12 flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <p className="text-sm font-semibold leading-[100%]">
                리뷰 작성하기 (최소 50자 이상)
              </p>
              <p className="text-xs leading-[140%] text-[#82817F]">
                핀베리 팀의 성장을 위해서 다양한 의견 작성 부탁드립니다. 리뷰를
                작성해신 분들께 추첨을 통해 소정의 상품을 드립니다.
              </p>
            </div>
            <div className="w-full">
              <TextareaAutosize
                minRows={5}
                onChange={handleInputChange}
                value={review}
                maxLength={500}
                placeholder="리뷰는 최소 20자에서 최대 500자까지 작성 가능합니다. 서비스를 이용한 느낌을 솔직하게 작성해주세요."
                style={{
                  width: '100%',
                  outline: 'none',
                  fontSize: '14px',
                  lineHeight: '20px',
                  color: '#8A8685',
                  background: 'inherit',
                  padding: '10px',
                  borderRadius: '10px',
                  border: '1px solid #ECECEC'
                }}
              />
            </div>
          </section>
          <section className="pt-6 flex gap-3">
            <div className="w-full">
              <button
                onClick={closeReviewPopup}
                className="block w-full py-[11px] px-[10px] text-sm font-semibold rounded-[4px] border border-[#D1D1D0]"
              >
                취소하기
              </button>
            </div>
            <div className="w-full">
              <PrimaryButton
                onClick={handleSubmit}
                disabled={review.length < 20}
                className="rounded-[4px] pt-[12px] pb-[12px]"
              >
                제출하기
              </PrimaryButton>
            </div>
          </section>
        </div>
      </div>
    </div>,
    rootElement
  );
};

export default ReviewPopup;
