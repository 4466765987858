import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { config } from '../config';
import { useHandleMultipleAnswerClick } from '../hook/useHandleMultipleAnswerClick';
import { useHandleMultipleChoice } from '../hook/useHandleMultipleChoice';
import { useReplaceQuestion } from '../hook/useReplaceQuestion';
import { useShouldDisplayOption } from '../hook/useShouldDisplayOption';
import { surveyState } from '../recoil/atoms';
import '../style/MessageBubble.css';
import { ApartmentNamecard } from './ApartmentNamecard';
import { CarNamecard } from './CarNamecard';
import InfoIcon from './icon/InfoIcon';
import NameCard from './NameCard';
import { SelectedApartmentNamecard } from './SelectedApartmentNamecard';
import { SelectedCarNamecard } from './SelectedCarNamecard';

const MessageBubble = ({ role, question }) => {
  const overlay = document.querySelector('.chatbot-overlay');
  const [survey, setSurvey] = useRecoilState(surveyState);
  const [visibleSubText, setVisibleSubText] = useState({});
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const subTextRefs = useRef({});
  const replaceQuestion = useReplaceQuestion();
  const { r_isCashExplained, r_isInvestmentExplained } = survey;
  const shouldDisplayOption = useShouldDisplayOption();
  const navigate = useNavigate();

  const modifiedOptions = question?.options?.filter((option) => {
    if (
      r_isCashExplained &&
      option.optionText === '현금성 자산에 대해 설명 듣기'
    )
      return false;
    if (
      r_isInvestmentExplained &&
      option.optionText === '투자 자산에 대해 설명 듣기'
    )
      return false;
    return true;
  });

  const handleMultipleAnswerClick = useHandleMultipleAnswerClick();
  const handleMultipleChoice = useHandleMultipleChoice();

  const handleSubTextClick = (optionId) => {
    setVisibleSubText((prev) => {
      const newState = {};
      for (let key in prev) {
        newState[key] = false;
      }
      newState[optionId] = !prev[optionId];
      return newState;
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const openOptionId = Object.keys(visibleSubText).find(
        (key) => visibleSubText[key]
      );

      if (openOptionId && subTextRefs.current[openOptionId]) {
        if (!subTextRefs.current[openOptionId].contains(event.target)) {
          setVisibleSubText({});
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [visibleSubText]);

  useEffect(() => {
    // Tooltip 열릴 시 배경 흐림 처리
    if (overlay) {
      if (Object.values(visibleSubText).includes(true)) {
        overlay.classList.add('bg-custom'); // 클래스 추가
      } else {
        overlay.classList.remove('bg-custom'); // 클래스 제거
      }
    }
  }, [visibleSubText, overlay]);

  const handleSubmit = (option) => {
    question.questionType === 'MULTIPLE_ANSWER'
      ? handleMultipleAnswerClick(option)
      : handleMultipleChoice(option);
  };

  const renderMessageContent = () => {
    // console.log('questionId!!!:', question?.questionId);
    // console.log(
    //   'Apartment Again?:',
    //   question?.questionId === config.APARTMENT_PRICE_IDX &&
    //     !survey.r_isApartmentSearchOk
    // );
    let newQuestionText = replaceQuestion(question);

    if (question && role === 'assistant') {
      if (question.questionText === '@CAR_LIST') {
        if (survey.r_carList.length > 0) {
          return (
            <div className="carnamecard-message">
              <CarNamecard />
            </div>
          );
        } else {
          return (
            <div className="assistant-message">
              {String('조건을 다시 설정해보세요')}
            </div>
          );
        }
      }
      if (question.questionText === '@APT_LIST') {
        if (survey.r_apartmentList.length > 0) {
          return (
            <div className="apartmentnamecard-message">
              <ApartmentNamecard />
            </div>
          );
        } else {
          return (
            <div className="assistant-message">
              {String('조건을 다시 설정해보세요')}
            </div>
          );
        }
      }
      // if (question.questionText === '@SELF_NAMECARD') {
      //   return (
      //     <div className="carnamecard-message">
      //       <NameCard isSelf={true} />
      //     </div>
      //   );
      // }
      // if (question.questionText === '@SPOUSE_NAMECARD') {
      //   return (
      //     <div className="carnamecard-message">
      //       <NameCard isSelf={false} />
      //     </div>
      //   );
      // }
      if (question.questionText === '@CAR_NAMECARD') {
        return (
          <div className="carnamecard-message">
            <SelectedCarNamecard />
          </div>
        );
      }
      if (question.questionText === '@APT_NAMECARD') {
        return (
          <div className="carnamecard-message">
            <SelectedApartmentNamecard />
          </div>
        );
      }
      return (
        <>
          {question && question.questionText === '@SELF_NAMECARD' ? (
            <div className="carnamecard-message">
              <NameCard isSelf={true} />
            </div>
          ) : question && question.questionText === '@SPOUSE_NAMECARD' ? (
            <div className="carnamecard-message">
              <NameCard isSelf={false} />
            </div>
          ) : (
            <div className="assistant-message">{newQuestionText}</div>
          )}
          {question &&
            question.options &&
            Array.isArray(question.options) &&
            question.options.length > 0 && (
              <div
                className={
                  question.questionType === 'MULTIPLE_CHOICE_H'
                    ? 'options-container-horizontal'
                    : 'options-container-vertical'
                }
              >
                {modifiedOptions.map((option) => {
                  const isDisplay = shouldDisplayOption(option);
                  const isSubTextVisible = visibleSubText[option.id] || false; // Check if subtext is visible for this option

                  return isDisplay ? (
                    <div
                      key={option.id}
                      className="relative flex flex-row"
                      ref={(el) => (subTextRefs.current[option.id] = el)}
                    >
                      <button
                        onClick={() => handleSubmit(option)}
                        className="option-button"
                      >
                        {option.optionText}
                      </button>

                      {option.optionSubText &&
                        (option.questionId === config.SELF_DEBT_IDX ||
                          option.questionId === config.SPOUSE_DEBT_IDX) && (
                          <>
                            <button
                              onClick={() => handleSubTextClick(option.id)}
                              className="h-8"
                            >
                              <InfoIcon />
                            </button>
                            <div
                              className={`sub-text-container ${isSubTextVisible ? 'visible' : 'hidden'}`}
                            >
                              {option.optionSubText}
                            </div>
                          </>
                        )}
                    </div>
                  ) : null;
                })}
              </div>
            )}
        </>
      );
    } else if (role === 'finberry') {
      return (
        <div className="relative flex flex-row ">
          <img
            src="/picture/icons/logo-profile.png"
            alt="Profile Icon"
            className="finberry-profile"
          />
          <div className="finberry-tag">{question.questionText}</div>
        </div>
      );
    } else if (question && role === 'user') {
      return <div className="user-message">{String(question.optionText)}</div>;
    }
    return null;
  };

  return (
    <div
      className={`mb-1.5 ml-[45px] ${role === 'assistant' ? 'self-start' : 'self-end'}`}
    >
      {renderMessageContent()}
    </div>
  );
};
// const isSelf = question?.questionType === 'SELF_NAMECARD';
// if (
//   question?.questionId === config.APARTMENT_PRICE_IDX &&
//   !survey.r_isApartmentSearchOk
// ) {
//   console.log('Apartment Not Found');
//   return (
//     <div className="assistant-message">
//       {String(
//         '입력한 가격에 해당된 아파트가 없습니다. 다른 가격을 입력 해주세요.'
//       )}
//     </div>
//   );
// }
//     return (
//       <>
//         {question &&
//         (question.questionType === 'SPOUSE_NAMECARD' ||
//           question.questionType === 'SELF_NAMECARD') ? (
//           <div className="assistant-message">
//             <NameCard isSelf={isSelf} />
//           </div>
//         ) : question.questionText === 'SELECTED_CAR' ? (
//           <div className="carnamecard-message">
//             <SelectedCarNamecard />
//           </div>
//         ) : question.questionText === 'SELECTED_APARTMENT' ? (
//           <div className="carnamecard-message">
//             <SelectedApartmentNamecard />
//           </div>
//         ) : question.questionId === config.CAR_PRICE_IDX + 1 ? (
//           <div className="assistant-message">
//             {String(
//               question &&
//                 question.questionText.replace(
//                   'input',
//                   formatKoreanCurrency(survey.r_carPrice)
//                 )
//             )}
//           </div>
//         ) : question.questionId === config.EXTRA_GOAL_CONFIRM_IDX ? (
//           <div className="assistant-message">
//             {String(
//               question &&
//                 question.questionText
//                   .replace('input1', survey.r_etc_goal_year)
//                   .replace(
//                     'input2',
//                     formatKoreanCurrency(survey.r_etc_goal_money)
//                   )
//             )}
//           </div>
//         ) : question.questionId === config.CAR_NAMECARD_IDX ? (
//           <div className="carnamecard-message">
//             <CarNamecard />
//           </div>
//         ) : question.questionId === config.APARTMENT_NAMECARD_IDX ? (
//           <div className="apartmentnamecard-message">
//             <ApartmentNamecard />
//           </div>
//         ) : question.questionId === config.APARTMENT_JEONSE_SALE_IDX + 1 ? (
//           <div className="assistant-message">
//             {String(
//               question &&
//                 question.questionText.replace('input', survey.r_jeonseSale)
//             )}
//           </div>
//         ) : question.questionId === config.ADDRESS1_IDX + 1 ? (
//           <div className="assistant-message">
//             {String(
//               question &&
//                 question.questionText
//                   .replace('input1', survey.r_apartmentAddress_1)
//                   .replace('input2', survey.r_jeonseSale)
//             )}
//           </div>
//         ) : question.questionId === config.ADDRESS2_IDX + 1 ? (
//           <div className="assistant-message">
//             {String(
//               question &&
//                 question.questionText
//                   .replace(
//                     'input1',
//                     formatKoreanCurrency(survey.r_apartmentPrice)
//                   )
//                   .replace('input2', survey.r_apartmentAddress_2)
//             )}
//           </div>
//         ) : question.questionId === config.CAR_NAMECARD_IDX + 1 &&
//           survey.r_carList.length === 0 ? (
//           <div className="assistant-message">
//             {String('조건을 다시 설정해보세요')}
//           </div>
//         ) : question.questionId === config.APARTMENT_NAMECARD_IDX &&
//           survey.r_apartmentList.length === 0 ? (
//           <div className="assistant-message">
//             {String('조건을 다시 설정해보세요')}
//           </div>
//         ) : question.questionId === config.SELF_CASH_ASSET_IDX + 1 ||
//           question.questionId === config.SPOUSE_CASH_ASSET_IDX + 1 ||
//           question.questionId === config.SELF_INVESTMENT_ASSET_IDX + 1 ||
//           question.questionId ===
//             config.SPOUSE_INVESTMENT_ASSET_IDX + 1 ? null : (
//           <div className="assistant-message">
//             {String(question && question.questionText?.replace(/\\n/g, '\n'))}
//           </div>
//         )}

//         {question &&
//           question.options &&
//           Array.isArray(question.options) &&
//           question.options.length > 0 && (
//             <div
//               className={
//                 question.questionType === 'MULTIPLE_CHOICE_H'
//                   ? 'options-container-horizontal'
//                   : 'options-container-vertical'
//               }
//             >
//               {modifiedOptions.map((option) => {
//                 const isDisplay = shouldDisplayOption(option);
//                 const isSubTextVisible = visibleSubText[option.id] || false; // Check if subtext is visible for this option

//                 return isDisplay ? (
//                   <div
//                     key={option.id}
//                     className="relative flex flex-row"
//                     ref={(el) => (subTextRefs.current[option.id] = el)}
//                   >
//                     <button
//                       onClick={() => handleSubmit(option)}
//                       className="option-button"
//                     >
//                       {option.optionText}
//                     </button>

//                     {option.optionSubText &&
//                       (option.questionId === config.SELF_DEBT_IDX ||
//                         option.questionId === config.SPOUSE_DEBT_IDX) && (
//                         <>
//                           <button
//                             onClick={() => handleSubTextClick(option.id)}
//                             className="h-8"
//                           >
//                             <InfoIcon />
//                           </button>
//                           <div
//                             className={`sub-text-container ${isSubTextVisible ? 'visible' : 'hidden'}`}
//                           >
//                             {option.optionSubText}
//                           </div>
//                         </>
//                       )}
//                   </div>
//                 ) : null;
//               })}
//             </div>
//           )}
//       </>
//     );
//   } else if (role === 'finberry') {
//     return (
//       <div className="relative flex flex-row ">
//         <img
//           src="/picture/icons/logo-profile.png"
//           alt="Profile Icon"
//           className="finberry-profile"
//         />
//         <div className="finberry-tag">{question.questionText}</div>
//       </div>
//     );
//   } else if (question && role === 'user') {
//     return <div className="user-message">{String(question.optionText)}</div>;
//   }
//   return null;
// };

// return (
//   <div
//     className={`mb-1.5 ml-[45px] ${role === 'assistant' ? 'self-start' : 'self-end'}`}
//   >
//     {renderMessageContent()}
//   </div>
// );

export default MessageBubble;
