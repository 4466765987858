import { useNavigate } from 'react-router-dom';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { surveyState } from '../recoil/atoms';
// import { saveAnswer } from '../util/saveAnswer';
import { config } from '../config';
import { useAddMessage } from './useAddMessage';
import { useAddUserMessage } from './useAddUserMessage';
import { useDeleteSelectedOptions } from './useDeleteSelectedOptions';
import { useFetchAllQuestions } from './useFetchAllQuestions';
import { useFetchApartmentList } from './useFetchApartmentList';
import { useFetchPreviousAnswer } from './useFetchPreviousAnswer';
import { useMoveToNextQuestion } from './useMoveToNextQuestion';
import { useMoveToTheQuestion } from './useMoveToTheQuestion';
import { useReportPopup } from './useReportPopup';
import { useSaveAnswer } from './useSaveAnswer';
import { useSetMultipleChoiceValue } from './useSetMultipleChoiceValue';
import { useSetSurveyValue } from './useSetSurveyValue';
import { useSkipValueSet } from './useSkipValueSet';
import { useUpdateIsFinished } from './useUpdateIsFinished';
export const useHandleMultipleChoice = () => {
  const [survey, setSurvey] = useRecoilState(surveyState);
  const { r_selectedPurchase } = survey;
  const resetSurvey = useResetRecoilState(surveyState);
  const updateIsFinished = useUpdateIsFinished();
  const deleteSelectedOptions = useDeleteSelectedOptions();
  const skipValueSet = useSkipValueSet();
  const addUserMessage = useAddUserMessage();
  const addMessage = useAddMessage();
  const fetchAllQuestions = useFetchAllQuestions();
  const moveToNextQuestion = useMoveToNextQuestion();
  const moveToTheQuestion = useMoveToTheQuestion();
  const setSurveyValue = useSetSurveyValue();
  const fetchApartmentList = useFetchApartmentList();
  const navigate = useNavigate();
  const fetchPreviousAnswer = useFetchPreviousAnswer();
  const { openPopup } = useReportPopup();
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const saveAnswer = useSaveAnswer();
  const setMultipleChoiceValue = useSetMultipleChoiceValue();
  const handleMultipleChoice = async (option) => {
    console.log('option: ', option);
    if (
      option.questionId !== config.SELF_HOUSE_LOAN_CALCULATOR_IDX &&
      option.questionId !== config.SPOUSE_HOUSE_LOAN_CALCULATOR_IDX
    ) {
      console.log('deleteSelectedOptions');
      deleteSelectedOptions();
      setMultipleChoiceValue(option.questionId, option);
    }
    if (option.questionId === config.GENDER_IDX) {
      setSurveyValue(
        'r_gender',
        option.optionText === '남자' ? 'male' : 'female'
      );
      console.log('r_gender: ', survey.r_gender);
    }
    if (option.questionId === config.CHECK_REDO_SURVEY_IDX) {
      setSurveyValue('r_isRedoSurveyAsked', true);
      if (option.orderIndex === 1) {
        const response = await fetchPreviousAnswer();
        console.log('previous answer: ', response);
        setSurvey((prev) => {
          const updatedSurvey = { ...prev, ...response }; // Merge new data into the existing state
          // console.log('Updated survey state:', updatedSurvey);
          return updatedSurvey;
        });
        addUserMessage(option);

        setSurveyValue('r_previousAnswers', response);
        setSurveyValue('r_isPreviousAnswerPadVisible', true);
        return;
      } else {
        setSurveyValue('r_messages', [...survey.r_messages.slice(0, 3)]);
        moveToTheQuestion(config.GENDER_IDX);
      }
      return;
    }
    if (
      option.questionId === config.SELF_HOUSE_LOAN_CALCULATOR_IDX ||
      option.questionId === config.SPOUSE_HOUSE_LOAN_CALCULATOR_IDX
    ) {
      setSurveyValue('r_isCalculatorVisible', true);
      return;
    }
    addUserMessage(option);
    if (
      option.questionId === config.CAR_PURCHASE_CONFIRM_IDX &&
      !option.nextQuestionId
    ) {
      saveAnswer(
        config.CAR_SELECT_IDX,
        JSON.stringify(survey.r_selectedCar),
        null
      );
      saveAnswer(config.CAR_PRICE_IDX, survey.r_carPrice, null);
      saveAnswer(config.CAR_PURCHASE_YEAR_IDX, survey.r_car_age, null);

      if (r_selectedPurchase.some((item) => item.orderIndex === 2)) {
        moveToTheQuestion(config.APT_PURCHASE_TYPE_IDX);
      } else if (r_selectedPurchase.some((item) => item.orderIndex === 3)) {
        moveToTheQuestion(config.CHILDREN_AGE_IDX);
      } else if (r_selectedPurchase.some((item) => item.orderIndex === 4)) {
        moveToTheQuestion(config.EXTRA_GOAL_COMMENT_IDX);
      } else {
        moveToTheQuestion(config.END_IDX);
      }
    } else if (
      option.questionId === config.APT_PURCHASE_CONFIRM_IDX &&
      !option.nextQuestionId
    ) {
      saveAnswer(
        config.APT_PURCHASE_TYPE_IDX,
        survey.r_apartmenttPurchaseType,
        null
      );
      saveAnswer(
        config.APT_SELECT_IDX,
        JSON.stringify(survey.r_selectedApartment),
        null
      );
      saveAnswer(config.APT_PURCHASE_YEAR_IDX, survey.r_apartment_age, null);
      saveAnswer(config.APT_PRICE_IDX, survey.r_apartmentPrice, null);
      if (r_selectedPurchase.some((item) => item.orderIndex === 3)) {
        moveToTheQuestion(config.CHILDREN_AGE_IDX);
      } else if (r_selectedPurchase.some((item) => item.orderIndex === 4)) {
        moveToTheQuestion(config.EXTRA_GOAL_COMMENT_IDX);
      } else {
        moveToTheQuestion(config.END_IDX);
      }
    } else if (option.questionId === config.APT_PURCHASE_TYPE_IDX) {
      moveToTheQuestion(option.nextQuestionId);
    } else if (option.questionId === config.APT_REGION1_IDX) {
      setSurveyValue('r_apartmentAddress_1', option.optionText);
      moveToTheQuestion(option.nextQuestionId);
    } else if (option.questionId === config.APT_REGION2_IDX) {
      setSurveyValue('r_apartmentAddress_2', option.optionText);
      const result = await fetchApartmentList(option.optionText);
      console.log('result:  ', result, result.length, result.length === 0);
      if (result.length === 0 || result === -1) {
        addMessage({
          questionText: '해당 조건을 만족하는 아파트가 없습니다.'
        });
        moveToTheQuestion(config.APT_PRICE_IDX);
      } else {
        moveToNextQuestion();
      }
    } else if (option.questionId === config.END_IDX) {
      // openPopup();
      console.log('END_IDX');
      navigate('/');
    } else if (
      option.questionId === config.SELF_HOUSE_LOAN_CALCULATOR_IDX ||
      option.questionId === config.SPOUSE_HOUSE_LOAN_CALCULATOR_IDX
    ) {
      setSurveyValue('r_isCalculatorVisible', true);
    } else {
      moveToTheQuestion(option.nextQuestionId);
      saveAnswer(option.questionId, option.optionText, option.orderIndex);
    }
  };
  return handleMultipleChoice;
};
