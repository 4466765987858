import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  Filler,
  CategoryScale
} from 'chart.js';
import { formatChartLabel } from '../../util/formatChartLabel';
import { formatKoreanCurrency } from '../../util/formatKoreanCurrency';

ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  Filler,
  CategoryScale
);

const LineChartExample = ({ data1 = {}, data2 = {} }) => {
  const { ages, assets: assets1 } = data1;
  const { assets: assets2 } = data2;

  const data = {
    labels: ages,
    datasets: [
      {
        label: '부동산 포함한 순자산',
        data: assets1,
        borderColor: '#FFC941',
        borderWidth: 2,
        backgroundColor: '#FFC941',
        pointRadius: 0
      },
      {
        label: '부동산 제외한 순자산',
        data: assets2,
        borderColor: '#FF6319',
        borderWidth: 2,
        backgroundColor: '#FF6319',
        pointRadius: 0
      }
    ].filter(Boolean)
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          font: {
            size: 12
          },
          color: '#8A8685', // 범례 텍스트 색상
          boxWidth: 15, // 범례 박스 크기
          boxHeight: 2, // 박스 높이
          padding: 10 // 범례 간격
        }
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: (tooltipItem) => {
            return `${tooltipItem.dataset.label}: ${formatKoreanCurrency(Math.round(tooltipItem.raw / 100) * 100 * 1000)}원`;
          }
        }
      },
      datalabels: {
        display: false,
        align: 'top', // 데이터 값을 포인트 위로 배치
        color: '#8A8685', // 숫자 텍스트 색상
        font: {
          size: 10,
          weight: 'bold'
        },
        formatter: (value) => `${value}억`
      }
    },
    scales: {
      x: {
        type: 'linear',
        title: { display: true },
        grid: { display: false },
        min: data1?.ages?.[0] || 0,
        max: data1?.ages?.[data1?.ages.length - 1] || 0,
        offset: false,
        ticks: {
          color: '#8A8685',
          font: {
            size: 12
          },
          stepSize: 10
        }
      },
      y: {
        title: { display: true },
        grid: {
          display: true,
          borderDash: [0, 0],
          borderDashOffset: 0,
          drawBorder: false,
          lineWidth: 0.5
        },
        ticks: {
          color: '#8A8685',
          callback: (value) => formatChartLabel(value * 1000)
        },
        beginAtZero: false
      }
    }
  };

  return (
    <div style={{ width: '300px', height: '270px', margin: '0' }}>
      <Line data={data} options={options} />
    </div>
  );
};

export default LineChartExample;
