import { useRecoilState } from 'recoil';
import { config } from '../config';
import { surveyState } from '../recoil/atoms';
export const useSelectedOptionReset = () => {
  const [survey, setSurvey] = useRecoilState(surveyState);
  const selectedOptionReset = (questionId) => {
    switch (questionId) {
      case config.PURCHASE_IDX:
        setSurvey({ ...survey, r_selectedPurchase: [] });
        break;
      // case config.SELF_DEBT_IDX:
      //   setSurvey({ ...survey, r_selectedSelfDebt: [] });
      //   break;
      // case config.SPOUSE_DEBT_IDX:
      //   setSurvey({ ...survey, r_selectedSpouseDebt: [] });
      //   break;
    }
  };
  return selectedOptionReset;
};
