import React from 'react';
import ReactMarkdown from 'react-markdown';
import PercentProgressBar from '../component/PercentProgressBar';

const CarLoanReport = ({ data = {} }) => {
  const { plot = [], text = '' } = data;

  return (
    <div className="report-container">
      <section className="p-6 pb-4 flex flex-col">
        <div className="flex flex-col justify-between gap-6 px-6 py-5 report-shadow rounded-2xl">
          {plot[0] > 0 ? (
            <p className="text-base leading-[140%] text-[#82817F]">
              목표 시기에 구매하기 위해서
              <br />
              <span className="text-purple2 font-bold">
                {plot[0].toLocaleString()}만원의 대출이 발생
              </span>
              해요
            </p>
          ) : (
            <p className="text-base leading-[140%] text-[#82817F]">
              목표 시기에
              <br />
              <span className="text-purple2 font-bold">
                대출없이 구매할 수 있어요!
              </span>
            </p>
          )}

          <div className="w-full">
            <PercentProgressBar
              plot={[plot[1], plot[3]]}
              bg1="progress-purple-gradient"
              bg2="#D8C0DA"
              labels={[
                `대출금 ${plot[0]?.toLocaleString()}만원 (${plot[1]}%)`,
                `현금성 자산 ${plot[2]?.toLocaleString()}만원 (${plot[3]}%)`
              ]}
              labelsCenter={false}
            />
          </div>
        </div>
      </section>
      <section className="w-full pt-4 px-[30px] pb-6">
        <p className="text-[16px] font-bold leading-[100%] mb-3">
          <span className="mr-[6px]">🏛️</span>
          대출이 얼마나 필요할까요?
        </p>
        <ReactMarkdown className="text-[14px] font-normal leading-[24px]">
          {text}
        </ReactMarkdown>
      </section>
    </div>
  );
};

export default CarLoanReport;
