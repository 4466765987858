import React from 'react';

const FinalSecondReport = () => {
  return (
    <div className="report-container">
      <section className="p-6 flex flex-col gap-5">
        <div>
          <h2 className="text-xl leading-[140%]">
            진단 분석 결과 및 정보 관리에 대한
            <br />
            안내사항을 확인해주세요
          </h2>
        </div>
        <div className="flex flex-col gap-2">
          <h3 className="text-base leading-[100%] font-bold">진단의 제한성</h3>
          <p className="text-sm leading-[170%]">
            본 진단은 소득, 저축 등 제한된 정보를 바탕으로 사용자의 재무 상태를
            분석하고, 재무 목표 달성 가능성을 예측하여 제공합니다. 이 정보는
            재무 계획을 세울 때 참고할 수 있으나, 실제 실행 단계에서는 더 다양한
            정보를 바탕으로 한 구체적인 검토가 필요합니다.
            <br />
            제공된 결과는 사용자의 세부 지출, 투자 항목, 현금 흐름 등 모든
            변수를 고려하지 않고 최소한의 정보와 과거 데이터를 기반으로 분석된
            예측입니다. 따라서 실제 미래 상황과는 다를 수 있으며, 추후 새로운
            정보를 입력하거나 환경 변화에 따라 결과가 달라질 수 있습니다.
          </p>
        </div>
        <div className="flex flex-col gap-2">
          <h3 className="text-base leading-[100%] font-bold">
            개인정보에 대한 보호 및 관리 방안
          </h3>
          <p className="text-sm leading-[170%]">
            본 서비스를 통해 수집 및 생성된 개인정보는 진단 서비스 이외의
            목적으로 사용되지 않으며, ‘개인정보보호법’ 제23조에 근거하여 분실,
            도난, 유출, 위조, 변조 또는 훼손되지 않도록 관리하고 있습니다.
            <br />
            채팅을 통해 수집되고 생성된 개인 정보는 처리 목적의 달성 등
            개인정보가 불필요하게 되었을 때에는 지체없이 파기합니다.
            <br />
            다만, 다른 법령에 따라 보존하여야 하는 경우에는 그러하지 않을 수
            있습니다. 자세한 내용은 ‘개인정보정보처리방침’을 확인해주세요.
          </p>
        </div>
      </section>
    </div>
  );
};

export default FinalSecondReport;
