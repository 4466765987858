import React from 'react';
import ReactMarkdown from 'react-markdown';

function calculatePercentage(value) {
  const percentage = Math.min((value / 6) * 100, 100);
  return `${percentage.toFixed(2)}%`;
}

const EmergencyFundReport = ({ data = {} }) => {
  const { plot = [], text = '' } = data;
  const percentage = calculatePercentage(plot[2]);

  return (
    <div className="report-container">
      <section className="p-6 pb-4 flex flex-col">
        <div className="flex flex-col justify-between gap-6 px-6 py-5 report-shadow rounded-2xl">
          {plot[0] > 0 ? (
            <div>
              <p className="text-base font-bold leading-[140%]">비상 자금이</p>
              <p className="text-base font-bold leading-[140%]">
                <span className="text-orange">{plot[0]}개월치 </span>더
                필요해요!
              </p>
            </div>
          ) : (
            <div>
              <p className="text-base font-bold leading-[140%]">
                비상 자금 <span className="text-orange">6개월치 준비 완료</span>
              </p>
              <p className="text-base font-bold leading-[140%]">
                예기치 못한 상황에도 문제없어요!
              </p>
            </div>
          )}

          <div>
            <TotalProgressLabel />
            <div className="relative">
              <div className="w-full h-[30px] rounded-[8px] relative flex bg-[#FFE6B8]">
                <div
                  className={`h-full rounded-s-[8px] relative bg-progress-gradient ${plot[0] > 0 ? '' : 'rounded-e-[8px]'}`}
                  style={{ width: `${percentage}` }}
                />
              </div>
              <div
                className={`tooltip ${plot[0] > 0 ? 'translate-x-[-50%]' : 'end translate-x-[-100%]'} transform mt-[13px] bg-label-gradient`}
                style={{ left: `${percentage}` }}
              >
                현재 {plot[2]}개월치 준비
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="w-full pt-4 px-[30px] pb-6">
        <p className="text-[16px] font-bold leading-[100%] mb-3">
          <span className="mr-[6px]">⚠️</span>
          비상자금, 준비됐나요?
        </p>
        <ReactMarkdown className="text-[14px] font-normal leading-[170%]">
          {text}
        </ReactMarkdown>
      </section>
    </div>
  );
};

const TotalProgressLabel = () => {
  return (
    <div className="flex items-start w-full h-4 justify-between leading-[14px]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="14"
        viewBox="0 0 73 9"
        fill="none"
      >
        <path d="M1 9V1H73" stroke="#D1D1D0" />
      </svg>
      <p className="w-full text-sm font-normal leading-[70%] text-center text-[#959493]">
        비상 자금
        <span className="font-bold"> 6개월치 </span>
        마련
      </p>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="14"
        viewBox="0 0 73 9"
        fill="none"
      >
        <path d="M72 9V1H-5.36442e-07" stroke="#D1D1D0" />
      </svg>
    </div>
  );
};

export default EmergencyFundReport;
