import React, { useState } from 'react';
import UserLayout from './UserLayout';

const MIN_LENGTH = 8;
const MAX_LENGTH = 16;

const PasswordForm = ({ prevStep, setUserData, setIsReadyToSubmit }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // 비밀번호 보기 토글 상태
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // 비밀번호 확인 보기 토글 상태

  const hasMinLength = password.length >= MIN_LENGTH; // 최소 8자
  const hasUpperAndLower = /[A-Z]/.test(password) && /[a-z]/.test(password); // 영문 대소문자 혼합
  const hasNumber = /\d/.test(password); // 최소 한 개 숫자 포함
  const hasSpecialChar = /[@$!%*#?&]/.test(password); // 특수 문자 포함

  const regex =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,16}$/;

  // 비밀번호 유효성 검사
  const validatePassword = (password) => {
    if (!regex.test(password)) {
      if (!hasMinLength) {
        return `최소 ${MIN_LENGTH}자 이상 입력해주세요.`;
      }
      if (password.length > MAX_LENGTH) {
        return `최대 ${MAX_LENGTH}자까지 입력 가능합니다.`;
      }
      return '영문, 숫자, 특수문자를 포함해야 합니다.';
    }
    return '';
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);

    const error = validatePassword(value);
    setIsValid(!error);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const isConfirmPasswordValid =
    !!confirmPassword && password === confirmPassword;

  const onClickConfirm = (e) => {
    e.preventDefault();
    setUserData((prev) => ({ ...prev, plainPassword: password }));
    setIsReadyToSubmit(true);
  };

  return (
    <UserLayout
      title="비밀번호를 적어주세요!"
      description={`핀베리에 로그인하기 위해 사용할\n비밀번호를 입력해주세요.`}
      goToPrev={prevStep}
      onClick={onClickConfirm}
      disabled={!isValid || !isConfirmPasswordValid}
    >
      <div className="flex flex-col w-full">
        <div className="w-full mt-4 relative">
          <label className="text-sm text-grayDark3">비밀번호</label>
          <input
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={handlePasswordChange}
            placeholder="영문·숫자·특수문자를 포함한 8~16자"
            className={`w-full border outline-none ${
              password ? 'border-orange' : 'border-grayMid2'
            } rounded-lg px-4 py-2 text-sm text-[#82817F]`}
          />
          <button
            type="button"
            onClick={() => setShowPassword((prev) => !prev)}
            className="absolute right-3 top-1/2 transform"
          >
            {password ? (
              showPassword ? (
                <img
                  src="/picture/icons/eye-open.png"
                  alt="숨기기"
                  width={24}
                  height={24}
                />
              ) : (
                <img
                  src="/picture/icons/eye-off.png"
                  alt="보기"
                  width={24}
                  height={24}
                />
              )
            ) : (
              <></>
            )}
          </button>
        </div>
        <div className="w-full mt-4 relative">
          <label className="text-sm text-grayDark3">비밀번호 확인</label>
          <input
            type={showConfirmPassword ? 'text' : 'password'}
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            placeholder="비밀번호를 다시 입력해주세요"
            className={`w-full border outline-none ${
              confirmPassword ? 'border-orange' : 'border-grayMid2'
            } rounded-lg px-4 py-2 text-sm text-[#82817F]`}
          />
          <button
            type="button"
            onClick={() => setShowConfirmPassword((prev) => !prev)}
            className="absolute right-3 top-1/2 transform"
          >
            {confirmPassword ? (
              showConfirmPassword ? (
                <img
                  src="/picture/icons/eye-open.png"
                  alt="숨기기"
                  width={24}
                  height={24}
                />
              ) : (
                <img
                  src="/picture/icons/eye-off.png"
                  alt="보기"
                  width={24}
                  height={24}
                />
              )
            ) : (
              <></>
            )}
          </button>
        </div>
        {confirmPassword && !isConfirmPasswordValid && (
          <span className="text-[#EA534B] text-xs mt-1">비밀번호 불일치</span>
        )}
        <div className="py-6 px-4 text-xs text-grayDark2">
          <p className={`${hasMinLength ? 'text-[#62BBB1]' : ''}`}>최소 8자</p>
          <p className={`${hasUpperAndLower ? 'text-[#62BBB1]' : ''}`}>
            영문 대소문자 혼합
          </p>
          <p className={`${hasNumber ? 'text-[#62BBB1]' : ''}`}>
            최소한 한 개 이상 숫자 포함
          </p>
          <p className={`${hasSpecialChar ? 'text-[#62BBB1]' : ''}`}>
            !, @, #, $, %, ^, &, * 등과 같은 특수 문자 포함
          </p>
        </div>
      </div>
    </UserLayout>
  );
};

export default PasswordForm;
