import ktorApi from '../api/ktorApi';
import { useSetSurveyValue } from './useSetSurveyValue';

export const useFetchReportSelection = () => {
  const setSurveyValue = useSetSurveyValue();
  const sendSelectionRequest = async ({ orderId }) => {
    try {
      const response = await ktorApi.post('/selections', {
        orderId
      });
      if (response.data) {
        setSurveyValue('r_report_selections', response.data || []);
      } else {
        console.error('Empty response data');
      }
    } catch (error) {
      console.error('Error fetching report selections:', error);
    }
  };

  return { sendSelectionRequest };
};
