import { useRecoilState } from 'recoil';
import { userState } from '../recoil/userAtom';
import { fetchUserInfo } from '../util/fetchUserInfo';
import { useSetSurveyValue } from './useSetSurveyValue';

export const useFetchUser = () => {
  const [user, setUser] = useRecoilState(userState);
  const setSurveyValue = useSetSurveyValue();

  const initializeUser = async () => {
    // 1. 로컬 스토리지 복원
    const storedUser = JSON.parse(sessionStorage.getItem('user'));
    if (storedUser) {
      setUser(storedUser);
      setSurveyValue('r_userId', storedUser.id);
      setSurveyValue('r_orderId', storedUser.orderId);
    }
    // 2. /me API 호출로 최신 정보 동기화
    try {
      const result = await fetchUserInfo(); // /me API 호출
      if (result.success) {
        console.log(result.data);
        setUser(result.data);
        sessionStorage.setItem('user', JSON.stringify(result.data));

        setSurveyValue('r_userId', result.data.userId);
        setSurveyValue('r_name', result.data.fullName);
        const paymentHistories = [
          ...result.data.paymentHistoriesWithDetail
        ].sort(
          (a, b) =>
            new Date(b.paymentHistory.createdAt) -
            new Date(a.paymentHistory.createdAt)
        );
        paymentHistories.forEach((item) => {
          if (!item.paymentHistory.isFinished) {
            console.log('r_orderId: ', item.paymentHistory.orderId);
            setSurveyValue('r_orderId', item.paymentHistory.orderId);
          }
        });
        setSurveyValue('r_paymentHistories', paymentHistories);
        setSurveyValue('r_reviewed', result.data.reviewed);
      } else {
        // 유저 정보 초기화
        setUser(null);
        sessionStorage.removeItem('user');
        setSurveyValue('r_userId', 0);
        setSurveyValue('r_paymentHistories', []);
        setSurveyValue('r_reviewed', false);
      }
    } catch (error) {
      console.error('유저 정보 업데이트 실패:', error);
      setUser(null);
      sessionStorage.removeItem('user');
      setSurveyValue('r_userId', 0);
      setSurveyValue('r_paymentHistories', []);
      setSurveyValue('r_reviewed', false);
      // setSurveyValue('r_orderId', '');
    }
  };

  return { user, initializeUser };
};
