import React from 'react';
import ReactMarkdown from 'react-markdown';
import { formatKoreanCurrency } from '../../../util/formatKoreanCurrency';
import DonutChart from '../../chart/DobutChart';

const ExtraGoalsReport = ({ data = {} }) => {
  const { plot = [], text = '' } = data;

  const chartPlot = (value) => {
    return [value, 100 - value];
  };

  return (
    <div className="report-container">
      <section className="w-full p-6 pb-2">
        <div className="flex justify-start items-start gap-6">
          <div>
            <p className="text-lg font-bold leading-[100%]">🎯 기타 목표</p>
          </div>
          <div>
            <p className="flex gap-3">
              <span className="text-sm leading-[140%] text-[#959493]">
                금액
              </span>
              <span className="text-sm font-medium leading-[140%]">
                {formatKoreanCurrency(plot[0] * 10000)}원
              </span>
            </p>
            <p className="flex gap-3">
              <span className="text-sm leading-[140%] text-[#959493]">
                시기
              </span>
              <span className="text-sm font-medium leading-[140%]">
                결혼 {plot[1]}년 후 ({plot[2]}세)
              </span>
            </p>
          </div>
        </div>
      </section>
      <section className="px-6 py-4 pb-[10px]">
        <div className="p-6 report-shadow rounded-3xl">
          <div>
            <p className="text-base font-bold leading-[140%]">
              대출 없이 목표를 달성할 가능성은
              <br />
              <span className="text-purple2">{plot[3]}%</span>입니다
            </p>
          </div>
          <div className="flex justify-center">
            <DonutChart plot={chartPlot(plot[3])} color="purple" />
          </div>
        </div>
      </section>
      <section className="w-full pt-4 px-[30px] pb-6">
        <ReactMarkdown className="text-[14px] font-normal leading-[170%]">
          {text}
        </ReactMarkdown>
      </section>
    </div>
  );
};

export default ExtraGoalsReport;
