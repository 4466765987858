import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { surveyState } from '../../recoil/atoms';
import dayjs from 'dayjs';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import BackHeader from '../../component/header/BackHeader';
import RightArrowIcon from '../../component/icon/RightArrowIcon';
import { formatKoreanCurrency } from '../../util/formatKoreanCurrency';
import { config } from '../../config';

const PurchaseListPage = () => {
  const survey = useRecoilValue(surveyState);
  const userProducts = survey.r_paymentHistories;
  const sortedProducts = [...userProducts].sort((a, b) =>
    dayjs(b.paymentHistory.updatedAt || b.paymentHistory.createdAt).diff(
      dayjs(a.paymentHistory.updatedAt || a.paymentHistory.createdAt)
    )
  );

  // 구매 상태에 따른 텍스트와 스타일 반환
  const getPurchaseStatus = (isRefunded) => ({
    text: isRefunded ? '환불 완료' : '구매 완료',
    amountPrefix: isRefunded ? '-' : ''
  });

  // 개별 구매 아이템 컴포넌트
  const PurchaseItem = ({ product = {} }) => {
    const navigate = useNavigate();
    const { paymentHistory = {} } = product;
    const { text, amountPrefix } = getPurchaseStatus(paymentHistory.isRefunded);
    const formattedAmount =
      formatKoreanCurrency(paymentHistory.amount) ||
      formatKoreanCurrency(config.FINBERRY_INFO.price);

    const handleDetailClick = () => {
      navigate(`/member/purchase/${paymentHistory.id}`);
    };

    return (
      <div className="mt-4 flex flex-col gap-4 p-4 border-[1.5px] border-[#F8F8F8] rounded-lg">
        <div className="flex justify-between">
          <span className="text-sm font-semibold leading-[140%] tracking-[-0.28px] text-[#82817F]">
            {paymentHistory?.createdAt
              ? dayjs(paymentHistory.createdAt).format('YYYY.MM.DD')
              : ''}
          </span>
          <button
            onClick={handleDetailClick}
            className="flex items-center text-sm font-semibold leading-[140%] tracking-[-0.28px] text-[#82817F]"
          >
            상세보기
            <RightArrowIcon size={16} className="text-[#82817F]" />
          </button>
        </div>
        <div>
          <p className="text-xs font-semibold leading-[140%] tracking-[-0.24px]">
            {text}
          </p>
          <div className="flex justify-between items-stretch gap-[18px]">
            <img src="/logo174.png" alt="logo" width={64} height={64} />
            <div className="w-full flex flex-col justify-between items-stretch">
              <h3 className="text-sm font-semibold leading-[140%] tracking-[-0.3px]">
                {paymentHistory.productName}
              </h3>
              <span className="text-sm font-semibold leading-[140%] tracking-[-0.3px] text-[#82817F]">
                {amountPrefix}
                {formattedAmount}원
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // 구매 내역 필터링 및 렌더링
  const renderTabPanel = (filterFn) => {
    const filteredProducts = sortedProducts.filter(filterFn);
    if (filteredProducts.length === 0) {
      return <div className="p-3 text-sm">해당 조건의 내역이 없습니다.</div>;
    }
    return filteredProducts.map((product) => (
      <PurchaseItem key={product.paymentHistory.id} product={product} />
    ));
  };

  return (
    <div className="relative max-w-md w-full h-[100dvh] overflow-hidden pb-12">
      <header className="sticky z-50 border-b border-[#ECECEC] text-black flex justify-center items-center">
        <div className="absolute left-0">
          <BackHeader className="flex items-center px-4" />
        </div>
        <h1 className="header-title font-bold leading-[28px] py-[10px]">
          구매 내역
        </h1>
      </header>

      <div className="p-4 pt-6 whitespace-pre-line text-black text-xs font-normal leading-[24px] overflow-y-auto h-full">
        <TabGroup>
          <TabList className="w-fit justify-start items-center inline-flex gap-2">
            <Tab className="px-4 py-[6px] outline-none border rounded-full text-[15px] font-normal leading-[20px] border-[#D1D1D0] data-[selected]:bg-white data-[selected]:border-black">
              전체
            </Tab>
            <Tab className="px-4 py-[6px] outline-none border rounded-full text-[15px] font-normal leading-[20px] border-[#D1D1D0] data-[selected]:bg-white data-[selected]:border-black">
              취소/환불
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel className="flex flex-col gap-4">
              {renderTabPanel(() => true)}
            </TabPanel>
            <TabPanel className="flex flex-col gap-4">
              {renderTabPanel((product) => product.paymentHistory.isRefunded)}
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </div>
    </div>
  );
};

export default PurchaseListPage;
