import React from 'react';
import ReactMarkdown from 'react-markdown';
import ProgressBar from '../component/ProgressBar';
import { formatKoreanCurrency } from '../../../util/formatKoreanCurrency';
import { calculatePercentageOfTotal } from '../../../util/calculatePercentage';

const RetirementSustainabilityReport = ({ data = {} }) => {
  const { plot = [], text = '', text2 = '' } = data;
  const percentage = calculatePercentageOfTotal([plot[1], plot[2]]);
  const isFull = Number(percentage.replace('%', '')) >= 100;

  return (
    <div className="report-container">
      <section className="px-[30px] pt-6 pb-2">
        <div className="flex flex-col gap-3">
          <h1 className="text-[16px] font-bold leading-[100%]">
            <span className="mr-[6px]">⏰</span>
            은퇴 생활 지속력 체크
          </h1>
          <ReactMarkdown className="text-[14px] font-normal leading-[170%]">
            {text}
          </ReactMarkdown>
        </div>
      </section>
      <section className="px-6 py-4">
        <div className="px-6 py-5 report-shadow rounded-2xl">
          <div className="flex flex-col gap-5">
            <p className="text-base leading-[140%] text-[#82817F]">
              은퇴 후 현금(현금성자산+투자자산)이
              <br />
              <span className="text-orange font-bold">
                {isFull
                  ? `${formatKoreanCurrency(plot[1] * 10000)} 원으로 충분해요`
                  : `${formatKoreanCurrency(plot[0] * 10000)} 원 더 필요해요`}
              </span>
            </p>
            <ProgressBar
              plot={[
                formatKoreanCurrency(plot[1] * 10000),
                formatKoreanCurrency(plot[2] * 10000)
              ]}
              bg1="progress-gradient"
              bg2="#FFE6B8"
              percentage={percentage}
              isFull={isFull}
              showPlot2={false}
            >
              <div className="flex flex-col mt-[10px] gap-1">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="inline h-[12px] w-[12px] rounded-full bg-progress-gradient" />
                    <p className="text-xs leading-[140%] ml-1">
                      은퇴 시 주택자금 제외한 예상 순자산
                    </p>
                  </div>
                  <p className="text-xs font-semibold leading-[140%] text-end">
                    {formatKoreanCurrency(plot[1] * 10000)}원
                  </p>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="inline h-[12px] w-[12px] rounded-full bg-[#FFE6B8]" />
                    <span className="text-xs leading-[140%] ml-1">
                      은퇴 시 필요한 자금
                    </span>
                  </div>
                  <span className="text-xs font-semibold leading-[140%]">
                    {formatKoreanCurrency(plot[2] * 10000)}원
                  </span>
                </div>
              </div>
            </ProgressBar>
          </div>
        </div>
      </section>
      <section className="px-[30px] pt-2 pb-6">
        <ReactMarkdown className="text-[14px] font-normal leading-[170%]">
          {text2}
        </ReactMarkdown>
      </section>
    </div>
  );
};

export default RetirementSustainabilityReport;
