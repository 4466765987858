import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AlertModal from '../../component/AlertModal';
import PrimaryButton from '../../component/button/PrimaryButton';
import BackHeader from '../../component/header/BackHeader';
import { useFetchUser } from '../../hook/useFetchUser';
import { login } from '../../util/login';

const MAX_PHONE_LENGTH = 11;

const MobileLoginPage = () => {
  const navigate = useNavigate();
  const { initializeUser } = useFetchUser();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [alertModalMessage, setAlertModalMessage] = useState(
    `입력하신 정보에 해당하는 계정을 찾을 수 없습니다. 휴대전화번호 또는 비밀번호를\n확인해 주세요.`
  );
  const [isRememberMeChecked, setIsRememberMeChecked] = useState(false);

  const isButtonDisabled = !phoneNumber || !password;

  const handleRememberMe = (e) => {
    setIsRememberMeChecked((prev) => !prev);
  };

  const onChangePhoneNumber = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    setPhoneNumber(value);
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const userData = {
      phoneNum: phoneNumber,
      plainPassword: password
    };

    const result = await login(userData, initializeUser, setAlertModalMessage);
    console.log('result:', result);
    if (result.success) {
      alert('로그인이 성공적으로 완료되었습니다.');
      navigate('/');
    } else {
      setShowAlertModal(true);
    }
  };

  const handleModalClose = () => {
    setShowAlertModal(false);

    if (alertModalMessage.includes('로그인 오류 횟수가 5회를 초과했습니다')) {
      navigate('/user/resetpassword');
    }
  };

  return (
    <div
      className={`h-[100dvh] w-full max-w-md ${showAlertModal ? 'pointer-events-none bg-[#e9e9e9]' : ''}`}
    >
      {showAlertModal && (
        <AlertModal
          onClick={handleModalClose}
          message={alertModalMessage}
          className="top-[34%] left-1/2 transform -translate-x-1/2"
        />
      )}
      <div className="flex flex-col h-[100dvh] px-6 py-8">
        <BackHeader className="left-[-11px]" />
        <form onSubmit={handleSubmit} className="flex-1">
          <div className="pt-6">
            <div className="w-auto h-8 mb-1">
              <img
                src="/picture/finberry.png"
                alt="finberry"
                className="w-auto h-full"
              />
            </div>
          </div>
          {/* 휴대폰 번호 */}
          <div className="w-full">
            <div className="w-full mt-4">
              <input
                type="text"
                inputMode="tel"
                maxLength={MAX_PHONE_LENGTH}
                value={phoneNumber}
                onChange={(e) => onChangePhoneNumber(e)}
                placeholder="휴대전화번호"
                className={`outline-none p-3 text-[14px] leading-normal tracking-[-0.28px] text-grayDark3 placeholder-grayDark2 w-full justify-start items-center gap-3 flex border rounded-lg bg-inherit ${
                  phoneNumber
                    ? 'border-orange font-semibold'
                    : 'border-grayMid2 font-normal'
                }`}
              />
            </div>
          </div>
          {/* 비밀번호 */}
          <div className="w-full">
            <div className="w-full mt-2">
              <input
                type="password"
                value={password}
                onChange={handlePasswordChange}
                placeholder="비밀번호"
                className={`outline-none p-3 text-[14px] leading-normal tracking-[-0.28px] text-grayDark3 placeholder-grayDark2 w-full justify-start items-center gap-3 flex border rounded-lg bg-inherit ${
                  password
                    ? 'border-orange font-semibold'
                    : 'border-grayMid2 font-normal'
                }`}
              />
            </div>
          </div>

          {/* <div className="mt-4">
            <label className="round-checkbox mb-0">
              <input
                type="checkbox"
                checked={isRememberMeChecked}
                onChange={(e) => handleRememberMe(e)}
                className="checkbox-input"
              />
              <span className="text-xs text-black font-normal leading-[20px]">
                로그인 상태 유지하기
              </span>
            </label>
          </div> */}

          <div className="w-full h-10 mt-6">
            <PrimaryButton
              type="submit"
              disabled={isButtonDisabled}
              className="outline-none text-base font-semibold rounded-lg pt-0 pb-0 h-full"
            >
              로그인
            </PrimaryButton>
          </div>

          <div className="flex mt-6 justify-between">
            <Link to="/user/signup">
              <span className="text-sm font-medium text-grayDark2 leading-[21px]">
                회원가입
              </span>
            </Link>
            <Link to="/user/resetpassword">
              <span className="text-sm font-medium text-grayDark2 leading-[21px]">
                비밀번호 재설정
              </span>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MobileLoginPage;
