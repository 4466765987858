const PrimaryButton = ({
  onClick = () => {},
  children,
  className = '',
  disabled = false
}) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`block w-full py-[10px] px-[10px] rounded-[12px] text-sm font-semibold ${disabled ? 'text-grayDark3 bg-[#EBEBEB]' : 'text-white bg-orange'} ${className}`}
    >
      {children}
    </button>
  );
};

export default PrimaryButton;
