import { atom, selector } from 'recoil';
import { config } from '../config';

export const surveyState = atom({
  key: 'surveyState',
  default: {
    r_surveyId: 1,
    r_paymentHistories: [],
    r_idx: config.START_IDX,
    r_messages: [],
    r_allQuestions: [],
    r_allQuestions2: [],
    r_currentQuestion: null,
    r_orderId: '',
    r_hasAgreedPayment: false,
    r_name: '',
    r_spouse_name: '',
    r_selectedPurchase: [],
    r_carList: [],
    r_apartmentList: [],

    r_sessionId: '0',
    r_userId: null,
    r_age: 29,
    r_selfAge: 29,
    r_spouseAge: 29,
    r_gender: 'male',
    r_marriageYear: 1,
    r_selfRetirementAge: 80,
    r_spouseRetirementAge: 85,
    r_selfIncome: 4000000,
    r_spouseIncome: 4000000,
    r_selfSaving: 3000000,
    r_spouseSaving: 2000000,
    r_selfCashAsset: 10000000,
    r_spouseCashAsset: 0,
    r_selfInvestmentAsset: 20000000,
    r_spouseInvestmentAsset: 20000000,

    r_selfHouseLoanYN: false,
    r_selfHouseLoanType: '',
    r_selfDeposit: 0,
    r_selfHomeLoan: 0,
    r_selfHouseMonthlyPayment: 0,
    r_selfHousingExpense: 0,
    r_selfHousingType: '',

    r_selfHouseLoanCalculator: 0,
    r_selfPrincipal: '300000000',
    r_selfAnnualRate: '4.3',
    r_selfRepaymentTerm: '30',
    r_selfGracePeriod: '3',
    r_selfRepaymentOption: '원리금 균등',
    r_selfPaymentFrequency: 12,
    r_selfRepaymentStart: '2020',

    r_spouseHouseLoanYN: false,
    r_spouseHouseLoanType: '',
    r_spouseDeposit: 0,
    r_spouseHomeLoan: 0,
    r_spouseHouseMonthlyPayment: 0,
    r_spouseHousingExpense: 0,
    r_spouseHousingType: '',

    r_spouseHouseLoanCalculator: 0,
    r_spousePrincipal: 300000000,
    r_spouseAnnualRate: 4.3,
    r_spouseRepaymentTerm: 30,
    r_spouseGracePeriod: 3,
    r_spouseRepaymentOption: '원리금 균등',
    r_spousePaymentFrequency: 12,
    r_spouseRepaymentStart: 2020,
    r_selfEtcDebt: 0,
    r_spouseEtcDebt: 0,

    r_carPrice: 0,
    r_carName: '',
    r_car_age: 0,
    r_selectedCar: {},

    r_apartmenttPurchaseType: '전세',
    r_apartmentPrice: 0,
    r_apartment_age: 0,
    r_selectedApartment: {},
    r_apartmentAddress_1: '',
    r_apartmentAddress_2: '',
    r_apartmentAddress_3: '',

    r_children_age: 0,

    r_etc_goal_money: 0,
    r_etc_goal_year: 0,

    r_filter_korea: false,
    r_filter_america: false,
    r_filter_japan: false,
    r_filter_europe: false,

    // r_isCashExplained: false,
    // r_isInvestmentExplained: false,

    r_isApartmentSearchOk: true,
    r_isCarSearchOk: true,

    r_isCarListVisible: false,
    r_isApartmentListVisible: false,
    r_isChartVisible: false,
    r_isCalculatorVisible: false,
    r_isRedoSurveyAsked: false,
    r_isPreviousAnswerPadVisible: false,
    r_isPreviousAnswerPadSelf: true,

    r_selfMortgageAmount: 0,
    r_spouseMortgageAmount: 0,
    r_selfMortgageStart: 0,
    r_spouseMortgageStart: 0,
    r_selfMortgage: [],
    r_spouseMortgage: [],

    r_selfCurrentMonthData: {},
    r_spouseCurrentMonthData: {},
    r_previousAnswers: {},
    r_report: '',
    r_report_selections: [],
    r_report_cash_investment: {}

    // r_chartNoSpend: {
    //   ages: [
    //     27, 28, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46,
    //     47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64,
    //     65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82,
    //     83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100
    //   ],
    //   assets: [
    //     29834, 40222, 129791, 156214, 182266, 218873, 249514, 261022, 297297,
    //     329570, 351300, 368987, 388461, 448515, 481118, 507998, 527680, 573696,
    //     619324, 651384, 682421, 739136, 776646, 820964, 823496, 906587, 917841,
    //     1045675, 1046442, 1159825, 1321227, 1395421, 1398714, 1346713, 1273543,
    //     1332250, 1332546, 1155100, 1031844, 910239, 817030, 716553, 635186,
    //     488848, 371194, 229034, 78878, -72419, -224868, -378587, -534470,
    //     -693291, -854680, -1015622, -1175485, -1341712, -1517245, -1697467,
    //     -1883978, -2080131, -2279567, -2481513, -2684996, -2890175, -3098243,
    //     -3310231, -3525648, -3740469, -3953850, -4175724, -4410022, -4650577
    //   ]
    // },
    // r_chartSpend: {
    //   ages: [
    //     27, 28, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46,
    //     47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64,
    //     65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82,
    //     83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100
    //   ],
    //   assets: [
    //     29734, 40122, 129691, 156114, 182166, 218773, 249414, 260922, 297197,
    //     329470, 351200, 368887, 388361, 448415, 481018, 507898, 527580, 573596,
    //     619224, 651284, 682321, 739036, 776546, 820864, 823396, 906487, 917741,
    //     1045575, 1046342, 1159725, 1321127, 1395321, 1398614, 1346613, 1273443,
    //     1332150, 1332446, 1155000, 1031744, 910139, 816930, 716453, 635086,
    //     488748, 371094, 228934, 78778, -72519, -224968, -378687, -534570,
    //     -693391, -854780, -1015722, -1175585, -1341812, -1517345, -1697567,
    //     -1884078, -2080231, -2279667, -2481613, -2685096, -2890275, -3098343,
    //     -3310331, -3525748, -3740569, -3953950, -4175824, -4410122, -4650677
    //   ]
    // },
    // r_chartAltered: {
    //   ages: [
    //     27, 28, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46,
    //     47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64,
    //     65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82,
    //     83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100
    //   ],
    //   assets: [
    //     29834, 40222, 129791, 156214, 182266, 218873, 249514, 261022, 297297,
    //     329570, 351300, 368987, 388461, 448515, 481118, 507998, 527680, 573696,
    //     619324, 651384, 682421, 739136, 776646, 820964, 823496, 906587, 917841,
    //     1045675, 1046442, 1159825, 1321227, 1395421, 1398714, 1346713, 1273543,
    //     1332250, 1332546, 1155100, 1031844, 910239, 817030, 716553, 635186,
    //     488848, 371194, 229034, 78878, -72419, -224868, -378587, -534470,
    //     -693291, -854680, -1015622, -1175485, -1341712, -1517245, -1697467,
    //     -1883978, -2080131, -2279567, -2481513, -2684996, -2890175, -3098243,
    //     -3310231, -3525648, -3740469, -3953850, -4175724, -4410022, -4650577
    //   ]
    // }
    // r_selectedPurchase: [
    //   {
    //     surveyId: 1,
    //     questionId: 2,
    //     optionText: '자동차 구입하기',
    //     orderIndex: 1,
    //     nextQuestionId: null
    //   },
    //   {
    //     surveyId: 1,
    //     questionId: 2,
    //     optionText: '아파트 구하기',
    //     orderIndex: 2,
    //     nextQuestionId: null
    //   },
    //   {
    //     surveyId: 1,
    //     questionId: 2,
    //     optionText: '자녀 양육하기',
    //     orderIndex: 3,
    //     nextQuestionId: null
    //   },
    //   {
    //     surveyId: 1,
    //     questionId: 2,
    //     optionText: '기타 목표 달성하기',
    //     orderIndex: 4,
    //     nextQuestionId: null
    //   }
    // ],
  }
});

export const selfSpouseSelector = selector({
  key: 'selfSpouseSelector',
  get:
    ({ get }) =>
    ({ isSelf, field }) => {
      const survey = get(surveyState);
      return survey[`r_${isSelf ? 'self' : 'spouse'}${field}`];
    }
});

export const isMakerUPState = atom({
  key: 'isMakerUPState',
  default: false
});

export const isBrandUPState = atom({
  key: 'isBrandUPState',
  default: false
});

export const isCarTypeUPState = atom({
  key: 'isCarTypeUPState',
  default: false
});

export const isFuelTypeUPState = atom({
  key: 'isFuelTypeUPState',
  default: false
});

export const isGradationState = atom({
  key: 'isGradationState',
  default: false
});

export const stateHandlerSelector = selector({
  key: 'stateHandlerSelector',
  get: ({ get }) => {
    return {
      isMakerUP: get(isMakerUPState),
      isBrandUP: get(isBrandUPState),
      isCarTypeUP: get(isCarTypeUPState),
      isFuelTypeUP: get(isFuelTypeUPState)
    };
  },
  set: ({ set }, action) => {
    switch (action.type) {
      case 'SET_MAKER_UP':
        set(isMakerUPState, action.payload);
        set(isBrandUPState, false);
        set(isCarTypeUPState, false);
        set(isFuelTypeUPState, false);
        break;
      case 'SET_BRAND_UP':
        set(isBrandUPState, action.payload);
        set(isMakerUPState, false);
        set(isCarTypeUPState, false);
        set(isFuelTypeUPState, false);
        break;
      case 'SET_CAR_TYPE_UP':
        set(isCarTypeUPState, action.payload);
        set(isMakerUPState, false);
        set(isBrandUPState, false);
        set(isFuelTypeUPState, false);
        break;
      case 'SET_FUEL_TYPE_UP':
        set(isFuelTypeUPState, action.payload);
        set(isMakerUPState, false);
        set(isBrandUPState, false);
        set(isCarTypeUPState, false);
        break;
      case 'CLOSE_MODAL':
        set(isMakerUPState, false);
        set(isBrandUPState, false);
        set(isCarTypeUPState, false);
        set(isFuelTypeUPState, false);
        break;
      default:
        break;
    }
  }
});
