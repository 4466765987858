export const addKakaoChannel = () => {
  if (window.Kakao) {
    const kakao = window.Kakao;

    // 인증이 안되어있는 경우 인증한다.
    if (!kakao.isInitialized()) {
      kakao.init(process.env.REACT_APP_KAKAO_API_KEY);
    }

    kakao.Channel.chat({
      channelPublicId: process.env.REACT_APP_KAKAO_CHANNEL_ID
    });
  }
};
