import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import PercentProgressBar from '../component/PercentProgressBar';
import DSRPopup from '../popupReport/DSRPopup';
import { formatKoreanCurrency } from '../../../util/formatKoreanCurrency';
import RightArrowIcon from '../../icon/RightArrowIcon';

const ApartmentLoanDSRReport = ({ data = {} }) => {
  const { plot = [], text = '' } = data;
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => setIsPopupOpen(true);
  const closePopup = () => setIsPopupOpen(false);

  if (isPopupOpen) {
    return <DSRPopup close={closePopup} />;
  }

  return (
    <div className="report-container">
      <section className="p-6 pb-4 flex flex-col">
        <div className="flex flex-col justify-between gap-6 px-6 py-5 report-shadow rounded-2xl">
          {plot[0] > 0 ? (
            <p className="text-base leading-[140%] text-[#82817F]">
              목표 시기에 구매하기 위해서
              <br />
              <span className="text-orange font-bold">
                {formatKoreanCurrency(plot[0] * 10000)}원의 대출이 발생
              </span>
              해요
            </p>
          ) : (
            <p className="text-base leading-[140%] text-[#82817F]">
              목표 시기에
              <br />
              <span className="text-orange font-bold">
                대출없이 구매할 수 있어요!
              </span>
            </p>
          )}
          <div className="flex flex-col gap-[10px] w-full">
            <label className="text-end text-sm font-bold leading-[100%] text-[#A9A8A7]">
              목표 금액 {formatKoreanCurrency(plot[1] * 10000)}원
            </label>
            <PercentProgressBar
              plot={[plot[2], plot[4], plot[6]]}
              bg1="progress-gradient"
              bg2="#653075"
              labels={[
                `대출금 ${formatKoreanCurrency(plot[0] * 10000)}원 (${plot[2]}%)`,
                `현금성자산 ${formatKoreanCurrency(plot[3] * 10000)}원 (${plot[4]}%)`,
                plot[6] > 0 &&
                  `부족한 금액 ${formatKoreanCurrency(plot[5] * 10000)}원 (${plot[6]}%)`
              ]}
              labelsCenter={false}
            />
          </div>
        </div>
      </section>
      <section className="px-6 py-3">
        <button
          onClick={openPopup}
          className="w-full px-4 py-[10px] flex items-center justify-between border border-[#E5E5E5] rounded-[10px] bg-[#F8F8F8]"
        >
          <p className="text-left text-sm font-medium leading-[24px] text-[#6F6D6C]">
            💡 DSR(총부채원리금상환비율)이 무엇인가요?
          </p>
          <RightArrowIcon className="text-[#6F6D6C]" size={16} />
        </button>
      </section>
      <section className="w-full pt-4 px-[30px] pb-6">
        <p className="text-[16px] font-bold leading-[100%] mb-3">
          <span className="mr-[6px]">🏛️</span>
          대출이 얼마나 필요할까요?
        </p>
        <ReactMarkdown className="text-[14px] font-normal leading-[170%]">
          {text}
        </ReactMarkdown>
      </section>
    </div>
  );
};

export default ApartmentLoanDSRReport;
