import React from 'react';

const InvestmentPropensitySecondReport = () => {
  return (
    <div className="report-container">
      <section className="p-6 pb-2 flex flex-col gap-6">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2 pb-4 border-b border-[#EBEBEB]">
            <h3 className="text-orange text-sm font-bold leading-[100%]">
              적극투자형
            </h3>
            <p className="text-sm leading-[170%]">
              원금 보전보다는 높은 수익을 목표로 하며, 일정 수준의 위험을 감수할
              준비가 되어 있는 투자 성향으로 자산의 상당 부분을 주식, 주식형
              펀드 또는 파생상품과 같은 변동성이 큰 위험 자산에 투자해 수익을
              극대화하고자 하는 유형
            </p>
            <div className="flex items-center">
              <span className="p-[6px] border border-[#BFBEBD] rounded text-xs leading-[100%] text-[#959493] mr-[10px]">
                적합한 상품
              </span>
              <span className="text-sm font-bold leading-[100%]">
                높은 위험 / 주식투자, 주식펀드 등
              </span>
            </div>
          </div>
          <div className="flex flex-col gap-2 pb-4 border-b border-[#EBEBEB]">
            <h3 className="text-orange text-sm font-bold leading-[100%]">
              공격투자형
            </h3>
            <p className="text-sm leading-[170%]">
              시장 평균을 뛰어넘는 높은 수익을 목표로 하며, 이를 위해 자산 가치
              변동에 따른 손실 위험도 기꺼이 감수할 수 있는 투자 성향으로 투자
              자금의 대부분을 주식, 주식형 펀드, 또는 파생상품 같은 위험 자산에
              투자하며, 높은 성과를 추구하는 유형
            </p>
            <div className="flex items-center">
              <span className="p-[6px] border border-[#BFBEBD] rounded text-xs leading-[100%] text-[#959493] mr-[10px]">
                적합한 상품
              </span>
              <span className="text-sm font-bold leading-[100%]">
                매우 높은 위험 / 선물옵션, 레버리지 등
              </span>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default InvestmentPropensitySecondReport;
