import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { surveyState } from '../../recoil/atoms';
import '../../style/Report.css';
import ReportHeader from '../header/ReportHeader';
import ReportPagination from './component/ReportPagination';

import AdditionalRetirementStrategiesReport from './pageReport/AdditionalRetirementStrategiesReport';
import ApartmentDSRAnalysisReport from './pageReport/ApartmentDSRAnalysisReport';
import ApartmentDSRMethodReport from './pageReport/ApartmentDSRMethodReport';
import ApartmentLoanDSRReport from './pageReport/ApartmentLoanDSRReport';
import ApartmentLoanLTVReport from './pageReport/ApartmentLoanLTVReport';
import ApartmentReport from './pageReport/ApartmentReport';
import CarLoanReport from './pageReport/CarLoanReport';
import CarReport from './pageReport/CarReport';
import ChildCareReport from './pageReport/ChildCareReport';
import DebtChartReport from './pageReport/DebtChartReport';
import EmergencyFundReport from './pageReport/EmergencyFundReport';
import ExtraGoalsReport from './pageReport/ExtraGoalsReport';
import FinalFirstReport from './pageReport/FinalFirstReport';
import FinalSecondReport from './pageReport/FinalSecondReport';
import FinalThirdReport from './pageReport/FinalThirdReport';
import FinancialScoreReport from './pageReport/FinancialScoreReport';
import IncomeReport from './pageReport/IncomeReport';
import InvestmentPropensityFirstReport from './pageReport/InvestmentPropensityFirstReport';
import InvestmentPropensitySecondReport from './pageReport/InvestmentPropensitySecondReport';
import InvestReport from './pageReport/InvestReport';
import LoadingReport from './pageReport/LoadingReport';
import MonteCarloReport from './pageReport/MonteCarloReport';
import RetirementFirstReport from './pageReport/RetirementFirstReport';
import RetirementFundsReport from './pageReport/RetirementFundsReport';
import RetirementMonthlyPaymentReport from './pageReport/RetirementMonthlyPaymentReport';
import RetirementNetWorthReport from './pageReport/RetirementNetWorthReport';
import RetirementPensionEstimateReport from './pageReport/RetirementPensionEstimateReport';
import RetirementPrepReport from './pageReport/RetirementPrepReport';
import RetirementSecondReport from './pageReport/RetirementSecondReport';
import RetirementSustainabilityReport from './pageReport/RetirementSustainabilityReport';
import SavingsReport from './pageReport/SavingsReport';
import ScenarioReport from './pageReport/ScenarioReport';
import SmartSaverPlanReport from './pageReport/SmartSaverPlanReport';

const ReportContainer = ({ onClose }) => {
  const survey = useRecoilValue(surveyState);
  const [isLoading, setIsLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const [totalIndex, setTotalIndex] = useState(0);
  const { r_report, r_report_selections, r_report_cash_investment } = survey;

  const [report, setReport] = useState('');
  const [userName, setUserName] = useState(report?.page2?.plot[0] || '');
  const [reportSelections, setReportSelections] = useState({
    selectedGoals: r_report_selections || [],
    cashInvestment: r_report_cash_investment || {}
  });

  useEffect(() => {
    if (r_report_selections) {
      setReportSelections((prev) => ({
        ...prev,
        selectedGoals: r_report_selections
      }));
    }
    if (r_report_cash_investment) {
      setReportSelections((prev) => ({
        ...prev,
        cashInvestment: r_report_cash_investment
      }));
    }
  }, [r_report_selections, r_report_cash_investment]);

  const notShowCashInvestment = useMemo(() => {
    const { selfCash, selfInvestment, spouseCash, spouseInvestment } =
      reportSelections.cashInvestment;
    return (
      Number(selfCash) === 0 &&
      Number(selfInvestment) === 0 &&
      Number(spouseCash) === 0 &&
      Number(spouseInvestment) === 0
    );
  }, [reportSelections.cashInvestment]);

  const selectionChecks = useMemo(() => {
    return {
      showCar: reportSelections.selectedGoals.includes('자동차 구입하기'),
      showApartment: reportSelections.selectedGoals.includes('아파트 구하기'),
      showChildren: reportSelections.selectedGoals.includes('자녀 양육하기'),
      showEtcGoal: reportSelections.selectedGoals.includes('기타 목표 달성하기')
    };
  }, [reportSelections.selectedGoals]);

  const isForSale = useMemo(() => {
    return reportSelections.cashInvestment?.jeonseSale === 'o'; // o는 매매 d는 전세
  }, [reportSelections.cashInvestment]);

  useEffect(() => {
    if (r_report) {
      try {
        const parsedReport = JSON.parse(r_report);
        const reportResults1 = JSON.parse(
          parsedReport.report_results.replace(/'/g, '"')
        );
        const reportResults2 = JSON.parse(
          parsedReport.simulation_results.replace(/'/g, '"')
        );
        const reportResults = {
          ...reportResults1,
          ...reportResults2
        };
        setReport(reportResults);
      } catch (error) {
        console.error('Error parsing r_report:', error);
        setReport({}); // 빈 객체로 초기화
      }
    }
  }, [r_report]);

  console.log('rendered report', report);

  useEffect(() => {
    setUserName(report?.page2?.plot[0] || '');
  }, [report]);

  const handleSwiperChange = (e) => {
    setActiveIndex(e.activeIndex);
  };

  const reportContents = useMemo(() => {
    const baseReports = [
      <LoadingReport
        key="page1"
        data={{
          data_type: 'loading',
          userName
        }}
        stage="first"
      />,
      <IncomeReport key="page2" data={report.page2} />,
      <DebtChartReport key="page3" data={report.page3} />,
      !notShowCashInvestment ? (
        <InvestReport key="page4" data={report.page4} />
      ) : null,
      <FinancialScoreReport
        key="page5"
        data={{
          userName,
          self: report.page5_1,
          spouse: report.page5_2
        }}
      />,
      <SavingsReport key="page6" data={{ ...report.page6, userName }} />,
      <SmartSaverPlanReport key="page7" data={report.page7} />,
      <EmergencyFundReport key="page8" data={report.page8} />,
      <LoadingReport
        key="page9"
        data={{ data_type: 'loading' }}
        stage="second"
      />,
      <MonteCarloReport key="page10" />,
      selectionChecks.showCar ? (
        <CarReport key="page11" data={report.page11} />
      ) : null,
      selectionChecks.showCar ? (
        <CarLoanReport key="page12" data={report.page12} />
      ) : null,
      selectionChecks.showApartment ? (
        <ApartmentReport key="page13" data={report.page13} />
      ) : null,
      selectionChecks.showApartment ? (
        isForSale ? (
          <ApartmentLoanDSRReport key="page14-dsr" data={report.page14} />
        ) : (
          <ApartmentLoanLTVReport key="page14-ltv" data={report.page14} />
        )
      ) : null,
      selectionChecks.showApartment &&
      isForSale &&
      report.page15?.plot[0] > 0 ? (
        <ApartmentDSRAnalysisReport key="page15" data={report.page15} />
      ) : null,
      selectionChecks.showApartment &&
      isForSale &&
      report.page15?.plot[0] > 0 ? (
        <ApartmentDSRMethodReport key="page16" />
      ) : null,
      selectionChecks.showChildren ? (
        <ChildCareReport key="page17" data={report.page17} />
      ) : null,
      selectionChecks.showEtcGoal ? (
        <ExtraGoalsReport key="page18" data={report.page18} />
      ) : null,
      <LoadingReport
        key="page19"
        data={{ data_type: 'loading' }}
        stage="third"
      />,
      <RetirementFirstReport key="page20" />,
      <RetirementSecondReport key="page21" />,
      <RetirementPrepReport key="page22" />,
      <RetirementMonthlyPaymentReport key="page23" data={report.page23} />,
      <RetirementPensionEstimateReport key="page24" data={report.page24} />,
      <RetirementNetWorthReport key="page25" data={report.page25} />,
      <RetirementSustainabilityReport key="page26" data={report.page26} />,
      <RetirementFundsReport key="page27" />,
      <ScenarioReport key="page28" order={1} data={report.page28} />,
      <ScenarioReport key="page29" order={2} data={report.page29} />,
      <ScenarioReport key="page30" order={3} data={report.page30} />,
      <InvestmentPropensityFirstReport key="page31" />,
      <InvestmentPropensitySecondReport key="page32" />,
      selectionChecks.showApartment ? (
        <AdditionalRetirementStrategiesReport
          key="page33"
          isForSale={isForSale}
          data={report.page33}
        />
      ) : null,
      <LoadingReport
        key="page34"
        data={{ data_type: 'loading' }}
        stage="fourth"
      />,
      <FinalFirstReport key="page35" />,
      <FinalSecondReport key="page36" />,
      <FinalThirdReport key="page37" />
    ];

    return baseReports.filter(Boolean);
  }, [report, userName, isForSale, notShowCashInvestment, selectionChecks]);

  useEffect(() => {
    const swiperContainer = document.querySelector('.swiper-container');
    const slides = swiperContainer?.querySelectorAll('.swiper-slide');
    if (slides) {
      setTotalIndex(slides.length);
    }
  }, [reportContents]);

  useEffect(() => {
    const isLoadingComponent =
      reportContents[activeIndex]?.props?.data?.data_type === 'loading';

    setIsLoading(isLoadingComponent);

    const progressBar = document.querySelector(
      '.swiper-container .swiper-pagination-progressbar'
    );
    const progressBarFill = document.querySelector(
      '.swiper-container span.swiper-pagination-progressbar-fill'
    );

    if (progressBar) {
      progressBar.classList.remove('default-progress', 'loading-progress'); // 기존 클래스를 제거
      progressBar.classList.add(
        isLoadingComponent ? 'loading-progress' : 'default-progress'
      );
    }
    if (progressBarFill) {
      progressBarFill.classList.remove('default-progress', 'loading-progress'); // 기존 클래스를 제거
      progressBarFill.classList.add(
        isLoadingComponent ? 'loading-progress' : 'default-progress'
      );
    }
  }, [activeIndex, reportContents]);

  return (
    <div className="w-full max-w-md h-[100dvh] relative bg-white">
      {/* 헤더 */}
      <ReportHeader title="나의 재무 리포트" onClick={onClose} />

      {/* Swiper 슬라이드 */}
      <Swiper
        modules={[Pagination, Navigation]}
        navigation={{
          nextEl: '.custom-next-button',
          prevEl: '.custom-prev-button'
        }}
        slidesPerView={1}
        pagination={{ clickable: true, type: 'progressbar' }}
        scrolleventthrottle={16}
        className="swiper-container"
        // onSwiper={(swiper) => setTotalIndex(swiper.slides.length)}
        onSlideChange={handleSwiperChange}
      >
        {reportContents.map((Component, index) => (
          <SwiperSlide key={Component.key}>{Component}</SwiperSlide>
        ))}
      </Swiper>

      {/* 페이지네이션 */}
      <ReportPagination
        activeIndex={activeIndex}
        totalIndex={totalIndex}
        isLoading={isLoading}
      />
    </div>
  );
};

export default ReportContainer;
