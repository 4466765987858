import React from 'react';
import { Route, Routes } from 'react-router-dom';
import NotFoundPage from '../page/NotFoundPage';
import MyContentsPage from '../page/member/MyContentsPage';
import PurchaseListPage from '../page/member/PurchaseListPage';
import PurchaseDetailPage from '../page/member/PurchaseDetailPage';

const MemberRoute = () => {
  return (
    <Routes>
      <Route path="/mycontents" element={<MyContentsPage />} />
      <Route path="/purchase" element={<PurchaseListPage />} />
      <Route path="/purchase/:id" element={<PurchaseDetailPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default MemberRoute;
