import React from 'react';
import { useRecoilValue } from 'recoil';
import { surveyState } from '../recoil/atoms';
import { formatKoreanCurrency } from '../util/formatKoreanCurrency';

const NameCard = ({ isSelf = false }) => {
  const survey = useRecoilValue(surveyState);
  const age = survey[`r_${isSelf ? 'self' : 'spouse'}Age`];
  const gender =
    (survey.r_gender === 'male' && isSelf) ||
    (!(survey.r_gender === 'male') && !isSelf)
      ? 'male'
      : 'female';

  const retirementAge = survey[`r_${isSelf ? 'self' : 'spouse'}RetirementAge`];
  const income = survey[`r_${isSelf ? 'self' : 'spouse'}Income`];
  const savings = survey[`r_${isSelf ? 'self' : 'spouse'}Saving`];
  const cashAsset = survey[`r_${isSelf ? 'self' : 'spouse'}CashAsset`];
  const investmentAsset =
    survey[`r_${isSelf ? 'self' : 'spouse'}InvestmentAsset`];
  const mortgage = survey[`r_${isSelf ? 'self' : 'spouse'}Mortgage`];
  const currentMonthData =
    survey[`r_${isSelf ? 'self' : 'spouse'}CurrentMonthData`];

  const houseLoanYN = survey[`r_${isSelf ? 'self' : 'spouse'}HouseLoanYN`]
    ? '있음'
    : '없음';
  const housingExpense =
    survey[`r_${isSelf ? 'self' : 'spouse'}HousingExpense`];
  const etcDebt = survey[`r_${isSelf ? 'self' : 'spouse'}EtcDebt`];
  const houseMonthlyPayment =
    survey[`r_${isSelf ? 'self' : 'spouse'}HouseMonthlyPayment`];
  const profileImg = {
    male: '/picture/icons/profile-male.png',
    female: '/picture/icons/profile-female.png'
  };
  // console.log('isSelf: ', isSelf);
  // console.log('gender: ', gender);

  const detailsData = [
    {
      name: isSelf ? 'selfAge' : 'spouseAge',
      label: '현재 나이',
      value: `만 ${age}세`
    },
    {
      name: isSelf ? 'selfRetirementAge' : 'spouseRetirementAge',
      label: '은퇴 시기',
      value: `만 ${retirementAge}세`
    },
    {
      name: isSelf ? 'selfIncome' : 'spouseIncome',
      label: '세후 소득',
      value: `월 ${formatKoreanCurrency(income)} 원`
    },
    {
      name: isSelf ? 'selfSaving' : 'spouseSaving',
      label: '저축 금액',
      value: `월 ${formatKoreanCurrency(savings)} 원`
    },
    {
      name: isSelf ? 'selfRetirementAge' : 'spouseRetirementAge',
      label: '은퇴 시기',
      value: `만 ${retirementAge}세`
    },
    {
      name: isSelf ? 'selfCashAsset' : 'spouseCashAsset',
      label: '현금성 자산',
      value: `${formatKoreanCurrency(cashAsset)} 원`
    },
    {
      name: isSelf ? 'selfInvestmentAsset' : 'spouseInvestmentAsset',
      label: '투자 자산',
      value: `${formatKoreanCurrency(investmentAsset)} 원`
    },
    {
      name: isSelf ? 'selfHouseLoanYN' : 'spouseHouseLoanYN',
      label: '주거용 대출',
      value: `${houseLoanYN}`
    },
    {
      name: isSelf ? 'selfHousingExpense' : 'spouseHousingExpense',
      label: '주거비',
      value: `${parseInt(
        (parseFloat(housingExpense || 0) +
          parseFloat(houseMonthlyPayment || 0)) /
          10000
      )} 만 원`
    },
    {
      name: isSelf ? 'selfEtcDebt' : 'spouseEtcDebt',
      label: '기타 대출',
      value: `${formatKoreanCurrency(etcDebt)} 원`
    }
  ].filter(({ value }) => !value.includes('-- 원'));

  return (
    <div className="flex justify-center items-center w-[234px]">
      <div className="w-full p-7">
        <div className="flex flex-col items-center">
          {/* Avatar Placeholder */}
          <div className="mb-2 w-[78px] h-auto">
            <img
              src={profileImg[gender]}
              alt="Avatar"
              className="w-full h-full"
            />
          </div>
          {/* Name and Gender */}
          <div className="text-center">
            <p className="font-semibold text-base">
              {isSelf ? '나의 프로필' : '미래 배우자'}
            </p>
          </div>
        </div>

        {/* Details Section */}
        <div className="mt-4">
          {detailsData.map(({ name, label, value }) => (
            <div key={name} className="flex justify-between mb-1.5">
              <p className="w-1/2 text-sm text-left pl-4 text-grayDark2">
                {label}
              </p>
              <p className="w-1/2 text-sm text-left pl-2">{value}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NameCard;
