import React from 'react';
import { getDaysDifference } from '../util/getDaysDifference';
import RightArrowIcon from './icon/RightArrowIcon';

const ProductBox = ({
  product = {},
  openReport,
  goToChat,
  openReviewPopup,
  goToCart,
  hasReviewed = false
}) => {
  const { paymentHistory = {} } = product;

  return (
    <div className="p-4 border-[1.5px] border-[#F8F8F8] rounded-lg">
      <p className="text-xs font-semibold leading-[140%] tracking-[-0.24px]">
        {paymentHistory.isFinished && paymentHistory.isReportReady
          ? '진행 완료'
          : getDaysDifference(paymentHistory.createdAt) <= 7 &&
              !paymentHistory.isFinished &&
              !paymentHistory.isReportReady
            ? 'NEW'
            : '진행 중'}
      </p>
      <div className="flex justify-between items-stretch gap-[18px]">
        <div className="w-full h-full max-w-[64px]">
          <img src="/logo174.png" alt="logo" width={64} height={64} />
        </div>
        <div className="w-full flex flex-col justify-between items-stretch">
          <h3 className="text-sm font-semibold leading-[140%] tracking-[-0.24px]">
            {paymentHistory.productName}
          </h3>
          {paymentHistory.isFinished && paymentHistory.isReportReady ? (
            <button
              onClick={() => openReport(paymentHistory.orderId)}
              className="flex items-center text-sm font-semibold leading-[140%] tracking-[-0.24px] text-orange"
            >
              나의 재무 리포트 보기
              <RightArrowIcon size={16} className="text-orange" />
            </button>
          ) : paymentHistory.isFinished ? (
            <span className="flex items-center w-fit px-2 py-1 text-xs font-semibold leading-[140%] tracking-[-0.24px] text-[#82817F] bg-[#EBEBEB] rounded">
              나의 재무 데이터 분석중
            </span>
          ) : (
            <button
              onClick={goToChat}
              className="flex items-center text-sm font-semibold leading-[140%] tracking-[-0.24px] text-orange"
            >
              시작하기
              <RightArrowIcon size={16} className="text-orange" />
            </button>
          )}
        </div>
      </div>
      {paymentHistory.isFinished && paymentHistory.isReportReady ? (
        <div className="mt-4 flex justify-between h-8 gap-2">
          {!hasReviewed && (
            <button
              onClick={openReviewPopup}
              className="h-full w-full border border-[#D1D1D0] rounded-[5px] bg-[#F8F8F8]"
            >
              <span className="text-sm font-semibold leading-[140%] tracking-[-0.28px]">
                리뷰 작성하기
              </span>
            </button>
          )}
          <button
            onClick={goToCart}
            className="h-full w-full border border-[#D1D1D0] rounded-[5px] bg-[#F8F8F8]"
          >
            <span className="text-sm font-semibold leading-[140%] tracking-[-0.28px]">
              재구매하기
            </span>
          </button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ProductBox;
