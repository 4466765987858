import { useRecoilValue } from 'recoil';
import { surveyState } from '../recoil/atoms';
import { formatKoreanCurrency } from '../util/formatKoreanCurrency';

export const useReplaceQuestion = () => {
  const survey = useRecoilValue(surveyState);

  const replaceQuestion = (question) => {
    let newQuestionText = '';
    if (question && question.questionText) {
      //@NAME, @CAR_PRICE. @REGION1 , @REGION2, @@APT_PRICE
      newQuestionText = question.questionText.replace('@NAME', survey.r_name);
      newQuestionText = newQuestionText.replace(
        '@CAR_PRICE',
        formatKoreanCurrency(survey.r_carPrice)
      );
      newQuestionText = newQuestionText.replace(
        '@REGION1',
        survey.r_apartmentAddress_1
      );
      newQuestionText = newQuestionText.replace(
        '@REGION2',
        survey.r_apartmentAddress_2
      );
      newQuestionText = newQuestionText.replace(
        '@APT_PRICE',
        formatKoreanCurrency(survey.r_apartmentPrice)
      );
    }
    return newQuestionText;
  };
  return replaceQuestion;
};
