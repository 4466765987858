import React from 'react';

const RetirementFirstReport = () => {
  return (
    <div className="report-container">
      <section className="p-6 pb-2 flex flex-col gap-3">
        <div>
          <p className="text-xl leading-[140%]">
            아직 결혼도 안했는데,
            <br />
            은퇴 준비를 벌써부터 시작해야 하나요?
          </p>
        </div>
        <div>
          <p className="text-sm leading-[170%]">
            2024년 기대수명은 남성이 86.7세, 여성이 90.7세로 처음으로 90세가
            넘었어요. 우리가 은퇴하게 되는 시점인 30년 후에는 아마 더 높아져있을
            거에요.
            <br />
            하지만 일을 할 수 있는 기간은 여전히 비슷해요. 대부분 직장에서 50대
            초중반이면 은퇴하게 되죠. 로봇과 AI가 사람들의 일을 대신하게 되면서
            아마 이 시기는 더 당겨질 수도 있어요. 이렇게 되면{' '}
            <strong>
              은퇴 후 최소 30~40년의 시간을 어떻게 준비하느냐가 정말
              중요해집니다.
            </strong>
          </p>
        </div>
      </section>
      <section className="px-6 py-3">
        <div className="w-full">
          <img src="/picture/income-spending.png" alt="소득 소비 곡선" />
        </div>
      </section>
      <section className="w-full pt-4 px-[30px] pb-6">
        <p className="text-[14px] font-normal leading-[170%]">
          국민연금은 65세부터 나오기 시작하고, 이것만으로는 생활하기 부족하기
          때문에 소득보다 지출이 많아지는 시기를 미리 준비를 해두지 않으면
          자산이 빠르게 줄어들 수 있어요. 나만의 은퇴 자금을 미리 준비하는 것이
          필요합니다.
        </p>
      </section>
    </div>
  );
};

export default RetirementFirstReport;
