import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import AlertModal from '../component/AlertModal';
import { ApartmentList } from '../component/ApartmentList';
import { CarList } from '../component/CarList';
import { Footer } from '../component/Footer';
import { Header } from '../component/header/Header';
import LoanInterestCalculatorContainer from '../component/LoanInterestCalculator/LoanInterestCalculatorContainer';
import { MessageWindow } from '../component/MessageWindow';
import { PreviousAnswerPad } from '../component/PreviousAnswerPadContent/PreviousAnswerPad';
import { config } from '../config';
import { useAddLastMessageOption } from '../hook/useAddLastMessageOption';
import { useAddMessage } from '../hook/useAddMessage';
import { useFetchAllQuestions } from '../hook/useFetchAllQuestions';
import { useFetchReportData } from '../hook/useFetchReportData';
import { useMoveToNextQuestion } from '../hook/useMoveToNextQuestion';
import { useMoveToTheQuestion } from '../hook/useMoveToTheQuestion';
import { useRequestReportCreate } from '../hook/useRequestReportCreate';
import { useSaveAll } from '../hook/useSaveAll';
import { useSaveAnswer } from '../hook/useSaveAnswer';
import { useSetSurveyValue } from '../hook/useSetSurveyValue';
import { useUpdateIsFinished } from '../hook/useUpdateIsFinished';
import { useUpdateIsRefundable } from '../hook/useUpdateIsRefundable';
import { surveyState } from '../recoil/atoms';
import '../style/ChatbotPage.css';
const SPOUSE_IMG_URL = '/picture/icons/spouse-male.png';
const STAR_CLOUD_URL = '/picture/icons/star-cloud.png';

const ChatbotPage = () => {
  const { topic } = useParams();
  const navigate = useNavigate();
  const [survey, setSurvey] = useRecoilState(surveyState);
  const {
    r_sessionId,
    r_userId,
    r_surveyId,
    r_allQuestions,
    r_idx,
    r_orderId,
    r_messages
  } = survey;
  const saveAnswer = useSaveAnswer();
  const addMessage = useAddMessage();
  const moveToNextQuestion = useMoveToNextQuestion();
  const moveToTheQuestion = useMoveToTheQuestion();
  const setSurveyValue = useSetSurveyValue();
  const addLastMessageOption = useAddLastMessageOption();
  const fetchAllQuestions = useFetchAllQuestions();
  const fetchReportData = useFetchReportData();
  const saveAll = useSaveAll();
  const overlayRef = useRef(null);
  const requestReportCreate = useRequestReportCreate();
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const updateIsFinished = useUpdateIsFinished();
  const updateIsRefundable = useUpdateIsRefundable();
  const [isFirstNoticePopupOpen, setIsFirstNoticePopupOpen] = useState(true);
  const [isRecommendationPopupOpen, setRecommendationPopupOpen] =
    useState(false);
  const [isServiceUnavailablePopupOpen, setServiceUnavailablePopupOpen] =
    useState(false);

  const toggleRecommendationPopup = () => {
    setRecommendationPopupOpen((prevState) => !prevState);
  };

  const toggleServiceUnavailablePopup = () => {
    setServiceUnavailablePopupOpen((prevState) => !prevState);
  };

  const handleRecommendation = () => {
    toggleServiceUnavailablePopup();
    toggleRecommendationPopup();
    saveAnswer(
      r_sessionId,
      r_userId,
      r_surveyId,
      config.SPOUSE_RECOMMENDATION_QUESTION_ID,
      1,
      null
    );
  };

  const handleNoRecommendation = () => {
    toggleRecommendationPopup();
    saveAnswer(config.SPOUSE_RECOMMENDATION_QUESTION_ID, 0, null);
  };

  const closeFirstNoticePopup = () => setIsFirstNoticePopupOpen(false);

  // useEffect(() => {
  //   const chatTopic = topic || 'General';
  //   const newSurveyId = ['General', 'Finance'].includes(chatTopic) ? 1 : 1;
  //   setSurveyValue('r_surveyId', newSurveyId);
  // }, [topic, setSurvey]);
  // useEffect(() => {
  //   const params = new URLSearchParams(window.location.search);
  //   const idx = params.get('idx');
  //   console.log('idx', idx);
  //   if (idx) {
  //     setSurveyValue('r_idx', parseInt(idx));
  //   }
  // }, []);

  // useEffect(() => {
  //   if (!survey.r_sessionId || survey.r_sessionId === '0') {
  //     const uuid = uuidv4();
  //     setSurveyValue('r_sessionId', uuid);
  //   }
  // }, []);

  useEffect(() => {
    console.log('r_surveyId: ', r_surveyId);
    if (r_messages.length === 0 && r_allQuestions.length === 0) {
      console.log('fetchAllQuestions');
      fetchAllQuestions(r_surveyId);
    }
  }, [r_surveyId]);

  useEffect(() => {
    console.log('r_surveyId: ', r_surveyId);
  }, []);

  useEffect(() => {
    // console.log('useEffect2');
    if (r_allQuestions?.length > 0) {
      const params = new URLSearchParams(window.location.search);
      const idx = params.get('idx');
      if (idx) {
        setSurveyValue('r_idx', parseInt(idx));
        return;
      }
      setSurveyValue('r_messages', [
        { role: 'finberry', content: { questionText: '핀베리' } },
        { role: 'assistant', content: r_allQuestions[0] },
        { role: 'assistant', content: r_allQuestions[1] }
      ]);
      setSurveyValue('r_idx', r_idx + 1);
    }
    updateIsRefundable(survey.r_orderId, false);
  }, [r_allQuestions]);

  // useEffect(() => {
  //   console.log('useEffect3', r_idx);
  //   let tempIdx = r_idx;
  //   addMessage(r_allQuestions[tempIdx]);
  //   while (r_allQuestions[tempIdx]?.questionType === 'COMMENT') {
  //     tempIdx++;
  //     addMessage(r_allQuestions[tempIdx]);
  //   }
  //   setSurveyValue('r_idx', tempIdx);
  // }, []);

  useEffect(() => {
    // 채팅 페이지 시작하자마자 isRefundable false로 업데이트
    if (survey.r_orderId) {
      updateIsRefundable(survey.r_orderId, false);
    }
  }, [survey.r_orderId, updateIsRefundable]);

  useEffect(() => {
    console.log('r_idx: ', r_idx);

    if (r_idx === 2 && !survey.r_isRedoSurveyAsked && r_orderId) {
      setTimeout(() => {
        console.log('대기중...');
      }, 1000);
      // updateIsRefundable(survey.r_orderId, false);

      const paymentHistories = [...survey.r_paymentHistories].sort(
        (a, b) =>
          new Date(b.paymentHistory.createdAt) -
          new Date(a.paymentHistory.createdAt)
      );
      const hasNewOrder = paymentHistories.some((item) => {
        if (
          !item.paymentHistory.isFinished &&
          !item.paymentHistory.isRefunded
        ) {
          console.log('r_orderId: ', item.paymentHistory.orderId);
          setSurveyValue('r_orderId', item.paymentHistory.orderId);
          return true;
        }
        return false;
      });
      // fetchReportData();

      const hasAnyFinished = paymentHistories.some((item) => {
        if (item.paymentHistory.isFinished) {
          return true;
        }
        return false;
      });

      if (!hasNewOrder) {
        navigate(-1);
      }
      if (hasNewOrder && hasAnyFinished) {
        console.log('r_idx: ', r_idx, 'question: ', r_allQuestions[r_idx]);
        moveToTheQuestion(config.MULTIPLE_PURCHASE_IDX);
      } else {
        addMessage(r_allQuestions[r_idx]);
      }
    } else if (
      r_allQuestions[r_idx]?.questionId === config.MARRIAGE_AFTER_IDX
    ) {
      saveAll();
    } else if (r_allQuestions.length > 0 && r_allQuestions[r_idx]) {
      addMessage(r_allQuestions[r_idx]);

      if (r_allQuestions[r_idx].questionType === 'SPOUSE_NAMECARD') {
        setTimeout(() => {
          toggleRecommendationPopup();
        }, 750);
      }
      // console.log('r_idx', r_idx, 'question', r_allQuestions[r_idx]);

      if (
        r_allQuestions[r_idx].questionType === 'COMMENT' ||
        r_allQuestions[r_idx].questionType === 'CAR_LIST' ||
        r_allQuestions[r_idx].questionType === 'APARTMENT_NAMECARD' ||
        r_allQuestions[r_idx].questionType === 'SELF_NAMECARD' ||
        r_allQuestions[r_idx].questionType === 'CAR_NAMECARD' ||
        r_allQuestions[r_idx].questionType === 'APT_LIST'
      ) {
        console.log('moveToNextQuestion');
        moveToNextQuestion();
      }

      // 아파트 상세주소 선택 추가
      if (r_allQuestions[r_idx].questionId === config.APT_REGION2_IDX) {
        switch (survey.r_apartmentAddress_1) {
          case '수도권':
            addLastMessageOption(config.CAPITAL_AREA);
            break;
          case '경상권':
            addLastMessageOption(config.KYUNGSANG_AREA);
            break;
          case '충청권':
            addLastMessageOption(config.CHOONGCHUNG_AREA);
            break;
          case '전라권':
            addLastMessageOption(config.JEONRA_AREA);
            break;
          case '강원권':
            addLastMessageOption(config.KWANGWON_AREA);
            break;
          case '제주권':
            addLastMessageOption(config.JEJU_AREA);
            break;
        }
      }
      if (r_allQuestions[r_idx]?.questionId === config.END_IDX) {
        console.log('end');
        console.log('survey.r_userId: ', survey.r_userId);
        requestReportCreate();
        if (survey.r_userId !== 90) {
          updateIsFinished(survey.r_orderId, true);
        }
        return;
      }
    }
  }, [r_idx]);

  return (
    <div className="w-full max-w-md bg-white h-[100dvh]">
      {(isRecommendationPopupOpen ||
        isServiceUnavailablePopupOpen ||
        isFirstNoticePopupOpen) && (
        <div
          className="z-30 bg-[#484644] bg-opacity-20 absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 w-full min-h-full h-max"
          ref={overlayRef}
          style={{ pointerEvents: 'none' }}
        />
      )}
      <div className="chatbot-window">
        <Header />
        <MessageWindow />
        <Footer />
        {survey.r_isCarListVisible && <CarList />}
        {survey.r_isApartmentListVisible && <ApartmentList />}
        {survey.r_isCalculatorVisible && <LoanInterestCalculatorContainer />}
        {survey.r_isPreviousAnswerPadVisible && <PreviousAnswerPad />}
        {isFirstNoticePopupOpen && (
          <AlertModal
            onClick={closeFirstNoticePopup}
            message={
              <>
                <strong className="text-xl font-semibold leading-[24px]">
                  잠깐!
                </strong>
                <div className="mt-2">
                  <ul className="list-disc ml-5 text-sm leading-[20px] text-[#8A8685]">
                    <li>1회성 콘텐츠입니다!</li>
                    <li>정확한 결과를 위해 신중히 답변 부탁드립니다.</li>
                    <li>
                      정확한 금액을 모르더라도 대략적인 금액은 괜찮지만, 임의의
                      숫자는 피해주세요.
                    </li>
                    <li className="font-bold text-orange">
                      내용을 잘못 입력하셨다면, 페이지 좌측 상단의 뒤로가기{' '}
                      {`[<]`}아이콘으로 이전 질문으로 돌아가 수정할 수 있습니다.
                    </li>
                    <li>단, 결과 보고서 생성 후에는 수정이 어렵습니다!</li>
                  </ul>
                </div>
              </>
            }
          />
        )}
        {isRecommendationPopupOpen && (
          <AlertModal
            onClick={handleRecommendation}
            onClose={handleNoRecommendation}
            message={`완성한 배우자 프로필과 유사한 사람을
                여러 명 찾았어요! 추천 받으시겠어요?`}
            buttonText="추천 받으러 가기"
            subButtonText="아니오, 괜찮습니다."
            imgUrl={SPOUSE_IMG_URL}
          />
        )}
        {isServiceUnavailablePopupOpen && (
          <AlertModal
            onClick={toggleServiceUnavailablePopup}
            message={
              <>
                <strong>현재 해당 서비스는 준비 중입니다!!</strong>
                <p>
                  곧 다양한 배우자 추천을 받아보실 수 있도록 준비 중이니 조금만
                  기다려 주세요!
                </p>
              </>
            }
            imgUrl={STAR_CLOUD_URL}
          />
        )}
      </div>
    </div>
  );
};

export default ChatbotPage;
