export const currentDateTime = () => {
  const today = new Date();
  const formattedDate = today.toLocaleDateString('ko-KR', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
  const formattedTime = today.toLocaleTimeString('ko-KR', {
    hour: '2-digit',
    minute: '2-digit'
  });
  return { formattedDate, formattedTime };
};
