import React from 'react';
import MobilePhoneForm from './MobilePhoneForm';

const MobilePhoneFormWrapper = ({ type, nextStep, prevStep, setUserData }) => {
  const title =
    type === 'reset' ? '비밀번호 재설정' : '휴대전화번호를 알려주세요!';
  const description =
    type === 'reset'
      ? `비밀번호를 재설정하려면 아래에 로그인 시\n사용했던 휴대전화번호를 입력해주세요.`
      : `회원가입, 로그인, 비밀번호 재설정에\n사용될 휴대전화번호를 입력해주세요.`;
  const url = type === 'reset' ? '/aligo/send2' : '/aligo/send';

  return (
    <MobilePhoneForm
      title={title}
      description={description}
      url={url}
      nextStep={nextStep}
      prevStep={prevStep}
      setUserData={setUserData}
    />
  );
};

export default MobilePhoneFormWrapper;
