import React from 'react';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import ReactMarkdown from 'react-markdown';
import RadarChartExample from '../../chart/RadarChartExample';

const FinancialScoreReport = ({ data = {} }) => {
  return (
    <div className="report-container">
      <section className="px-[30px] pt-6 pb-2">
        <p className="text-[18px] font-bold leading-[100%] mb-3">
          <span className="mr-[6px]">📊</span>
          나의 재무 점수는 몇 점 일까요?
        </p>
        <p className="text-sm text-l inline leading-[170%]">
          동일한 나이대의 소득과 지출 통계를 바탕으로 두 분의 재무 상태를
          종합적으로 평가한 결과를 알려드립니다.
        </p>
      </section>
      <section className="p-6 pt-2">
        <TabGroup>
          <TabList className="tab-list">
            <Tab className="tab-list-item">{data.userName}</Tab>
            <Tab className="tab-list-item">배우자</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <PersonalFinancialScore data={data.self} />
            </TabPanel>
            <TabPanel>
              <PersonalFinancialScore data={data.spouse} />
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </section>
    </div>
  );
};

const PersonalFinancialScore = ({ data = {} }) => {
  const { plot = [], text = '' } = data;
  return (
    <div className="mt-4 flex flex-col gap-6">
      <section className="report-shadow rounded-2xl">
        <div className="pt-4">
          <RadarChartExample plot={[plot[1], plot[3], plot[2]]} />
        </div>
        <div className="px-6 py-2 flex flex-col justify-center items-center gap-[2px] bg-[#FFF7E6] rounded-b-2xl">
          <p className="text-xs text-[#FF8440] leading-[140%]">종합 점수</p>
          <p className="text-sm text-orange font-bold leading-[140%]">
            {plot[4]}점 / 상위 {plot[5]}%
          </p>
        </div>
      </section>
      <section>
        <ReactMarkdown className="text-[14px] font-normal leading-[170%]">
          {text}
        </ReactMarkdown>
      </section>
    </div>
  );
};

export default FinancialScoreReport;
