import React from 'react';
import ReactMarkdown from 'react-markdown';
import ProgressBar from '../component/ProgressBar';
import { calculateProportionalPercentage } from '../../../util/calculatePercentage';
import { formatKoreanCurrency } from '../../../util/formatKoreanCurrency';

const SmartSaverPlanReport = ({ data = {} }) => {
  const { plot = [], text = '' } = data;
  const percentage = calculateProportionalPercentage([plot[1], plot[2]]);

  return (
    <div className="report-container">
      <section className="p-6 pb-4 flex flex-col">
        <div className="flex flex-col justify-between gap-6 px-6 py-5 report-shadow rounded-2xl">
          <p className="text-base font-bold leading-[140%]">
            스마트한 저축 플랜 제안
          </p>
          <div className="flex flex-col gap-[10px] w-full">
            <label className="text-end text-sm font-medium leading-[100%]">
              월 저축액 {plot[0]}만원
            </label>
            <ProgressBar
              plot={[
                formatKoreanCurrency(plot[1] * 10000),
                formatKoreanCurrency(plot[2] * 10000)
              ]}
              bg1="progress-purple-gradient"
              bg2="#D8C0DA"
              percentage={percentage}
            >
              <div className="flex justify-between mt-[10px] h-[17px]">
                <div className="flex items-center">
                  <div className="inline h-[12px] w-[12px] rounded-full bg-progress-purple-gradient" />
                  <span className="text-xs leading-[140%] ml-1">투자자산</span>
                </div>
                <div className="flex items-center">
                  <div className="inline h-[12px] w-[12px] rounded-full bg-[#D8C0DA]" />
                  <span className="text-xs leading-[140%] ml-1">
                    현금성자산
                  </span>
                </div>
              </div>
            </ProgressBar>
          </div>
        </div>
      </section>
      <section className="w-full pt-4 px-[30px] pb-6">
        <ReactMarkdown className="text-[14px] font-normal leading-[24px]">
          {text}
        </ReactMarkdown>
      </section>
    </div>
  );
};

export default SmartSaverPlanReport;
