import React from 'react';

const ResetButton = ({ onClick, children }) => {
  return (
    <button
      onClick={onClick}
      className="flex justify-center items-center w-full py-[10px] px-[10px] rounded-[5px] text-sm font-semibold border border-grayDark2"
    >
      {children}
    </button>
  );
};

export default ResetButton;
