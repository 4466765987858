import React from 'react';
import ReactMarkdown from 'react-markdown';
import { formatKoreanCurrency } from '../../../util/formatKoreanCurrency';

const ScenarioReport = ({ order = 1, data = {} }) => {
  const { plot = [], text = '', text2 = '' } = data;

  const riskColor = getRiskColor(plot[3]);

  return (
    <div className="report-container">
      <section className="p-6 pb-4 flex flex-col gap-2">
        <p className="text-orange text-xs font-bold leading-[100%]">
          시나리오 {order}.
        </p>
        {plot[0] > 0 ? (
          <h2 className="font-bold leading-[150%]">
            월 저축액을 {plot[0]}만 원 늘리고,
            <br />
            예금과 투자 자산을 {plot[1]} 비율로 조정
          </h2>
        ) : (
          <h2 className="font-bold leading-[150%]">
            예금과 투자 자산을 {plot[1]} 비율로 조정
          </h2>
        )}
        <p className="text-sm leading-[170%]">{text}</p>
      </section>
      <section className="px-6 py-4 flex flex-col gap-6">
        <div className="flex justify-between items-end">
          <div className="flex flex-col gap-2">
            <h3 className="text-sm font-bold leading-[100%] text-[#82817F]">
              핀베리 추천 점수
            </h3>
            <span className="text-[32px] text-[#291334] font-bold leading-[100%]">
              {plot[2]}점
            </span>
          </div>
          <div
            className="w-[104px] h-[34px] flex justify-center items-center rounded"
            style={{ background: riskColor }}
          >
            <span className="text-sm font-bold leading-[100%] text-white">
              {plot[3]}
            </span>
          </div>
        </div>
        <div>
          <div className="flex justify-between py-3 border-b border-[#EBEBEB]">
            <span className="text-sm font-medium leading-[100%] text-[#82817F]">
              수익률 범위
            </span>
            <span className="text-sm font-bold leading-[100%]">{plot[4]}</span>
          </div>
          <div className="flex justify-between py-3 border-b border-[#EBEBEB]">
            <span className="text-sm font-medium leading-[100%] text-[#82817F]">
              평균 예상 자산액
            </span>
            <span className="text-sm font-bold leading-[100%]">
              {formatKoreanCurrency(plot[5] * 10000)}원
            </span>
          </div>
          <div className="flex justify-between py-3 border-b border-[#EBEBEB]">
            <span className="text-sm font-medium leading-[100%] text-[#82817F]">
              최대 상승폭 일 때
            </span>
            <span className="text-sm font-bold leading-[100%]">
              {formatKoreanCurrency(plot[6] * 10000)}원
            </span>
          </div>
          <div className="flex justify-between py-3 border-b border-[#EBEBEB]">
            <span className="text-sm font-medium leading-[100%] text-[#82817F]">
              최대 하락폭 일 때
            </span>
            <span className="text-sm font-bold leading-[100%]">
              {formatKoreanCurrency(plot[7] * 10000)}원
            </span>
          </div>
          <div className="flex justify-between py-3 border-b border-[#EBEBEB]">
            <span className="text-sm font-medium leading-[100%] text-[#82817F]">
              손실 확률
            </span>
            <span className="text-sm font-bold leading-[100%]">{plot[8]}%</span>
          </div>
        </div>
      </section>
      <section className="px-[30px] pt-2 pb-6">
        <ReactMarkdown className="text-sm leading-[170%]">
          {text2}
        </ReactMarkdown>
      </section>
    </div>
  );
};

function getRiskColor(risk) {
  const riskColors = {
    '낮은 위험': '#0B792F',
    '중간 위험': '#FF900C',
    '높은 위험': '#DB4A1A'
  };

  return riskColors[risk] || '#0B792F';
}

export default ScenarioReport;
