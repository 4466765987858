import React from 'react';
import { Radar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const RadarChartExample = ({ plot }) => {
  const getGradient = (ctx, chartArea) => {
    const gradient = ctx.createLinearGradient(
      0,
      chartArea.top,
      0,
      chartArea.bottom
    );
    gradient.addColorStop(0, 'rgba(255, 144, 12, 0.7)');
    gradient.addColorStop(1, 'rgba(255, 99, 25, 0.7)');
    return gradient;
  };

  const data = {
    labels: ['순자산', '저축', '소득'],
    datasets: [
      {
        label: '점수',
        data: plot,
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return '#FF900C';
          }
          return getGradient(ctx, chartArea) || '#FF900C';
        },
        borderColor: '#FF6319',
        borderWidth: 1,
        pointBackgroundColor: '#FF6319',
        pointBorderColor: '#FF6319'
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      r: {
        angleLines: {
          display: true // 축 각도선 표시
        },
        grid: {
          color: '#EBEBEB' // 격자선 색상
        },
        ticks: {
          display: false // 내부 점수 레이블 숨기기
        },
        suggestedMin: 0,
        suggestedMax: 100,
        pointLabels: {
          display: true,
          font: {
            size: 12,
            family: 'Pretendard'
          },
          color: '#484644',
          callback: (value, index) => {
            const scores = plot;
            const labels = ['순자산', '저축', '소득'];
            const result = `${labels[index]}\n${scores[index]}점`;
            return result.split('\n');
          }
        }
      }
    },
    plugins: {
      legend: {
        display: false // 범례 숨김
      },
      datalabels: {
        display: false
      }
    },
    layout: {
      padding: 0
    },
    animation: {
      duration: 0
    }
  };

  return (
    <div
      style={{
        width: '300px',
        height: '300px',
        margin: '0 auto'
      }}
    >
      <Radar data={data} options={options} />
    </div>
  );
};

export default RadarChartExample;
