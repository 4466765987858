import React, { useState } from 'react';
import RightArrowIcon from '../../icon/RightArrowIcon';
import ReactMarkdown from 'react-markdown';
import LineChartExample from '../../chart/LineChartExample';
import AssetFlowPopup from '../popupReport/AssetFlowPopup';
import { formatKoreanCurrency } from '../../../util/formatKoreanCurrency';

const RetirementNetWorthReport = ({ data = {} }) => {
  const { plot = [], text = '' } = data;
  const maxValue = Math.max(...(plot[0]?.plot_data_1?.assets || []), 0);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => setIsPopupOpen(true);
  const closePopup = () => setIsPopupOpen(false);

  if (isPopupOpen) {
    return <AssetFlowPopup close={closePopup} />;
  }

  return (
    <div className="report-container">
      <section className="p-6 pb-2">
        <div className="flex flex-col gap-2">
          <p className="text-xs text-orange font-bold leading-[100%]">
            예상 소득액 2.
          </p>
          <p className="text-[16px] font-bold leading-[100%]">
            <span className="mr-[6px]">👛</span>
            은퇴 시기 부부의 순자산
          </p>
          <ReactMarkdown className="text-[14px] font-normal leading-[170%]">
            {text}
          </ReactMarkdown>
        </div>
      </section>
      <section className="px-6 py-4 w-full">
        <div className="w-full report-shadow rounded-lg pb-2">
          <p className="px-4 pt-4 pb-2">
            <span className="text-[15px] font-semibold leading-[24px] mr-1">
              최고점
            </span>
            <span className="text-xs font-semibold leading-[24px] text-orange">
              {formatKoreanCurrency(Math.round(maxValue / 10) * 10 * 1000)} 원
            </span>
          </p>
          <LineChartExample
            data1={plot[0]?.plot_data_1}
            data2={plot[0]?.plot_data_2}
          />
        </div>
      </section>
      <section className="px-6 py-3 pb-5">
        <button
          onClick={openPopup}
          className="w-full px-4 py-[10px] flex items-center justify-between border border-[#E5E5E5] rounded-[10px] bg-[#F8F8F8]"
        >
          <p className="text-left text-sm font-medium leading-[24px] text-[#6F6D6C]">
            💡 자산 흐름 그래프는 어떻게 계산되었나요?
          </p>
          <RightArrowIcon className="text-[#6F6D6C]" size={16} />
        </button>
      </section>
    </div>
  );
};

export default RetirementNetWorthReport;
