import React from 'react';
import FocusLock from 'react-focus-lock';

const LayerPopup = ({ close, fileContent, title = '' }) => {
  return (
    <FocusLock>
      <div className="fixed bottom-4 w-full p-2 h-3/5 max-w-md z-50 overflow-hidden">
        <div className="h-full py-6 border-none rounded-2xl bg-white shadow-[0px_6px_12px_-6px_rgba(43,22,12,0.10),0px_8px_24px_-4px_rgba(43,22,12,0.06)]">
          <header className="px-6 py-2 border-b border-grayLight3 text-black flex justify-between items-center">
            <div className="header-title">
              <h1 className="text-base font-bold">
                {title || '이용약관 상세'}
              </h1>
            </div>
          </header>

          <div className="px-6 py-4 whitespace-pre-line text-black text-xs font-normal leading-[24px] overflow-y-auto h-4/5">
            {fileContent}
          </div>

          <div className="h-10 w-full mt-1 flex justify-center">
            <button
              onClick={close}
              className="w-full h-full text-[15px] font-semibold text-[#82817F]"
            >
              닫기
            </button>
          </div>
        </div>
      </div>
    </FocusLock>
  );
};

export default LayerPopup;
