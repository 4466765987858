import React from 'react';

const ProgressBar = ({
  plot = [],
  bg1 = 'progress-gradient',
  bg2 = '#FFBE5C',
  percentage = '0%',
  isFull = false,
  showPlot2 = true,
  children
}) => {
  return (
    <div>
      <div className="relative">
        <div
          className="w-full h-10 rounded-[8px] relative flex"
          style={{ background: bg2 }}
        >
          <div
            className={`h-full rounded-s-[8px] relative max-w-full bg-${bg1} ${isFull ? 'rounded-e-[8px]' : ''}`}
            style={{ width: `${percentage}` }}
          >
            <p
              className={`text-white text-base leading-[100%] font-bold absolute top-1/2 ${showPlot2 ? 'left-3' : 'right-3'} transform -translate-y-1/2`}
            >
              {plot[0]}원
            </p>
          </div>
          {showPlot2 && (
            <p className="text-white text-base leading-[100%] font-bold absolute top-1/2 right-3 transform -translate-y-1/2">
              {plot[1]}원
            </p>
          )}
        </div>
      </div>
      {children}
    </div>
  );
};

export default ProgressBar;
