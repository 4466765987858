import { useRecoilState } from 'recoil';
import { surveyState } from '../recoil/atoms';
export const useAddMessage = () => {
  const [survey, setSurvey] = useRecoilState(surveyState);
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const addMessage = async (newMessage) => {
    // console.log('survey.r_messages', survey.r_messages);
    // console.log('newMessage', newMessage);
    // console.log('survey.r_idx', survey.r_idx);
    // console.log('survey.r_messages', survey.r_messages);
    if (!newMessage) {
      return;
    }
    const lastMessage = survey.r_messages[survey.r_messages.length - 1];

    if (
      lastMessage?.content?.questionText === newMessage?.questionText &&
      lastMessage?.role === 'assistant'
    ) {
      return;
    }
    if (!lastMessage || lastMessage.role === 'user') {
      // console.log('Finberry Label 달자');
      // await delay(1000);
      await setSurvey((prevSurvey) => ({
        ...prevSurvey,
        r_messages: [
          ...prevSurvey.r_messages,
          { role: 'finberry', content: { questionText: '핀베리' } },
          { role: 'assistant', content: newMessage }
        ]
      }));
    } else {
      if (lastMessage !== newMessage?.questionText) {
        // await delay(1000);
        await setSurvey((prevSurvey) => ({
          ...prevSurvey,
          r_messages: [
            ...prevSurvey.r_messages,
            { role: 'assistant', content: newMessage }
          ]
        }));
      }
    }
    await delay(1000);
  };
  return addMessage;
};
