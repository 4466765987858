import React from 'react';
import '../../style/Header.css';

const ReportHeader = ({ onClick = () => {}, title = '' }) => {
  return (
    <header className="report-header-container">
      <div className="header-title">
        <h1 className="text-[15px] font-medium leading-[100%] tracking-[-0.408px]">
          {title}
        </h1>
      </div>
      <button onClick={onClick} className="close-text">
        <span className="text-[15px] font-semibold leading-[100%] text-orange tracking-[-0.408px]">
          닫기
        </span>
      </button>
    </header>
  );
};

export default ReportHeader;
