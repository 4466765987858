import { useRecoilState } from 'recoil';
import { surveyState } from '../recoil/atoms';

export const useDeleteSelectedOptions = () => {
  const [survey, setSurvey] = useRecoilState(surveyState);

  const deleteSelectedOptions = () => {
    setSurvey((prevSurvey) => {
      const updatedMessages = [...prevSurvey.r_messages];
      const lastMessage = { ...updatedMessages[updatedMessages.length - 1] };
      if (lastMessage.content && lastMessage.content.options) {
        lastMessage.content = { ...lastMessage.content, options: [] };
      }
      updatedMessages[updatedMessages.length - 1] = lastMessage;
      return {
        ...prevSurvey,
        r_messages: updatedMessages
      };
    });
  };

  return deleteSelectedOptions;
};
