import mixpanel from 'mixpanel-browser';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { v4 as uuidv4 } from 'uuid';
import AlertModal from '../component/AlertModal';
import MyPageSlidePannel from '../component/MyPageSlidePannel';
import ReportContainer from '../component/report/ReportContainer';
import ReviewPopup from '../component/report/ReviewPopup';
import ImageCarousel from '../component/ImageCarousel';
import { config } from '../config';
import { useFetchUser } from '../hook/useFetchUser';
import { useReportPopup } from '../hook/useReportPopup';
import { useSetSurveyValue } from '../hook/useSetSurveyValue';
import { surveyState } from '../recoil/atoms';
import { isAuthenticatedState } from '../recoil/userAtom';

const LOGIN_ROUTE = '/user/login';
const TOSS_PAY_ROUTE = '/checkout/cart';
const CHAT_ROUTE = '/chat';
const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;

if (MIXPANEL_TOKEN) {
  mixpanel.init(MIXPANEL_TOKEN, {
    debug: process.env.NODE_ENV === 'development',
    track_pageview: true
  });
} else {
  console.error('Mixpanel token is not defined');
}

const CAROUSEL_IMAGES = [
  '10-1',
  '10-2',
  '10-3',
  '10-4',
  '10-5',
  '10-6',
  '10-7',
  '10-8',
  '10-9',
  '10-10',
  '10-11',
  '10-12',
  '10-13',
  '10-14',
  '10-15',
  '10-16',
  '10-17',
  '10-18',
  '10-19'
];
const MAIN_IMG_LIST = [2, 3, 4, 5, 6, 7, 8, 9, 'carousel', 11, 12, 13, 14];

const MainPage = () => {
  const isAuthenticated = useRecoilValue(isAuthenticatedState);
  const [isLoginAlertOpen, setIsLoginAlertOpen] = useState(false);
  const [isRePurchaseAlertOpen, setIsRePurchaseAlertOpen] = useState(false);
  const survey = useRecoilValue(surveyState);
  const [showButton, setShowButton] = useState(false);
  const refWrapper = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { initializeUser } = useFetchUser();
  const { isOpen, openPopup, closePopup } = useReportPopup();
  const [isReviewPopupOpen, setIsReviewPopupOpen] = useState(false);
  const { price } = config.FINBERRY_INFO;

  const setSurveyValue = useSetSurveyValue();
  const resetSurvey = useResetRecoilState(surveyState);
  const isMyPageOpen =
    new URLSearchParams(location.search).get('mypage') === 'true';

  const goToLogin = () => navigate(LOGIN_ROUTE);
  const goToChat = () => navigate(CHAT_ROUTE);
  const goToCart = () => navigate(TOSS_PAY_ROUTE);

  const toggleMyPage = () => {
    const queryParams = new URLSearchParams(location.search);
    if (isMyPageOpen) {
      queryParams.delete('mypage');
    } else {
      queryParams.set('mypage', 'true');
    }
    navigate({ search: queryParams.toString() });
  };

  const handleAlert = () => {
    const validProducts = survey.r_paymentHistories.filter(
      (item) => item?.paymentHistory.isRefunded === false
    );
    const isRepurchase =
      validProducts.length > 0 &&
      validProducts.every((item) => item?.paymentHistory.isFinished === true);

    const isLegit = survey.r_paymentHistories.some(
      (item) =>
        item?.paymentHistory.isRefunded === false &&
        item?.paymentHistory.isFinished === false
    );

    if (!isAuthenticated) {
      // 로그인 하지 않은 경우
      toggleLoginAlert();
      return;
    }
    if (isRepurchase) {
      // 재구매 - 정상(환불x)건 모두 채팅 완료한 경우
      toggleRePurchaseAlert();
      return;
    }
    if (isLegit) {
      // 정상(환불x)건 중에 채팅 완료하지 않은 경우
      goToChat();
      return;
    }

    goToCart();
  };

  const toggleLoginAlert = () => {
    setIsLoginAlertOpen((prev) => !prev);
  };

  const toggleRePurchaseAlert = () => {
    setIsRePurchaseAlertOpen((prev) => !prev);
  };

  const openReport = (orderId) => {
    openPopup(orderId);
  };

  const openReviewPopup = (e) => {
    setIsReviewPopupOpen(true);
  };

  const closeReviewPopup = (e) => {
    setIsReviewPopupOpen(false);
  };

  const handleScroll = () => {
    const scrollTop = refWrapper.current?.scrollTop || 0;
    setShowButton(scrollTop > 10);
  };

  useEffect(() => {
    initializeUser();
    const uuid = uuidv4();
    resetSurvey();
    setSurveyValue('r_sessionId', uuid);
    const paymentHistories = [...survey.r_paymentHistories].sort(
      (a, b) =>
        new Date(b.paymentHistory.createdAt) -
        new Date(a.paymentHistory.createdAt)
    );
    paymentHistories.forEach((item) => {
      if (!item.paymentHistory.isFinished) {
        console.log('r_orderId: ', item.paymentHistory.orderId);
        setSurveyValue('r_orderId', item.paymentHistory.orderId);
      }
    });
  }, []);

  if (isOpen) {
    return <ReportContainer onClose={closePopup} />;
  }

  if (isReviewPopupOpen) {
    return <ReviewPopup closeReviewPopup={closeReviewPopup} />;
  }

  return (
    <div
      ref={refWrapper}
      onScroll={handleScroll}
      className={`w-full max-w-md h-[100dvh] bg-purple relative ${isMyPageOpen ? 'overflow-hidden' : 'overflow-auto'}`}
    >
      {/* 화면 배경 오버레이 */}
      {isMyPageOpen && (
        <div
          className="fixed inset-0 w-full bg-[#242424] bg-opacity-10 z-40"
          onClick={toggleMyPage}
        />
      )}
      {/* 마이페이지 슬라이드 패널 */}
      {isMyPageOpen && (
        <div
          className={`absolute top-0 right-0 h-[100dvh] overflow-y-auto w-4/5 max-w-sm bg-white shadow-lg transform transition-all duration-1000 ease-in-out ${
            isMyPageOpen
              ? 'translate-x-0 opacity-100'
              : 'translate-x-full opacity-0'
          } z-50`}
        >
          <MyPageSlidePannel
            toggleMyPage={toggleMyPage}
            openReport={openReport}
            goToChat={goToChat}
            openReviewPopup={openReviewPopup}
            goToCart={goToCart}
          />
        </div>
      )}

      {/* 메인 화면 */}
      <div className="relative w-full aspect-[112/200] pt-8 px-6">
        {isLoginAlertOpen && (
          <AlertModal
            onClick={goToLogin}
            onClose={toggleLoginAlert}
            className="top-[32%] left-1/2 transform -translate-x-1/2"
            message={
              <>
                <p className="text-[20px] font-semibold leading-[24px]">
                  로그인이 필요한 서비스입니다!
                </p>
                <p className="mt-2 text-[14px] leading-[20px] text-[#8A8685]">
                  핀베리는 로그인이 필요한 서비스입니다.
                  <br />
                  로그인 먼저 하신 후 이용해주세요!
                </p>
              </>
            }
          />
        )}
        {isRePurchaseAlertOpen && (
          <AlertModal
            onClick={goToCart}
            onClose={toggleRePurchaseAlert}
            className="top-[32%] left-1/2 transform -translate-x-1/2"
            message={
              <>
                <p className="text-[20px] font-semibold leading-[24px]">
                  이미 해당 상품의 결제 내역이 있습니다. 계속 구매하시겠습니까?
                </p>
                <p className="mt-2 text-[14px] leading-[20px] text-[#8A8685]">
                  재구매 시, 채팅이 처음부터 진행되며 새로운 결과 보고서가
                  생성됩니다.
                </p>
              </>
            }
            flexButton={true}
            buttonText="계속하기"
            subButtonText="취소"
          />
        )}
        <header className="flex justify-between items-center h-[30px]">
          <img
            src="/picture/finberry-white.png"
            alt="logo"
            className="w-auto h-full"
          />
          {isAuthenticated ? (
            <button onClick={toggleMyPage} className="h-full">
              <img
                src="/picture/icons/logo-profile.png"
                alt="profile"
                className="w-auto h-full"
              />
            </button>
          ) : (
            <Link to={LOGIN_ROUTE} className="flex">
              <span className="text-white text-[14px] font-medium tracking-[-0.28px] mr-1">
                Log in / Join
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M8 6L12 10L8 14"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Link>
          )}
        </header>
        <div className="mt-[26px] pt-[18px] rounded-2xl text-white bg-gradient-to-br from-[#ec5b16] to-[#ffad45]">
          <div className="flex justify-end mb-[18px]">
            <span className="w-[84px] text-center py-1 mr-[18px] bg-black rounded-full text-sm font-semibold leading-normal tracking-[-0.28px]">
              ₩ {price.toLocaleString()}
            </span>
          </div>
          <div>
            <p className="text-white text-center text-[32px] font-bold leading-[120%] tracking-[-0.64px]">
              결혼부터 은퇴까지
            </p>
            <p className="text-white text-center text-[32px] font-bold leading-[120%] tracking-[-0.64px]">
              AI를 활용한
            </p>
            <p className="text-white text-center text-[32px] font-bold leading-[120%] tracking-[-0.64px]">
              프리미엄 재무 설계
            </p>
          </div>
          <div className="my-5 text-center text-white text-[16px] leading-[150%] tracking-[-0.32px]">
            <p className="font-light">
              <span className="font-semibold">AI 재무 코칭 챗봇 ‘핀베리’</span>
              로
            </p>
            <p className="font-light">미래 자산의 변화를 예측해보세요!</p>
          </div>
          <div className="flex justify-center">
            <button
              id="payment-button-fixed"
              onClick={handleAlert}
              className="w-[222px] rounded-full py-3 text-lg font-bold tracking-[-0.36px] bg-white text-orange leading-[150%]"
            >
              {price.toLocaleString()}원 구매하기
            </button>
          </div>

          <div className="w-full">
            <img
              src="/picture/main/simulation-sample.png"
              alt="simulation sample"
              className="w-full h-auto"
            />
          </div>
        </div>

        <div className="pt-[26px] pl-[18px]">
          <span className="text-xs text-white">
            * 본 콘텐츠의 최대 이용기간은 1년입니다.
          </span>
        </div>
      </div>

      <div>
        {MAIN_IMG_LIST.map((img, index) => (
          <div key={index}>
            {img === 'carousel' ? (
              <div className="w-full h-[760px] flex flex-col justify-center items-center bg-[#502660]">
                <div className="w-full">
                  <ImageCarousel carouselImages={CAROUSEL_IMAGES} />
                </div>
                <div className="mt-10">
                  <p className="text-xl text-center font-bold leading-[140%] tracking-[-0.4px] text-white">
                    더 구체적인 진단 결과는
                  </p>
                  <p className="text-xl text-center leading-[140%] tracking-[-0.4px] text-white">
                    핀베리 사용 후 자세히 확인해보세요!
                  </p>
                </div>
              </div>
            ) : (
              <img
                src={`/picture/main/${img}.png`}
                alt={`${img}`}
                className="w-full h-auto"
              />
            )}
          </div>
        ))}
      </div>

      <div className="pb-32 bg-white">
        <footer>
          <div className="flex justify-center pt-10 pb-8">
            <img
              src="/picture/finberry.png"
              alt="logo"
              className="h-[34px] w-auto"
            />
          </div>
          <div className="text-[10px] text-center leading-[160%] tracking-[-0.2px] text-[rgba(43,22,12,0.70)]">
            <p>주식회사 비해피 Company Reg. 224-88-02373</p>
            <p>CEO 이도경 Network Reg. 제 2025 -충남아산-0016 호</p>
            <p>(본점)</p>
            <p>충청남도 아산시 배방읍 희망로46번길 45-11, 305호</p>
            <p>(기업부설연구소)</p>
            <p>
              서울특별시 영등포구 의사당대로83, 오투타워 5층 115호 서울핀테크랩
            </p>
            <p>E-mail setmefree15@naver.com</p>
            <p>Tel 070-4578-4998</p>
          </div>
        </footer>
      </div>

      {/* 스크롤 위치에 따라 나타나는 하단 고정 버튼 */}
      {showButton && (
        <div className="fixed z-50 flex justify-center bottom-8 left-0 right-0 mx-6">
          <button
            id="payment-button-float"
            onClick={handleAlert}
            className="w-[345px] block px-[10px] rounded-full py-3 text-lg font-bold tracking-[-0.36px] bg-white text-orange leading-[150%] shadow-[0px_4px_0px_0px_#DADADA]"
          >
            {price.toLocaleString()}원 구매하기
          </button>
        </div>
      )}
    </div>
  );
};

export default MainPage;
