import React from 'react';
import { useNavigate } from 'react-router-dom';
import LeftArrowIcon from '../icon/LeftArrowIcon';

const BackHeader = ({ goToPrev, className = 'left-[-3px]' }) => {
  const navigate = useNavigate();

  return (
    <div className={`relative h-[42px] ${className}`}>
      <button
        onClick={() => (goToPrev ? goToPrev() : navigate(-1))}
        aria-label="뒤로 가기"
      >
        <LeftArrowIcon className="text-black" />
      </button>
    </div>
  );
};

export default BackHeader;
