import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userState } from '../recoil/userAtom';
import { surveyState } from '../recoil/atoms';
import { logout } from '../util/logout';
import RightArrowIcon from './icon/RightArrowIcon';
import dayjs from 'dayjs';
import ProductBox from './ProductBox';
import { addKakaoChannel } from '../util/addKakaoChannel';

const MyPageSlidePannel = ({
  toggleMyPage,
  openReport,
  goToChat,
  openReviewPopup,
  goToCart
}) => {
  const navigate = useNavigate();
  const user = useRecoilValue(userState);
  const survey = useRecoilValue(surveyState);
  const validProducts =
    (survey.r_paymentHistories &&
      survey.r_paymentHistories?.length > 0 &&
      survey.r_paymentHistories?.filter(
        (item) => !item.paymentHistory.isRefunded
      )) ||
    [];
  const latestPayment =
    (validProducts.length > 0 &&
      validProducts.reduce((latest, current) =>
        dayjs(current.paymentHistory.createdAt).isAfter(
          dayjs(latest.paymentHistory.createdAt)
        )
          ? current
          : latest
      )) ||
    {};
  const hasReviewed = survey.r_reviewed;

  const openPolicyDetail = async (id) => {
    navigate(`/policy/?id=${id}`);
  };

  useEffect(() => {
    if (!user) {
      toggleMyPage();
    }
  }, [user, toggleMyPage]);

  return (
    <div>
      <section className="p-12 pb-6 flex flex-col items-center gap-2">
        <img
          src="/picture/icons/logo-profile.png"
          alt="logo profile"
          width={64}
          height={64}
        />
        <span className="text-sm font-semibold tracking-[-0.28px]">
          {user?.fullName}
        </span>
        {user?.login_type === 'kakao' ? (
          <div className="flex items-center gap-1">
            <img
              src="/picture/icons/kakao-logo.png"
              alt="카카오 로고"
              width={18}
              height={18}
            />
            <span className="text-xs text-[#82817F] font-semibold tracking-[-0.24px]">
              카카오 간편 로그인
            </span>
          </div>
        ) : user?.login_type === 'naver' ? (
          <div className="flex items-center gap-1">
            <img
              src="/picture/icons/naver-logo.png"
              alt="네이버 로고"
              width={18}
              height={18}
            />
            <span className="text-xs text-[#82817F] font-semibold tracking-[-0.24px]">
              네이버 간편 로그인
            </span>
          </div>
        ) : (
          <span className="text-xs text-[#82817F] font-semibold leading-[140%] tracking-[-0.24px]">
            {/* 회원 정보 */}
          </span>
        )}
      </section>
      <section className="py-4 px-6">
        <div className="flex items-center mb-4">
          {validProducts.length > 0 ? (
            <Link to="/member/mycontents" className="inline-flex items-center">
              <h2 className="text-base font-semibold leading-[140%] tracking-[-0.32px] mr-1">
                MY 콘텐츠
              </h2>
              <RightArrowIcon className="text-black" size={16} />
            </Link>
          ) : (
            <h2 className="text-base font-semibold leading-[140%] tracking-[-0.32px] mr-1">
              MY 콘텐츠
            </h2>
          )}
        </div>
        {latestPayment.paymentHistory ? (
          <ProductBox
            product={latestPayment}
            openReport={openReport}
            goToChat={goToChat}
            openReviewPopup={openReviewPopup}
            goToCart={goToCart}
            hasReviewed={hasReviewed}
          />
        ) : (
          <div className="my-2 h-[68px] flex flex-col justify-center items-center gap-2">
            <img
              src="/picture/icons/warning-square.png"
              alt="warning"
              width={32}
              height={32}
            />
            <span className="text-xs text-grayDark3 font-normal leading-[140%] tracking-[-0.24px]">
              아직 구매한 콘텐츠가 없어요
            </span>
          </div>
        )}
      </section>
      <section className="p-6">
        <ul className="space-y-6 ml-0 text-black text-[15px] text-normal leading-[140%] tracking-[-0.3px]">
          <li className="flex items-center gap-4">
            <img
              src="/picture/icons/google-docs.png"
              alt="google-docs"
              width={20}
              height={20}
            />
            <button onClick={() => navigate('/member/purchase')}>
              구매 내역
            </button>
          </li>
          <li className="flex items-center gap-4">
            <img
              src="/picture/icons/megaphone.png"
              alt="megaphone"
              width={20}
              height={20}
            />
            <button onClick={() => openPolicyDetail(10000)}>공지사항</button>
          </li>
          <li className="flex items-center gap-4">
            <img
              src="/picture/icons/chat.png"
              alt="chat"
              width={20}
              height={20}
            />
            <button onClick={addKakaoChannel}>1:1 문의하기</button>
          </li>
          <li className="flex items-center gap-4">
            <img
              src="/picture/icons/shield-check.png"
              alt="shield-check"
              width={20}
              height={20}
            />
            <button onClick={() => openPolicyDetail(10002)}>
              개인정보 처리방침
            </button>
          </li>
          <li className="flex items-center gap-4">
            <img
              src="/picture/icons/page.png"
              alt="page"
              width={20}
              height={20}
            />
            <button onClick={() => openPolicyDetail(10001)}>
              서비스 이용약관
            </button>
          </li>
          <li className="flex items-center gap-4">
            <img
              src="/picture/icons/log-out.png"
              alt="log-out"
              width={20}
              height={20}
            />
            <button onClick={logout}>로그아웃하기</button>
          </li>
        </ul>
      </section>
    </div>
  );
};

export default MyPageSlidePannel;
