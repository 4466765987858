import { Link, useSearchParams } from 'react-router-dom';

const FailPage = () => {
  const [searchParams] = useSearchParams();
  const errorCode = searchParams.get('code') || 'PROVIDER_ERROR';
  const errorMessage =
    searchParams.get('message') ||
    '일시적인 오류가 발생했습니다. 잠시 후 다시 시도해주세요.';

  return (
    <div className="wrapper w-100">
      <div className="flex-column align-center w-100 max-w-540">
        <img
          src="https://static.toss.im/lotties/error-spot-apng.png"
          alt=""
          width="120"
          height="120"
        />
        <h2 className="payment-title">결제를 실패했어요</h2>
        <h3>결제를 다시 시도해주세요.</h3>
        <div className="response-section w-100">
          <div className="flex justify-between">
            <span className="response-label">code</span>
            <span id="error-code" className="response-text">
              {errorCode}
            </span>
          </div>
          <div className="flex justify-between">
            <span className="response-label">message</span>
            <span id="error-message" className="response-text">
              {errorMessage}
            </span>
          </div>
        </div>

        <div className="w-100 button-group">
          <Link className="btn" to="/checkout">
            다시 시도하기
          </Link>
          {/* <div className="flex" style={{ gap: '16px' }}>
            <a
              className="btn w-100"
              href="https://docs.tosspayments.com/reference/error-codes"
              target="_blank"
              rel="noreferrer noopener"
            >
              에러코드 문서보기
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default FailPage;
