import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
// import ChatbotPage from '../page/ChatbotPage';
import ChartPage from '../page/ChartPage';
import ChatbotPage from '../page/ChatbotPage';
import SimulationPage from '../page/SimulationPage';
import SurveyPopupPage from '../page/SurveyPopupPage';
import NotFoundPage from '../page/NotFoundPage';
import { useRecoilValue } from 'recoil';
import { surveyState } from '../recoil/atoms';

const ChatRoutes = () => {
  const survey = useRecoilValue(surveyState);
  const navigate = useNavigate();

  const isChatDisabled =
    survey.r_paymentHistories?.length > 0 &&
    survey.r_paymentHistories.every((item) => item.paymentHistory.isFinished);

  useEffect(() => {
    if (isChatDisabled) {
      navigate('/');
    }
  }, [isChatDisabled, navigate]);

  return (
    <Routes>
      <Route index element={<ChatbotPage />} />
      <Route path=":topic" element={<ChatbotPage />} />
      <Route path="/simulation" element={<SimulationPage />} />
      <Route path="/chart" element={<ChartPage />} />
      <Route path="/survey" element={<SurveyPopupPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default ChatRoutes;
