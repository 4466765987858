// 원리금 계산 함수
export const calculateLoan = ({
  principal, // 원금
  annualRate, // 연 이율 (예: 5% -> 5)
  repaymentTerm, // 상환기간 (년 단위)
  gracePeriod, // 대치기간 (년 단위)
  repaymentOption, // 상환 옵션 ("원리금 균등", "원금 균등", 또는 "만기일시")
  paymentFrequency = 12 // 상환 차수 (기본: 월 단위)
}) => {
  const monthlyRate = annualRate / 100 / paymentFrequency; // 월 이율 계산
  const totalPayments = repaymentTerm * paymentFrequency; // 총 상환 횟수 계산
  const gracePayments = gracePeriod * paymentFrequency; // 대치기간 동안의 상환 횟수
  const schedule = [];
  console.log('principal', principal);
  console.log('annualRate', annualRate);
  console.log('repaymentTerm', repaymentTerm);
  console.log('gracePeriod', gracePeriod);
  console.log('repaymentOption', repaymentOption);
  console.log('paymentFrequency', paymentFrequency);

  if (repaymentOption === '원리금 균등') {
    // 원리금 균등 방식
    const monthlyPayment =
      (principal * monthlyRate) /
      (1 - Math.pow(1 + monthlyRate, -totalPayments));

    for (let i = 1; i <= totalPayments; i++) {
      const interest = principal * monthlyRate; // 이자 계산
      const principalPayment = monthlyPayment - interest; // 상환 원금 계산

      const totalPayment =
        i <= gracePayments
          ? interest // 대치기간 동안은 이자만 납부
          : monthlyPayment; // 대치기간 이후에는 원금과 이자 합산

      schedule.push({
        month: i,
        principalPayment: i <= gracePayments ? 0 : principalPayment, // 대치기간 동안 원금 상환 없음
        interest: interest,
        totalPayment: totalPayment
      });

      if (i > gracePayments) {
        principal -= principalPayment; // 남은 원금 감소
      }
    }
  } else if (repaymentOption === '원금 균등') {
    // 원금 균등 방식
    const monthlyPrincipal = principal / totalPayments; // 매월 상환 원금

    for (let i = 1; i <= totalPayments; i++) {
      const interest = principal * monthlyRate; // 이자 계산
      const totalPayment =
        i <= gracePayments
          ? interest // 대치기간 동안은 이자만 납부
          : monthlyPrincipal + interest; // 대치기간 이후에는 원금과 이자 합산

      schedule.push({
        month: i,
        principalPayment: i <= gracePayments ? 0 : monthlyPrincipal, // 대치기간 동안 원금 상환 없음
        interest: interest,
        totalPayment: totalPayment
      });

      if (i > gracePayments) {
        principal -= monthlyPrincipal; // 남은 원금 감소
      }
    }
  } else if (repaymentOption === '만기일시') {
    // 만기일시 방식
    for (let i = 1; i <= totalPayments; i++) {
      const interest = principal * monthlyRate; // 이자 계산

      schedule.push({
        month: i,
        principalPayment: i < totalPayments ? 0 : principal, // 만기일에 원금 상환
        interest: interest,
        totalPayment: interest + (i === totalPayments ? principal : 0)
      });
    }
  } else {
    throw new Error(
      "Invalid repayment option. Use '원리금 균등', '원금 균등', or '만기일시'."
    );
  }

  // Rounding payments to avoid floating point precision issues
  return schedule.map((entry) => ({
    ...entry,
    principalPayment: Math.round(entry.principalPayment * 100) / 100,
    interest: Math.ceil(entry.interest),
    totalPayment: Math.ceil(entry.totalPayment)
  }));
};

// // 함수 사용 예시
// const loanDetails = {
//   principal: 10000000, // 1천만 원
//   annualRate: 5, // 연 5% 이율
//   repaymentTerm: 5, // 5년 상환
//   gracePeriod: 1, // 1년 대치기간
//   repaymentOption: "만기일시", // 원리금 균등 상환
// };

// const repaymentSchedule = calculateLoan(loanDetails);
// console.table(repaymentSchedule);
