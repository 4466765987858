import React from 'react';
import ReactDOM from 'react-dom';
import usePreventSwipe from '../../../hook/usePreventSwipe';
import BackHeader from '../../header/BackHeader';

const DSRPopup = ({ close }) => {
  const rootElement = document.getElementById('root'); // <div id="root"> 안에 렌더링
  usePreventSwipe('.popup-report-container'); // 터치 이동 방지 훅 호출

  return ReactDOM.createPortal(
    <div className="popup-report-container">
      <div className="px-4">
        <BackHeader goToPrev={close} className="flex items-center" />
      </div>
      <section className="p-6 pt-5 flex flex-col gap-6">
        <div>
          <h1 className="text-[18px] font-bold leading-[100%] mb-3">
            <span className="mr-[6px]">🛎️</span>
            소득 대비 대출 상환 비율 (DSR)이란?
          </h1>
          <p className="text-sm leading-[24px]">
            DSR은 대출을 갚는 데 드는 금액이 연소득에서 차지하는 비율을 말해요.
            총 대출 금액이 1억 원을 넘을 경우, DSR 40%를 초과하는 대출은 받을 수
            없어요.
            <br />
            <strong>
              1년 동안 갚아야 하는 원리금이 연소득의 40%(비은행권은 50%)를 넘지
              않도록 규제하는 제도입니다.
            </strong>
            <br />
            부부는 부부 소득 합산을 적용할 수 있어 대출 한도를 늘릴 수 있는
            방법이 있어요.
          </p>
        </div>
        <div className="flex items-center px-4 py-[10px] bg-[#F8F8F8] rounded-lg">
          <p className="text-sm font-medium leading-[24px] text-[#6F6D6C]">
            예외사항ㅣ전세자금대출, 중도금대출, 소액 신용대출
          </p>
        </div>
        <div className="flex flex-col gap-8 mb-6">
          <p className="text-sm leading-[24px]">
            <strong>2024년부터는 스트레스 DSR</strong>이 도입돼요.
            <br />
            이는 금리 인상 가능성을 반영해, 미래를 대비한 ‘스트레스 금리’를
            추가로 적용하는 방식이에요.
          </p>
          <p className="text-sm leading-[24px]">
            <strong>스트레스 금리란? </strong>
            <br />
            대출 심사 시 기준 금리에 1.5%p~3%p의 이자율을 더해 적용하는거에요.
            스트레스 금리가 반영되면 대출 이자율이 높아지고 대출 한도가 줄어들게
            됩니다.
          </p>
        </div>
      </section>
    </div>,
    rootElement
  );
};

export default DSRPopup;
