import React from 'react';
import { config } from '../../config';
import KakaoIcon from '../icon/KakaoIcon';
import NaverIcon from '../icon/NaverIcon';

const LoginForm = () => {
  return (
    <div className="flex flex-col gap-4">
      <a
        href={config.NAVER_AUTH_URL}
        className="w-full h-12 px-4 relative flex items-center rounded-lg text-white bg-[#03C75A]"
      >
        <div className="absolute left-4 flex items-center">
          <NaverIcon />
        </div>
        <span className="w-full text-center font-semibold text-[15px] leading-normal">
          네이버 로그인
        </span>
      </a>
      <a
        href={config.KAKAO_AUTH_URL}
        className="w-full h-12 px-4 relative flex items-center rounded-lg text-[#47272C] bg-[#FFE700]"
      >
        <div className="absolute left-4 flex items-center">
          <KakaoIcon />
        </div>
        <span className="w-full text-center font-semibold text-[15px] leading-normal">
          카카오 로그인
        </span>
      </a>
      {/* <a
        href={config.GOOGLE_AUTH_URL}
        className="w-full h-12 px-4 relative flex items-center rounded-lg text-[#47272C] bg-white border border-grayLight3"
      >
        <div className="absolute left-4 flex items-center">
          <GoogleIcon />
        </div>
        <span className="w-full text-center font-semibold text-[15px] leading-normal">
          구글 로그인
        </span>
      </a> */}
    </div>
  );
};

export default LoginForm;
