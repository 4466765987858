import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import LoginLayout from '../../component/user/LoginLayout';
import LoginForm from '../../component/user/LoginForm';
import AlertModal from '../../component/AlertModal';

const LoginPage = () => {
  const location = useLocation();
  const [alertModalMessage, setAlertModalMessage] = useState('');

  const getAlertMessage = (msg) => {
    const messages = {
      naver: `이미 네이버 계정으로 가입하셨네요!\n계정 확인 후 기존 계정으로 로그인해주세요.`,
      kakao: `이미 카카오 계정으로 가입하셨네요!\n계정 확인 후 기존 계정으로 로그인해주세요.`,
      homepage: `이미 휴대전화번호로 가입하셨네요!\n계정 확인 후 기존 계정으로 로그인해주세요.`
    };
    return messages[msg] || '';
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const msg = queryParams.get('msg');
    setAlertModalMessage(getAlertMessage(msg));
  }, [location]);

  return (
    <div>
      {alertModalMessage && (
        <>
          <div
            className="bg-[#484644] bg-opacity-20 z-20 absolute top-0 left-0 w-full h-full"
            style={{ pointerEvents: 'none' }}
          />
          <AlertModal
            message={alertModalMessage}
            onClick={() => setAlertModalMessage('')}
          />
        </>
      )}
      <LoginLayout title="로그인" disabled={!!alertModalMessage}>
        <div className={!!alertModalMessage ? 'pointer-events-none' : ''}>
          <LoginForm />
        </div>
      </LoginLayout>
    </div>
  );
};

export default LoginPage;
