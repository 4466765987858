import React from 'react';
import { useRecoilState } from 'recoil';
import { surveyState } from '../../recoil/atoms';
import ChatHeader from '../header/ChatHeader';
import { LoanInterestCalculator } from './LoanInterestCalculator';

const LoanInterestCalculatorContainer = () => {
  const [survey, setSurvey] = useRecoilState(surveyState);
  const closeCalculator = () => {
    setSurvey((prev) => ({ ...prev, r_isCalculatorVisible: false }));
  };

  return (
    <div className="overlay">
      <div className="relative z-50 h-[100dvh] w-full max-w-md bg-white overflow-auto">
        <ChatHeader onClick={closeCalculator} title="대출 이자 계산기" />
        <LoanInterestCalculator />
      </div>
    </div>
  );
};

export default LoanInterestCalculatorContainer;
