import React from 'react';
import RightArrowIcon from '../../icon/RightArrowIcon';
import LeftArrowIcon from '../../icon/LeftArrowIcon';

const CustomNavigationButton = ({
  direction,
  activeIndex,
  totalIndex,
  isLoading,
  onClick
}) => (
  <button className={`custom-${direction}-button`} onClick={onClick}>
    {direction === 'next'
      ? activeIndex < totalIndex - 1 && (
          <RightArrowIcon
            className={isLoading ? 'text-white' : 'text-[#484644]'}
            strokeWidth={1.5}
          />
        )
      : activeIndex > 0 && (
          <LeftArrowIcon
            className={isLoading ? 'text-white' : 'text-[#484644]'}
            strokeWidth={1.5}
          />
        )}
  </button>
);

const CustomPagination = ({ activeIndex, totalIndex, isLoading }) => (
  <div
    className={`${isLoading ? 'text-white' : 'text-[#484644]'} report-paging`}
  >
    <span className="mr-[13px]">{activeIndex + 1}</span>/
    <span className="ml-[13px]">{totalIndex}</span>
  </div>
);

const ReportPagination = ({ activeIndex, totalIndex, isLoading }) => {
  return (
    <div className="px-6 bg-white">
      <CustomNavigationButton
        direction="prev"
        activeIndex={activeIndex}
        totalIndex={totalIndex}
        isLoading={isLoading}
      />
      <CustomPagination
        activeIndex={activeIndex}
        totalIndex={totalIndex}
        isLoading={isLoading}
      />
      <CustomNavigationButton
        direction="next"
        activeIndex={activeIndex}
        totalIndex={totalIndex}
        isLoading={isLoading}
      />
    </div>
  );
};

export default ReportPagination;
