import React, { useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { config } from '../../config';
import { useAddUserMessage } from '../../hook/useAddUserMessage';
import { useDeleteSelectedOptions } from '../../hook/useDeleteSelectedOptions';
import { useMoveToNextQuestion } from '../../hook/useMoveToNextQuestion';
import { useMoveToTheQuestion } from '../../hook/useMoveToTheQuestion';
import { useSaveAnswer } from '../../hook/useSaveAnswer';
import { useSetSurveyValue } from '../../hook/useSetSurveyValue';
import { surveyState } from '../../recoil/atoms';
import '../../style/LoanInterestCalculator.css';
import { calculateLoan } from '../../util/calculateLoan';
import { currentDateTime } from '../../util/currentDateTime';
import { formatKoreanCurrency } from '../../util/formatKoreanCurrency';
import PrimaryButton from '../button/PrimaryButton';
import { Numberpad } from './Numberpad';

export const LoanInterestCalculator = ({
  isSelfArg,
  setIsCalculatorOpen,
  setForm
}) => {
  const numberpadRef = useRef(null);
  const deleteSelectedOptions = useDeleteSelectedOptions();
  const [currentMonthData, setCurrentMonthData] = useState(null);
  const [selectedItem, setSelectedItem] = useState('');

  const survey = useRecoilValue(surveyState);
  const [isSelf, setIsSelf] = useState(
    isSelfArg ? isSelfArg : survey.r_idx < config.SELF_HOUSE_LOAN_CALCULATOR_IDX
  );
  const questionId = survey.r_allQuestions[survey.r_idx].questionId;
  const addUserMessage = useAddUserMessage();
  const setSurveyValue = useSetSurveyValue();
  const moveToNextQuestion = useMoveToNextQuestion();
  const moveToTheQuestion = useMoveToTheQuestion();
  const saveAnswer = useSaveAnswer();
  const [isCalculated, setIsCalculated] = useState(false);
  const [isNumberpadOn, setIsNumberpadOn] = useState(false);
  const shareButtonsRef = useRef(null);

  let tempCurrentMonthData = {};
  const { formattedDate, formattedTime } = currentDateTime();

  const handleClick = (item) => {
    setSelectedItem(item);
    setIsNumberpadOn(!isNumberpadOn);
    setIsCalculated(false);
  };

  const handleRepaymentMethodChange = (method) => {
    setSurveyValue(`r_${isSelf ? 'self' : 'spouse'}RepaymentOption`, method);
    setIsCalculated(false);
  };

  const handleSubmit = () => {
    if (survey.r_idx === config.ANSWER_PAD_IDX) {
      setIsCalculatorOpen(false);
    }
    if (isSelf) {
      setSurveyValue('r_selfCurrentMonthData', currentMonthData);
      setSurveyValue('r_selfHousingExpense', currentMonthData.interest);
      if (survey.r_idx === config.ANSWER_PAD_IDX) {
        setForm((prev) => ({
          ...prev,
          r_selfHousingExpense: currentMonthData.interest
        }));
      }
    } else {
      setSurveyValue('r_spouseCurrentMonthData', currentMonthData);
      setSurveyValue('r_spouseHousingExpense', currentMonthData.interest);
      if (survey.r_idx === config.ANSWER_PAD_IDX) {
        setForm((prev) => ({
          ...prev,
          r_spouseHousingExpense: currentMonthData.interest
        }));
      }
    }
    if (survey.r_idx !== config.ANSWER_PAD_IDX) {
      deleteSelectedOptions();
      addUserMessage({
        optionText: formatKoreanCurrency(currentMonthData.interest) + '원'
      });
      setSurveyValue('r_isCalculatorVisible', false);
    }

    if (isSelf) {
      moveToTheQuestion(config.SPOUSE_HOUSE_LOAN_YN_IDX);
    } else {
      moveToTheQuestion(config.SELF_ETC_DEBT_COMMENT_IDX);
    }
  };

  const handleCalculate = () => {
    const result = calculateLoan({
      principal: parseFloat(survey[`r_${isSelf ? 'self' : 'spouse'}Principal`]),
      annualRate: parseFloat(
        survey[`r_${isSelf ? 'self' : 'spouse'}AnnualRate`]
      ),
      repaymentTerm: parseFloat(
        survey[`r_${isSelf ? 'self' : 'spouse'}RepaymentTerm`]
      ),
      gracePeriod: parseFloat(
        survey[`r_${isSelf ? 'self' : 'spouse'}GracePeriod`]
      ),
      repaymentOption: survey[`r_${isSelf ? 'self' : 'spouse'}RepaymentOption`],
      paymentFrequency: 12
    });
    console.log('Result:', result);

    if (questionId === config.SELF_HOUSE_LOAN_CALCULATOR_IDX) {
      setSurveyValue(
        'r_selfMortgageAmount',
        parseFloat(survey[`r_${isSelf ? 'self' : 'spouse'}Principal`])
      );
      setSurveyValue(
        'r_selfMortgageStart',
        parseInt(survey[`r_${isSelf ? 'self' : 'spouse'}RepaymentStart`])
      );
      setSurveyValue('r_selfMortgage', result);
    } else if (questionId === config.SPOUSE_HOUSE_LOAN_CALCULATOR_IDX) {
      setSurveyValue(
        'r_spouseMortgageAmount',
        parseFloat(survey[`r_${isSelf ? 'self' : 'spouse'}Principal`])
      );
      setSurveyValue(
        'r_spouseMortgageStart',
        parseInt(survey[`r_${isSelf ? 'self' : 'spouse'}RepaymentStart`])
      );
      setSurveyValue('r_spouseMortgage', result);
    }
    const currentYear = new Date().getFullYear();
    const monthDiff =
      (currentYear -
        parseInt(survey[`r_${isSelf ? 'self' : 'spouse'}RepaymentStart`])) *
      12;
    const currentMonthIndex = Math.min(monthDiff, result.length - 1);
    tempCurrentMonthData = result[currentMonthIndex];
    setCurrentMonthData(tempCurrentMonthData);
    setSurveyValue(`r_${isSelf ? 'self' : 'spouse'}Mortgage`, result);

    setIsCalculated(true);
  };

  const loanContents = [
    {
      label: '대출 금액',
      name: 'Principal',
      onChange: handleClick,
      unit: '원'
    },
    {
      label: '연 이자율',
      name: 'AnnualRate',
      onChange: handleClick,
      unit: '%'
    },
    {
      label: '상환기간',
      name: 'RepaymentTerm',
      onChange: handleClick,
      unit: '년'
    },
    {
      label: '거치기간',
      name: 'GracePeriod',
      onChange: handleClick,
      unit: '년'
    },
    {
      label: '대출 개시 시점',
      name: 'RepaymentStart',
      onChange: handleClick,
      unit: '년도'
    }
  ];

  return (
    <div>
      <div className="flex flex-col gap-8 p-6">
        {loanContents.map((item, index) => (
          <div
            key={index}
            className="flex justify-between items-center pb-2 border-b border-[#EBEBEB]"
          >
            <label className="text-[#5B5A58] font-[15px] leading-[140%] tracking-[-0.3px]">
              {item.label}
            </label>
            <div className="flex gap-2">
              <div className="text-right text-[16px] font-medium leading-[140%] tracking-[-0.36px] focus:outline-none">
                <button
                  onClick={() => item.onChange(item)}
                  value={item.name}
                  ref={numberpadRef}
                >
                  {item.name === 'Principal'
                    ? formatKoreanCurrency(
                        survey[`r_${isSelf ? 'self' : 'spouse'}${item.name}`]
                      )
                    : survey[`r_${isSelf ? 'self' : 'spouse'}${item.name}`]}
                </button>
              </div>
              <span className="text-right text-[16px] font-medium leading-[140%] tracking-[-0.36px]">
                {item.unit}
              </span>
            </div>
          </div>
        ))}
      </div>

      {/* Repayment Method */}
      <div className="px-5 flex flex-col gap-2 w-full">
        <label className="block text-[#5B5A58] font-[15px] leading-[140%] tracking-[-0.3px]">
          상환방식
        </label>
        <div className="flex gap-2 w-full">
          {['원리금 균등', '원금 균등', '만기일시'].map((method) => (
            <button
              key={method}
              onClick={() => handleRepaymentMethodChange(method)}
              className={`w-full py-3 border rounded-lg text-[16px] font-medium leading-[140%] tracking-[-0.36px] text-[#5B5A58] bg-white ${
                survey[`r_${isSelf ? 'self' : 'spouse'}RepaymentOption`] ===
                method
                  ? 'border-orange'
                  : ' border-[#D1D1D0]'
              }`}
            >
              {method}
            </button>
          ))}
        </div>
      </div>

      <div className="p-4 py-6">
        {isCalculated && (
          <>
            <div className="flex justify-between pt-6 border-t border-[#EBEBEB]">
              <span className="text-[#5B5A58] text-sm leading-[140%] tracking-[-0.28px]">
                월납입금
              </span>
              <span className="text-[16px] font-medium leading-[140%] tracking-[-0.36px]">
                {formatKoreanCurrency(currentMonthData.totalPayment)} 원
              </span>
            </div>
            <div className="flex justify-between pt-6">
              <span className="text-[#5B5A58] text-sm leading-[140%] tracking-[-0.28px]">
                월평균이자
              </span>
              <span className="text-[16px] font-medium leading-[140%] tracking-[-0.36px]">
                {formatKoreanCurrency(currentMonthData.interest)} 원
              </span>
            </div>
          </>
        )}
      </div>

      {/* Calculate and Submit Button */}
      <div className="w-full px-4 pb-4 z-50 flex justify-center rounded-[8px]">
        {!isCalculated ? (
          <PrimaryButton
            onClick={handleCalculate}
            className="rounded-[inherit]"
          >
            이자 계산하기
          </PrimaryButton>
        ) : (
          <PrimaryButton onClick={handleSubmit} className="rounded-[inherit]">
            확인
          </PrimaryButton>
        )}
      </div>
      {isNumberpadOn && (
        <Numberpad
          selectedItem={selectedItem}
          isSelf={isSelf}
          setIsNumberpadOn={setIsNumberpadOn}
        />
      )}
    </div>
  );
};
