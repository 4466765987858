import axios from 'axios';
import { useRecoilValue } from 'recoil';
import ktorApi from '../api/ktorApi';
import { surveyState } from '../recoil/atoms';

export const useRequestReportCreate = () => {
  const survey = useRecoilValue(surveyState);
  const requestReport = async () => {
    const noSpendBody = {
      user: {
        sex: survey.r_gender,
        age: Number(survey.r_selfAge),
        salary: Number(survey.r_selfIncome),
        saving: Number(survey.r_selfSaving),
        retire_age: Number(survey.r_selfRetirementAge),
        married: 'n',
        kid: 'n',
        single_dual: 'd',
        individual_together: 't',
        number_of_children: 0,
        cash: Number(survey.r_selfCashAsset),
        deposit: Number(survey.r_selfDeposit),
        stock: Number(survey.r_selfInvestmentAsset),
        housing_expense:
          Number(survey.r_selfHousingExpense) +
          Number(survey.r_selfHouseMonthlyPayment),
        home_loan: Number(survey.r_selfHomeLoan),
        mortgage_amount: Number(survey.r_selfMortgageAmount),
        mortgage_start: Number(survey.r_selfMortgageStart),
        mortgage: survey.r_selfMortgage,
        debt: Number(survey.r_selfEtcDebt)
      },
      spouse: {
        sex: survey.r_gender === 'male' ? 'female' : 'male',
        age: Number(survey.r_spouseAge),
        salary: Number(survey.r_spouseIncome),
        saving: Number(survey.r_spouseSaving),
        retire_age: Number(survey.r_spouseRetirementAge),
        married: 'n',
        kid: 'n',
        single_dual: 'd',
        individual_together: 't',
        number_of_children: 0,
        cash: Number(survey.r_spouseCashAsset),
        deposit: Number(survey.r_spouseDeposit),
        stock: Number(survey.r_spouseInvestmentAsset),
        housing_expense:
          Number(survey.r_spouseHousingExpense) +
          Number(survey.r_spouseHouseMonthlyPayment),
        home_loan: Number(survey.r_spouseHomeLoan),
        mortgage_amount: Number(survey.r_spouseMortgageAmount),
        mortgage_start: Number(survey.r_spouseMortgageStart),
        mortgage: survey.r_spouseMortgage,
        debt: Number(survey.r_spouseEtcDebt)
      },
      llm_params: {
        name: survey.r_name,
        spouse_name: '(예비) 배우자'
      },
      user_id: survey.r_userId,
      order_id: survey.r_orderId,
      marriage_year: Number(survey.r_marriageYear),
      have_children: Number(survey.r_children_age) > 0
    };

    const spendBody = {
      ...noSpendBody
    };
    if (
      survey.r_selectedPurchase.some(
        (item) => item.optionText === '자동차 구입하기'
      )
    ) {
      spendBody.car_purchase = {
        car_price: Math.floor(Number(survey.r_carPrice) / 1000),
        car_purchase_age:
          Number(survey.r_selfAge) +
          Number(survey.r_marriageYear) +
          Number(survey.r_car_age)
      };
      spendBody.llm_params.car_name = survey.r_selectedCar.title;
    }
    if (
      survey.r_selectedPurchase.some(
        (item) => item.optionText === '아파트 구하기'
      )
    ) {
      spendBody.house_purchase = {
        house_price: Math.floor(Number(survey.r_apartmentPrice) / 1000),
        house_purchase_age:
          Number(survey.r_selfAge) +
          Number(survey.r_marriageYear) +
          Number(survey.r_apartment_age),
        house_type: survey.r_jeonseSale === '전세' ? 'd' : 'o',
        rent: 0
      };
      spendBody.llm_params.apartment_name = survey.r_selectedApartment?.title;
    }
    if (
      survey.r_selectedPurchase.some(
        (item) => item.optionText === '자녀 양육하기'
      )
    ) {
      spendBody.child_options = {
        kid_years: Number(survey.r_children_age)
      };
    }
    if (
      survey.r_selectedPurchase.some(
        (item) => item.optionText === '기타 목표 달성하기'
      )
    ) {
      spendBody.additional_expenses = {
        amount: Math.floor(Number(survey.r_etc_goal_money) / 1000),
        additional_expense_age: Number(survey.r_etc_goal_year)
      };
    }
    // console.log(
    //   'spouseHouseingExpense',
    //   Number(survey.r_spouseHousingExpense),
    //   Number(survey.r_spouseHouseMonthlyPayment),
    //   Number(survey.r_spouseCurrentMonthData?.interest || 0)
    // );
    console.log('user.sex:', spendBody.user.sex, typeof spendBody.user.sex);
    console.log('user.age:', spendBody.user.age, typeof spendBody.user.age);
    console.log(
      'user.salary:',
      spendBody.user.salary,
      typeof spendBody.user.salary
    );
    console.log(
      'user.saving:',
      spendBody.user.saving,
      typeof spendBody.user.saving
    );
    console.log(
      'user.retire_age:',
      spendBody.user.retire_age,
      typeof spendBody.user.retire_age
    );
    console.log(
      'user.married:',
      spendBody.user.married,
      typeof spendBody.user.married
    );
    console.log('user.kid:', spendBody.user.kid, typeof spendBody.user.kid);
    console.log(
      'user.single_dual:',
      spendBody.user.single_dual,
      typeof spendBody.user.single_dual
    );
    console.log(
      'user.individual_together:',
      spendBody.user.individual_together,
      typeof spendBody.user.individual_together
    );
    console.log(
      'user.number_of_children:',
      spendBody.user.number_of_children,
      typeof spendBody.user.number_of_children
    );
    console.log('user.cash:', spendBody.user.cash, typeof spendBody.user.cash);
    console.log(
      'user.deposit:',
      spendBody.user.deposit,
      typeof spendBody.user.deposit
    );
    console.log(
      'user.stock:',
      spendBody.user.stock,
      typeof spendBody.user.stock
    );
    console.log(
      'user.housing_expense:',
      spendBody.user.housing_expense,
      typeof spendBody.user.housing_expense
    );
    console.log(
      'user.home_loan:',
      spendBody.user.home_loan,
      typeof spendBody.user.home_loan
    );
    console.log(
      'user.mortgage_amount:',
      spendBody.user.mortgage_amount,
      typeof spendBody.user.mortgage_amount
    );
    console.log(
      'user.mortgage_start:',
      spendBody.user.mortgage_start,
      typeof spendBody.user.mortgage_start
    );
    console.log(
      'user.mortgage:',
      spendBody.user.mortgage,
      typeof spendBody.user.mortgage
    );
    console.log('user.debt:', spendBody.user.debt, typeof spendBody.user.debt);
    console.log(
      'spouse.age:',
      spendBody.spouse.age,
      typeof spendBody.spouse.age
    );
    console.log(
      'spouse.salary:',
      spendBody.spouse.salary,
      typeof spendBody.spouse.salary
    );
    console.log(
      'spouse.saving:',
      spendBody.spouse.saving,
      typeof spendBody.spouse.saving
    );
    console.log(
      'spouse.retire_age:',
      spendBody.spouse.retire_age,
      typeof spendBody.spouse.retire_age
    );
    console.log(
      'spouse.married:',
      spendBody.spouse.married,
      typeof spendBody.spouse.married
    );
    console.log(
      'spouse.kid:',
      spendBody.spouse.kid,
      typeof spendBody.spouse.kid
    );
    console.log(
      'spouse.single_dual:',
      spendBody.spouse.single_dual,
      typeof spendBody.spouse.single_dual
    );
    console.log(
      'user.spouse.individual_together:',
      spendBody.spouse.individual_together,
      typeof spendBody.spouse.individual_together
    );
    console.log(
      'spouse.number_of_children:',
      spendBody.spouse.number_of_children,
      typeof spendBody.spouse.number_of_children
    );
    console.log(
      'spouse.cash:',
      spendBody.spouse.cash,
      typeof spendBody.spouse.cash
    );
    console.log(
      'spouse.deposit:',
      spendBody.spouse.deposit,
      typeof spendBody.spouse.deposit
    );
    console.log(
      'spouse.stock:',
      spendBody.spouse.stock,
      typeof spendBody.spouse.stock
    );
    console.log(
      'spouse.housing_expense:',
      spendBody.spouse.housing_expense,
      typeof spendBody.spouse.housing_expense
    );
    console.log(
      'spouse.home_loan:',
      spendBody.spouse.home_loan,
      typeof spendBody.spouse.home_loan
    );
    console.log(
      'spouse.mortgage_amount:',
      spendBody.spouse.mortgage_amount,
      typeof spendBody.spouse.mortgage_amount
    );
    console.log(
      'spouse.mortgage_start:',
      spendBody.spouse.mortgage_start,
      typeof spendBody.spouse.mortgage_start
    );
    console.log(
      'spouse.mortgage:',
      spendBody.spouse.mortgage,
      typeof spendBody.spouse.mortgage
    );
    console.log(
      'spouse.debt:',
      spendBody.spouse.debt,
      typeof spendBody.spouse.debt
    );
    console.log(
      'user.llm_params.name:',
      spendBody.llm_params.name,
      typeof spendBody.llm_params.name
    );
    console.log(
      'user.llm_params.spouse_name:',
      spendBody.llm_params.spouse_name,
      typeof spendBody.llm_params.spouse_name
    );
    console.log(
      'user.llm_params.car_name:',
      spendBody.llm_params.car_name,
      typeof spendBody.llm_params.car_name
    );
    console.log(
      'user.llm_params.apartment_name:',
      spendBody.llm_params.apartment_name,
      typeof spendBody.llm_params.apartment_name
    );
    console.log(
      'user.order_id:',
      spendBody.order_id,
      typeof spendBody.order_id
    );
    console.log(
      'user.marriage_year:',
      spendBody.marriage_year,
      typeof spendBody.marriage_year
    );
    console.log(
      'user.have_children:',
      spendBody.have_children,
      typeof spendBody.have_children
    );
    console.log(
      'user.car_purchase.car_price:',
      spendBody.car_purchase?.car_price,
      typeof spendBody.car_purchase?.car_price
    );
    console.log(
      'user.car_purchase.car_purchase_age:',
      spendBody.car_purchase?.car_purchase_age,
      typeof spendBody.car_purchase?.car_purchase_age
    );
    console.log(
      'user.house_purchase.house_price:',
      spendBody.house_purchase?.house_price,
      typeof spendBody.house_purchase?.house_price
    );
    console.log(
      'user.house_purchase.house_purchase_age:',
      spendBody.house_purchase?.house_purchase_age,
      typeof spendBody.house_purchase?.house_purchase_age
    );
    console.log(
      'user.house_purchase.house_type:',
      spendBody.house_purchase?.house_type,
      typeof spendBody.house_purchase?.house_type
    );
    console.log(
      'user.child_options.kid_years:',
      spendBody.child_options?.kid_years,
      typeof spendBody.child_options?.kid_years
    );
    console.log(
      'user.additional_expenses.amount:',
      spendBody.additional_expenses?.amount,
      typeof spendBody.additional_expenses?.amount
    );
    console.log(
      'user.additional_expenses.additional_expense_age:',
      spendBody.additional_expenses?.additional_expense_age,
      typeof spendBody.additional_expenses?.additional_expense_age
    );

    try {
      const response = await axios.post(
        'https://finberry.co.kr/fastapi/simulate_llm2',
        spendBody
      );
      console.log('report reqeuset body: ', spendBody);
      const response2 = await ktorApi.post('/payment/debug', response);
      return response2;
    } catch (error) {
      console.log(error);
    }
  };
  return requestReport;
};
