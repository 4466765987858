import React, { useMemo } from 'react';

const ApartmentDSRMethodReport = () => {
  const cases = useMemo(() => {
    return [
      {
        title: '대출 금액 조정하기',
        description:
          '대출 금액을 줄이거나, 자산의 일부를 사용하여 필요한 대출 금액을 낮추세요.'
      },
      {
        title: '상환 기간 연장하기',
        description:
          '대출 상환 기간을 늘리면 매달 갚아야 할 금액(원리금)이 줄어들어 DSR도 함께 낮아집니다.'
      },
      {
        title: '부채 구조 조정하기',
        description:
          '금리가 높은 기타 대출을 먼저 상환하거나, 통합 대출로 금리를 낮추는 방안을 검토해보세요.'
      },

      {
        title: '목표 재조정하기',
        description:
          '현재의 목표 달성 시기를 재조정하거나, 초기 비용을 줄이는 대안을 고려해보세요.'
      }
    ];
  }, []);

  return (
    <div className="report-container">
      <section className="p-6">
        <div>
          <h1 className="text-xl text-orange">
            <strong>DSR을 낮추기 위한 방안</strong>은
            <br />
            무엇이 있을까요?
          </h1>
        </div>

        {cases.map((item, index) => (
          <div
            key={index}
            className={`py-6 ${index === cases.length - 1 ? 'pb-0' : 'border-b border-[#EBEBEB]'}`}
          >
            <p className="mb-2 text-[11px] font-semibold leading-[100%] text-orange">
              Case.{index + 1}
            </p>
            <h2 className="mb-3 text-base font-bold leading-[100%]">
              {item.title}
            </h2>
            <p className="text-sm leading-[170%]">{item.description}</p>
          </div>
        ))}
      </section>
    </div>
  );
};

export default ApartmentDSRMethodReport;
