import { useRecoilValue } from 'recoil';
import ktorApi from '../api/ktorApi';
import { surveyState } from '../recoil/atoms';

export const useUpdateIsFinished = () => {
  const survey = useRecoilValue(surveyState);
  const { r_orderId } = survey;

  const updateIsFinished = async (orderId, isFinished) => {
    try {
      const response = await ktorApi.post('/payment/update/isFinished', {
        orderId: orderId,
        isFinished: isFinished
      });
      return response;
    } catch (error) {
      console.error('Error updating isFinished:', error);
    }
  };
  return updateIsFinished;
};
