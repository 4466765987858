import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MobilePhoneFormWrapper from '../../component/user/MobilePhoneFormWrapper';
import NicknameForm from '../../component/user/NicknameForm';
import PasswordForm from '../../component/user/PasswordForm';
import PolicyForm from '../../component/user/PolicyForm';
import { useStep } from '../../hook/useStep';
import { signup } from '../../util/signup';
import { signupOAuth } from '../../util/signupOAuth';

const STEPS = ['이용약관', '닉네임', '휴대전화번호', '비밀번호'];

const SignupPage = () => {
  const { currentStep, nextStep, prevStep } = useStep(0, STEPS.length);
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    userId: '',
    fullName: '',
    phoneNum: '',
    plainPassword: '',
    termsAgreement: false
  });
  const [isReadyToSubmit, setIsReadyToSubmit] = useState(false);

  useEffect(() => {
    if (isReadyToSubmit) {
      handleSubmit(userData);
      setIsReadyToSubmit(false);
    }
  }, [isReadyToSubmit, userData]);

  const handleSubmit = async (userData) => {
    const result = await signup(userData);

    if (result.success) {
      alert('회원가입이 성공적으로 완료되었습니다.');
      navigate('/user/login/mobile');
    } else {
      alert(`${result.error}`);
      window.location.reload();
    }
  };

  const handleOAuthSignup = async (
    mobile,
    name,
    termsAgreement,
    loginType,
    email
  ) => {
    const userData = {
      name,
      termsAgreement,
      mobile,
      loginType,
      email
    };

    const result = await signupOAuth(userData);
    if (result.success) {
      alert('로그인이 성공적으로 완료되었습니다. 메인화면으로 이동합니다.');
      navigate('/');
    } else {
      alert(`${result.error}`);
      window.location.reload();
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <PolicyForm
            nextStep={nextStep}
            setUserData={setUserData}
            handleOAuthSignup={handleOAuthSignup}
          />
        );
      case 1:
        return (
          <NicknameForm
            nextStep={nextStep}
            prevStep={prevStep}
            setUserData={setUserData}
          />
        );
      case 2:
        return (
          <MobilePhoneFormWrapper
            type="default"
            nextStep={nextStep}
            prevStep={prevStep}
            setUserData={setUserData}
          />
        );
      case 3:
        return (
          <PasswordForm
            nextStep={nextStep}
            prevStep={prevStep}
            setUserData={setUserData}
            setIsReadyToSubmit={setIsReadyToSubmit}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <div className="h-[100dvh] w-full max-w-md">
      {renderStepContent(currentStep)}
    </div>
  );
};

export default SignupPage;
