import React from 'react';
import ResetButton from './button/ResetButton';
import PrimaryButton from './button/PrimaryButton';
import ResetIcon from './icon/ResetIcon';

const CarListItem = ({
  title = '',
  list,
  selectedList,
  handleSelection,
  handleReset,
  handleApply,
  closeModal
}) => {
  return (
    <div className="small-overlay" onClick={closeModal}>
      <div className="modal-wrapper">
        <h2>{title}</h2>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          {list.map((item, idx) => (
            <label key={idx} className="custom-checkbox">
              <input
                type="checkbox"
                value={item}
                checked={selectedList.includes(item)}
                onChange={() => handleSelection(item)}
                className="custom-checkbox-input"
              />
              <span>{item}</span>
            </label>
          ))}
        </div>
        <div className="flex w-full mt-4">
          <div className="w-2/5 mx-4">
            <ResetButton onClick={(e) => handleReset(e, title)}>
              <ResetIcon />
              <span className="ml-2">초기화</span>
            </ResetButton>
          </div>
          <div className="w-3/5 mr-4 rounded-[5px]">
            <PrimaryButton
              onClick={handleApply}
              className="rounded-[inherit] py-[11px]"
            >
              적용하기
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarListItem;
