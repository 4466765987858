import { useEffect } from 'react';

const usePreventHorizontalSwipe = (selector) => {
  useEffect(() => {
    const container = document.querySelector(selector);
    let startX = 0; // 터치 시작 X 좌표
    let startY = 0; // 터치 시작 Y 좌표

    const handleTouchStart = (e) => {
      const touch = e.touches[0];
      startX = touch.clientX;
      startY = touch.clientY;
    };

    const handleTouchMove = (e) => {
      const touch = e.touches[0];
      const diffX = touch.clientX - startX;
      const diffY = touch.clientY - startY;

      // 수평 스와이프만 차단 (diffX > diffY 기준)
      if (Math.abs(diffX) > Math.abs(diffY)) {
        e.preventDefault(); // 수평 이동 차단
      }
    };

    if (container) {
      container.addEventListener('touchstart', handleTouchStart);
      container.addEventListener('touchmove', handleTouchMove, {
        passive: false
      });
    }

    return () => {
      if (container) {
        container.removeEventListener('touchstart', handleTouchStart);
        container.removeEventListener('touchmove', handleTouchMove);
      }
    };
  }, [selector]);
};

export default usePreventHorizontalSwipe;
