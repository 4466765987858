import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useAddMessage } from '../hook/useAddMessage';
import { useAddUserMessage } from '../hook/useAddUserMessage';
import { useMoveToNextQuestion } from '../hook/useMoveToNextQuestion';
import { useSetSurveyValue } from '../hook/useSetSurveyValue';
import { surveyState } from '../recoil/atoms';
import '../style/ApartmentList.css';
import { ApartmentListEntry } from './ApartmentListEntry';
import { SortUp } from './SortUp';
import PrimaryButton from './button/PrimaryButton';
import LeftArrowIcon from './icon/LeftArrowIcon';

export const ApartmentList = () => {
  const [survey, setSurvey] = useRecoilState(surveyState);
  const [selectedApartmentIdx, setSelectedApartmentIdx] = useState(-1);
  const { r_isApartmentVisible, r_apartmentList, r_jeonseSale } = survey;
  const [isSortUP, setIsSortUP] = useState(false);
  const [sortIdx, setSortIdx] = useState(0);

  const setSurveyValue = useSetSurveyValue();
  const addUserMessage = useAddUserMessage();
  const addMessage = useAddMessage();
  const moveToNextQuestion = useMoveToNextQuestion();

  useEffect(() => {
    let tempApartmentList = [...r_apartmentList];

    if (sortIdx === 1) {
      if (r_jeonseSale === '전세') {
        tempApartmentList = tempApartmentList.sort(
          (a, b) => a.jeonsePriceMin - b.jeonsePriceMin
        );
      } else {
        tempApartmentList = tempApartmentList.sort(
          (a, b) => a.salePriceMin - b.salePriceMin
        );
      }
    } else if (sortIdx === 2) {
      if (r_jeonseSale === '전세') {
        tempApartmentList = tempApartmentList.sort(
          (a, b) => b.jeonsePriceMax - a.jeonsePriceMax
        );
      } else {
        tempApartmentList = tempApartmentList.sort(
          (a, b) => b.salePriceMax - a.salePriceMax
        );
      }
    } else if (sortIdx === 3) {
      tempApartmentList = tempApartmentList.sort((a, b) =>
        a.location.localeCompare(b.title, 'ko')
      );
    } else if (sortIdx === 4) {
      tempApartmentList = tempApartmentList.sort((a, b) =>
        a.title.localeCompare(b.title, 'ko')
      );
    }

    setSurveyValue('r_apartmentList', tempApartmentList);
  }, [sortIdx]);

  const handleApartmentIdx = (idx) => {
    if (idx === selectedApartmentIdx) {
      setSelectedApartmentIdx(-1);
      return;
    } else {
      setSelectedApartmentIdx(idx);
    }
    setSurveyValue('r_selectedApartment', survey.r_apartmentList[idx]);
  };

  const handleSortUP = () => {
    setIsSortUP(!isSortUP);
  };

  const handleApartmentConfirm = () => {
    addUserMessage({ optionText: r_apartmentList[selectedApartmentIdx].title });
    setSurveyValue('r_isApartmentListVisible', false);
    setSurveyValue(
      'r_selectedApartment',
      r_apartmentList[selectedApartmentIdx]
    );
    addMessage({
      questionText: '@APT_NAMECARD',
      questionType: 'APT_NAMECARD'
    });
    moveToNextQuestion();
  };

  return (
    <div className="overlay">
      <div className="content-container">
        <div className="header-container mb-4">
          <button
            onClick={() => setSurveyValue('r_isApartmentListVisible', false)}
            className="flex items-center"
          >
            <LeftArrowIcon />
            채팅
          </button>

          <div className="header-title">
            <div className="title">아파트 추천 리스트</div>
            <div className="date-info">2024년 09월 12일 목요일 업데이트</div>
          </div>
          <button onClick={handleSortUP} className="pl-6">
            <FontAwesomeIcon icon={faEllipsisVertical} />
          </button>
        </div>

        <div className="apartmentlist-container">
          {r_apartmentList.map((apartment, index) => (
            <ApartmentListEntry
              apartment={apartment}
              key={apartment.id}
              index={index}
              selectedApartmentIdx={selectedApartmentIdx}
              handleApartmentIdx={handleApartmentIdx}
            />
          ))}

          {isSortUP && (
            <SortUp
              sortIdx={sortIdx}
              setSortIdx={setSortIdx}
              setIsSortUP={setIsSortUP}
            />
          )}

          {selectedApartmentIdx !== -1 && (
            <div className="apartment-confirm-container">
              <PrimaryButton onClick={handleApartmentConfirm}>
                확인
              </PrimaryButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
