import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(BarElement, CategoryScale, LinearScale, ChartDataLabels);

const BarChartExample = ({ plot = [] }) => {
  const getGradient = (ctx, chartArea) => {
    const gradient = ctx.createLinearGradient(
      0,
      chartArea.top,
      0,
      chartArea.bottom
    );
    gradient.addColorStop(0, '#FF6319');
    gradient.addColorStop(1, '#FF900C');
    return gradient;
  };

  const data = {
    labels: ['중위 소득 평균', '부부 합산 소득', '상위 20~40% 평균'],
    datasets: [
      {
        data: plot,
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return ['#A9A8A7', '#FF8A00', '#4F4F4F'];
          }
          return ['#A9A8A7', getGradient(ctx, chartArea), '#4F4F4F'];
        },
        borderRadius: 5,
        barPercentage: 0.5, // Bar 너비 설정 (기본값 0.9)
        categoryPercentage: 0.8 // Bar 그룹 간 간격 설정 (기본값 0.8)
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // 차트 비율 고정 해제
    plugins: {
      legend: {
        display: false // 범례 제거
      },
      datalabels: {
        display: true,
        color: (context) => {
          // 첫 번째 Bar만 다른 색상 적용
          if (context.dataIndex === 0) {
            return '#A9A8A7';
          }
          return '#484644';
        },
        anchor: 'end',
        align: 'end',
        offset: 0, // 데이터 레이블과 Bar 사이의 간격 조정
        font: (context) => {
          if (context.dataIndex === 0) {
            return {
              size: 12,
              weight: 'normal',
              family: 'Pretendard',
              color: '#A9A8A7'
            };
          }
          return {
            size: 14,
            weight: 700,
            family: 'Pretendard',
            color: '#484644'
          };
        },
        formatter: (value, context) => {
          if (context.dataIndex === 0) {
            return `${value}`;
          }
          return `${value}만원`;
        }
      }
    },
    scales: {
      x: {
        grid: {
          drawBorder: false, // X축의 바깥 테두리 선 제거
          display: false // X축의 그리드라인 제거
        },
        ticks: {
          font: {
            size: 12,
            family: 'Pretendard'
          },
          color: (context) => {
            if (context.index === 0) {
              return '#A9A8A7';
            }
            return '#484644';
          },
          callback: function (value, index) {
            const labels = [
              '중위 소득\n평균',
              '부부 합산\n소득',
              '상위 20~40%\n평균'
            ];
            return labels[index].split('\n'); // 줄바꿈 처리
          }
        }
      },
      y: {
        display: false // Y축 숨김
      }
    },
    layout: {
      padding: {
        top: 20 // 차트 상단에 여백 추가
      }
    },
    animation: {
      duration: 0 // 애니메이션 지속시간을 0으로 설정하여 비활성화
    }
  };

  return (
    <div
      style={{
        width: '250px',
        height: '200px',
        margin: '0 auto'
      }}
    >
      <Bar data={data} options={options} />
    </div>
  );
};

export default BarChartExample;
