import React, { useState } from 'react';
import RightArrowIcon from '../../icon/RightArrowIcon';
import MonteCarloPopup from '../popupReport/MonteCarloPopup';

const MonteCarloReport = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => setIsPopupOpen(true);
  const closePopup = () => setIsPopupOpen(false);

  if (isPopupOpen) {
    return <MonteCarloPopup close={closePopup} />;
  }

  return (
    <div className="report-container">
      <section className="w-full p-6 pb-2">
        <div className="mb-4">
          <p className="text-xl leading-[140%]">
            이루고 싶은 목표에 대한 달성 가능성과
          </p>
          <p className="text-xl leading-[140%]">
            달성 방안에 대해서 알아보세요
          </p>
        </div>
        <div>
          <p className="text-[14px] font-normal leading-[170%]">
            입력하신 재무 상태와 자산을 바탕으로, 이루고 싶은 목표를 몬테카를로
            시뮬레이션으로 분석해드립니다.
            <br />
            목표 달성이 가능한지, 대출이 필요하다면 얼마나 필요한지도
            알려드릴게요.
            <br />
            다만, 제공되는 정보는 기본 데이터를 바탕으로 예측된 결과이니, 재무
            계획을 세우실 때 참고 자료로 활용해주세요. 😊
          </p>
        </div>
      </section>
      <section className="px-6 py-3">
        <button
          onClick={openPopup}
          className="w-full px-4 py-[10px] flex items-center justify-between border border-[#E5E5E5] rounded-[10px] bg-[#F8F8F8]"
        >
          <p className="text-left text-sm font-medium leading-[24px] text-[#6F6D6C]">
            💡 몬테카를로 시뮬레이션은 무엇인가요?
          </p>
          <RightArrowIcon className="text-[#6F6D6C]" size={16} />
        </button>
      </section>
    </div>
  );
};

export default MonteCarloReport;
