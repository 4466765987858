import React, { useMemo } from 'react';

const LoadingReport = ({ data = {}, stage = '' }) => {
  const stageData = useMemo(() => {
    const stages = {
      first: {
        title: (
          <h2 className="text-[26px] font-bold leading-none mb-3 text-white">
            {data.userName} 님
          </h2>
        ),
        img: '/picture/icons/mail-box.png',
        description: (
          <p className="text-center text-xl leading-[140%] text-white">
            당신과 배우자님의 재무 리포트가
            <br />
            도착했어요!
          </p>
        )
      },
      second: {
        title: (
          <h2 className="text-[28px] leading-[125%] mb-3 text-white">
            <p className="text-center font-normal">나의 재무 목표,</p>
            <p className="text-center font-bold">달성 가능할까요?</p>
          </h2>
        ),
        img: '/picture/icons/interest-rate.png',
        description: (
          <p className="text-center text-sm font-medium leading-[150%] text-white">
            내가 설정한 목표를
            <br />
            어떻게 하면 이룰 수 있을지 알아보세요!
          </p>
        )
      },
      third: {
        title: (
          <h2 className="text-[28px] leading-[125%] mb-3 text-white">
            <p className="text-center font-bold">우리의 미래</p>
            <p className="text-center font-bold">어떻게 준비해야 할까요?</p>
          </h2>
        ),
        img: '/picture/icons/interest-rate.png',
        description: (
          <p className="text-center text-sm font-medium leading-[150%] text-white">
            미래 자산 흐름과 은퇴 설계에 대해서 알아보고
            <br />
            안정적인 미래를 준비해보세요
          </p>
        )
      },
      fourth: {
        title: (
          <h2 className="text-[28px] leading-[125%] mb-3 text-white">
            <p className="text-center font-bold">앞으로의 여정을 위한</p>
            <p className="text-center font-bold">우리의 준비</p>
          </h2>
        ),
        img: '/picture/icons/interest-rate.png',
        description: (
          <p className="text-center text-sm font-medium leading-[150%] text-white">
            우리 부부가 앞으로 함께 살아가면서
            <br />
            어떻게 재무 관리를 진행하면 좋을까요?
          </p>
        )
      }
    };

    return stages[stage] || stages.second;
  }, [data, stage]);

  const { title, img, description } = stageData;

  return (
    <div className="report-background">
      <div className="px-8 pb-20">
        <div className="flex flex-col h-full justify-center items-center">
          <div className="w-[88px] h-auto mb-5 flex items-center justify-center">
            <img
              src={img}
              alt={stage === 'first' ? 'mailbox' : 'interest-rate'}
              className="object-contain w-full h-full"
            />
          </div>
          {title}
          {description}
        </div>
      </div>
    </div>
  );
};

export default LoadingReport;
