import api from '../api/api';

export const requestRefund = async ({ userId, orderId }) => {
  try {
    const response = await api.post('/payment/refund', {
      userId,
      orderId
    });
    if (response.status === 200 || response.status === 201) {
      return {
        success: true,
        data: response.data
      };
    } else {
      console.error('환불 실패:', response.status);
      return {
        success: false,
        error: `Unexpected response status: ${response.status}`
      };
    }
  } catch (error) {
    console.error('환불 실패:', error);
    return {
      success: false,
      error: error.response?.data || '환불 실패하였습니다. 다시 시도해주세요.'
    };
  }
};
