import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { surveyState } from '../../recoil/atoms';

const CheckoutPage = () => {
  const navigate = useNavigate();
  const survey = useRecoilValue(surveyState);

  useEffect(() => {
    const isLegit = survey.r_paymentHistories.some(
      (item) =>
        item?.paymentHistory.isRefunded === false &&
        item?.paymentHistory.isFinished === false
    );
    if (isLegit) {
      navigate('/chat', { replace: true }); // 히스토리에 CheckoutPage를 남기지 않고 '/chat'으로 교체
      return;
    }
    navigate('/checkout/cart', { replace: true }); // 히스토리에 CheckoutPage를 남기지 않고 '/checkout/cart'로 교체
  }, [survey, navigate]);

  return <div></div>;
};

export default CheckoutPage;
