import React, { useState } from 'react';
import RightArrowIcon from '../../icon/RightArrowIcon';
import InflationPopup from '../popupReport/InflationPopup';

const RetirementPrepReport = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => setIsPopupOpen(true);
  const closePopup = () => setIsPopupOpen(false);

  if (isPopupOpen) {
    return <InflationPopup close={closePopup} />;
  }

  return (
    <div className="report-container">
      <section className="w-full p-6 pb-2">
        <div className="mb-2">
          <p className="text-xl leading-[140%]">
            은퇴 준비 진단 및 맞춤 시나리오
          </p>
        </div>
        <div>
          <p className="text-[14px] font-normal leading-[170%]">
            현재 저축 상황과 미래 목표를 바탕으로 은퇴 준비 상태를 진단하고,
            다양한 시뮬레이션을 통해 효과적인 준비 방법을 안내해드려요.
            <br />
            <strong>
              최소한의 정보로 맞춤형 은퇴 가이드를 제공해드리기 때문에 제시된
              내용은 계획 수립에 참고용으로만 활용해 주세요.
            </strong>
            <br />
            또한, 모든 금액은 현재 가치 기준으로 환산하여 표시되었으니 이 점
            참고 부탁드립니다.
          </p>
        </div>
      </section>
      <section className="px-6 py-3">
        <button
          onClick={openPopup}
          className="w-full px-4 py-[10px] flex items-center justify-between border border-[#E5E5E5] rounded-[10px] bg-[#F8F8F8]"
        >
          <p className="text-left text-sm font-medium leading-[24px] text-[#6F6D6C]">
            💡 물가상승률과 현금 가치의 변화
          </p>
          <RightArrowIcon className="text-[#6F6D6C]" size={16} />
        </button>
      </section>
    </div>
  );
};

export default RetirementPrepReport;
