export function generateRandomAlphabet(length) {
  if (length <= 0) return ''; // 유효하지 않은 길이는 빈 문자열 반환
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'; // 영문 대소문자
  let result = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters[randomIndex];
  }
  return result;
}
