import { useRecoilState } from 'recoil';
import ktorApi from '../api/ktorApi';
import { useSetSurveyValue } from '../hook/useSetSurveyValue';
import { surveyState } from '../recoil/atoms';

export const useFetchReportData = () => {
  const [survey, setSurvey] = useRecoilState(surveyState);
  const setSurveyValue = useSetSurveyValue();
  const fetchReportData = async ({ orderId }) => {
    try {
      const response = await ktorApi.post('/report', {
        userId: survey.r_userId,
        orderId
      });
      if (response.data) {
        console.log('response.data', response.data);
        setSurveyValue('r_report', response.data?.whole_report);
      } else {
        console.error('Empty response data');
      }
    } catch (error) {
      console.error('Error fetching report data:', error);
    }
  };

  return { fetchReportData };
};
