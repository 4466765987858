import { formatKoreanCurrency } from '../util/formatKoreanCurrency';
import '../style/ApartmentListEntry.css';

export const ApartmentListEntry = ({
  apartment,
  index,
  selectedApartmentIdx,
  handleApartmentIdx,
  isJeonseSale
}) => {
  const {
    title,
    salePriceMin,
    salePriceMax,
    jeonsePriceMin,
    jeonsePriceMax,
    location
  } = apartment;
  const priceMin = isJeonseSale ? jeonsePriceMin : salePriceMin;
  const priceMax = isJeonseSale ? jeonsePriceMax : salePriceMax;
  const isSelected = selectedApartmentIdx === index;

  return (
    <button
      className="apartment-container"
      onClick={() => handleApartmentIdx(index)}
      key={index}
    >
      <div
        className={`apartment-list-item ${isSelected ? 'border border-orange rounded-2xl' : ''}`}
      >
        <div
          className={`apartment-item-number ${isSelected ? 'bg-orange' : 'bg-grayLight'}`}
        >
          <span className={`${isSelected ? 'text-white' : 'text-grayDark'}`}>
            {index + 1}
          </span>
        </div>

        <div className="apartment-item-content">
          <div className="apartment-details">
            <p className="apartment-location">{`${location}`}</p>
            <p className="apartment-name">{title}</p>
            <p className="apartment-price">
              {`${formatKoreanCurrency(priceMin)} ~ ${formatKoreanCurrency(priceMax)}원`}
            </p>
          </div>
        </div>
      </div>
    </button>
  );
};
