import React from 'react';
import LeftArrowIcon from '../icon/LeftArrowIcon';

const ChatHeader = ({ onClick = () => {}, title = '' }) => {
  return (
    <div className="sticky top-0 z-50 p-[13px] flex flex-row items-center justify-center bg-white text-orange">
      <button
        onClick={onClick}
        className="header-left-button font-medium absolute left-2"
      >
        <LeftArrowIcon />
        채팅
      </button>
      <div className="header-title">
        <div className="title">{title}</div>
      </div>
    </div>
  );
};

export default ChatHeader;
