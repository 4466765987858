import React from 'react';

const DSRProgressBar = ({ plot = [], label = '권장' }) => {
  return (
    <div>
      <div className={`relative ${label ? 'mt-[35px]' : ''}`}>
        <div
          className={`absolute ${label ? 'top-[-35px]' : ''} z-10 flex flex-col items-center transform translate-x-[-50%]`}
          style={{ left: `${plot[0]}%` }}
        >
          <label className="p-1 bg-[#793B8A] rounded-[4px] text-center text-xs font-bold leading-[140%] text-white">
            {label}
          </label>
          <div className="border border-dashed border-[#793B8A] h-[50px] t-[4px] w-0" />
        </div>
        <div className="w-full h-10 rounded-[8px] relative bg-[#FFE6B8]">
          <div
            className="h-full rounded-[8px] relative bg-progress-gradient max-w-full"
            style={{ width: `${plot[1]}%` }}
          />
          <span
            className="text-orange text-base leading-[100%] font-bold z-20 absolute top-1/2 transform -translate-y-1/2 translate-x-3"
            style={{ left: `${Math.min(plot[1], 80)}%` }}
          >
            {plot[1]}%
          </span>
        </div>
      </div>
    </div>
  );
};

export default DSRProgressBar;
