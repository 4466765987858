import React, { useMemo } from 'react';
import ReactMarkdown from 'react-markdown';

const ChildCareReport = ({ data = {} }) => {
  const { plot = [], text = '' } = data;

  const chartData = useMemo(() => {
    return [
      { value: 61, height: 29, bg: '#FFE6B8', label: `299만원\n이하` },
      { value: 116, height: 54, bg: '#FFD38A', label: `300~\n399만원` },
      { value: 159, height: 72, bg: '#FFA267', label: `400~\n499만원` },
      { value: 184, height: 90, bg: '#FF8440', label: `500~\n599만원` },
      { value: 343, height: 154, bg: '#FF6319', label: `600만원\n이상` }
    ];
  }, []);

  return (
    <div className="report-container">
      <section className="w-full p-6 pb-2">
        <div className="flex justify-start items-start gap-6">
          <div>
            <p className="text-lg font-bold leading-[100%]">👶🏻 자녀 양육</p>
          </div>
          <div>
            <p className="flex gap-3">
              <span className="text-sm leading-[140%] text-[#959493]">
                출산
              </span>
              <span className="text-sm font-medium leading-[140%]">
                결혼 {plot[0]}년 후 ({plot[1]}세)
              </span>
            </p>
          </div>
        </div>
      </section>
      <section className="px-6 py-4">
        <div className="px-6 py-5 report-shadow rounded-2xl">
          <div>
            <p className="text-base font-bold leading-[140%]">
              가구 소득구간별
              <br />월 평균 자녀 1인당 양육 비용
            </p>
            <p className="mt-[6px] text-[11px] leading-[100%]">
              (0세 ~만 24세까지 양육시)
            </p>
          </div>
          <div className="mt-[10px] flex justify-between gap-4 text-center">
            {chartData.map((item, index) => {
              return (
                <div
                  key={index}
                  className="flex flex-col justify-end items-center gap-[6px]"
                >
                  <p className="text-[11px] font-semibold leading-[140%]">
                    {item.value}만원
                  </p>
                  <div
                    className={`bg-[${item.bg}] w-9 h-[${item.height}px] rounded-[4px]`}
                    style={{
                      background: `${item.bg}`,
                      height: `${item.height}px`
                    }}
                  />
                  <label className="text-[11px] text-center leading-[120%] whitespace-pre">
                    {item.label}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section className="w-full pt-2 px-[30px] pb-6">
        <ReactMarkdown className="text-[14px] font-normal leading-[170%]">
          {text}
        </ReactMarkdown>
      </section>
    </div>
  );
};

export default ChildCareReport;
