import React from 'react';
import ReactDOM from 'react-dom';
import usePreventSwipe from '../../../hook/usePreventSwipe';
import BackHeader from '../../header/BackHeader';

const MonteCarloPopup = ({ close }) => {
  const rootElement = document.getElementById('root'); // <div id="root"> 안에 렌더링
  usePreventSwipe('.popup-report-container'); // 터치 이동 방지 훅 호출

  return ReactDOM.createPortal(
    <div className="popup-report-container">
      <div className="px-4">
        <BackHeader goToPrev={close} className="flex items-center" />
      </div>
      <section className="p-6 pt-5 flex flex-col gap-8">
        <div>
          <h1 className="text-[18px] font-bold leading-[100%] mb-3">
            <span className="mr-[6px]">📈</span>
            몬테카를로 시뮬레이션이란?
          </h1>
          <p className="text-sm leading-[170%]">
            몬테카를로 시뮬레이션은 미래에 어떤 일이 일어날지 예측하는 방법 중
            하나예요. 쉽게 말해, 다양한 상황에서 결과가 어떻게 달라질지 수천 번
            계산해보는 방식이라고 생각하면 됩니다.
            <br />
            투자 수익률, 물가 상승률, 경제 상황 등이 어떻게 변할지는 아무도
            확실히 알 수 없어 미래를 예측하기 어렵기 때문에{' '}
            <strong>
              몬테카를로 시뮬레이션은 이런 불확실성을 고려해서, 최상의 경우,
              최악의 경우, 그리고 그 사이의 다양한 가능성을 보여줍니다.
            </strong>
          </p>
        </div>
        <div className="flex flex-col gap-4">
          <div>
            <h2 className="text-sm font-bold leading-[100%] text-orange">
              1. 가설 설정
            </h2>
            <p className="pt-2 pb-4 text-sm leading-[170%]">
              물가상승률 평균 2.09% min 0.6% max 5.0% 투자자산상승률 평균 3.00%
              std 0.02 median 2.5%~8.5% 적용하여 계산해요.
            </p>
          </div>
          <div>
            <h2 className="text-sm font-bold leading-[100%] text-orange">
              2. 랜덤 시뮬레이션
            </h2>
            <p className="pt-2 pb-4 text-sm leading-[170%]">
              가설 항목과 함께 나의 목표 금액, 소득, 지출 등을 고려하여 1,000번,
              10,000번 등 다양한 경우를 계산해요.
            </p>
          </div>
          <div>
            <h2 className="text-sm font-bold leading-[100%] text-orange">
              3. 결과 분석
            </h2>
            <p className="pt-2 pb-4 text-sm leading-[170%]">
              예를 들어, 100번 중에 내가 다양한 경제 상황에서도 목표를 이룬
              횟수가 65번이라면 목표를 달성할 확률을 65%로 계산해요.
            </p>
          </div>
        </div>
        <div className="mb-8">
          <p className="text-sm leading-[170%]">
            몬테카를로 시뮬레이션은 불확실한 미래를 수많은 경우의 수로 계산해서,
            목표를 달성할 가능성과 리스크를 예측해주는 도구예요. 복잡해
            보이지만, 결과만 보면 쉽고 명확하게 이해할 수 있을 거에요.😊
          </p>
        </div>
      </section>
    </div>,
    rootElement
  );
};

export default MonteCarloPopup;
