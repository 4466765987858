import { useRecoilValue } from 'recoil';
import ktorApi from '../api/ktorApi';
import { surveyState } from '../recoil/atoms';

export const useSaveAll = () => {
  const survey = useRecoilValue(surveyState);
  const {
    r_surveyId,
    r_userId,
    r_selfAge,
    r_spouseAge,
    r_selfRetirementAge,
    r_spouseRetirementAge,
    r_selfIncome,
    r_spouseIncome,
    r_selfSaving,
    r_spouseSaving,
    r_selfCashAsset,
    r_spouseCashAsset,
    r_selfInvestmentAsset,
    r_spouseInvestmentAsset,
    r_selfHouseLoanYN,
    r_selfHouseLoanType,
    r_selfDeposit,
    r_selfHomeLoan,
    r_selfHouseMonthlyPayment,
    r_selfHousingExpense,
    r_selfPrincipal,
    r_selfAnnualRate,
    r_selfRepaymentTerm,
    r_selfGracePeriod,
    r_selfRepaymentOption,
    r_selfPaymentFrequency,
    r_selfHousingType,
    r_spouseHouseLoanYN,
    r_spouseHouseLoanType,
    r_spouseDeposit,
    r_spouseHomeLoan,
    r_spouseHouseMonthlyPayment,
    r_spouseHousingExpense,
    r_spousePrincipal,
    r_spouseAnnualRate,
    r_spouseRepaymentTerm,
    r_spouseGracePeriod,
    r_spouseRepaymentOption,
    r_spousePaymentFrequency,
    r_spouseHousingType,
    r_selfEtcDebt,
    r_spouseEtcDebt,
    r_selfMortgageAmount,
    r_spouseMortgageAmount,
    r_selfMortgageStart,
    r_spouseMortgageStart,
    r_selfMortgage,
    r_spouseMortgage
  } = survey;

  // console.log('r_selfMortgage: ', r_selfMortgage);
  // console.log('r_spouseMortgage: ', r_spouseMortgage);

  const saveAll = async () => {
    console.log('r_selfIncome: ', r_selfIncome);
    try {
      const response = await ktorApi.post('/save/all', {
        r_surveyId,
        r_userId,
        r_selfAge: Number(r_selfAge),
        r_spouseAge: Number(r_spouseAge),
        r_selfRetirementAge: Number(r_selfRetirementAge),
        r_spouseRetirementAge: Number(r_spouseRetirementAge),
        r_selfIncome: Number(r_selfIncome),
        r_spouseIncome: Number(r_spouseIncome),
        r_selfSaving: Number(r_selfSaving),
        r_spouseSaving: Number(r_spouseSaving),
        r_selfCashAsset: Number(r_selfCashAsset),
        r_spouseCashAsset: Number(r_spouseCashAsset),
        r_selfInvestmentAsset: Number(r_selfInvestmentAsset),
        r_spouseInvestmentAsset: Number(r_spouseInvestmentAsset),
        r_selfHouseLoanYN: r_selfHouseLoanYN,
        r_selfHouseLoanType: r_selfHouseLoanType,
        r_selfDeposit: Number(r_selfDeposit),
        r_selfHomeLoan: Number(r_selfHomeLoan),
        r_selfHouseMonthlyPayment: Number(r_selfHouseMonthlyPayment),
        r_selfHousingExpense: Number(r_selfHousingExpense),
        r_selfPrincipal: Number(r_selfPrincipal),
        r_selfAnnualRate: Number(r_selfAnnualRate),
        r_selfRepaymentTerm: Number(r_selfRepaymentTerm),
        r_selfGracePeriod: Number(r_selfGracePeriod),
        r_selfRepaymentOption: r_selfRepaymentOption,
        r_selfPaymentFrequency: r_selfPaymentFrequency,
        r_selfHousingType: r_selfHousingType,
        r_spouseHouseLoanYN: r_spouseHouseLoanYN,
        r_spouseHouseLoanType: r_spouseHouseLoanType,
        r_spouseDeposit: Number(r_spouseDeposit),
        r_spouseHomeLoan: Number(r_spouseHomeLoan),
        r_spouseHouseMonthlyPayment: Number(r_spouseHouseMonthlyPayment),
        r_spouseHousingExpense: Number(r_spouseHousingExpense),
        r_spousePrincipal: Number(r_spousePrincipal),
        r_spouseAnnualRate: Number(r_spouseAnnualRate),
        r_spouseRepaymentTerm: Number(r_spouseRepaymentTerm),
        r_spouseGracePeriod: Number(r_spouseGracePeriod),
        r_spouseRepaymentOption: r_spouseRepaymentOption,
        r_spousePaymentFrequency: r_spousePaymentFrequency,
        r_spouseHousingType: r_spouseHousingType,
        r_selfEtcDebt: Number(r_selfEtcDebt),
        r_spouseEtcDebt: Number(r_spouseEtcDebt),
        r_selfMortgageAmount: Number(r_selfMortgageAmount),
        r_spouseMortgageAmount: Number(r_spouseMortgageAmount),
        r_selfMortgageStart,
        r_spouseMortgageStart,
        r_selfMortgage,
        r_spouseMortgage
      });
      return response;
    } catch (error) {
      console.error('Error saving all:', error);
    }
  };

  return saveAll;
};
