export function generateOrderId() {
  // Get current date
  const now = new Date();

  // Format date as YYMMDD
  const year = now.getFullYear().toString().slice(-2); // Last two digits of the year
  const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const day = String(now.getDate()).padStart(2, '0'); // Day of the month

  // Generate 4-digit random number
  const randomDigits = Math.floor(1000 + Math.random() * 9000); // Ensures a 4-digit number

  // Combine YYMMDD and random number
  return `${year}${month}${day}${randomDigits}`;
}
