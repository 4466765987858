import { useRecoilState } from 'recoil';
import ktorApi from '../api/ktorApi';
import { surveyState } from '../recoil/atoms';

export const useFetchPreviousAnswer = () => {
  const [survey, setSurvey] = useRecoilState(surveyState);

  const fetchPreviousAnswer = async () => {
    try {
      // Fetch previous answers from the API
      const response = await ktorApi.post('/get/all', {
        userId: survey.r_userId,
        surveyId: survey.r_surveyId
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching previous answers:', error);
      throw error; // Rethrow error for the caller to handle
    }
  };

  return fetchPreviousAnswer;
};
