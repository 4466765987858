import React from 'react';

const RetirementFundsReport = () => {
  return (
    <div className="report-container">
      <section className="p-6">
        <div className="flex flex-col gap-2">
          <h1 className="text-[16px] font-bold leading-[100%]">
            <span className="mr-[6px]">🎯</span>
            은퇴 자금을 달성할 수 있는 방안은?
          </h1>
          <p className="text-[14px] font-normal leading-[170%]">
            은퇴 준비를 위해 여러 가지 시나리오를 가정하고 핀베리만의 미래
            시뮬레이션 계산을 도입하여 예측해 보았어요. 이번 시뮬레이션은 주택
            자금을 제외하고 현금성 자산과 투자 자산만을 기준으로 결과를
            도출하였습니다.
            <br />
            <br />
            물론, 아주 간단한 정보만을 토대로 진행한 것이기 때문에 참고용으로만
            봐주시면 좋겠어요. 은퇴 자금을 더 꼼꼼하게 설계할 때 하나의
            가이드라인 정도로 생각해 주세요.😊
          </p>
        </div>
      </section>
    </div>
  );
};

export default RetirementFundsReport;
