import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useResetRecoilState } from 'recoil';
import { useSetSurveyValue } from '../../hook/useSetSurveyValue';
import { surveyState } from '../../recoil/atoms';
import ChatHeader from '../header/ChatHeader';
import { PreviousAnswerBody } from './PreviousAnswerBody';

export const PreviousAnswerPad = () => {
  const setSurveyValue = useSetSurveyValue();
  const resetSurvey = useResetRecoilState(surveyState);
  const navigate = useNavigate();

  const onClick = () => {
    console.log('HERE');
    // resetSurvey();
    setSurveyValue('r_surveyId', 1);
    setSurveyValue('r_idx', 0);
    setSurveyValue('r_messages', []);
    setSurveyValue('r_isRedoSurveyAsked', false);
    setSurveyValue('r_isPreviousAnswerPadVisible', false);
    navigate('/chat');
  };

  return (
    <div className="overlay">
      <div className="w-full max-w-md h-[100dvh] bg-white overflow-auto">
        <div className="pb-8">
          <ChatHeader onClick={onClick} title="프로필 정보" />
          <PreviousAnswerBody />
        </div>
      </div>
    </div>
  );
};
