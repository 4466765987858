import { useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { surveyState } from '../recoil/atoms';
import '../style/MessageWindow.css';
import MessageBubble from './MessageBubble';

export const MessageWindow = () => {
  const messages = useRecoilValue(surveyState).r_messages;
  const lastMessageRef = useRef(null);
  // 마지막 메시지로 스크롤 이동
  useEffect(() => {
    if (lastMessageRef.current) {
      setTimeout(() => {
        lastMessageRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'end'
        });
      }, 100);
    }
  }, [messages]);

  return (
    <div className="message-window">
      {messages.map((msg, index) => (
        <MessageBubble key={index} role={msg.role} question={msg.content} />
      ))}
      <div ref={lastMessageRef}></div>
    </div>
  );
};
