import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { config } from '../config';
import { useAddMessage } from '../hook/useAddMessage';
import { useAddUserMessage } from '../hook/useAddUserMessage';
import { useMoveToNextQuestion } from '../hook/useMoveToNextQuestion';
import { useMoveToTheQuestion } from '../hook/useMoveToTheQuestion';
import { useSetSurveyValue } from '../hook/useSetSurveyValue';
import {
  isBrandUPState,
  isCarTypeUPState,
  isFuelTypeUPState,
  isMakerUPState,
  stateHandlerSelector,
  surveyState
} from '../recoil/atoms';
import '../style/CarList.css';
import { CarListEntry } from './CarListEntry';
import CarListItem from './CarListItem';
import { SortUp } from './SortUp';
import LeftArrowIcon from './icon/LeftArrowIcon';
export const CarList = () => {
  const [survey, setSurvey] = useRecoilState(surveyState);
  const { r_isCarListVisible, r_carList } = survey;
  const [isMakerUP, setIsMakerUP] = useRecoilState(isMakerUPState);
  const [isBrandUP, setIsBrandUP] = useRecoilState(isBrandUPState);
  const [isCarTypeUP, setIsCarTypeUP] = useRecoilState(isCarTypeUPState);
  const [isFuelTypeUP, setIsFuelTypeUP] = useRecoilState(isFuelTypeUPState);
  const [filteredCarList, setFilteredCarList] = useState(survey.r_carList);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedCarTypes, setSelectedCarTypes] = useState([]);
  const [selectedFuelTypes, setSelectedFuelTypes] = useState([]);
  const [selectedCarIdx, setSelectedCarIdx] = useState(-1);
  const [isSortUP, setIsSortUP] = useState(false);
  const [sortIdx, setSortIdx] = useState(0);
  const sortUpRef = useRef(null);

  const setSurveyValue = useSetSurveyValue();
  const moveToNextQuestion = useMoveToNextQuestion();
  const moveToTheQuestion = useMoveToTheQuestion();
  const addUserMessage = useAddUserMessage();
  const addMessage = useAddMessage();
  const setStateHandler = useSetRecoilState(stateHandlerSelector);

  const allCountries = Array.from(new Set(r_carList.map((car) => car.maker)));
  const allBrands = Array.from(new Set(r_carList.map((car) => car.brand)));
  const allCarTypes = Array.from(new Set(r_carList.map((car) => car.car_type)));
  const allFuelTypes = ['가솔린', '디젤', '하이브리드', '전기', 'LPG'];

  const handleCarIdx = (idx) => {
    if (idx === selectedCarIdx) {
      setSelectedCarIdx(-1);
      return;
    }
    setSelectedCarIdx(idx);
  };

  const handleSortUP = () => {
    setIsSortUP(!isSortUP);
  };

  const handleCarConfirm = () => {
    addUserMessage({ optionText: filteredCarList[selectedCarIdx].title });
    addMessage({ questionText: '@CAR_NAMECARD', questionType: 'CAR_NAMECARD' });
    setSurveyValue('r_isCarListVisible', !r_isCarListVisible);
    setSurveyValue('r_carName', filteredCarList[selectedCarIdx].title);
    setSurveyValue('r_selectedCar', filteredCarList[selectedCarIdx]);
    moveToTheQuestion(config.CAR_NAMECARD_IDX);
  };

  const handleCountrySelection = (country) => {
    setSelectedCountries((prevSelected) =>
      prevSelected.includes(country)
        ? prevSelected.filter((c) => c !== country)
        : [...prevSelected, country]
    );
  };

  const handleBrandSelection = (brand) => {
    setSelectedBrands((prevSelected) =>
      prevSelected.includes(brand)
        ? prevSelected.filter((b) => b !== brand)
        : [...prevSelected, brand]
    );
  };

  const handleCarTypeSelection = (carType) => {
    setSelectedCarTypes((prevSelected) =>
      prevSelected.includes(carType)
        ? prevSelected.filter((ct) => ct !== carType)
        : [...prevSelected, carType]
    );
  };

  const handleFuelTypeSelection = (fuelType) => {
    setSelectedFuelTypes((prevSelected) =>
      prevSelected.includes(fuelType)
        ? prevSelected.filter((ft) => ft !== fuelType)
        : [...prevSelected, fuelType]
    );
  };

  const handleReset = (e, filterType) => {
    e.stopPropagation();

    const resetActions = {
      제조사: () => setSelectedCountries([]),
      브랜드: () => setSelectedBrands([]),
      '차량 종류': () => setSelectedCarTypes([]),
      '연료 유형': () => setSelectedFuelTypes([])
    };

    const resetAction = resetActions[filterType];
    if (resetAction) resetAction();
  };

  useEffect(() => {
    if (sortIdx === 1) {
      setFilteredCarList(
        (prev) => prev.sort((a, b) => a.price_min - b.price_min) // Sort by min price (가격 낮은 순)
      );
    } else if (sortIdx === 2) {
      setFilteredCarList(
        (prev) => prev.sort((a, b) => b.price_max - a.price_max) // Sort by max price (가격 높은 순)
      );
    } else if (sortIdx === 3) {
      setFilteredCarList(
        (prev) => prev.sort((a, b) => a.title.localeCompare(b.title, 'ko')) // Sort by title alphabetically (차량 이름 순)
      );
    }
  }, [sortIdx]);

  const handleApply = () => {
    let tempCars = [...survey.r_carList];

    if (selectedCountries.length > 0) {
      tempCars = tempCars.filter((car) =>
        selectedCountries.includes(car.maker)
      );
    }

    if (selectedBrands.length > 0) {
      tempCars = tempCars.filter((car) => selectedBrands.includes(car.brand));
    }

    if (selectedCarTypes.length > 0) {
      tempCars = tempCars.filter((car) =>
        car.car_type.split(',').some((type) => selectedCarTypes.includes(type))
      );
    }

    if (selectedFuelTypes.length > 0) {
      tempCars = tempCars.filter((car) =>
        car.fuel_type
          .split(',')
          .some((type) => selectedFuelTypes.includes(type))
      );
    }

    // Set the filtered list to the state
    setFilteredCarList(tempCars);
  };

  const handleMakerUP = () => {
    setStateHandler({ type: 'SET_MAKER_UP', payload: !isMakerUP });
  };

  const handleBrandUP = () => {
    setStateHandler({ type: 'SET_BRAND_UP', payload: !isBrandUP });
  };

  const handleCarTypeUP = () => {
    setStateHandler({ type: 'SET_CAR_TYPE_UP', payload: !isCarTypeUP });
  };

  const handleFuelTypeUP = () => {
    setStateHandler({ type: 'SET_FUEL_TYPE_UP', payload: !isFuelTypeUP });
  };

  const closeModal = () => {
    setStateHandler({ type: 'CLOSE_MODAL' });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sortUpRef.current && !sortUpRef.current.contains(event.target)) {
        setIsSortUP(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [sortUpRef]);

  const filterOptions = [
    {
      label: '제조사',
      onClick: handleMakerUP,
      length: selectedCountries.length
    },
    {
      label: '브랜드',
      onClick: handleBrandUP,
      length: selectedBrands.length
    },
    {
      label: '차량 종류',
      onClick: handleCarTypeUP,
      length: selectedCarTypes.length
    },
    {
      label: '연료 유형',
      onClick: handleFuelTypeUP,
      length: selectedFuelTypes.length
    }
  ];

  return (
    <div className="overlay">
      <div className="content-container">
        <div className="header-container">
          <button
            onClick={() => {
              setSurveyValue('r_isCarListVisible', !r_isCarListVisible);
            }}
            className="flex items-center"
          >
            <LeftArrowIcon />
            채팅
          </button>

          <div className="header-title">
            <div className="title">자동차 추천 리스트</div>
            <div className="date-info">2024년 09월 12일 목요일 업데이트</div>
          </div>
          <button onClick={handleSortUP} className="pl-6">
            <FontAwesomeIcon icon={faEllipsisVertical} />
          </button>
        </div>

        <div className="sort-container">
          {filterOptions.map(({ label, onClick, length }, index) => (
            <button
              key={index}
              className={`sort-button ${length !== 0 ? 'bg-orange text-white' : ''}`}
              onClick={onClick}
            >
              <span className={`${length !== 0 ? 'text-white' : ''}`}>
                {label}
              </span>
            </button>
          ))}
        </div>

        <div className="carlist-container">
          {filteredCarList.map((car, index) => (
            <CarListEntry
              car={car}
              key={car.id}
              index={index}
              selectedCarIdx={selectedCarIdx}
              handleCarIdx={handleCarIdx}
            />
          ))}
        </div>

        {isMakerUP && (
          <CarListItem
            title="제조사"
            list={allCountries}
            selectedList={selectedCountries}
            handleSelection={handleCountrySelection}
            handleReset={handleReset}
            handleApply={handleApply}
            closeModal={closeModal}
          />
        )}

        {isBrandUP && (
          <CarListItem
            title="브랜드"
            list={allBrands}
            selectedList={selectedBrands}
            handleSelection={handleBrandSelection}
            handleReset={handleReset}
            handleApply={handleApply}
            closeModal={closeModal}
          />
        )}

        {isCarTypeUP && (
          <CarListItem
            title="차량 종류"
            list={allCarTypes}
            selectedList={selectedCarTypes}
            handleSelection={handleCarTypeSelection}
            handleReset={handleReset}
            handleApply={handleApply}
            closeModal={closeModal}
          />
        )}

        {isFuelTypeUP && (
          <CarListItem
            title="연료 유형"
            list={allFuelTypes}
            selectedList={selectedFuelTypes}
            handleSelection={handleFuelTypeSelection}
            handleReset={handleReset}
            handleApply={handleApply}
            closeModal={closeModal}
          />
        )}

        {selectedCarIdx !== -1 && (
          <div className="car-confirm-container mr-8">
            <button className="car-confirm-button" onClick={handleCarConfirm}>
              확인
            </button>
          </div>
        )}

        {isSortUP && (
          <SortUp
            sortIdx={sortIdx}
            setSortIdx={setSortIdx}
            setIsSortUP={setIsSortUP}
          />
        )}
      </div>
    </div>
  );
};
