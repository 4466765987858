import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { config } from '../../config';
import { useMoveToTheQuestion } from '../../hook/useMoveToTheQuestion';
import { useSetSurveyValue } from '../../hook/useSetSurveyValue';
import { surveyState } from '../../recoil/atoms';
import { Numberpad2 } from './NnumberPad2';
import { PreviousAnswerSpouseList } from './PreviouosAnswerSpouseList';
import { PreviousAnswerSelfList } from './PreviousAnswerSelfList';
import { PreviousAnswerTab } from './PreviousAnswerTab';
import { TabGroup } from '@headlessui/react';

export const PreviousAnswerBody = () => {
  const [survey, setSurvey] = useRecoilState(surveyState);
  // const { r_previousAnswers } = survey;
  const [isSelf, setIsSelf] = useState(true);
  const [isNumberPadOn, setIsNumberPadOn] = useState(false);
  const [keySelected, setKeySelected] = useState('');
  const [isChanged, setIsChanged] = useState(false);
  const setSurveyValue = useSetSurveyValue();
  const moveToTheQuestion = useMoveToTheQuestion();

  const {
    r_selfAge,
    r_spouseAge,
    r_selfRetirementAge,
    r_spouseRetirementAge,
    r_selfIncome,
    r_spouseIncome,
    r_selfSaving,
    r_spouseSaving,
    r_selfCashAsset,
    r_spouseCashAsset,
    r_selfInvestmentAsset,
    r_spouseInvestmentAsset,
    r_selfHouseLoanYN,
    r_selfHouseLoanType,
    r_selfDeposit,
    r_selfHomeLoan,
    r_selfHouseMonthlyPayment,
    r_selfHousingExpense,
    r_selfPrincipal,
    r_selfAnnualRate,
    r_selfRepaymentTerm,
    r_selfGracePeriod,
    r_selfRepaymentOption,
    r_selfPaymentFrequency,
    r_selfHousingType,
    r_spouseHouseLoanYN,
    r_spouseHouseLoanType,
    r_spouseDeposit,
    r_spouseHomeLoan,
    r_spouseHouseMonthlyPayment,
    r_spouseHousingExpense,
    r_spousePrincipal,
    r_spouseAnnualRate,
    r_spouseRepaymentTerm,
    r_spouseGracePeriod,
    r_spouseRepaymentOption,
    r_spousePaymentFrequency,
    r_spouseHousingType,
    r_selfEtcDebt,
    r_spouseEtcDebt,
    r_selfMortgageAmount,
    r_spouseMortgageAmount,
    r_selfMortgageStart,
    r_spouseMortgageStart,
    r_selfMortgage,
    r_spouseMortgage
  } = survey;

  const [form, setForm] = useState({
    r_selfAge,
    r_spouseAge,
    r_selfRetirementAge,
    r_spouseRetirementAge,
    r_selfIncome,
    r_spouseIncome,
    r_selfSaving,
    r_spouseSaving,
    r_selfCashAsset,
    r_spouseCashAsset,
    r_selfInvestmentAsset,
    r_spouseInvestmentAsset,
    r_selfHouseLoanYN,
    r_selfHouseLoanType,
    r_selfDeposit,
    r_selfHomeLoan,
    r_selfHouseMonthlyPayment,
    r_selfHousingExpense,
    r_selfPrincipal,
    r_selfAnnualRate,
    r_selfRepaymentTerm,
    r_selfGracePeriod,
    r_selfRepaymentOption,
    r_selfPaymentFrequency,
    r_selfHousingType,
    r_spouseHouseLoanYN,
    r_spouseHouseLoanType,
    r_spouseDeposit,
    r_spouseHomeLoan,
    r_spouseHouseMonthlyPayment,
    r_spouseHousingExpense,
    r_spousePrincipal,
    r_spouseAnnualRate,
    r_spouseRepaymentTerm,
    r_spouseGracePeriod,
    r_spouseRepaymentOption,
    r_spousePaymentFrequency,
    r_spouseHousingType,
    r_selfEtcDebt,
    r_spouseEtcDebt,
    r_selfMortgageAmount,
    r_spouseMortgageAmount,
    r_selfMortgageStart,
    r_spouseMortgageStart,
    r_selfMortgage,
    r_spouseMortgage
  });

  const handleSave = () => {
    setSurveyValue('r_isPreviousAnswerPadVisible', false);
    moveToTheQuestion(config.SPOUSE_ETC_DEBT_IDX + 1);
    if (
      form.r_selfHouseLoanYN === true &&
      form.r_selfHouseLoanType === '월세자금대출'
    ) {
      setSurveyValue('r_selfHousingType', '');
    }

    if (
      form.r_selfHouseLoanYN === true &&
      form.r_selfHouseLoanType === '전세금자금대출'
    ) {
      setSurveyValue('r_selfHouseMonthlyPayment', '');
      setSurveyValue('r_selfHousingType', '');
    }

    if (
      form.r_selfHouseLoanYN === true &&
      form.r_selfHouseLoanType === '주택담보대출'
    ) {
      setSurveyValue('r_selfDeposit', '');
      setSurveyValue('r_selfHomeLoan', '');
      setSurveyValue('r_selfHouseMonthlyPayment', '');
      setSurveyValue('r_selfHousingType', '');
    }

    if (form.r_selfHouseLoanYN === false && form.r_selfHousingType === '월세') {
      setSurveyValue('r_selfHouseLoanType', '');
      setSurveyValue('r_selfDeposit', '');
      setSurveyValue('r_selfHomeLoan', '');
      setSurveyValue('r_selfHousingExpense', '');
    }

    if (form.r_selfHouseLoanYN === false && form.r_selfHousingType === '전세') {
      setSurveyValue('r_selfHouseLoanType', '');
      setSurveyValue('r_selfHomeLoan', '');
      setSurveyValue('r_selfHouseMonthlyPayment', '');
      setSurveyValue('r_selfHousingExpense', '');
    }

    if (
      form.r_selfHouseLoanYN === false &&
      form.r_selfHousingType === '자가 혹은 부모님과 함께 거주'
    ) {
      setSurveyValue('r_selfHouseLoanType', '');
      setSurveyValue('r_selfDeposit', '');
      setSurveyValue('r_selfHomeLoan', '');
      setSurveyValue('r_selfHouseMonthlyPayment', '');
      setSurveyValue('r_selfHousingExpense', '');
    }

    if (
      form.r_spouseHouseLoanYN === true &&
      form.r_spouseHouseLoanType === '월세자금대출'
    ) {
      setSurveyValue('r_spouseHousingType', '');
    }
    if (
      form.r_spouseHouseLoanYN === true &&
      form.r_spouseHouseLoanType === '전세금자금대출'
    ) {
      setSurveyValue('r_spouseHouseMonthlyPayment', '');
      setSurveyValue('r_spouseHousingType', '');
    }
    if (
      form.r_spouseHouseLoanYN === true &&
      form.r_spouseHouseLoanType === '주택담보대출'
    ) {
      setSurveyValue('r_spouseDeposit', '');
      setSurveyValue('r_spouseHomeLoan', '');
      setSurveyValue('r_spouseHouseMonthlyPayment', '');
      setSurveyValue('r_spouseHousingType', '');
    }
    if (
      form.r_spouseHouseLoanYN === false &&
      form.r_spouseHousingType === '월세'
    ) {
      setSurveyValue('r_spouseHouseLoanType', '');
      setSurveyValue('r_spouseDeposit', '');
      setSurveyValue('r_spouseHomeLoan', '');
      setSurveyValue('r_spouseHousingExpense', '');
    }
    if (
      form.r_spouseHouseLoanYN === false &&
      form.r_spouseHousingType === '전세'
    ) {
      setSurveyValue('r_spouseHouseLoanType', '');
      setSurveyValue('r_spouseHomeLoan', '');
      setSurveyValue('r_spouseHouseMonthlyPayment', '');
      setSurveyValue('r_spouseHousingExpense', '');
    }
    if (
      form.r_spouseHouseLoanYN === false &&
      form.r_spouseHousingType === '자가 혹은 부모님과 함께 거주'
    ) {
      setSurveyValue('r_spouseHouseLoanType', '');
      setSurveyValue('r_spouseDeposit', '');
      setSurveyValue('r_spouseHomeLoan', '');
      setSurveyValue('r_spouseHouseMonthlyPayment', '');
      setSurveyValue('r_spouseHousingExpense', '');
    }
    setSurvey((prev) => ({
      ...prev,
      ...form
    }));
  };

  return (
    <div className="px-4">
      <TabGroup>
        <PreviousAnswerTab isSelf={isSelf} setIsSelf={setIsSelf} />
        {isSelf ? (
          <PreviousAnswerSelfList
            isNumberPadOn={isNumberPadOn}
            setIsNumberPadOn={setIsNumberPadOn}
            setKeySelected={setKeySelected}
            setForm={setForm}
            form={form}
            setIsChanged={setIsChanged}
          />
        ) : (
          <PreviousAnswerSpouseList
            isNumberPadOn={isNumberPadOn}
            setIsNumberPadOn={setIsNumberPadOn}
            setKeySelected={setKeySelected}
            setForm={setForm}
            form={form}
            setIsChanged={setIsChanged}
          />
        )}
      </TabGroup>
      <button
        className="bg-orange text-white p-3 mt-4 rounded-lg flex justify-center items-center w-full font-semibold leading-[20px]"
        onClick={() => handleSave()}
      >
        {isChanged ? '수정 사항 저장하기' : '확인'}
      </button>

      {isNumberPadOn && (
        <Numberpad2
          isSelf={isSelf}
          keySelected={keySelected}
          setKeySelected={setKeySelected}
          setIsNumberPadOn={setIsNumberPadOn}
          setForm={setForm}
          form={form}
        />
      )}
    </div>
  );
};
