import React from 'react';
import ReactMarkdown from 'react-markdown';
import BarChartExample from '../../chart/BarChartExample';

const IncomeReport = ({ data = {} }) => {
  const { plot = [], text = '' } = data;

  return (
    <div className="report-container">
      <section className="w-full p-6 pb-2">
        <p className="text-xl leading-7">{plot[0]} 님과 배우자의 재무 상태를</p>
        <p className="text-xl leading-7">분석한 결과를 알려드려요!</p>
      </section>
      <section className="w-full px-6 py-4">
        <div className="px-6 py-5 bg-white rounded-2xl report-shadow">
          <div className="mb-6">
            <p className="text-base font-bold leading-[140%]">
              {plot[0]}님과 배우자의 부부 소득은{'\r\n'}
            </p>
            <p className="text-base font-bold leading-[140%]">
              <span className="text-orange">상위 {plot[1]}%</span>에 속해요
            </p>
          </div>
          <BarChartExample plot={[plot[2], plot[3], plot[4]]} />
        </div>
      </section>
      <section className="w-full pt-4 px-[30px] pb-6 ">
        <p className="text-base font-bold leading-[100%] mb-3">
          <span className="mr-[6px]">📄</span>
          우리 부부의 소득 수준은?
        </p>
        <ReactMarkdown className="text-[14px] font-normal leading-[170%]">
          {text}
        </ReactMarkdown>
      </section>
    </div>
  );
};

export default IncomeReport;
