export const config = {
  START_IDX: 1, //안녕하세요! @NAME님의 미래 자산 흐름 시뮬레이션을 도와드릴 재무 코치, 핀베리입니다!
  //2:이번 시뮬레이션은 결혼을 가정한 자산 흐름을 기반으로 진행됩니다.
  GENDER_IDX: 3, //먼저 @NAME님의 성별을 알려주세요!
  AGE_IDX: 4, //나이는 어떻게 되시나요?
  //5:현재 세후 월 소득은 얼마인가요?
  SELF_INCOME_IDX: 6, //대략적인 금액이라도 괜찮으니 편하게 알려주세요!
  SELF_SAVINGS_IDX: 7, //한 달에 저축하시는 금액은 얼마인가요?
  SELF_RETIREMENT_AGE_IDX: 8, //한 달에 저축하시는 금액은 얼마인가요?
  //9:이제 배우자에 대해서도 알아볼까요?
  SPOUSE_INCOME_IDX: 10, //배우자의 세후 월 소득은 얼마일까요?
  SPOUSE_SAVINGS_IDX: 11, // 배우자는 매달 얼마를 저축할까요?
  SPOUSE_AGE_IDX: 12, //배우자의 나이는 어떻게 되시나요?
  SPOUSE_RETIREMENT_AGE_IDX: 13, //배우자의 은퇴 시기는 언제일까요?
  //14:자산에 대한 몇 가지 질문만 더 드릴게요!
  //15:현재 본인의 현금성 자산은 얼마인가요?
  SELF_CASH_ASSET_IDX: 16, //현금성 자산은 언제든지 현금으로 바꿀 수 있는 돈이라고 생각하시면 돼요.
  SPOUSE_CASH_ASSET_IDX: 17, //배우자의 현금성 자산은 어느 정도로 예상하시나요?
  //18:현재 본인의 투자자산은 얼마인가요?
  SELF_INVESTMENT_ASSET_IDX: 19, //투자 자산은 주식, 부동산, 펀드처럼 수익을 목표로 장기 보유하는 자산을 말해요
  SPOUSE_INVESTMENT_ASSET_IDX: 20, //좋아요! 그럼 배우자의 투자자산은 얼마로 예상하시나요?

  // 본인 집 관련 대출 - 대출 있음
  SELF_HOUSE_LOAN_YN_IDX: 21, //마지막으로, 주거용 대출이 있으신가요?
  SELF_HOUSE_LOAN_TYPE_IDX: 22, //그렇다면 어떤 대출인가요?

  //월세자금대출
  SELF_MONTHLY_PAY_DEPOSIT_IDX: 23, //** 월세 보증금은 얼마인가요? */
  SELF_HOUSE_LOAN_AMOUNT_IDX_1: 24, // 대출금은 얼마를 가지고 계신가요?
  SELF_HOUSE_LOAN_INTEREST_IDX_1: 25, //** 이자로 얼마를 내시나요? */
  SELF_HOUSE_LOAN_MONTHLY_PAYMENT_IDX: 26, // 그럼 월세로는 얼마를 내시나요?

  //전세자금대출
  SELF_JEONSE_DEPOSIT_IDX: 27, //** 전세보증금은 얼마인가요? */
  SELF_HOUSE_LOAN_AMOUNT_IDX_2: 28, // 대출금은 얼마를 가지고 계신가요?
  SELF_HOUSE_LOAN_INTEREST_IDX_2: 29, // 이자로 얼마를 내시나요?

  //주택담보대출
  SELF_HOUSE_LOAN_CALCULATOR_IDX: 30, // 매달 이자로 얼마를 납부하시나요?

  //대출없음
  SELF_HOUSING_TYPE_IDX: 31, // 주거 형태는 어떻게 되시나요?
  //월세
  SELF_MONTHLY_PAY_AMOUNT_WO_LOAN_IDX: 32, //월세로 얼마를 내시나요?
  //전세
  SELF_JEONSE_AMONT_WO_LOAN_IDX: 33, // 전세금은 얼마인가요?

  // 배우자 대출 - 대출 있음
  SPOUSE_HOUSE_LOAN_YN_IDX: 34, //배우자는 주거용 대출이 있을까요?
  SPOUSE_HOUSE_LOAN_TYPE_IDX: 35, //그렇다면 어떤 대출인가요?

  //월세자금대출
  SPOUSE_MONTHLY_PAY_DEPOSIT_IDX: 36, //월세 보증금은 얼마인가요?
  SPOUSE_HOUSE_LOAN_AMOUNT_IDX_1: 37, //대출금은 얼마를 가지고 계신가요?
  SPOUSE_HOUSE_LOAN_INTEREST_IDX_1: 38, //이자로 얼마를 내시나요?
  SPOUSE_HOUSE_LOAN_MONTHLY_PAYMENT_IDX: 39, //그럼 월세로는 얼마를 내시나요?

  //전세자금대출
  SPOUSE_JEONSE_DEPOSIT_IDX: 40, //전세보증금은 얼마인가요?
  SPOUSE_HOUSE_LOAN_AMOUNT_IDX_2: 41, //대출금은 얼마를 가지고 계신가요?
  SPOUSE_HOUSE_LOAN_INTEREST_IDX_2: 42, //이자로 얼마를 내시나요?

  //주택담보대출
  SPOUSE_HOUSE_LOAN_CALCULATOR_IDX: 43, //매달 이자로 얼마를 납부하시나요?

  //대출없음
  SPOUSE_HOUSING_TYPE_IDX: 44, //주거 형태는 어떻게 되시나요?
  //월세
  SPOUSE_MONTHLY_PAY_AMOUNT_WO_LOAN_IDX: 45, //월세로 얼마를 내시나요?
  //전세
  SPOUSE_JEONSE_AMONT_WO_LOAN_IDX: 46, //전세금은 얼마인가요?
  SELF_ETC_DEBT_COMMENT_IDX: 47,
  SELF_ETC_DEBT_IDX: 48, //급여 담보 신용대출, 학자금 대출, 카드론, 자동차 할부 대출 등이 포함돼요
  SPOUSE_ETC_DEBT_IDX: 49, //배우자가 기타 대출이 있다면, 얼마나 있을까요?
  //50:좋아요! 이제 @NAME님과 배우자 설정이 완료되었어요!
  SELF_NAMECARD_IDX: 51,
  //52:@NAME님 배우자 프로필 입니다!
  SPOUSE_NAMECARD_IDX: 53,
  //54:이제 목표를 설정해볼게요!
  MARRIAGE_AFTER_COMMENT_IDX: 55, //결혼 시기는 언제쯤이 좋을까요?
  MARRIAGE_AFTER_IDX: 56, //1년 이내라면 0을 입력해주세요.
  PURCHASE_IDX: 57, //자산 흐름에 반영하고 싶은 목표를 선택해주세요. 원하는 항목을 모두 선택하실 수 있습니다!

  //차량 구매 계획
  CAR_PURCHASE_YEAR_COMMENT_IDX: 58, //자동차는 결혼 몇 년후 구입할 예정이신가요?
  CAR_PURCHASE_YEAR_IDX: 59, //결혼 후 1년 이내라면 0을 입력해주세요

  CAR_PRICE_IDX: 60, //구입을 고려 중인 차량의 가격대가 있나요? 참고해서 추천해드릴게요!
  //61: 좋습니다! @CAR_PRICE 원대 자동차를 리스트로 정리해보았습니다!
  CAR_LIST_IDX: 62,
  CAR_SELECT_IDX: 63, //'자세히 보기'를 눌러 리스트를 확인하고 하나 선택해주세요!
  CAR_NAMECARD_IDX: 64,
  CAR_PURCHASE_CONFIRM_IDX: 65, //선택한 차량 정보가 맞나요?

  //아파트 구매 계획
  APT_PURCHASE_TYPE_IDX: 66, //좋아요! 어떤 주거 형태의 아파트를 원하시나요?
  APT_PURCHASE_YEAR_COMMENT_IDX: 67, //결혼 후 언제쯤으로 계획하고 계신가요?
  APT_PURCHASE_YEAR_IDX: 68, //결혼 후 1년 이내라면 0을 입력해주세요
  APT_PRICE_IDX: 69, //원하는 아파트의 가격대를 알려주세요.
  APT_REGION1_IDX: 70, //다음 6개의 권역중에서 배우자와 아파트를 구하고 싶은 지역을 선택해주세요.
  APT_REGION2_IDX: 71, //@REGION1 내에서 아파트를 구하고 싶은 지역을 한 곳 선택해주세요.
  APT_LIST_IDX: 73,
  //73: @APT_LIST
  APT_SELECT_IDX: 74, //'자세히 보기'를 눌러 리스트를 확인하고 하나를 선택해주세요.
  APT_PURCHASE_CONFIRM_IDX: 75, //선택한 아파트 정보가 맞나요?

  //자녀 계획
  CHILDREN_AGE_IDX: 76, //좋아요! 그럼 자녀는 몇 살에 낳을 예정이신가요?

  //기타 목표 계획
  EXTRA_GOAL_COMMENT_IDX: 77, //좋습니다! @NAME님의 기타 목표를 이루기 위해 예상되는 금액은 얼마인가요?
  EXTRA_GOAL_MONEY_IDX: 78, //기타 목표는 예를 들어 해외여행을 가거나 새로운 취미를 시작하는 것들이 될 수 있어요.
  EXTRA_GOAL_YEAR_IDX: 79, //몇 살에 이 목표를 이루고 싶으신가요?
  //80: 좋아요! 이제 제공해 주신 모든 정보를 반영하여 @NAME님의 미래 자산 변화를 예측하고 분석한 보고서를 제작해볼게요!
  END_IDX: 81, //나의 재무 리포트 생성까지는 약 3~5분 정도 소요될 예정입니다. 완료되면 알림을 보내드릴게요

  MULTIPLE_PURCHASE_IDX: 82, //앗! 저번에 입력하신 본인과 배우자 정보가 저장되어 있네요!
  ANSWER_PAD_IDX: 83, // 지금 정보를 확인하시고, 수정이 필요하면 변경한 후 빠르게 진행할 수 있어요!
  CHECK_REDO_SURVEY_IDX: 84, //아니면, 기존 채팅 형식으로 처음부터 다시 진행하실 수도 있습니다!
  SPOUSE_RECOMMENDATION_QUESTION_ID: 100,

  CAPITAL_AREA: ['서울특별시', '인천광역시', '경기도'],
  KWANGWON_AREA: ['강원도'],
  CHOONGCHUNG_AREA: ['충청북도', '충청남도', '대전광역시', '세종특별자치시'],
  JEONRA_AREA: ['전라북도', '전라남도', '광주광역시'],
  KYUNGSANG_AREA: [
    '경상북도',
    '경상남도',
    '대구광역시',
    '부산광역시',
    '울산광역시'
  ],
  JEJU_AREA: ['제주시', '서귀포시'],

  FINBERRY_INFO: {
    title: '나만의 AI 재무 코치, 핀베리',
    description: '미래 배우자와의 자산 흐름은? 핀베리에서 시뮬레이션 해보세요!',
    price: 30000,
    productName: '결혼부터 은퇴까지 AI를 활용한 프리미엄 재무 설계'
  },

  // DELAY
  MESSAGE_BUBBLE_DELAY: 800,

  KAKAO_AUTH_URL: `https://finberry.co.kr/ktorapi/kakao/auth`,
  KAKAO_LOGOUT_URL: `https://finberry.co.kr/ktorapi/kakao/logout`,
  KAKAO_UNLINK_URL: `https://finberry.co.kr/ktorapi/kakao/unlink`,

  NAVER_AUTH_URL: `https://finberry.co.kr/ktorapi/naver/auth`,
  NAVER_UNLINK_URL: `https://finberry.co.kr/ktorapi/naver/unlink`,

  GOOGLE_AUTH_URL: `https://finberry.co.kr/ktorapi/google/auth`,
  GOOGLE_UNLINK_URL: `https://finberry.co.kr/ktorapi/google/unlink`,

  DEFAULT_POLICY: [
    {
      id: 10001,
      label: '핀베리 서비스 이용약관',
      isRequired: true,
      isChecked: false,
      path: '/policy/10001.md'
    },
    {
      id: 10002,
      label: '개인정보 수집 및 이용 동의',
      isRequired: true,
      isChecked: false,
      path: '/policy/10002.md'
    },
    {
      id: 10003,
      label: '광고성 정보 수신 동의',
      isRequired: false,
      isChecked: false,
      path: '/policy/10003.md'
    }
  ],

  NOTICE_POLICY: [
    {
      id: 10000,
      label: '공지사항',
      isRequired: false,
      isChecked: false,
      path: '/policy/10000.md'
    }
  ],

  PURCHASE_POLICY: [
    {
      id: 10004,
      label: '상품 구매 조건 확인 및 구매 동의',
      isRequired: true,
      isChecked: false
    },
    {
      id: 10005,
      label: '주문 취소/환불 정책 확인 및 동의',
      title: '결제 취소 및 환불 안내',
      isRequired: true,
      isChecked: false,
      path: '/policy/10005.md'
    },
    {
      id: 10006,
      label: '유의사항 확인 및 동의',
      title: '유의사항 안내 및 동의',
      isRequired: true,
      isChecked: false,
      path: '/policy/10006.md'
    }
  ]
};
