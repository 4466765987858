import ktorApi from '../api/ktorApi';

export const useUpdateIsRefundable = () => {
  const updateIsRefundable = async (orderId, isRefundable) => {
    if (!orderId) {
      console.error('Check orderId');
      return;
    }
    try {
      const response = await ktorApi.post('/payment/update/isRefundable', {
        orderId: orderId,
        isRefundable: isRefundable
      });
      return response;
    } catch (error) {
      console.error('Error updating isRefundable:', error);
    }
  };
  return updateIsRefundable;
};
