export const formatKoreanCurrency = (value) => {
  if (value === '--') return value;

  const num = parseFloat(value);

  if (isNaN(num)) return '0';

  const isNegative = num < 0;

  // Round up to two decimal places
  const absNum = Math.abs(num).toFixed(2);

  let result = '';

  if (absNum >= 100000000) {
    const eok = Math.floor(absNum / 100000000);
    const man = Math.floor((absNum % 100000000) / 10000);
    const rest = (absNum % 10000).toFixed(2);
    result =
      rest > 0
        ? `${eok.toLocaleString()}억 ${
            man > 0
              ? `${man.toLocaleString()}만 ${parseFloat(rest).toLocaleString()}`
              : `${parseFloat(rest).toLocaleString()}`
          }`
        : man > 0
          ? `${eok.toLocaleString()}억 ${man.toLocaleString()}만`
          : `${eok.toLocaleString()}억`;
  } else if (absNum >= 10000) {
    const man = Math.floor(absNum / 10000);
    const rest = (absNum % 10000).toFixed(2);
    result =
      rest > 0
        ? `${man.toLocaleString()}만 ${parseFloat(rest).toLocaleString()}`
        : `${man.toLocaleString()}만`;
  } else {
    result = parseFloat(absNum).toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    });
  }

  return isNegative ? `-${result}` : result;
};
