import React, { useState } from 'react';
import PrimaryButton from '../../button/PrimaryButton';
import ReviewPopup from '../ReviewPopup';
import { useReportPopup } from '../../../hook/useReportPopup';
import { useRecoilValue } from 'recoil';
import { surveyState } from '../../../recoil/atoms';

const FinalThirdReport = () => {
  const survey = useRecoilValue(surveyState);

  const { closePopup } = useReportPopup();
  const [isReviewPopupOpen, setIsReviewPopupOpen] = useState(false);

  const openReviewPopup = (e) => {
    e.preventDefault();
    setIsReviewPopupOpen(true);
  };

  const closeReviewPopup = (e) => {
    e.preventDefault();
    setIsReviewPopupOpen(false);
  };

  if (isReviewPopupOpen) {
    return <ReviewPopup closeReviewPopup={closeReviewPopup} />;
  }

  return (
    <div className="report-container flex flex-col justify-between">
      <section className="p-6 flex flex-col gap-3">
        <div>
          <h2 className="text-xl leading-[140%]">
            우리 부부의 남은 여정,
            <br />
            행복하고 밝은 미래를 그려보세요
          </h2>
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-sm leading-[170%]">
            앞으로 자동차 구매, 내 집 마련, 자녀 계획 등 중요한 인생 목표들을
            준비하면서, 현재의 재무 상태를 점검하고 미래를 위한 방향을 설정하신
            것만으로도 큰 첫걸음을 내디셨습니다!👏
            <br />
            <br />
            꾸준한 저축과 현명한 투자, 주기적인 재무 점검을 이어간다면 안정적인
            미래와 여유로운 은퇴 생활이 여러분을 기다리고 있을 거예요. 작은
            습관이 큰 변화를 만들어내듯, 오늘의 작은 노력이 내일의 큰 행복으로
            돌아올 거예요.
            <br />
            <br />
            지금처럼 체계적으로 준비하면서 조금씩 개선점을 보완해 나간다면, 더욱
            안정적이고 여유로운 미래를 만들어갈 수 있을 거예요. 핀베리가 똑똑한
            재무 파트너로 함께하며 여러분의 든든한 미래를 응원할게요! 💪✨
          </p>
        </div>
      </section>
      <section className="p-6 pt-0 flex flex-col gap-3">
        {!survey.r_reviewed && (
          <div className="w-full">
            <PrimaryButton
              onClick={openReviewPopup}
              className="rounded-[4px] pt-[12px] pb-[12px]"
            >
              리뷰 쓰기
            </PrimaryButton>
          </div>
        )}
        <div className="w-full">
          <button
            onClick={closePopup}
            className="block w-full py-[11px] px-[10px] text-sm font-semibold rounded-[4px] border border-[#D1D1D0]"
          >
            나의 재무 리포트 나가기
          </button>
        </div>
      </section>
    </div>
  );
};

export default FinalThirdReport;
