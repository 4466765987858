import React from 'react';

const ProgressLabelBar = ({
  plot = [],
  bg1 = 'progress-gradient',
  bg2 = '#FFE6B8',
  label = '권장',
  labels = ['투자자산', '현금성자산']
}) => {
  return (
    <div>
      <div className={`relative ${label ? 'mt-[35px]' : ''}`}>
        <div
          className={`absolute ${label ? 'top-[-35px]' : ''} z-50 flex flex-col items-center`}
          style={{ left: `${plot[0]}%` }}
        >
          <label className="px-2 py-1 bg-label-gradient rounded-[4px] text-center text-xs font-bold leading-[140%] text-white">
            권장
          </label>
          <div className="border border-dashed border-[#FF6319] h-[50px] t-[4px] w-0" />
        </div>
        <div
          className="w-full h-10 rounded-[8px] relative"
          style={{ background: bg2 }}
        >
          <div
            className={`h-full rounded-s-[8px] relative bg-${bg1}`}
            style={{ width: `${plot[1]}%` }}
          >
            {plot[1] > 0 && (
              <p className="text-white text-base leading-[100%] font-bold absolute top-1/2 left-2/3 transform -translate-x-1/2 -translate-y-1/2">
                {plot[1]}%
              </p>
            )}
          </div>
        </div>
      </div>

      <div className="flex justify-between mt-[10px] h-[17px]">
        <div className="flex items-center">
          <div className={`inline h-[12px] w-[12px] rounded-full bg-${bg1}`} />
          <span className="text-xs leading-[140%] ml-1">{labels[0]}</span>
        </div>
        <div className="flex items-center">
          <div
            className="inline h-[12px] w-[12px] rounded-full"
            style={{ background: bg2 }}
          />
          <span className="text-xs leading-[140%] ml-1">{labels[1]}</span>
        </div>
      </div>
    </div>
  );
};

export default ProgressLabelBar;
