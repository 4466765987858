import React, { useState } from 'react';
import { useSetSurveyValue } from '../../hook/useSetSurveyValue';
import { formatKoreanCurrency } from '../../util/formatKoreanCurrency';
import { LoanInterestCalculator } from '../LoanInterestCalculator/LoanInterestCalculator';
export const PreviousAnswerSpouseList = ({
  isNumberPadOn,
  setIsNumberPadOn,
  setKeySelected,
  setForm,
  form,
  setIsChanged
}) => {
  const [isCalculatorOpen, setIsCalculatorOpen] = useState(false);
  const setSurveyValue = useSetSurveyValue();
  const handleDropdownChange = (key, value) => {
    console.log(value, typeof value);
    setIsChanged(true);
    setForm((prev) => ({ ...prev, [key]: value }));
    if (
      key === 'r_spouseHouseLoanYN' &&
      value === true &&
      (form.r_spouseHouseLoanType === '' || form.r_spouseHouseLoanType === null)
    ) {
      setForm((prev) => ({ ...prev, r_spouseHouseLoanType: '월세자금대출' }));
    }
  };

  const handleNumberPadOn = (key, value) => {
    setIsChanged(true);
    setIsNumberPadOn(!isNumberPadOn);
    setKeySelected(key);
  };

  const EditableField = ({
    label,
    suffix,
    value,
    onChange,
    prefix,
    isDropdown,
    options
  }) => {
    return (
      <div className="flex items-center justify-between pl-2 pr-1 pt-6 pb-4 border-b border-[#EBEBEB] last:border-none">
        <label className="text-[15px] font-medium leading-[140%] tracking-[0.3px] text-[#5B5A58]">
          {label}
        </label>
        <div className="flex items-center">
          {prefix && (
            <span className="mr-2 text-[16px] font-medium leading-[140%] tracking-[0.32px] text-[#82817F]">
              {prefix}
            </span>
          )}
          {!isDropdown ? (
            <button
              onClick={() => onChange(value)}
              className="text-right border-b border-[#A9A8A7] focus:outline-none w-24 text-[16px] font-medium leading-[140%] tracking-[0.32px]"
            >
              {value}
            </button>
          ) : (
            <select
              value={value}
              onChange={(e) => {
                const inputValue = e.target.value;
                const parsedValue =
                  inputValue === 'true'
                    ? true
                    : inputValue === 'false'
                      ? false
                      : inputValue;
                onChange(parsedValue);
              }}
              className="px-3 py-[6px] border rounded-md border-[#959493] text-left text-sm leading-[24px] tracking-[-0.154px] focus:outline-none"
            >
              {options.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          )}
          {suffix && (
            <span className="ml-2 text-[16px] font-medium leading-[140%] tracking-[-0.32px] text-[#82817F]">
              {suffix}
            </span>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="w-full">
      <EditableField
        key="r_age"
        label="현재 나이"
        suffix="세"
        prefix="만"
        value={form.r_spouseAge}
        onChange={(value) => handleNumberPadOn('r_spouseAge', value)}
      />
      <EditableField
        key="r_spouseIncome"
        label="세후 소득"
        suffix="원"
        prefix="월"
        value={new Intl.NumberFormat('ko-KR').format(form.r_spouseIncome)}
        onChange={(value) => handleNumberPadOn('r_spouseIncome', value)}
      />
      <EditableField
        key="r_selfSaving"
        label="저축 금액"
        suffix="원"
        prefix="월"
        value={new Intl.NumberFormat('ko-KR').format(form.r_spouseSaving)}
        onChange={(value) => handleNumberPadOn('r_spouseSaving', value)}
      />
      <EditableField
        key="r_spouseRetirementAge"
        label="은퇴 시기"
        suffix="세"
        prefix="만"
        value={form.r_spouseRetirementAge}
        onChange={(value) => handleNumberPadOn('r_spouseRetirementAge', value)}
      />
      <EditableField
        key="r_spouseCashAsset"
        label="현금성 자산"
        suffix="원"
        value={new Intl.NumberFormat('ko-KR').format(form.r_spouseCashAsset)}
        onChange={(value) => handleNumberPadOn('r_spouseCashAsset', value)}
      />
      <EditableField
        key="r_spouseInvestmentAsset"
        label="투자자산"
        suffix="원"
        value={new Intl.NumberFormat('ko-KR').format(
          form.r_spouseInvestmentAsset
        )}
        onChange={(value) =>
          handleNumberPadOn('r_spouseInvestmentAsset', value)
        }
      />
      <EditableField
        key="r_spouseHouseLoanYN"
        label="주거용 대출"
        isDropdown
        value={form.r_spouseHouseLoanYN}
        options={[
          { label: '네, 있습니다', value: true },
          { label: '아니요, 없습니다', value: false }
        ]}
        onChange={(value) => handleDropdownChange('r_spouseHouseLoanYN', value)}
      />
      {form.r_spouseHouseLoanYN && (
        <EditableField
          key="r_spouseHouseLoanType"
          label="대출 종류"
          isDropdown
          value={form.r_spouseHouseLoanType}
          options={[
            { label: '월세자금대출', value: '월세자금대출' },
            { label: '전세자금대출', value: '전세자금대출' },
            { label: '주택담보대출', value: '주택담보대출' }
          ]}
          onChange={(value) =>
            handleDropdownChange('r_spouseHouseLoanType', value)
          }
        />
      )}
      {form.r_spouseHouseLoanYN &&
        (form.r_spouseHouseLoanType === '월세자금대출' ||
          form.r_spouseHouseLoanType === '전세자금대출') && (
          <EditableField
            key="r_spouseDeposit"
            label={
              form.r_spouseHouseLoanType === '월세자금대출'
                ? '월세 보증금'
                : '전세보증금'
            }
            suffix="원"
            value={new Intl.NumberFormat('ko-KR').format(form.r_spouseDeposit)}
            onChange={(value) => handleNumberPadOn('r_spouseDeposit', value)}
          />
        )}
      {form.r_spouseHouseLoanYN &&
        form.r_spouseHouseLoanType === '월세자금대출' && (
          <EditableField
            key="r_spouseHouseMonthlyPayment"
            label="월세"
            suffix="원"
            value={new Intl.NumberFormat('ko-KR').format(
              form.r_spouseHouseMonthlyPayment
            )}
            onChange={() => handleNumberPadOn('r_spouseHouseMonthlyPayment')}
          />
        )}
      {form.r_spouseHouseLoanYN &&
        (form.r_spouseHouseLoanType === '월세자금대출' ||
          form.r_spouseHouseLoanType === '전세자금대출') && (
          <EditableField
            key="r_spouseHomeLoan"
            label="대출금"
            suffix="원"
            value={new Intl.NumberFormat('ko-KR').format(form.r_spouseHomeLoan)}
            onChange={(value) => handleNumberPadOn('r_spouseHomeLoan', value)}
          />
        )}
      {form.r_spouseHouseLoanYN &&
        (form.r_spouseHouseLoanType === '월세자금대출' ||
          form.r_spouseHouseLoanType === '전세자금대출') && (
          <EditableField
            key="r_spouseHousingExpense"
            label="이자"
            suffix="원"
            value={new Intl.NumberFormat('ko-KR').format(
              form.r_spouseHousingExpense
            )}
            onChange={(value) =>
              handleNumberPadOn('r_spouseHousingExpense', value)
            }
          />
        )}
      {form.r_spouseHouseLoanYN === true &&
        form.r_spouseHouseLoanType === '주택담보대출' && (
          <div className="flex items-center justify-between pl-2 pr-1 pt-6 pb-4 border-b border-[#EBEBEB] last:border-none">
            <label className="text-[15px] font-medium leading-[140%] tracking-[0.3px] text-[#5B5A58]">
              <div>
                이자
                <button
                  onClick={() => {
                    setIsCalculatorOpen(true);
                  }}
                >
                  <span className="text-orange underline ml-3 text-xs">
                    이자 계산기
                  </span>
                </button>
              </div>
            </label>
            <div className="flex items-center">
              <button
                onClick={() =>
                  handleNumberPadOn(
                    'r_selfHousingExpense',
                    form.r_selfHousingExpense
                  )
                }
                className="text-right border-b border-[#A9A8A7] focus:outline-none w-24 text-[16px] font-medium leading-[140%] tracking-[0.32px]"
              >
                <span>{formatKoreanCurrency(form.r_selfHousingExpense)}</span>
              </button>
              <span className="ml-2 text-[16px] font-medium leading-[140%] tracking-[0.32px] text-[#82817F]">
                원
              </span>
            </div>
          </div>
        )}
      {form.r_spouseHouseLoanYN === false && (
        <EditableField
          key="r_spouseHousingType"
          label="주거 형태"
          isDropdown
          value={form.r_spouseHousingType}
          options={[
            { label: '월세', value: '월세' },
            { label: '전세', value: '전세' },
            {
              label: '자가 혹은 부모님과 함께 거주',
              value: '자가 혹은 부모님과 함께 거주'
            }
          ]}
          onChange={(value) =>
            handleDropdownChange('r_spouseHousingType', value)
          }
        />
      )}
      {form.r_spouseHouseLoanYN === false &&
        form.r_spouseHousingType === '월세' && (
          <EditableField
            key="r_spouseHouseMonthlyPayment"
            label="월세"
            suffix="원"
            value={new Intl.NumberFormat('ko-KR').format(
              form.r_spouseHouseMonthlyPayment
            )}
            onChange={() => handleNumberPadOn('r_spouseHouseMonthlyPayment')}
          />
        )}
      {form.r_spouseHouseLoanYN === false &&
        form.r_spouseHousingType === '전세' && (
          <EditableField
            key="r_spouseDeposit"
            label="전세보증금"
            suffix="원"
            value={new Intl.NumberFormat('ko-KR').format(form.r_spouseDeposit)}
            onChange={() => handleNumberPadOn('r_spouseDeposit')}
          />
        )}
      <EditableField
        key="r_spouseEtcDebt"
        label="기타 대출"
        suffix="원"
        value={new Intl.NumberFormat('ko-KR').format(form.r_spouseEtcDebt)}
        onChange={(value) => handleNumberPadOn('r_spouseEtcDebt', value)}
      />
      {isCalculatorOpen && (
        <div className="overlay bg-[#242424] bg-opacity-10 overflow-hidden">
          <div className="loan-calculator-container z-[100] absolute top-[54px] w-[94%] rounded-2xl bg-white overflow-auto ">
            <h2 className="px-6 pt-6 pb-2 border-b border-[#ECECEC] text-base font-bold leading-[24px] ">
              주택담보대출 이자 계산기
            </h2>
            <LoanInterestCalculator
              isSelfArg={false}
              setIsCalculatorOpen={setIsCalculatorOpen}
              setForm={setForm}
            />
          </div>
        </div>
      )}
    </div>
  );
};
