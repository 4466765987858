import ktorApi from '../api/ktorApi';

export const logout = async () => {
  try {
    const response = await ktorApi.post('/logout');
    if (response.status === 200 || response.status === 201) {
      sessionStorage.clear();
      alert('로그아웃 되었습니다.');
      window.location.reload();
    } else {
      deleteCookie('CU_SESSION_ID');
      console.error('로그아웃 실패:', response.status);
    }
  } catch (error) {
    console.error('로그아웃 실패하였습니다.', error);
  }
};

function deleteCookie(cookieName) {
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
}
