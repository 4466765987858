import { loadTossPayments } from '@tosspayments/tosspayments-sdk';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import AlertModal from '../../component/AlertModal';
import { config } from '../../config';
import { surveyState } from '../../recoil/atoms';
import { userState } from '../../recoil/userAtom';
import '../../style/Payment.css';
import { generateOrderId } from '../../util/generateOrderId';
import { generateRandomAlphabet } from '../../util/generateRandomAlphabet';

const clientKey = process.env.REACT_APP_TOSS_PAY_CLIENT_KEY;
const orderId = generateOrderId();

const OrderPage = () => {
  const user = useRecoilValue(userState);
  const survey = useRecoilValue(surveyState);
  const navigate = useNavigate();
  const [ready, setReady] = useState(false);
  const [widgets, setWidgets] = useState(null);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [amount, setAmount] = useState({
    currency: 'KRW',
    value: config.FINBERRY_INFO.price
  });

  const hasAgreedPayment = survey.r_hasAgreedPayment;

  const handleOrder = async () => {
    if (!hasAgreedPayment) {
      setIsAlertOpen(true);
      return;
    }

    try {
      /**
       * 결제 요청
       * 결제를 요청하기 전에 orderId, amount를 서버에 저장하세요.
       * 결제 과정에서 악의적으로 결제 금액이 바뀌는 것을 확인하는 용도입니다.
       * @docs https://docs.tosspayments.com/sdk/v2/js#widgetsrequestpayment
       */
      await widgets?.requestPayment({
        orderId: orderId,
        orderName: config.FINBERRY_INFO.productName,
        customerName: user.fullName,
        successUrl:
          window.location.origin +
          '/checkout' +
          '/success' +
          window.location.search,
        failUrl:
          window.location.origin +
          '/checkout' +
          '/fail' +
          window.location.search
      });
    } catch (error) {
      window.alert('결제 요청 실패하였습니다.');
    }
  };

  useEffect(() => {
    async function fetchPaymentWidgets() {
      const tossPayments = await loadTossPayments(clientKey);
      const customerKey =
        survey.r_sessionId.toString().padStart(6, '0') +
        generateRandomAlphabet(4);
      const widgets = tossPayments.widgets({
        customerKey: customerKey
      }); // 각 회원 구매자에게 발급한 고유 customerKey. ANONYMOUS는 비회원
      setWidgets(widgets);
    }

    fetchPaymentWidgets();
  }, [clientKey]);

  useEffect(() => {
    async function renderPaymentWidgets() {
      if (widgets == null) {
        return;
      }
      /**
       * 위젯의 결제금액을 결제하려는 금액으로 초기화하세요.
       * renderPaymentMethods, renderAgreement, requestPayment 보다 반드시 선행되어야 합니다.
       * @docs https://docs.tosspayments.com/sdk/v2/js#widgetssetamount
       */
      await widgets.setAmount(amount);

      await Promise.all([
        /**
         * 결제창을 렌더링합니다.
         * @docs https://docs.tosspayments.com/sdk/v2/js#widgetsrenderpaymentmethods
         */
        widgets.renderPaymentMethods({
          selector: '#payment-method',
          // 렌더링하고 싶은 결제 UI의 variantKey
          // 결제 수단 및 스타일이 다른 멀티 UI를 직접 만들고 싶다면 계약이 필요해요.
          // @docs https://docs.tosspayments.com/guides/v2/payment-widget/admin#새로운-결제-ui-추가하기
          variantKey: 'widgetA'
        }),
        /**
         * 약관을 렌더링합니다.
         * @docs https://docs.tosspayments.com/reference/widget-sdk#renderagreement선택자-옵션
         */
        widgets.renderAgreement({
          selector: '#agreement',
          variantKey: 'AGREEMENT'
        })
      ]);

      setReady(true);
    }

    renderPaymentWidgets();
  }, [widgets]);

  return (
    <div className="wrapper w-100">
      <div className="max-w-md w-100">
        <div id="payment-method" className="w-100" />
        <div id="agreement" className="w-100" />
        <div className="btn-wrapper w-100">
          <button
            className="btn primary w-100"
            disabled={!ready}
            onClick={handleOrder}
          >
            구매하기
          </button>
        </div>

        {isAlertOpen && (
          <AlertModal
            onClick={() => navigate('/checkout/cart')}
            className="top-[30%] left-1/2 transform -translate-x-1/2"
            message={<p>유의사항 안내 및 동의 후에 결제해주세요.</p>}
          />
        )}
      </div>
    </div>
  );
};

export default OrderPage;
