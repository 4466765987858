import { useRecoilValue } from 'recoil';
import { config } from '../config';
import { surveyState } from '../recoil/atoms';
import { useSetSurveyValue } from './useSetSurveyValue';
export const useSetMultipleChoiceValue = () => {
  const survey = useRecoilValue(surveyState);
  const setSurveyValue = useSetSurveyValue();

  const setMultipleChoiceValue = (questionId, option) => {
    switch (questionId) {
      case config.GENDER_IDX:
        setSurveyValue('r_gender', option.optionText);
        break;
      case config.SELF_HOUSE_LOAN_YN_IDX:
        console.log('SELF_HOUSE_LOAN_YN_IDX', option.orderIndex === 1, option);
        setSurveyValue('r_selfHouseLoanYN', option.orderIndex === 1);
        break;
      case config.SELF_HOUSE_LOAN_TYPE_IDX:
        console.log('SELF_HOUSE_LOAN_TYPE_IDX', option.optionText, option);
        setSurveyValue('r_selfHouseLoanType', option.optionText);
        break;
      case config.SELF_HOUSING_TYPE_IDX:
        console.log('SELF_HOUSING_TYPE_IDX', option.optionText, option);
        setSurveyValue('r_selfHousingType', option.optionText);
        break;
      case config.SPOUSE_HOUSE_LOAN_YN_IDX:
        console.log(
          'SPOUSE_HOUSE_LOAN_YN_IDX',
          option.orderIndex === 1,
          option
        );
        setSurveyValue('r_spouseHouseLoanYN', option.orderIndex === 1);
        break;
      case config.SPOUSE_HOUSE_LOAN_TYPE_IDX:
        console.log('SPOUSE_HOUSE_LOAN_TYPE_IDX', option.optionText, option);
        setSurveyValue('r_spouseHouseLoanType', option.optionText);
        break;
      case config.SPOUSE_HOUSING_TYPE_IDX:
        console.log('r_spouseHousingType', option.optionText, option);
        setSurveyValue('r_spouseHousingType', option.optionText);
        break;
      case config.APT_PURCHASE_TYPE_IDX:
        console.log('APT_PURCHASE_TYPE_IDX', option.optionText, option);
        setSurveyValue(
          'r_apartmenttPurchaseType',
          option.optionText === '전세' ? 'd' : 'o'
        );
        break;
    }
  };

  return setMultipleChoiceValue;
};
