import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LayerPopup from '../../component/LayerPopup';
import PrimaryButton from '../../component/button/PrimaryButton';
import LeftArrowIcon from '../../component/icon/LeftArrowIcon';
import RightArrowIcon from '../../component/icon/RightArrowIcon';
import { config } from '../../config';
import { useSetSurveyValue } from '../../hook/useSetSurveyValue';

const ORIGINAL_PRICE = 59000;
const CURRENT_PRICE = config.FINBERRY_INFO.price;
const SALE_AMOUNT = ORIGINAL_PRICE - CURRENT_PRICE;

const CartPage = () => {
  const navigate = useNavigate();
  const [policyList, setPolicyList] = useState(config.PURCHASE_POLICY);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [openPolicy, setOpenPolicy] = useState({ id: 0 });

  const setSurveyValue = useSetSurveyValue();

  const handleAllSelection = (e) => {
    setIsAllChecked(e.target.checked);
    setPolicyList((prev) =>
      prev.map((item) => ({ ...item, isChecked: e.target.checked }))
    );
  };

  const handleSelection = (id) => {
    setPolicyList((prev) =>
      prev.map((item) =>
        item.id === id ? { ...item, isChecked: !item.isChecked } : item
      )
    );
  };

  // 파일 읽기 및 모달 열기
  const openPolicyDetail = async (id) => {
    const policy = policyList.find((item) => item.id === id);
    try {
      const response = await fetch(policy.path);
      if (!response.ok) {
        throw new Error('Failed to fetch the text file.');
      }
      const text = await response.text();
      setOpenPolicy({ ...policy, text });
    } catch (error) {
      console.error('Error fetching the txt file:', error);
      setOpenPolicy({});
    }
  };

  const closePolicyDetail = () => {
    setOpenPolicy({ id: 0 });
  };

  const hasAgreedPayment = policyList
    .filter((policy) => policy.isRequired)
    .every((policy) => policy.isChecked);

  const handleSubmit = () => {
    setSurveyValue('r_hasAgreedPayment', hasAgreedPayment);

    setTimeout(() => {
      navigate('/checkout/order');
    }, 100);
  };

  useEffect(() => {
    if (policyList.every((item) => item.isChecked)) {
      setIsAllChecked(true);
    } else {
      setIsAllChecked(false);
    }
  }, [policyList]);

  return (
    <div
      className={`h-[100dvh] w-full max-w-md ${openPolicy?.id ? 'overflow-hidden' : 'overflow-auto'}`}
    >
      {!!openPolicy?.id && (
        <>
          <div
            onClick={closePolicyDetail}
            className="bg-[#484644] bg-opacity-20 absolute top-0 left-0 w-full h-full"
          />
          <LayerPopup
            close={closePolicyDetail}
            fileContent={openPolicy.text}
            title={openPolicy.title}
          />
        </>
      )}
      <div className="pb-8">
        <header className="relative h-[42px] border-b border-grayLight3 flex items-center justify-center">
          <button
            onClick={() => navigate(-1)}
            aria-label="뒤로 가기"
            className="absolute left-[13px]"
          >
            <LeftArrowIcon className="text-black" />
          </button>
          <h1 className="text-base text-center font-bold leading-[28px]">
            결제하기
          </h1>
        </header>
        <div>
          <section className="pt-6 mx-4 pb-4 border-b border-grayLight3">
            <h2 className="text-base font-bold leading-[140%] tracking-[-0.32px]">
              구매 상품
            </h2>
            <div className="flex mt-2">
              <img
                src="/favicon.png"
                alt="핀베리 로고"
                width={70}
                height={70}
              />
              <div className="ml-4">
                <p className="text-[15px] font-medium leading-[140%] tracking-[-0.3px]">
                  {config.FINBERRY_INFO.productName}
                </p>
                <p className="text-[15px] font-medium leading-[140%] tracking-[-0.3px]">
                  1개 / {ORIGINAL_PRICE.toLocaleString()}원
                </p>
              </div>
            </div>
          </section>
          <section className="p-4">
            <div className="flex justify-between">
              <p className="text-[15px] font-medium leading-[140%] tracking-[-0.3px]">
                상품 합계
              </p>
              <p className="text-[15px] font-bold leading-[140%] tracking-[-0.3px]">
                {ORIGINAL_PRICE.toLocaleString()}원
              </p>
            </div>
          </section>
          <div className="w-full h-2 bg-[#F8F8F8]" />
          <section className="mx-4 py-4 border-b border-grayLight3">
            <h2 className="text-base font-bold leading-[140%] tracking-[-0.32px]">
              할인 정보
            </h2>
            <div className="pt-2 flex justify-between">
              <p className="text-[15px] font-medium leading-[140%] tracking-[-0.3px]">
                오픈 할인
              </p>
              <p className="text-[15px] font-medium leading-[140%] tracking-[-0.3px]">
                -{SALE_AMOUNT.toLocaleString()}원
              </p>
            </div>
          </section>
          <div className="pt-2 px-4 pb-6 flex justify-between">
            <p className="text-[15px] font-medium leading-[140%] tracking-[-0.3px]">
              할인 금액
            </p>
            <p className="text-[15px] font-bold leading-[140%] tracking-[-0.3px]">
              -{SALE_AMOUNT.toLocaleString()}원
            </p>
          </div>
          <div className="w-full h-2 bg-[#F8F8F8]" />
          <section className="mx-4 py-4 border-b border-grayLight3">
            <h2 className="text-base font-bold leading-[140%] tracking-[-0.32px]">
              결제 정보
            </h2>
            <div className="pt-2 flex justify-between">
              <p className="text-[15px] font-medium leading-[140%] tracking-[-0.3px]">
                상품 합계
              </p>
              <p className="text-[15px] font-medium leading-[140%] tracking-[-0.3px]">
                {ORIGINAL_PRICE.toLocaleString()}원
              </p>
            </div>
            <div className="pt-2 flex justify-between">
              <p className="text-[15px] font-medium leading-[140%] tracking-[-0.3px]">
                할인 금액
              </p>
              <p className="text-[15px] font-medium leading-[140%] tracking-[-0.3px]">
                -{SALE_AMOUNT.toLocaleString()}원
              </p>
            </div>
          </section>
          <div className="pt-2 px-4 pb-6 flex justify-between">
            <p className="text-[15px] font-medium leading-[140%] tracking-[-0.3px]">
              결제금액
            </p>
            <p className="text-lg font-bold leading-[140%] tracking-[-0.36px] text-orange">
              {CURRENT_PRICE.toLocaleString()}원
            </p>
          </div>
          <div className="w-full h-2 bg-[#F8F8F8]" />
          <section className="p-4">
            <div className="px-4 py-2 w-full justify-start items-start flex rounded-lg bg-[#F8F8F8]">
              <label className="round-checkbox mb-0">
                <input
                  type="checkbox"
                  checked={isAllChecked}
                  onChange={(e) => handleAllSelection(e)}
                  className="round-checkbox-input"
                />
                <span className="text-[15px] font-semibold leading-[24px]">
                  아래 내용 확인 및 전체 동의
                </span>
              </label>
            </div>
            <div className="px-4 flex flex-col gap-3 py-3">
              {policyList.map((item, idx) => (
                <div key={item.id} className="round-checkbox justify-between">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      id={`checkbox-${item.id}`}
                      checked={item.isChecked}
                      onChange={() => handleSelection(item.id)}
                      className="round-checkbox-input"
                    />
                    <label htmlFor={`checkbox-${item.id}`}>
                      <span className="text-grayDark3 text-[15px] font-normal leading-[24px]">{`${item.isRequired ? '(필수)' : '(선택)'} ${item.label}`}</span>
                    </label>
                  </div>
                  {item.path && (
                    <button
                      onClick={() => {
                        openPolicyDetail(item.id);
                      }}
                    >
                      <RightArrowIcon className="cursor-pointer text-[#C6C3C2]" />
                    </button>
                  )}
                </div>
              ))}
            </div>
          </section>

          <div className="sticky w-full px-4 py-2">
            <PrimaryButton
              onClick={handleSubmit}
              disabled={!hasAgreedPayment}
              className="outline-none rounded-lg py-[16px] text-[15px] font-semibold"
            >
              <span className="text-[16px]">
                {config.FINBERRY_INFO.price.toLocaleString()}원 결제하기
              </span>
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartPage;
