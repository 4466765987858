import React from 'react';
import FocusLock from 'react-focus-lock';
import PrimaryButton from './button/PrimaryButton';
import SecondaryButton from './button/SecondaryButton';

const AlertModal = ({
  onClick,
  onClose,
  message = '',
  buttonText = '확인',
  subButtonText = '',
  imgUrl = '',
  flexButton = false,
  className = 'top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
}) => {
  return (
    <FocusLock>
      <div
        style={{ pointerEvents: 'auto' }}
        className={`fixed z-50 w-[316px] gap-[-24px] shadow-[0px_20px_25px_0px_rgba(28,28,34,0.15)] bg-white rounded-[16px] ${className}`}
      >
        <div
          className={`w-full pt-6 ${imgUrl ? 'pb-4' : ''} bg-white justify-center items-center inline-flex rounded-t-[16px]`}
        >
          {imgUrl && (
            <div className="w-[156px] h-auto relative justify-center items-center flex">
              <img src={imgUrl} alt="" />
            </div>
          )}
        </div>
        <div className="rounded-[16px] rounded-t-[16px] bg-white">
          <div
            className={`flex flex-col justify-start items-start px-6 rounded-t-[16px] ${imgUrl ? 'pt-6' : ''}`}
          >
            <div className="w-full text-black text-[15px] leading-5 tracking-wide whitespace-pre-line">
              {message}
            </div>
          </div>
          {flexButton ? (
            <div className="w-full flex p-6 gap-2">
              <div className="w-full rounded-[12px]">
                <SecondaryButton
                  onClick={onClose}
                  className="rounded-[inherit] border-none text-[#82817F] bg-[#EBEBEB] pt-[8px] pb-[8px]"
                >
                  {subButtonText}
                </SecondaryButton>
              </div>
              <div className="w-full rounded-[12px]">
                <PrimaryButton
                  onClick={onClick}
                  className="rounded-[inherit] pt-[8px] pb-[8px] outline-none"
                >
                  {buttonText}
                </PrimaryButton>
              </div>
            </div>
          ) : (
            <div className="w-full p-6">
              <div className="w-full rounded-[12px]">
                <PrimaryButton
                  onClick={onClick}
                  className="rounded-[inherit] pt-[8px] pb-[8px] outline-none"
                >
                  {buttonText}
                </PrimaryButton>
              </div>
              {subButtonText && (
                <div className="w-full rounded-full mt-2">
                  <SecondaryButton
                    onClick={onClose}
                    className="rounded-[inherit] text-orange border-orange pt-[7px] pb-[7px]"
                  >
                    {subButtonText}
                  </SecondaryButton>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </FocusLock>
  );
};

export default AlertModal;
