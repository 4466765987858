import React, { useState } from 'react';
import BackHeader from '../../component/header/BackHeader';
import { useRecoilValue } from 'recoil';
import { surveyState } from '../../recoil/atoms';
import ProductBox from '../../component/ProductBox';
import { useNavigate } from 'react-router-dom';
import { useReportPopup } from '../../hook/useReportPopup';
import ReportContainer from '../../component/report/ReportContainer';
import ReviewPopup from '../../component/report/ReviewPopup';
import dayjs from 'dayjs';

const MyContentsPage = () => {
  const survey = useRecoilValue(surveyState);
  const userProducts = survey.r_paymentHistories;
  const sortedProducts = [...userProducts]
    .filter((item) => item?.paymentHistory.isRefunded === false)
    .sort((a, b) =>
      dayjs(b.paymentHistory.createdAt).diff(dayjs(a.paymentHistory.createdAt))
    ); // 최신순 정렬
  const hasReviewed = survey.r_reviewed;
  const { isOpen, openPopup, closePopup } = useReportPopup();
  const navigate = useNavigate();
  const [isReviewPopupOpen, setIsReviewPopupOpen] = useState(false);

  const openReport = (orderId) => openPopup(orderId);

  const goToChat = () => navigate('/chat');

  const openReviewPopup = (e) => {
    e.preventDefault();
    setIsReviewPopupOpen(true);
  };

  const closeReviewPopup = (e) => {
    e.preventDefault();
    setIsReviewPopupOpen(false);
  };

  const goToCart = () => navigate('/checkout/cart');

  if (isOpen) {
    return <ReportContainer onClose={closePopup} />;
  }

  if (isReviewPopupOpen) {
    return <ReviewPopup closeReviewPopup={closeReviewPopup} />;
  }

  return (
    <div className="relative max-w-md w-full h-[100dvh] overflow-hidden pb-12">
      <header className="sticky z-50 border-b border-[#ECECEC] text-black flex justify-center items-center">
        <div className="absolute left-0">
          <BackHeader className="flex items-center px-4" />
        </div>
        <h1 className="header-title font-bold leading-[28px] py-[10px]">
          MY 콘텐츠
        </h1>
      </header>

      <div className="p-4 pt-6 flex flex-col gap-4 whitespace-pre-line text-black text-xs font-normal leading-[24px] overflow-y-auto h-full">
        {sortedProducts.map((product, index) => (
          <ProductBox
            key={`${product.id}_${index}`}
            product={product}
            openReport={openReport}
            goToChat={goToChat}
            openReviewPopup={openReviewPopup}
            goToCart={goToCart}
            hasReviewed={hasReviewed}
          />
        ))}
      </div>
    </div>
  );
};

export default MyContentsPage;
