import { useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { surveyState } from '../../recoil/atoms';
import api from '../../api/api';
import { config } from '../../config';
import { useFetchUser } from '../../hook/useFetchUser';

const SuccessPage = () => {
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [searchParams] = useSearchParams();
  const paymentKey = searchParams.get('paymentKey');
  const orderId = searchParams.get('orderId');
  const amount = searchParams.get('amount');
  const { initializeUser } = useFetchUser();
  const survey = useRecoilValue(surveyState);
  const navigate = useNavigate();

  async function confirmPayment() {
    // 서버에서 해당 데이터를 가지고 승인 API를 호출하면 결제가 완료됩니다.
    // https://docs.tosspayments.com/reference#%EA%B2%B0%EC%A0%9C-%EC%8A%B9%EC%9D%B8
    try {
      const response = await api.post('/payment', {
        paymentKey,
        orderId,
        amount,
        productName: config.FINBERRY_INFO.productName,
        surveyId: survey.r_surveyId
      });
      const { status, data } = response;
      if (status === 200 || status === 201) {
        if (!data || data === 'OK') {
          setIsConfirmed(true); // 결제 성공
          initializeUser();
          return;
        }
        const message =
          data === 'UNKNOWN'
            ? '결제 실패하였습니다. 잠시 후에 다시 시도해주세요.'
            : data;
        console.error('결제 실패:', data);
        window.alert(message);
        navigate('/checkout/cart', { replace: true });
      }
    } catch (error) {
      console.error('결제 중 오류 발생:', error);
      window.alert(
        error.response?.data ||
          '결제 실패하였습니다. 잠시 후에 다시 시도해주세요.'
      );
      navigate('/checkout/cart', { replace: true });
    }
  }

  return (
    <div className="wrapper w-100">
      {isConfirmed ? (
        <div
          className="flex-column align-center h-[100dvh] confirm-success w-100 max-w-540"
          style={{
            display: 'flex'
          }}
        >
          <div className="flex flex-col align-center w-100">
            <img
              src="/picture/icons/star-cloud.png"
              alt=""
              width="100"
              height="100"
            />
            <h2 className="payment-title">결제를 완료했어요</h2>
            <div className="response-section w-100">
              <div className="flex justify-between">
                <span className="response-label">결제 금액</span>
                <span id="amount" className="response-text">
                  {Number(amount).toLocaleString()}원
                </span>
              </div>
              <div className="flex justify-between">
                <span className="response-label">주문번호</span>
                <span id="orderId" className="response-text">
                  {orderId}
                </span>
              </div>
              <div className="flex justify-between">
                <span className="response-label">paymentKey</span>
                <span id="paymentKey" className="response-text">
                  {paymentKey}
                </span>
              </div>
            </div>
          </div>
          <div className="w-100 button-group">
            <div className="flex flex-col" style={{ gap: '16px' }}>
              <Link to="/chat" replace className="btn finberry w-100">
                지금 바로 시작하기
              </Link>
              <Link to="/" replace className="btn w-100">
                홈으로 돌아가기
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex-column align-center confirm-loading w-100 max-w-540">
          <div className="flex-column align-center">
            <img
              src="https://static.toss.im/lotties/loading-spot-apng.png"
              alt=""
              width="100"
              height="100"
            />
            <h2 className="title text-center">결제 요청까지 성공했어요.</h2>
            <h4 className="text-center description">
              결제 승인하고 완료해보세요.
            </h4>
          </div>
          <div className="w-100">
            <button className="btn primary w-100" onClick={confirmPayment}>
              결제 승인하기
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SuccessPage;
