import { useRecoilState } from 'recoil';
import { surveyState } from '../recoil/atoms';

export const useAddUserMessage = () => {
  const [survey, setSurvey] = useRecoilState(surveyState);
  const addUserMessage = (newMessage) => {
    setSurvey((prevSurvey) => ({
      ...prevSurvey,
      r_messages: [
        ...prevSurvey.r_messages,
        {
          role: 'user',
          content: { ...newMessage, questionId: survey.r_idx + 1 }
        }
      ]
    }));
  };
  return addUserMessage;
};
