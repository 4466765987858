import React from 'react';
import ReactDOM from 'react-dom';
import usePreventSwipe from '../../../hook/usePreventSwipe';
import BackHeader from '../../header/BackHeader';

const InflationPopup = ({ close }) => {
  const rootElement = document.getElementById('root'); // <div id="root"> 안에 렌더링
  usePreventSwipe('.popup-report-container'); // 터치 이동 방지 훅 호출

  return ReactDOM.createPortal(
    <div className="popup-report-container">
      <div className="px-4">
        <BackHeader goToPrev={close} className="flex items-center" />
      </div>
      <section className="px-6 pt-5 flex flex-col gap-6">
        <div>
          <h1 className="text-[18px] font-bold leading-[100%] mb-3">
            <span className="mr-[6px]">📈</span>
            물가상승률과 현금 가치의 변화
          </h1>
          <p className="text-sm leading-[24px]">
            "물가상승률"이란 물건과 서비스의 가격이 전반적으로 올라가는 속도를
            뜻해요. 쉽게 말해, 같은 돈으로 살 수 있는 물건이 줄어드는 현상이죠.
            🤔
            <br />
            예를 들어, 30년 전 1995년에 라면은 300원이었지만, 지금은 그 돈으로
            라면을 사기 어려운 상황, 바로 물가상승 때문이에요.
            <br />
            <br />
            그렇다면 “현금 가치”는 어떻게 변할까요? 시간이 지나면서 물가가
            오르면, 같은 돈의 실제 가치는 줄어들게 됩니다. 오늘 10,000원으로 살
            수 있는 것을 10년 후에는 더 많은 돈을 내야 살 수 있게 되는 거예요.
          </p>
        </div>
        <div className="flex flex-col gap-4 w-full">
          <h2 className="text-sm font-bold leading-[100%] text-orange">
            왜 중요한 내용일까요?
          </h2>
          <div className="flex gap-3">
            <div className="w-5 px-[6.5px] flex justify-center items-center rounded bg-orange text-white text-sm font-semibold leading-[100%]">
              1
            </div>
            <p className="text-sm leading-[24px]">
              물가상승률을 이해하면, 돈의 가치 하락을 막기 위해 저축과 투자
              계획을 세울 수 있어요.
            </p>
          </div>
          <div className="flex gap-3">
            <div className="w-5 px-[6.5px] flex justify-center items-center rounded bg-orange text-white text-sm font-semibold leading-[100%]">
              2
            </div>
            <p className="text-sm leading-[24px]">
              단순히 "돈을 모으는 것"이 아니라 돈의 가치를 지키는 방법을
              고민해야 해요!
            </p>
          </div>
          <div>
            <p className="text-sm leading-[24px]">
              돈이 잠자는 동안 가치를 잃지 않도록 적절히 투자하거나,
              물가상승률보다 높은 수익률을 목표로 하는 금융 상품을 활용해보세요!
            </p>
          </div>
        </div>
      </section>
    </div>,
    rootElement
  );
};

export default InflationPopup;
