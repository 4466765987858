import React from 'react';
import ReactMarkdown from 'react-markdown';
import DonutChart from '../../chart/DobutChart';
import { formatKoreanCurrency } from '../../../util/formatKoreanCurrency';

const ApartmentReport = ({ data = {} }) => {
  const { plot = [], text = '' } = data;

  const chartPlot = (value) => {
    return [value, 100 - value];
  };

  return (
    <div className="report-container">
      <section className="w-full p-6 pb-2">
        <div className="flex justify-start items-start gap-6">
          <div>
            <p className="text-lg font-bold leading-[100%]">🏠 아파트</p>
          </div>
          <div>
            <p className="flex gap-3">
              <span className="text-sm leading-[140%] text-[#959493]">
                종류
              </span>
              <span className="text-sm font-medium leading-[140%]">
                {plot[0]}
              </span>
            </p>
            <p className="flex gap-3">
              <span className="text-sm leading-[140%] text-[#959493]">
                유형
              </span>
              <span className="text-sm font-medium leading-[140%]">
                {plot[1]}
              </span>
            </p>
            <p className="flex gap-3">
              <span className="text-sm leading-[140%] text-[#959493]">
                금액
              </span>
              <span className="text-sm font-medium leading-[140%]">
                {formatKoreanCurrency(plot[2] * 10000)}원 (예상)
              </span>
            </p>
            <p className="flex gap-3">
              <span className="text-sm leading-[140%] text-[#959493]">
                시기
              </span>
              <span className="text-sm font-medium leading-[140%]">
                결혼 {plot[3]}년 후 ({plot[4]}세)
              </span>
            </p>
          </div>
        </div>
      </section>
      <section className="px-6 pt-4 pb-[10px]">
        <div className="p-6 report-shadow rounded-[24px]">
          <p className="text-base font-bold leading-[150%]">
            대출을 포함하여 목표를 달성할 가능성은
            <br />
            <span className="text-orange">{plot[5]}%</span>입니다
          </p>
          <div className="flex justify-center">
            <DonutChart plot={chartPlot(plot[5])} color="orange" />
          </div>
        </div>
      </section>
      <section className="w-full pt-4 px-[30px] pb-6">
        <ReactMarkdown className="text-[14px] font-normal leading-[170%]">
          {text}
        </ReactMarkdown>
      </section>
    </div>
  );
};

export default ApartmentReport;
