import React from 'react';
import ReactMarkdown from 'react-markdown';

function calculatePercentage(value1, value2) {
  const percentage = Math.min((value1 / value2) * 100, 100);
  return `${percentage}%`;
}

const RetirementMonthlyPaymentReport = ({ data = {} }) => {
  const { plot = [], text = '' } = data;
  const percentage = calculatePercentage(plot[1], plot[2]);

  return (
    <div className="report-container">
      <section className="p-6 pb-4">
        <div className="px-6 py-5 report-shadow rounded-2xl">
          <p className="mb-[6px] text-sm text-[#959493] font-semibold leading-[100%]">
            은퇴 이후 예상 월 지출액
          </p>
          <span className="text-lg font-bold leading-[100%] text-orange">
            월 {plot[0]}만원
          </span>
          <div className="mt-[18px]">
            <div className="w-full h-4 rounded-sm relative flex bg-[#FFE6B8]">
              <div
                className="h-full rounded-s-sm relative bg-progress-gradient max-w-full"
                style={{ width: `${percentage}` }}
              />
            </div>

            <div className="flex justify-between mt-[6px] ">
              <span className="w-9 text-[10px] leading-[140%] text-[#959493]">
                0
              </span>
              <span className="w-9 text-[10px] leading-[140%] text-[#959493] text-end">
                500만원
                <br />
                이상
              </span>
            </div>
            <div className="mt-[-26px] flex flex-col items-center relative">
              <span
                className={` mb-[2px] ${percentage ? 'transform translate-x-[-50%]' : ''}`}
                style={{
                  left: percentage
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11"
                  height="9"
                  viewBox="0 0 11 9"
                  fill="none"
                >
                  <path
                    d="M5.49986 0L10.696 9H0.303711L5.49986 0Z"
                    fill="#FF6319"
                  />
                </svg>
              </span>
              <span className="text-xs leading-[140%]">
                부부 합산 최소 생활비
              </span>
              <span className="text-xs font-semibold leading-[140%]">
                월 평균 240만 원
              </span>
            </div>
          </div>
        </div>
      </section>
      <section className="px-6 py-4">
        <div className="flex flex-col gap-2">
          <p className="text-xs font-bold leading-[100%] text-orange">
            예상 지출액
          </p>
          <p className="text-[16px] font-bold leading-[100%]">
            <span className="mr-[6px]">🛒</span>
            은퇴 후 부부 합산 월 평균 생활비
          </p>
          <ReactMarkdown className="text-[14px] font-normal leading-[170%]">
            {text}
          </ReactMarkdown>
        </div>
      </section>
    </div>
  );
};

export default RetirementMonthlyPaymentReport;
