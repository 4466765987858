import React, { useState } from 'react';
import UserLayout from './UserLayout';

const MAX_LENGTH = 20;
const MIN_LENGTH = 2;

const NicknameForm = ({ nextStep, prevStep, setUserData }) => {
  const [inputValue, setInputValue] = useState('');

  const isValidLength = inputValue.length >= MIN_LENGTH;

  const handleChange = (e) => {
    const value = e.target.value;

    // 한글, 영문, 아포스트로피, 공백만 허용
    const regex = /^[ㄱ-ㅎ가-힣a-zA-Z'\s]*$/;

    if (regex.test(value) && value.length <= MAX_LENGTH) {
      setInputValue(value); // 입력 중에는 양끝 공백 허용
    }
  };

  const handleBlur = () => {
    // 입력 완료 후 (포커스 아웃 시) 양끝 공백 제거
    setInputValue((prev) => prev.trim());
  };

  const onClickConfirm = () => {
    setUserData((prev) => ({ ...prev, fullName: inputValue }));
    setTimeout(() => {
      nextStep();
    }, 100);
  };

  return (
    <UserLayout
      title="이름을 입력해 주세요!"
      description={`채팅과 보고서에 쓰이는 이름이에요.\n프로필 페이지에서 언제든 변경 가능해요.`}
      goToPrev={prevStep}
      onClick={onClickConfirm}
      disabled={!isValidLength}
    >
      <div className="flex flex-col py-4 w-full">
        <input
          type="text"
          inputMode="text"
          value={inputValue}
          onChange={handleChange}
          onBlur={handleBlur}
          maxLength={MAX_LENGTH}
          placeholder="2글자 이상 한글, 영문, 아포스트로피(') 입력 가능"
          className={`outline-none p-3 text-[15px] font-semibold leading-[150%] text-[#82817F] placeholder-grayMid w-full rounded-lg border justify-start items-center gap-3 inline-flex ${
            inputValue ? 'border-orange' : 'border-grayMid2'
          }`}
        />
        <span className="text-sm text-grayDark4 mt-1 ml-3">{`${inputValue.length}/${MAX_LENGTH}`}</span>
      </div>
    </UserLayout>
  );
};

export default NicknameForm;
