import { useRecoilValue } from 'recoil';
import { config } from '../config';
import { useSetSurveyValue } from '../hook/useSetSurveyValue';
import { surveyState } from '../recoil/atoms';
import { useDeleteSelectedOptions } from './useDeleteSelectedOptions';
import { useMoveToNextQuestion } from './useMoveToNextQuestion';
import { useMoveToTheQuestion } from './useMoveToTheQuestion';
import { useSelectedOptionSave } from './useSelectedOptionSave';

export const useHandleMultipleAnswerSave = () => {
  const survey = useRecoilValue(surveyState);
  const { r_idx, r_allQuestions, r_selectedPurchase } = survey;
  const currentQuestion =
    r_allQuestions && r_allQuestions.length > 0 && r_allQuestions[r_idx];
  const deleteSelectedOptions = useDeleteSelectedOptions();
  const selectedOptionSave = useSelectedOptionSave();
  const moveToTheQuestion = useMoveToTheQuestion();
  const moveToNextQuestion = useMoveToNextQuestion();
  const setSurveyValue = useSetSurveyValue();

  const handleMultipleAnswerSave = async () => {
    // console.log('HHH!!!!:', survey.r_selectedPurchase);
    console.log('idx: ', r_idx);
    deleteSelectedOptions();
    selectedOptionSave();
    if (currentQuestion.questionId === config.PURCHASE_IDX) {
      console.log('r_selectedPurchase: ', r_selectedPurchase);
      switch (r_selectedPurchase[0].orderIndex) {
        case 1:
          moveToTheQuestion(config.CAR_PURCHASE_YEAR_COMMENT_IDX);
          break;
        case 2:
          moveToTheQuestion(config.APT_PURCHASE_TYPE_IDX);
          break;
        case 3:
          moveToTheQuestion(config.CHILDREN_AGE_IDX);
          break;
        case 4:
          console.log('HHHH');
          moveToTheQuestion(config.EXTRA_GOAL_COMMENT_IDX);
          break;
      }
    }
    return;
  };
  return handleMultipleAnswerSave;
};
