import { useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import '../style/ImageCarousel.css';

const ImageCarousel = ({ carouselImages = [] }) => {
  const swiperRef = useRef(null); // Swiper 인스턴스를 참조하기 위한 ref

  useEffect(() => {
    const updateScale = () => {
      const slides = document.querySelectorAll('.swiper-slide-custom');
      slides.forEach((slide) => {
        if (slide.classList.contains('swiper-slide-active')) {
          slide.style.transform = 'scale(1.25)';
          slide.style.opacity = '1';
        } else {
          slide.style.transform = 'scale(1)';
          slide.style.opacity = '0.5';
        }
      });
    };

    // Swiper가 초기화된 후와 슬라이드가 변경될 때 이벤트를 연결
    if (swiperRef.current) {
      swiperRef.current.on('slideChangeTransitionStart', updateScale);
      updateScale(); // 초기 상태 업데이트
    }

    return () => {
      // 컴포넌트 언마운트 시 이벤트 정리
      if (swiperRef.current) {
        swiperRef.current.off('slideChangeTransitionStart', updateScale);
      }
    };
  }, []);

  return (
    <div className="relative w-full h-full">
      <Swiper
        modules={[Navigation, Pagination]}
        navigation={{
          nextEl: '.custom-button-next',
          prevEl: '.custom-button-prev'
        }}
        pagination={{ clickable: true }}
        loop={true} // 순환 슬라이드 활성화
        centeredSlides={true}
        slidesPerView="auto"
        spaceBetween={52}
        className="image-swiper-container"
        onSwiper={(swiper) => (swiperRef.current = swiper)} // Swiper 인스턴스 저장
      >
        {carouselImages.map((img, index) => (
          <SwiperSlide key={index} className="swiper-slide-custom">
            <img
              src={`/picture/main/${img}.png`}
              alt={`carousel-img-${img}`}
              className="w-full h-auto"
            />
          </SwiperSlide>
        ))}
      </Swiper>
      {/* 화살표 버튼 - SVG 사용 */}
      <button className="custom-button-prev custom-arrow">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24" /* 적절한 뷰 박스 설정 */
          fill="none"
        >
          <path
            d="M15 18L9 12L15 6" /* 보라색 화살표 모양 경로 */
            stroke="#502660"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      <button className="custom-button-next custom-arrow">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
          <path
            d="M9 18L15 12L9 6"
            stroke="#502660"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </div>
  );
};

export default ImageCarousel;
