import React from 'react';

const RetirementPensionEstimateReport = ({ data = {} }) => {
  const { plot = [] } = data;
  return (
    <div className="report-container">
      <section className="p-6">
        <div className="flex flex-col gap-2">
          <p className="text-xs text-orange font-bold leading-[100%]">
            예상 소득액 1.
          </p>
          <p className="text-[16px] font-bold leading-[100%]">
            <span className="mr-[6px]">👛</span>
            국민 연금 예상 수령액
          </p>
          <p className="text-[14px] font-normal leading-[170%]">
            <strong>
              우리 부부의 국민 연금 예상 수령액은 부부 합산 월 {plot[0]}
              만원입니다.
            </strong>
            해당 금액은 국민연금공단에서 제공하는 국민연금수령표를 기준으로
            추정한 금액입니다. 최초 가입 나이의 경우 여자는 27세, 남자 29세를
            기준으로 하였습니다.
            <br />
            국민연금 수령 나이는 65세를 기준으로 하였으며, 은퇴 나이가 65세가
            넘을 경우 70세부터 수령하는 것으로 하였습니다.
            <br />
            <span className="font-bold text-orange">
              실제 가입 이력을 확인할 수 없기 때문에 정확한 예상 연금 수령액은
              국민연금 노후 서비스에서 확인해보시는 것을 추천해드립니다.
            </span>
          </p>
        </div>
      </section>
    </div>
  );
};

export default RetirementPensionEstimateReport;
