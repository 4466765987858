import React from 'react';
import BackHeader from '../header/BackHeader';
import PrimaryButton from '../button/PrimaryButton';

const UserLayout = ({
  title,
  description,
  goToPrev,
  onClick,
  disabled,
  children
}) => {
  return (
    <div className="flex flex-col h-[100dvh] px-4 py-8">
      <BackHeader goToPrev={goToPrev} />
      <div className="h-[360px]">
        <div className="pt-6">
          <h1 className="text-2xl font-bold leading-normal tracking-[-0.48px]">
            {title}
          </h1>
          <p className="mt-2 text-sm font-normal leading-[normal] text-grayDark1 whitespace-pre-line">
            {description}
          </p>
        </div>
        {children}
      </div>

      <div className="sticky w-full py-2">
        <PrimaryButton
          onClick={onClick}
          disabled={disabled}
          className="outline-none rounded-[8px] text-[15px] font-medium"
        >
          확인
        </PrimaryButton>
      </div>
    </div>
  );
};

export default UserLayout;
