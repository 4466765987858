import { useRecoilState } from 'recoil';
import ktorApi from '../api/ktorApi';
import { surveyState } from '../recoil/atoms';
import { useMoveToTheQuestion } from './useMoveToTheQuestion';
import { useSetSurveyValue } from './useSetSurveyValue';

export const useFetchCarList = () => {
  const [survey, setSurvey] = useRecoilState(surveyState);
  const setSurveyValue = useSetSurveyValue();
  const moveToTheQuestion = useMoveToTheQuestion();

  const fetchCarList = async (price) => {
    try {
      const response = await ktorApi.post('/car', { price });
      setSurveyValue('r_carList', response.data);
      if (response.data.length === 0) {
        return -1;
      }
      return 0; // Success
    } catch (error) {
      console.error('Error fetching car list:', error);
      // Optionally move to a specific question if needed
      // moveToTheQuestion(config.CAR_PRICE_IDX);
      return -1; // Failure
    }
  };

  return fetchCarList;
};
