import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from '../page/user/LoginPage';
import MobileLogin from '../page/user/MobileLoginPage';
import ResetPassword from '../page/user/ResetPasswordPage';
import Signup from '../page/user/SignupPage';
import NotFoundPage from '../page/NotFoundPage';

const UserRoute = () => {
  return (
    <Routes>
      <Route path="/signup" element={<Signup />} />
      <Route path="/login" element={<Login />} />
      <Route path="/login/mobile" element={<MobileLogin />} />
      <Route path="/resetpassword" element={<ResetPassword />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default UserRoute;
