export function calculatePercentageOfTotal(values) {
  if (values.length !== 2) {
    throw new Error('Input array must contain exactly two numbers.');
  }

  const [value1, value2] = values;

  if (value2 === 0) {
    throw new Error('Division by zero is not allowed.');
  }

  const percentage = (value1 / value2) * 100;
  return `${percentage.toFixed(2)}%`;
}

export function calculateProportionalPercentage(values) {
  if (values.length !== 2) {
    throw new Error('Input array must contain exactly two numbers.');
  }

  const [value1, value2] = values;

  if (value2 === 0) {
    throw new Error('Division by zero is not allowed.');
  }

  const percentage = (value1 / (value1 + value2)) * 100;
  return `${percentage.toFixed(2)}%`;
}
