import { Chart, registerables } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { Line } from 'react-chartjs-2';
import { formatChartLabel } from '../../util/formatChartLabel';

Chart.register(...registerables, zoomPlugin);

const labelMaxYValuePlugin = {
  id: 'labelMaxYValue',
  afterDatasetsDraw(chart) {
    const {
      ctx,
      scales: { x, y }
    } = chart;

    let maxY = -Infinity;
    let maxX = null;
    let datasetIndex = null;

    chart.data.datasets.forEach((dataset, dIndex) => {
      dataset.data.forEach((value, index) => {
        if (value > maxY) {
          maxY = value;
          maxX = index;
          datasetIndex = dIndex;
        }
      });
    });

    if (maxX !== null && datasetIndex !== null) {
      ctx.save();
      ctx.font = 'bold 11px Pretendard';
      ctx.fillStyle = '#2b160c';
      const xPos = x?.getPixelForValue(maxX + 1);
      const yPos = y?.getPixelForValue(maxY);
      // ctx.fillText(
      //   `최고점: ${formatChartLabel(maxY.toFixed(2) * 1000)}`,
      //   xPos,
      //   yPos - 10
      // );
      ctx.restore();
    }
  }
};

// Function to draw a rounded rectangle (used for message bubbles)
const drawRoundedRect = (ctx, x, y, width, height, radius) => {
  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  ctx.lineTo(x + width - radius, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
  ctx.lineTo(x + width, y + height - radius);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
  ctx.lineTo(x + radius, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
  ctx.lineTo(x, y + radius);
  ctx.quadraticCurveTo(x, y, x + radius, y);
  ctx.closePath();
  ctx.fill();
};

// Custom plugin to add multiple labels with vertical lines and message bubbles
const customLabelPlugin = {
  id: 'customLabelPlugin',
  afterDatasetsDraw(chart, args, options) {
    const { ctx, data, scales } = chart;
    const customLabels = options.customLabels || [];

    customLabels.forEach(
      ({ datasetIndex, dataIndex, label, color }, labelIndex) => {
        const x = scales.x.getPixelForValue(data.labels[dataIndex]);
        const y = scales.y.getPixelForValue(
          data.datasets[datasetIndex].data[dataIndex]
        );

        // Calculate dynamic offset to avoid overlap
        const baseOffsetY = -30; // Base offset
        const offsetY =
          baseOffsetY -
          (labelIndex % 2 === 0 ? labelIndex * 10 : -labelIndex * 10);

        const labelX = x;
        const labelY = y + offsetY;

        // Draw vertical line from point to label
        ctx.save();
        ctx.strokeStyle = color;
        ctx.lineWidth = 1;
        ctx.beginPath();
        ctx.moveTo(x, y);
        ctx.lineTo(labelX, labelY - 5); // Adjust to end line near the bubble
        ctx.stroke();
        ctx.restore();

        // Draw message bubble for label
        ctx.save();
        ctx.fillStyle = color;
        const textWidth = ctx.measureText(label).width;
        const bubbleWidth = textWidth + 20; // Add padding
        const bubbleHeight = 20;
        drawRoundedRect(
          ctx,
          labelX - bubbleWidth / 2,
          labelY - bubbleHeight,
          bubbleWidth,
          bubbleHeight,
          10
        );
        ctx.fillStyle = 'white';
        ctx.font = 'bold 11px Pretendard';
        ctx.textAlign = 'center';
        ctx.fillText(label, labelX, labelY - 5); // Center text in bubble
        ctx.restore();
      }
    );
  }
};

Chart.register(labelMaxYValuePlugin, customLabelPlugin);

const DataChart = ({
  data1,
  label1,
  data2,
  label2,
  isZoomAvailable = true
}) => {
  const maxIndex1 = data1.assets?.findIndex(
    (value) => value === Math.max(...data1.assets)
  );
  const maxIndex2 = data2?.assets?.findIndex(
    (value) => value === Math.max(...data2?.assets)
  );

  const data = {
    labels: data1.ages,
    datasets: [
      {
        data: data1.assets,
        borderColor: 'orange',
        borderWidth: 1,
        type: 'line',
        fill: true,
        backgroundColor: 'rgba(255, 165, 0, 0.2)',
        pointRadius: (context) =>
          context.dataIndex === maxIndex1 ||
          label1?.some((item) => item.index === context.dataIndex)
            ? 3
            : 0
      },
      data2 && {
        data: data2.assets,
        borderColor: 'blue',
        borderWidth: 1,
        type: 'line',
        fill: true,
        backgroundColor: 'rgba(0, 0, 255, 0.2)',
        pointRadius: (context) =>
          context.dataIndex === maxIndex2 ||
          label2?.some((item) => item.index === context.dataIndex)
            ? 3
            : 0
      }
    ].filter(Boolean) // Filter out `null` if `data2` is not provided
  };

  const options = {
    responsive: true,
    plugins: {
      legend: { display: false },
      zoom: {
        pan: {
          enabled: isZoomAvailable,
          mode: 'x' // Enable horizontal panning only
        },
        zoom: {
          wheel: {
            enabled: isZoomAvailable,
            mode: 'x' // Enable horizontal zooming only
          },
          pinch: {
            enabled: isZoomAvailable,
            mode: 'x' // Enable pinch-to-zoom horizontally
          }
        }
      },
      customLabelPlugin: {
        customLabels: [
          ...(label1 || []).map((item) => ({
            datasetIndex: 0,
            dataIndex: item.index,
            label: item.label,
            color: 'orange'
          })),
          ...(label2 || []).map((item) => ({
            datasetIndex: 1,
            dataIndex: item.index,
            label: item.label,
            color: 'blue'
          }))
        ]
      }
    },
    scales: {
      x: {
        type: 'linear',
        title: { display: true },
        grid: { display: false },
        min: data1.ages[0],
        max: data1.ages[data1.ages.length - 1],
        offset: false
      },
      y: {
        title: { display: true },
        grid: {
          display: true,
          borderDash: [5, 5],
          borderDashOffset: 0
        },
        ticks: {
          callback: (value) => formatChartLabel(value * 1000)
        }
      }
    }
  };

  return <Line data={data} options={options} />;
};

export default DataChart;
