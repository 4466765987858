import ktorApi from '../api/ktorApi';

// 실패 횟수를 저장하는 클로저 함수
const createLoginFailureCounter = (setAlertModalMessage) => {
  // 세션 스토리지에서 초기값 가져오기 (없으면 0)
  let failureCount = Number(sessionStorage.getItem('loginFailureCount')) || 0;

  return () => {
    failureCount += 1; // 실패 횟수 증가
    sessionStorage.setItem('loginFailureCount', failureCount);
    console.warn(`로그인 실패 횟수: ${failureCount}`);

    // 5회 실패 시 알림 메시지 변경
    if (failureCount >= 5) {
      setAlertModalMessage(
        '로그인 오류 횟수가 5회를 초과했습니다. 계속하려면 비밀번호를 재설정해주세요.'
      );
    }
    return failureCount;
  };
};

// 로그인 실패 횟수 카운터를 글로벌로 생성(해야 0으로 초기화 되지 않음)
let incrementLoginFailure;

export const login = async (userData, initializeUser, setAlertModalMessage) => {
  // 실패 카운터 초기화 (최초 1회만)
  if (!incrementLoginFailure) {
    incrementLoginFailure = createLoginFailureCounter(setAlertModalMessage);
  }

  try {
    console.log('log in attempt');
    const response = await ktorApi.post('/login', userData);
    console.log('response:', response.status);
    if (response.status === 200 || response.status === 201) {
      // 로그인 성공 후 유저 정보 초기화
      console.log('initializeUser');
      await initializeUser();
      sessionStorage.removeItem('loginFailureCount'); // 성공 시 실패 카운트 초기화
      return {
        success: true,
        data: response.data
      };
    } else {
      console.error('로그인 실패:', response.status);
      incrementLoginFailure(); // 실패 카운트 증가
      return {
        success: false,
        error: `Unexpected response status: ${response.status}`
      };
    }
  } catch (error) {
    console.error('로그인 중 오류 발생:', error);
    incrementLoginFailure(); // 실패 카운트 증가
    return {
      success: false,
      error: error.response?.data || '로그인 실패하였습니다. 다시 시도해주세요.'
    };
  }
};
