export function getDaysDifference(createdAt) {
  if (!createdAt) return;
  const createdDate = new Date(createdAt);
  const currentDate = new Date();

  const timeDifference = currentDate - createdDate;
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

  return daysDifference;
}
