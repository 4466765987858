import React from 'react';
import ReactMarkdown from 'react-markdown';

function calculateBarHeights(value1, value2, maxHeight = 100) {
  const maxPercentage = Math.max(value1, value2);
  const height1 = (value1 / maxPercentage) * maxHeight;
  const height2 = (value2 / maxPercentage) * maxHeight;

  return [Math.round(height1), Math.round(height2)];
}

const SavingsReport = ({ data = {} }) => {
  const { plot = [], text = '', userName = '' } = data;
  const [height부부합산, height동일소득] = calculateBarHeights(
    plot[1],
    plot[2]
  );

  return (
    <div className="report-container">
      <section className="p-6 pb-4 flex flex-col">
        <div className="flex flex-col justify-between gap-6 px-6 py-5 report-shadow rounded-2xl">
          <div>
            <p className="text-base font-bold leading-[140%]">
              {userName} 님과 배우자의 저축률은
            </p>
            <p className="text-base font-bold leading-[140%]">
              동일 소득 구간 대비
              <span className="text-orange"> {plot[0]}</span>
            </p>
          </div>
          <div className="flex justify-center gap-6">
            <div className="flex flex-col justify-end items-center gap-[6px]">
              <p className="text-base font-bold leading-[140%] text-orange">
                {plot[1]}%
              </p>
              <div
                className="bg-bar-gradient w-9 rounded-[4px]"
                style={{ height: `${height부부합산}px` }}
              />
              <label className="text-xs font-medium leading-[140%]">
                부부합산
              </label>
            </div>
            <div className="flex flex-col justify-end items-center gap-[6px]">
              <p className="text-base font-bold leading-[140%] text-[#A9A8A7]">
                {plot[2]}%
              </p>
              <div
                className="bg-[#A9A8A7] w-9 h-25 rounded-[4px]"
                style={{ height: `${height동일소득}px` }}
              />
              <label className="text-xs text-[#A9A8A7] font-medium leading-[140%]">
                동일소득
              </label>
            </div>
          </div>
        </div>
      </section>
      <section className="w-full pt-4 px-[30px] pb-6">
        <p className="text-[16px] font-bold leading-[100%] mb-3">
          <span className="mr-[6px]">🐷</span>
          저축, 얼마나 잘하고 있을까요?
        </p>
        <ReactMarkdown className="text-[14px] font-normal leading-[24px]">
          {text}
        </ReactMarkdown>
      </section>
    </div>
  );
};

export default SavingsReport;
