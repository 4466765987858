import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const DonutChart = ({ plot = [], color = 'orange' }) => {
  const getGradient = (ctx, chartArea, color) => {
    const gradient = ctx.createLinearGradient(
      0,
      chartArea.top,
      0,
      chartArea.bottom
    );
    if (color === 'orange') {
      gradient.addColorStop(0, '#FF6319');
      gradient.addColorStop(1, '#FF900C');
    }
    if (color === 'purple') {
      gradient.addColorStop(0, '#793B8A');
      gradient.addColorStop(1, '#AA7EB2');
    }
    return gradient;
  };

  const data = {
    datasets: [
      {
        data: plot, // 데이터 값
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            return color === 'purple'
              ? ['#793B8A', '#EBEBEB']
              : ['#FF6319', '#EBEBEB'];
          }
          return [getGradient(ctx, chartArea, color), '#EBEBEB'];
        },
        hoverBackgroundColor:
          color === 'purple' ? ['#793B8A', '#EBEBEB'] : ['#FF6319', '#EBEBEB'], // hover 색상
        borderWidth: 0,
        cutout: '50%', // 도넛 두께 설정
        offset: [20, 0]
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false // 기본 범례 숨김
      },
      tooltip: {
        enabled: false // 툴팁 비활성화
      },
      datalabels: {
        display: false // 회색 숫자 숨김
      }
    },
    layout: {
      padding: {
        top: 20,
        bottom: 20
      }
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        width: '200px',
        height: '200px'
      }}
    >
      {/* 차트 */}
      <Doughnut data={data} options={options} />
      {/* 중앙 텍스트 */}
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-45%, -35%)',
          fontSize: '24px',
          fontWeight: 'bold',
          lineHeight: '100%',
          color: color === 'purple' ? '#793B8A' : '#FF6319'
        }}
      >
        {plot[0]}%
      </div>
    </div>
  );
};

export default DonutChart;
