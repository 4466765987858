import React from 'react';

const InvestmentPropensityFirstReport = () => {
  return (
    <div className="report-container">
      <section className="p-6 pb-2 flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <h2 className="text-xl leading-[140%]">
            나의 성향에 맞는 금융 상품,
            <br />
            어떻게 선택할까요?
          </h2>
          <p className="text-sm leading-[170%]">
            자신의 투자 성향을 파악하고, 어떤 상품이 적합한지 고민해보세요! 😊
            공격투자형일수록 위험성을 낮은 위험부터 높은 위험의 상품까지 폭넓게
            활용할 수 있습니다.
          </p>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2 pb-4 border-b border-[#EBEBEB]">
            <h3 className="text-orange text-sm font-bold leading-[100%]">
              안정형
            </h3>
            <p className="text-sm leading-[170%]">
              예금 또는 적금 수준의 수익률을 기대하며, 투자원금에 손실이
              발생하는 것을 원하지 않는 유형
            </p>
            <div className="flex items-center">
              <span className="p-[6px] border border-[#BFBEBD] rounded text-xs leading-[100%] text-[#959493] mr-[10px]">
                적합한 상품
              </span>
              <span className="text-sm font-bold leading-[100%]">
                매우 낮은 위험 / 은행 예적금, 국공채형 등
              </span>
            </div>
          </div>
          <div className="flex flex-col gap-2 pb-4 border-b border-[#EBEBEB]">
            <h3 className="text-orange text-sm font-bold leading-[100%]">
              안정추구형
            </h3>
            <p className="text-sm leading-[170%]">
              투자 원금 손실 위험은 최소화하되, 안정적인 이자 소득이나 배당
              소득을 목표로 하는 투자 성향. 수익을 위해 단기적인 손실은 감수할
              수 있으며, 예/적금보다 높은 수익률을 기대하며 자산의 일부를
              변동성이 있는 상품에 투자할 의지가 있는 유형
            </p>
            <div className="flex items-center">
              <span className="p-[6px] border border-[#BFBEBD] rounded text-xs leading-[100%] text-[#959493] mr-[10px]">
                적합한 상품
              </span>
              <span className="text-sm font-bold leading-[100%]">
                낮은 위험 / 채권형, 원금 보존형 ELF 등
              </span>
            </div>
          </div>
          <div className="flex flex-col gap-2 pb-4 border-b border-[#EBEBEB]">
            <h3 className="text-orange text-sm font-bold leading-[100%]">
              위험중립형
            </h3>
            <p className="text-sm leading-[170%]">
              투자에는 항상 일정한 위험이 따른다는 점을 잘 이해하고 있고,
              예/적금보다 높은 수익을 기대할 수 있다면 적정 수준의 손실 가능성도
              받아들일 수 있는 투자 성향
            </p>
            <div className="flex items-center">
              <span className="p-[6px] border border-[#BFBEBD] rounded text-xs leading-[100%] text-[#959493] mr-[10px]">
                적합한 상품
              </span>
              <span className="text-sm font-bold leading-[100%]">
                보통 위험 / 장기 주식펀드, ELS 등
              </span>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default InvestmentPropensityFirstReport;
