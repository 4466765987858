import React, { useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChartExample = ({
  plot = [],
  labels = ['주거용 대출', '기타 대출'],
  unit = '%',
  backgroundColor = ['#793B8A', '#FF6319'],
  hoverBackgroundColor = ['#6D327A', '#E8570F'],
  defaultActiveIndex = 1
}) => {
  const [activeIndex, setActiveIndex] = useState(defaultActiveIndex); // 초기 활성화: 기타 대출

  const data = {
    labels: labels,
    datasets: [
      {
        data: plot, // 데이터 값
        backgroundColor: backgroundColor, // 기본 색상
        hoverBackgroundColor: hoverBackgroundColor, // hover 색상
        borderWidth: 0, // 경계선 없음
        cutout: '50%' // 도넛 두께 설정
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false // 기본 범례 숨김
      },
      tooltip: {
        enabled: false // 툴팁 비활성화
      },
      datalabels: {
        display: false // 회색 숫자 숨김
      }
    },
    layout: {
      padding: {
        top: 20,
        bottom: 20
      }
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        setActiveIndex(elements[0].index); // 클릭한 Pie 조각의 인덱스 저장
      }
    }
  };

  const calculateOffset = (index) => {
    if (index === activeIndex) {
      return 20; // 활성화된 Pie 조각의 확대 효과
    }
    return 0; // 기본 상태
  };

  const updatedData = {
    ...data,
    datasets: [
      {
        ...data.datasets[0],
        offset: data.labels.map((_, index) => calculateOffset(index)) // 확대 효과 적용
      }
    ]
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'end',
        justifyContent: 'space-between'
      }}
    >
      {/* 라벨 */}
      <div
        style={{
          marginRight: '20px',
          textAlign: 'left',
          paddingBottom: '20px'
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            fontWeight: 'normal',
            color: backgroundColor[0],
            fontSize: '11px'
          }}
          onClick={() => setActiveIndex(0)}
        >
          <div
            style={{
              width: '8px',
              height: '8px',
              backgroundColor: backgroundColor[0],
              borderRadius: '50%',
              marginRight: '4px'
            }}
          />
          {labels[0]}
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '6px',
            cursor: 'pointer',
            fontWeight: 'normal',
            color: backgroundColor[1],
            fontSize: '11px'
          }}
          onClick={() => setActiveIndex(1)}
        >
          <div
            style={{
              width: '8px',
              height: '8px',
              backgroundColor: backgroundColor[1],
              borderRadius: '50%',
              marginRight: '4px'
            }}
          />
          {labels[1]}
        </div>
      </div>

      {/* 차트 */}
      <div style={{ position: 'relative', width: '200px', height: '200px' }}>
        <Doughnut data={updatedData} options={options} />
        {/* 활성화된 색깔 위에 텍스트 표시 */}
        {activeIndex !== null && (
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: activeIndex === 1 ? '10%' : '90%', // 기타: 오른쪽, 주거용: 왼쪽
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
              fontSize: '11px',
              fontWeight: 'bold',
              color:
                activeIndex === 0 ? backgroundColor[0] : backgroundColor[1],
              background: 'white', // 텍스트 배경색
              width: '76px', // 원형 배경 크기
              height: '76px', // 원형 배경 크기
              lineHeight: '76px', // 텍스트 수직 정렬
              borderRadius: '50%', // 원형 배경
              boxShadow: '0 4px 10px rgba(0,0,0,0.2)' // 그림자 효과
            }}
          >
            {activeIndex === 0
              ? `${labels[0].split(' ')[0]}\n${plot[0]}${unit}`
              : `${labels[1].split(' ')[0]}\n${plot[1]}${unit}`}
          </div>
        )}
      </div>
    </div>
  );
};

export default PieChartExample;
