import React from 'react';

const RetirementSecondReport = () => {
  return (
    <div className="report-container">
      <section className="px-[30px] py-6">
        <div>
          <div className="text-sm font-normal leading-[170%]">
            아마 이런 생각도 드실 거예요.
            <br />
            "아직 결혼도 안 했는데 은퇴 준비라니, 너무 이르지 않아?" 하지만
            복리의 효과를 누리려면 일찍 시작할수록 더 큰 효과를 볼 수 있어요!
            <br />
            <br />
            <ul>
              <li>지금부터 월 20만 원을 저축한다고 가정해볼게요.</li>
              <li>
                <strong>
                  연 5% 수익률로 복리 효과를 누리면 30년 후에는 무려 약 1억
                  6천만 원이 됩니다.
                </strong>
              </li>
              <li>
                만약 시작을 10년 미루면 같은 조건에서도 6천만 원밖에 되지
                않아요.
              </li>
            </ul>
            <br />
            지금이라도 나의 소득의 일정부분을 미래의 나에게 보낼 수 있도록 빨리
            시작한다면 시간이 내 편이 되어서 더 큰 자산을 만들 수 있어요.
            <br />
            <br />
            예비부부라면 앞으로 결혼, 집 마련, 자녀 계획 등 준비해야 할 일이
            많을 거예요. 하지만 이런 목표를 달성하면서도 미래를 함께 준비하는
            습관을 들이는 게 중요해요.
            <br />
            은퇴 준비는 시작하는 순간부터 가장 쉬워집니다. 지금 한 걸음씩
            준비하면 은퇴 후에도 여유롭고 행복한 삶을 누릴 수 있어요. 핀베리가
            여러분의 든든한 미래 은퇴 준비를 함께 도와드릴게요.
          </div>
        </div>
      </section>
    </div>
  );
};

export default RetirementSecondReport;
