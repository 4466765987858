import React from 'react';
import ReactMarkdown from 'react-markdown';

const AdditionalRetirementStrategiesReport = ({
  isForSale = false,
  data = {}
}) => {
  return (
    <div className="report-container">
      <section className="p-6 pb-4">
        <div className="flex flex-col gap-2">
          <h2 className="text-[16px] font-bold leading-[100%]">
            <span className="mr-[6px]">🔔</span>
            추가적인 은퇴 자금을 준비할 수 있는 방안은?
          </h2>
          <p className="text-[14px] font-normal leading-[170%]">
            주택 연금과 주택 다운사이징을 활용해 추가 생활비를 마련하는 방법을
            확인해보세요
          </p>
        </div>
      </section>
      {isForSale ? (
        <section className="p-6 pt-4">
          <div className="flex flex-col gap-8">
            <div className="flex flex-col gap-2">
              <h3 className="text-orange text-xs font-bold leading-[100%]">
                방안 1.
              </h3>
              <h4 className="font-bold leading-[100%]">주택 연금 활용하기</h4>
              <ReactMarkdown className="text-sm leading-[170%]">
                {data.text}
              </ReactMarkdown>
            </div>
            <div className="flex flex-col gap-2">
              <h3 className="text-orange text-xs font-bold leading-[100%]">
                방안 2.
              </h3>
              <h4 className="font-bold leading-[100%]">
                보유한 주택의 매매로 현금 흐름 확보하기
              </h4>
              <ReactMarkdown className="text-sm leading-[170%]">
                {data.text2}
              </ReactMarkdown>
            </div>
          </div>
        </section>
      ) : (
        <section className="p-6 pt-4">
          <div className="flex flex-col gap-8">
            <div className="flex flex-col gap-2">
              <h3 className="text-orange text-xs font-bold leading-[100%]">
                연금 활용 1.
              </h3>
              <h4 className="font-bold leading-[100%]">주택 연금 활용하기</h4>
              <p className="text-sm leading-[170%]">{data.text}</p>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default AdditionalRetirementStrategiesReport;
