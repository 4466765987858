import React from 'react';
import ReactDOM from 'react-dom';
import usePreventSwipe from '../../../hook/usePreventSwipe';
import BackHeader from '../../header/BackHeader';
import PercentProgressBar from '../component/PercentProgressBar';

const InvestReportPopup = ({ close }) => {
  const rootElement = document.getElementById('root'); // <div id="root"> 안에 렌더링
  usePreventSwipe('.popup-report-container'); // 터치 이동 방지 훅 호출

  return ReactDOM.createPortal(
    <div className="popup-report-container">
      <div className="px-4">
        <BackHeader goToPrev={close} className="flex items-center" />
      </div>
      <div className="p-6">
        <section className="w-full px-1 mb-6">
          <p className="text-[18px] font-bold leading-[100%] mb-3">
            <span className="mr-[6px]">📊</span>
            현금성 자산과 투자 자산의 균형
          </p>
          <p className="text-sm text-l inline font-bold leading-[24px]">
            현금성 자산과 투자 자산의 균형은 나이에 맞게 조정하는 게 중요해요.
          </p>
          <p className="pl-1 text-sm text-l inline leading-[24px]">
            쉽게 말해, 나이만큼의 비율(%)는 안전하게, 나머지는 조금 더 수익을
            기대할 수 있는 투자로 활용하는 방식이죠.
          </p>
        </section>
        <section className="flex flex-col gap-3 mb-6">
          <div className="px-6 py-5 report-shadow rounded-2xl flex flex-col gap-4">
            <p className="text-base font-bold leading-[140%]">
              20대의 자산 분배
            </p>
            <PercentProgressBar
              plot={[80, 20]}
              bg1="progress-gradient"
              bg2="#FFBE5C"
              labels={['투자자산', '현금성자산']}
            />
          </div>
          <div className="px-6 py-5 report-shadow rounded-2xl flex flex-col gap-4">
            <p className="text-base font-bold leading-[140%]">
              50대의 자산 분배
            </p>
            <PercentProgressBar
              plot={[40, 60]}
              bg1="progress-purple-gradient"
              bg2="#D8C0DA"
              labels={['투자자산', '현금성자산']}
            />
          </div>
        </section>
        <section className="px-1">
          <p className="text-left text-sm leading-[24px]">
            예를 들어, 30살이라면 30%는 예금이나 저축 같은 안전한
            곳에(안전자산), 70%는 주식이나 펀드처럼 수익을 기대할 수 있는 곳에
            투자(위험자산)하는 걸 의미해요.
          </p>
          <p className="text-left text-sm font-bold leading-[24px]">
            젊을 때는 조금 더 공격적으로,🌱 나이가 들수록 안전하게 투자하는 게
            핵심이에요! ✨
          </p>
        </section>
      </div>
    </div>,
    rootElement
  );
};

export default InvestReportPopup;
