import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import RightArrowIcon from '../../icon/RightArrowIcon';
import InvestReportPopup from '../popupReport/InvestReportPopup';
import ProgressLabelBar from '../component/ProgressLabelBar';

const InvestReport = ({ data = {} }) => {
  const { plot = [], text = '' } = data;
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => setIsPopupOpen(true);
  const closePopup = () => setIsPopupOpen(false);

  if (isPopupOpen) {
    return <InvestReportPopup close={closePopup} />;
  }

  return (
    <div className="report-container">
      <section className="w-full p-6 pb-4">
        <div className="px-6 py-5 report-shadow rounded-xl">
          {Math.abs(plot[0] - plot[1]) <= 2 ? (
            <div className="mb-4">
              <p className="text-base font-bold leading-[140%]">
                투자 자산과 현금성 자산의 균형을
              </p>
              <p className="text-base font-bold leading-[140%]">
                잘 유지하고 계시네요!
              </p>
            </div>
          ) : (
            <div className="mb-4">
              <p className="text-base font-bold leading-[140%]">
                투자 자산의 비율을
              </p>
              <p className="text-base font-bold leading-[140%]">
                <span className="text-orange">{plot[0]}%</span>까지{' '}
                {plot[0] > plot[1] ? '높여보세요!' : '낮춰주세요!'}
              </p>
            </div>
          )}

          <ProgressLabelBar
            plot={plot}
            bg1="progress-gradient"
            bg2="#FFE6B8"
            label="권장"
            labels={['투자자산', '현금성자산']}
          />
        </div>
      </section>
      <section className="px-6 py-3">
        <button
          onClick={openPopup}
          className="w-full px-4 py-[10px] flex items-center justify-between border border-[#E5E5E5] rounded-[10px] bg-[#F8F8F8]"
        >
          <p className="text-left text-sm font-medium leading-[24px] text-[#6F6D6C]">
            💡 왜 자산 비율을 조정해야 할까요?
          </p>
          <RightArrowIcon className="text-[#6F6D6C]" size={16} />
        </button>
      </section>
      <section className="w-full pt-4 px-[30px] pb-6">
        <p className="text-[16px] font-bold leading-[100%] mb-3">
          <span className="mr-[6px]">📊</span>
          현금성 자산과 투자 자산의 균형
        </p>
        <ReactMarkdown className="text-[14px] font-normal leading-[170%]">
          {text}
        </ReactMarkdown>
      </section>
    </div>
  );
};

export default InvestReport;
