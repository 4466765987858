import { faDeleteLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { useSetSurveyValue } from '../../hook/useSetSurveyValue';
import { surveyState } from '../../recoil/atoms';
import '../../style/NumberPad.css';
import { formatKoreanCurrency } from '../../util/formatKoreanCurrency';
// import { saveAnswer } from '../util/saveAnswer';
import SendMessageIcon from '../icon/SendMessageIcon';

export const Numberpad2 = ({
  keySelected,
  setForm,
  setIsNumberPadOn,
  isSelf,
  form
}) => {
  const [survey, setSurvey] = useRecoilState(surveyState);
  const [inputValue, setInputValue] = useState(survey[keySelected]);
  console.log(keySelected, survey[keySelected], form[keySelected]);
  const setSurveyValue = useSetSurveyValue();

  const handleConfirm = async () => {
    setSurveyValue(keySelected, inputValue);
    setForm((prev) => ({ ...prev, [keySelected]: inputValue }));
    setInputValue('');
    setIsNumberPadOn(false);
  };
  let suffix =
    keySelected === 'r_age' ||
    keySelected === 'r_selfAge' ||
    keySelected === 'r_spouseAge' ||
    keySelected === 'r_selfRetirementAge' ||
    keySelected === 'r_spouseRetirementAge'
      ? '세'
      : '원';

  let prefix =
    keySelected === 'r_age' ||
    keySelected === 'r_selfRetirementAge' ||
    keySelected === 'r_spouseRetirementAge'
      ? ' 만   '
      : keySelected === 'r_selfIncome' ||
          keySelected === 'r_spouseIncome' ||
          keySelected === 'r_selfSaving' ||
          keySelected === 'r_spouseSaving'
        ? ' 월   '
        : '';

  return (
    <div className="overlay">
      <div className="number-pad-container px-[13px]">
        <div className="result-window">
          <span className="prefix">{prefix}</span>
          <span className="value">
            {inputValue !== '' ? formatKoreanCurrency(inputValue) : '      '}
          </span>
          <span className="suffix">{suffix}</span>
          <button
            type="submit"
            className="confirm-button"
            onClick={handleConfirm}
            disabled={inputValue === ''}
          >
            <SendMessageIcon
              className={`confirm-icon ${inputValue !== '' ? 'text-orange' : 'text-gray'}`}
            />
          </button>
        </div>
        <div className="number-pad">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, '00', '0'].map((num) => (
            <button
              key={num}
              onClick={() => {
                setInputValue(
                  parseInt(inputValue + num.toString(), 10).toString()
                );
              }}
              className="number-pad-button"
            >
              {num}
            </button>
          ))}
          <button
            className="number-pad-button"
            onClick={() => {
              console.log('type: ', typeof inputValue);
              return setInputValue(Number(inputValue.toString().slice(0, -1)));
            }}
          >
            <FontAwesomeIcon icon={faDeleteLeft} />
          </button>
        </div>
      </div>
    </div>
  );
};
