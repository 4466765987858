import React from 'react';
import ReactDOM from 'react-dom';
import usePreventSwipe from '../../../hook/usePreventSwipe';
import BackHeader from '../../header/BackHeader';

const LTVPopup = ({ close }) => {
  const rootElement = document.getElementById('root'); // <div id="root"> 안에 렌더링
  usePreventSwipe('.popup-report-container'); // 터치 이동 방지 훅 호출

  return ReactDOM.createPortal(
    <div className="popup-report-container">
      <div className="px-4">
        <BackHeader goToPrev={close} className="flex items-center" />
      </div>
      <section className="p-6 pt-5 flex flex-col gap-6">
        <div>
          <h1 className="text-[18px] font-bold leading-[100%] mb-3">
            <span className="mr-[6px]">🛎️</span>
            주택담보인정비율(LTV)란?
          </h1>
          <p className="text-sm leading-[24px]">
            LTV는 Loan to Value의 약자로, 쉽게 말해 '집값 대비 대출 비율'이에요.
            예를 들어, 집값이 5억 원인데 LTV가 60%라면, 최대 3억 원(5억 ×
            60%)까지 대출을 받을 수 있다는 뜻이죠.
          </p>
        </div>
        <div>
          <h2 className="text-sm font-bold leading-[100%] text-orange">
            그럼 왜 LTV가 중요할까요?
          </h2>
          <p className="pt-2 pb-4 text-sm leading-[170%]">
            은행은 대출해준 돈이 안전하게 회수될 수 있는지 판단하기 위해 LTV를
            기준으로 대출 한도를 정해요. 집값이 5억인데 5억을 전부 대출해주면,
            집값이 떨어질 경우 은행도 위험해질 수 있겠죠? 그래서 LTV 비율을
            제한해둔 거예요.
            <br />
            전세 자금 대출의 경우, 해당 비율에 영향을 받을 수 있어요.
          </p>
        </div>
        <div>
          <h2 className="text-sm font-bold leading-[100%] text-orange">
            LTV는 어떻게 달라지나요?
          </h2>
          <p className="pt-2 pb-4 text-sm leading-[170%]">
            LTV 비율은 주택 가격, 주택 보유 여부, 지역, 나이 등에 따라 달라질 수
            있어요.
            <br />
            예를 들어, 주택 가격이 9억 원 이상인지 이하인지 또는 해당 주택이
            투기 지역이나 투기 과열지구에 위치해 있는지에 따라 비율이 달라질 수
            있습니다.
            <br />
            또한, 청년이나 예비 부부를 위한 특별 은행 상품에 따라 최대 LTV
            비율이 달라질 수도 있어요.
            <br />
            특히, 전세자금대출은 LTV 비율에 따라 준비해야 할 현금 금액이
            달라지므로, 입주를 원하는 주택의 LTV를 미리 알아두면 입주 계획을
            세우는 데 큰 도움이 될 거예요!
          </p>
        </div>
      </section>
    </div>,
    rootElement
  );
};

export default LTVPopup;
