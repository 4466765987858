import ktorApi from '../api/ktorApi';
import { useSetSurveyValue } from './useSetSurveyValue';

export const useFetchReportCashInvestment = () => {
  const setSurveyValue = useSetSurveyValue();
  const sendCashInvestmentRequest = async ({ orderId }) => {
    try {
      const response = await ktorApi.post('/cash_investment', {
        orderId
      });
      if (response.data) {
        setSurveyValue('r_report_cash_investment', response.data || {});
      } else {
        console.error('Empty response data');
      }
    } catch (error) {
      console.error('Error fetching report selection:', error);
    }
  };

  return { sendCashInvestmentRequest };
};
