import { faDeleteLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { useAddUserMessage } from '../../hook/useAddUserMessage';
import { useFetchCarList } from '../../hook/useFetchCarList';
import { useMoveToNextQuestion } from '../../hook/useMoveToNextQuestion';
import { useMoveToTheQuestion } from '../../hook/useMoveToTheQuestion';
import { useSetNumberpadValue } from '../../hook/useSetNumberpadValue';
import { useSetSurveyValue } from '../../hook/useSetSurveyValue';
import { surveyState } from '../../recoil/atoms';
import '../../style/NumberPad.css';
// import { saveAnswer } from '../util/saveAnswer';
import { useSaveAnswer } from '../../hook/useSaveAnswer';
import SendMessageIcon from '../icon/SendMessageIcon';

export const Numberpad = ({ selectedItem, isSelf, setIsNumberpadOn }) => {
  const [survey, setSurvey] = useRecoilState(surveyState);
  const { r_allQuestions, r_idx, r_sessionId, r_userId, r_surveyId } = survey;
  const currentQuestion =
    r_allQuestions && r_allQuestions.length > 0 && r_allQuestions[r_idx];
  const questionType = currentQuestion.questionType;
  const addUserMessage = useAddUserMessage();
  const moveToNextQuestion = useMoveToNextQuestion();
  const moveToTheQuestion = useMoveToTheQuestion();
  const setSurveyValue = useSetSurveyValue();
  const setNumberpadValue = useSetNumberpadValue();
  const fetchCarList = useFetchCarList();
  const saveAnswer = useSaveAnswer();
  const selectedKey = `r_${isSelf ? 'self' : 'spouse'}${selectedItem.name}`;
  console.log('selectedKey', selectedKey);
  const [inputValue, setInputValue] = useState(survey[selectedKey]);
  console.log(selectedItem);
  const prefix =
    questionType === 'AGE_INPUT' || questionType === 'YEAR_INPUT'
      ? ' 만   '
      : questionType === 'YEAR_AFTER'
        ? ' 약   '
        : '';
  const suffix =
    selectedItem.name === 'Principal'
      ? '   원'
      : selectedItem.name === 'RepaymentTerm' ||
          selectedItem.name === 'GracePeriod' ||
          selectedItem.name === 'RepaymentStart'
        ? '   년'
        : selectedItem.name === 'AnnualRate'
          ? '   %'
          : '';

  const handleConfirm = async () => {
    setSurveyValue(selectedKey, inputValue);
    setIsNumberpadOn(false);

    // if (
    //   (await setNumberpadValue(
    //     currentQuestion.questionId,
    //     inputValue,
    //     setInputValue
    //   )) === 0
    // ) {
    //   console.log('currentQuestion.questionId:', currentQuestion.questionId);
    //   saveAnswer(currentQuestion.questionId, inputValue, null);
    //   const newMessage =
    //     questionType === 'MONEY_INPUT'
    //       ? formatKoreanCurrency(inputValue) + ' 원'
    //       : questionType === 'YEAR_AFTER'
    //         ? '약 ' +
    //           (parseInt(inputValue) > 100 ? '100' : inputValue) +
    //           '년 후'
    //         : questionType === 'AGE_INPUT' &&
    //             (currentQuestion.questionId ===
    //               config.SPOUSE_RETIREMENT_AGE_IDX ||
    //               currentQuestion.questionId === config.SELF_RETIREMENT_AGE_IDX)
    //           ? '만 ' + (parseInt(inputValue) > 80 ? '80' : inputValue) + '세'
    //           : questionType === 'AGE_INPUT'
    //             ? '만 ' +
    //               (parseInt(inputValue) > 100 ? '100' : inputValue) +
    //               '세'
    //             : inputValue;
    //   addUserMessage({ optionText: newMessage });
    //   if (
    //     currentQuestion.questionId ===
    //       config.SELF_HOUSE_LOAN_MONTHLY_PAYMENT_IDX ||
    //     currentQuestion.questionId === config.SELF_HOUSE_LOAN_INTEREST_IDX_2 ||
    //     currentQuestion.questionId ===
    //       config.SELF_MONTHLY_PAY_AMOUNT_WO_LOAN_IDX ||
    //     currentQuestion.questionId === config.SELF_JEONSE_AMONT_WO_LOAN_IDX
    //   ) {
    //     console.log('HEY:  ', currentQuestion.questionId);
    //     moveToTheQuestion(config.SPOUSE_HOUSE_LOAN_YN_IDX);
    //   } else if (
    //     currentQuestion.questionId ===
    //       config.SPOUSE_HOUSE_LOAN_MONTHLY_PAYMENT_IDX ||
    //     currentQuestion.questionId ===
    //       config.SPOUSE_HOUSE_LOAN_INTEREST_IDX_2 ||
    //     currentQuestion.questionId ===
    //       config.SPOUSE_MONTHLY_PAY_AMOUNT_WO_LOAN_IDX ||
    //     currentQuestion.questionId === config.SPOUSE_JEONSE_AMONT_WO_LOAN_IDX
    //   ) {
    //     console.log('HEY:  ', currentQuestion.questionId);
    //     moveToTheQuestion(config.SELF_ETC_DEBT_IDX);
    //   } else if (currentQuestion.questionId === config.CAR_PRICE_IDX) {
    //     fetchCarList(inputValue);
    //     moveToNextQuestion();
    //   } else if (currentQuestion.questionId === config.CHILDREN_AGE_IDX) {
    //     console.log('survey.r_selectedPurchase: ', survey.r_selectedPurchase);
    //     if (
    //       survey.r_selectedPurchase[survey.r_selectedPurchase.length - 1]
    //         .orderIndex !== 4
    //     ) {
    //       moveToTheQuestion(config.END_IDX - 1);
    //     } else {
    //       moveToNextQuestion();
    //     }
    //   } else {
    //     moveToNextQuestion();
    //   }
    //   setInputValue('');
    // }
  };
  return (
    <div className="number-pad-container px-[13px] absolute bottom-0">
      <div className="result-window">
        <span className="prefix">{prefix}</span>
        <span className="value">{inputValue}</span>
        <span className="suffix">{suffix}</span>
        <button
          type="submit"
          className="confirm-button"
          onClick={handleConfirm}
          disabled={inputValue === ''}
        >
          <SendMessageIcon
            className={`confirm-icon ${inputValue !== '' ? 'text-orange' : 'text-gray'}`}
          />
        </button>
      </div>
      <div className="number-pad">
        {[
          1,
          2,
          3,
          4,
          5,
          6,
          7,
          8,
          9,
          selectedItem.name === 'Principal'
            ? '00'
            : selectedItem.name === 'AnnualRate'
              ? '.'
              : '',
          '0'
        ].map((num) => (
          <button
            key={num}
            onClick={() => {
              if (selectedItem.name === 'Principal') {
                setInputValue(
                  parseInt(inputValue + num.toString(), 10).toString()
                );
              } else if (selectedItem.name === 'AnnualRate' && num === '.') {
                if (!inputValue.includes('.')) {
                  setInputValue(inputValue.toString() + num);
                }
              } else {
                setInputValue(
                  parseFloat(inputValue + num.toString()).toString()
                );
              }
            }}
            className="number-pad-button"
          >
            {num}
          </button>
        ))}
        <button
          className="number-pad-button"
          onClick={() => setInputValue(inputValue.toString().slice(0, -1))}
        >
          <FontAwesomeIcon icon={faDeleteLeft} />
        </button>
      </div>
    </div>
  );
};
