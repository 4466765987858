import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import BackHeader from '../component/header/BackHeader';
import ReactMarkdown from 'react-markdown';
import { config } from '../config';

const PolicyPage = () => {
  const location = useLocation();
  const [policyContent, setPolicyContent] = useState('');
  const [policyTitle, setPolicyTitle] = useState('');
  const policies = [...config.DEFAULT_POLICY, ...config.NOTICE_POLICY];

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');

    if (id) {
      const policy = policies.find((item) => item.id === Number(id));

      if (policy) {
        setPolicyTitle(policy.label);
        fetch(encodeURI(policy.path), {
          method: 'GET',
          headers: {
            'Cache-Control': 'no-cache'
          }
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error('Failed to load policy content');
            }
            return response.text();
          })
          .then((text) => setPolicyContent(text))
          .catch((error) => console.error('Error fetching policy:', error));
      }
    }
  }, [location]);

  return (
    <div className="relative max-w-md w-full h-[100dvh] overflow-hidden pb-12">
      <header className="sticky z-50 border-b border-[#ECECEC] text-black flex justify-center items-center">
        <div className="absolute left-0">
          <BackHeader className="flex items-center px-4" />
        </div>
        <h1 className="header-title font-bold leading-[28px] py-[10px]">
          {policyTitle || '이용약관'}
        </h1>
      </header>

      <div className="p-6 whitespace-pre-line text-black text-xs font-normal leading-[24px] overflow-y-auto h-full">
        <ReactMarkdown>{policyContent}</ReactMarkdown>
      </div>
    </div>
  );
};

export default PolicyPage;
