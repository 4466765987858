import React from 'react';
import { Tab, TabList } from '@headlessui/react';

export const PreviousAnswerTab = ({ setIsSelf }) => {
  const handleTabSwitch = (self) => {
    setIsSelf(self);
  };

  return (
    <TabList className="w-full justify-between items-center inline-flex">
      <Tab
        onClick={() => handleTabSwitch(true)}
        className="w-full px-6 py-3 outline-none text-[#82817F] border-b-2 border-[#EBEBEB] text-base font-semibold leading-[18px] tracking-[-0.078px] data-[selected]:text-black data-[selected]:border-orange"
      >
        나의 프로필
      </Tab>
      <Tab
        onClick={() => handleTabSwitch(false)}
        className="w-full px-6 py-3 outline-none text-[#82817F] border-b-2 border-[#EBEBEB] text-base font-semibold leading-[18px] tracking-[-0.078px] data-[selected]:text-black data-[selected]:border-orange"
      >
        배우자 프로필
      </Tab>
    </TabList>
  );
};
