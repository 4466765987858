import React from 'react';

const FinalFirstReport = () => {
  return (
    <div className="report-container">
      <section className="p-6 flex flex-col gap-5">
        <div>
          <h2 className="text-xl leading-[140%]">
            더 나은 오늘,
            <br />더 탄탄한 내일을 위한 재무 관리 방법은?
          </h2>
        </div>
        <div className="flex flex-col gap-[18px]">
          <p className="text-sm leading-[170%]">
            안정적이고 건강한 재무 상태를 유지하기 위해서는 주기적인 점검과
            관리가 필수입니다. 재무 상태는 고정된 것이 아니라 소득, 지출, 투자,
            대출 등 여러 요인에 따라 변화하기 때문에, 꾸준히 점검하며 목표에
            맞게 잘 움직이고 있는지 확인해야 해요!
          </p>
          <div>
            <p className="text-sm leading-[170%] font-bold">
              ✅ 단기적으로 체크해야 할 것 (분기마다)
            </p>
            <ul className="text-sm leading-[170%]">
              <li>월 저축과 소비 패턴이 계획대로 유지되고 있는지 점검</li>
              <li>투자 포트폴리오를 재평가하고 목표에 맞게 조정</li>
              <li>분기 및 연간 저축 목표 달성 여부 확인</li>
            </ul>
          </div>
          <div>
            <p className="text-sm leading-[170%] font-bold">
              ✅ 장기적으로 체크해야 할 것 (1년마다)
            </p>
            <ul className="text-sm leading-[170%]">
              <li>대출 상환 계획을 검토하고 추가 상환 가능성 확인</li>
              <li>주택, 차량, 자녀 교육비 등 중장기 목표 달성 현황 확인</li>
              <li>은퇴 자금 목표에 맞게 자산이 잘 쌓이고 있는지 점검</li>
              <li>예상치 못한 경제적 리스크에 대비할 수 있는 안전망 구축</li>
            </ul>
          </div>
          <p className="text-sm leading-[170%]">
            재무 계획은 한 번 세우고 끝나는 것이 아니라, 끊임없이 점검하고
            상황에 따라 유연하게 조정해야 합니다. 작은 습관과 꾸준한 점검이
            쌓이면, 더 큰 재무적 안정과 목표 달성이 가능해집니다.
            <br />
            핀베리와 함께 주기적인 점검을 통해 현재를 점검하고 미래를
            대비하세요. 핀베리는 언제나 여러분의 곁에서 건강한 재무 관리를
            지원할 수 있도록 다양한 서비스를 준비중입니다. 앞으로 많은 관심
            부탁드릴게요 😊✨
          </p>
        </div>
      </section>
    </div>
  );
};

export default FinalFirstReport;
