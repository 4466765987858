import React, { useMemo } from 'react';
import ReactDOM from 'react-dom';
import usePreventSwipe from '../../../hook/usePreventSwipe';
import BackHeader from '../../header/BackHeader';

const AssetFlowPopup = ({ close }) => {
  const rootElement = document.getElementById('root'); // <div id="root"> 안에 렌더링
  usePreventSwipe('.popup-report-container'); // 터치 이동 방지 훅 호출

  const data = useMemo(() => {
    return [
      {
        title: '물가 및 임금 상승률',
        text: '2010~2023년 한국은행 데이터를 참고'
      },
      {
        title: '지출 계산',
        text: '‘소득 - 저축 = 지출’ 공식 기반'
      },
      {
        title: '은퇴 이후 소득',
        text: '국민연금 예상 수령액을 반영하여 소득 산출'
      },
      {
        title: '투자 수익률',
        text: '투자 자산 수익률은 3%에 변동성 0.02를 적용한 몬테카를로 시뮬레이션(불확실한 사건의 가능한 결과를 장기 예측하는 통계적 기법)을 사용'
      },
      {
        title: '대출',
        text: '목표로 설정한 시기에 자동차, 아파트 구매를 가정하고  부족한 자산은 대출로 보충이 되도록 설정'
      },
      {
        title: '자녀 양육 비용',
        text: '자녀 양육에 따른 연령대별 지출 자료를 바탕으로 계산'
      }
    ];
  }, []);

  return ReactDOM.createPortal(
    <div className="popup-report-container">
      <div className="px-4">
        <BackHeader goToPrev={close} className="flex items-center" />
      </div>
      <div className="p-6 pt-5 flex flex-col gap-6">
        <section>
          <h1 className="text-[18px] font-bold leading-[100%] mb-3">
            <span className="mr-[6px]">📈</span>
            자산 흐름 그래프는 어떻게 계산되었나요?
          </h1>
          <p className="text-sm leading-[24px]">
            자산 흐름 그래프는 입력된 저축 목표를 100% 달성했을 때의 총 순자산
            변화를 보여줍니다. 순자산은 자산에서 부채를 제외해 계산되었으며,
            예측에는 다음 요소들이 반영되었습니다.
          </p>
        </section>
        <section className="flex flex-col gap-4">
          {data.map((item, index) => {
            return (
              <div key={index} className="flex flex-col gap-2 mb-4">
                <h2 className="text-sm leading-[100%] text-orange font-bold">
                  {item.title}
                </h2>
                <p className="text-sm leading-[170%]">{item.text}</p>
              </div>
            );
          })}
        </section>
      </div>
    </div>,
    rootElement
  );
};

export default AssetFlowPopup;
