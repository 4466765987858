import ktorApi from '../api/ktorApi';
export const useSendConfirmCode = () => {
  const sendConfirmCode = async (phoneNumber, url = '/aligo/send') => {
    const response = await ktorApi.post(url, {
      phoneNum: phoneNumber
    });
    return response.data;
  };
  return sendConfirmCode;
};
